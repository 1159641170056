import * as React from "react";
import { Col, Button } from "react-bootstrap";
import styled from "styled-components";
import { useIntl } from "react-intl";
import Loader from "@Elements/Loader";

const Wrapper = styled.div<{active: boolean}>`
  border: ${props => props.active ? `1px solid ${props.theme.colors.accent}` : props.theme.colors.border};
  border-radius: 4px;
  padding: 30px;
`;

const Desc = styled.p`
  color: #858C97;
  line-height: 18px;
  font-size: 13px;
`;

const ContentWrapper = styled.div`
  margin-top: 40px;
`;

const ButtonWrapper = styled.div`
  margin-top: 60px;
  display: flex;
`;

const NextButton = styled(Button)`
  margin-left: auto;
`;

const H2 = styled.h2`
  margin-bottom: 0;
  &::first-letter {
    font-size: 36px;
  }
`;

const LoaderWrap = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  svg {
    height: 30px;
    width: 30px;
  }
`;

interface StepProps{
  active: {frame: boolean, forward: boolean};
  step: number;
  title: string;
  desc: string;
  actions: {next?: () => void; back?: () => void; finish?: boolean;};
  processing?: boolean;
  twin?: boolean;
}

const Step = ({children, active, step, title, desc, actions, processing, twin}: React.PropsWithChildren<StepProps>) => {
  
  const {next, back, finish} = actions;
  const {frame, forward} = active;

  const { formatMessage } = useIntl();
  
  return(
    <Col lg={4}>
      <Wrapper active={frame}>
        <H2>
          {`${step} ${title}`}
        </H2>
        <Desc>
          {desc}
        </Desc>
        <ContentWrapper>
          {children}
        </ContentWrapper>
        <ButtonWrapper>
          {back && <Button onClick={back} disabled={!frame}>{formatMessage({id: "back", defaultMessage: "Tilbake"})}</Button>}
          {next && <NextButton onClick={next} disabled={!forward || !frame}>{formatMessage({id: twin ? "add" : "next", defaultMessage: twin ? "Add" : "Neste"})}</NextButton>}
          {processing ? <LoaderWrap><Loader/></LoaderWrap> : finish && <NextButton type="submit" disabled={!forward || !frame}>{formatMessage({id: "add", defaultMessage: "Add"})}</NextButton>}
        </ButtonWrapper>
      </Wrapper>
    </Col>
  )
}

export default Step;