import {call, put, select, takeLatest} from "redux-saga/effects";
import * as Routines from "../routines";
import * as Api from '../api';
import {AppAction} from "../../../DefaultSettings/Store/types";
import {UserWithCustomer} from "../../Models/UserWithCustomer";
import {Role} from "../../Models/Role";
import {Customer, CustomerWithChildren} from "../../Models/Customer";
import {LdapUser} from "../../Models/LdapUser";
import {mapLdapUsers} from "../../Users/utils";
import {DeletedUser} from "../../Models/User";
import {accessToCustomer} from "../../../Policy/rightsUtil";
import {PolicyNameEnum} from "../../../PolicyNameEnum";
import {feedbackFailure} from "../../../Feedback/reducer";
import { SiteTypeEnum } from "../../../SiteTypeEnum";
import { selectCurrentContext, selectCustomers } from "@Safemate/Store/selectors";
import { ICustomerWithPolicy } from "@Safemate/Model/Customer";
import { GlobalRoles } from "@Safemate/GlobalRoles";

export interface Initialize{
  siteType: SiteTypeEnum;
}

function* initialize(action: AppAction<Initialize>){
  const currentContext: SiteTypeEnum = yield select(selectCurrentContext);
  try {
    if(currentContext !== SiteTypeEnum.AHP){

      const customers: ICustomerWithPolicy[] = yield select(selectCustomers);


      for(let i = 0; i < customers.length; i++){
        if(accessToCustomer(customers[i].policies, PolicyNameEnum.DELETED_USERS, [GlobalRoles.SUPERFIRM])){
          yield put(Routines.initializeDeletedUsers());
          break;
        }
      }

      yield put(Routines.initializeCustomers());
      yield put(Routines.initializeLdapUsers());
    }

    yield put(Routines.initializeRoles());
    yield put(Routines.initializeUsers());
    yield put(Routines.initialize.success());
  }
  catch(e){
    yield put(Routines.initialize.failure());
  }
}

function* initializeUsers(action: AppAction<SiteTypeEnum>){
  const currentContext: SiteTypeEnum = yield select(selectCurrentContext);
  try{
    const users: Array<UserWithCustomer> = yield call(Api.getUsers, currentContext);
    yield put(Routines.initializeUsers.success(users));
  }
  catch(e){
    feedbackFailure(e);
    yield put(Routines.initializeUsers.failure());
  }
}

function* initializeDeletedUsers(action: AppAction<null>){
  try{
    const deletedUsers: Array<DeletedUser> = yield call(Api.getDeletedUsers)
    yield put(Routines.initializeDeletedUsers.success(deletedUsers))
  }
  catch(e){
    feedbackFailure(e);
    yield put(Routines.initializeDeletedUsers.failure());
  }
}

function* initializeLdapUsers(action: AppAction<any>){
  try{
    const ldapUsers: Array<LdapUser> = yield call(Api.getLdapUsers);
    yield put(Routines.initializeLdapUsers.success(mapLdapUsers(ldapUsers)));
  }
  catch(e){
    feedbackFailure(e);
    yield put(Routines.initializeLdapUsers.failure());
  }
}

function* initializeRoles(action: AppAction<any>){
  try{
    const roles: Array<Role> = yield call(Api.getRoles);
    yield put(Routines.initializeRoles.success(roles));
  }
  catch(e){
    feedbackFailure(e);
    yield put(Routines.initializeRoles.failure());
  }
}

function* initializeCustomers(action: AppAction<string>){
  try{
    const customerHierarchy: CustomerWithChildren = yield call(Api.getHierarchy)
    yield put(Routines.initializeCustomers.success(customerHierarchy));
  }
  catch(e){
    feedbackFailure(e);
    yield put(Routines.initializeCustomers.failure());
  }
}

function* initializeAdminRole(action: AppAction<null>){
  try{
    const admin: Role = yield call(Api.getAdminRole);
    yield put(Routines.initializeAdminRole.success(admin));
  }
  catch(e){
    feedbackFailure(e);
    yield put(Routines.initializeAdminRole.failure());
  }
}


function* initializationSaga(){
  yield takeLatest(Routines.initialize.TRIGGER, initialize);
  yield takeLatest(Routines.initializeUsers.TRIGGER, initializeUsers);
  yield takeLatest(Routines.initializeDeletedUsers.TRIGGER, initializeDeletedUsers);
  yield takeLatest(Routines.initializeLdapUsers.TRIGGER, initializeLdapUsers);
  yield takeLatest(Routines.initializeRoles.TRIGGER, initializeRoles);
  yield takeLatest(Routines.initializeCustomers.TRIGGER, initializeCustomers);
  yield takeLatest(Routines.initializeAdminRole.TRIGGER, initializeAdminRole);
  yield takeLatest(Routines.deleteUser.FULFILL, initializeDeletedUsers);
}

export default initializationSaga;
