import {formatDate} from "../../utils";
import GoogleUtils from "../../googleUtils";
import {Transaction} from "../Store/types";
import {TransactionSource} from "../TransactionSourceEnum";
import {DeviceHardwareEnum, isGx8} from "../../DeviceHardwareEnum";
import React from "react";
import  Loader  from "@Elements/Loader";
import SignalBars from "../../DeviceList/DeviceList/signalBars";
import { IDevice } from "@Safemate/Model/Device";
import { MapType } from "..";



//101 failed self check 105 reset
//102
//103
//106 107 reset
//7
//10 low batteri reset 112

export const prepareTransaction = async (tx: Transaction ) => {
  if(tx.tranSource === TransactionSource.SERVER){
    tx.serverEvent = true;
    tx.isAdvGeofence = tx.metyId === 102 || tx.metyId === 103;
  }
  
  if( tx.metyId === 6 || tx.metyId === 7 || tx.metyId === 10 || tx.metyId == 11 || tx.metyId == 37 || tx.metyId == 38  
    || tx.metyId == 45 || tx.metyId === 101 || tx.metyId === 102 || tx.metyId === 103 || tx.metyId === 105
    || tx.metyId === 106|| tx.metyId === 107 || tx.metyId === 111 || tx.metyId === 112 || tx.metyId === 63 || tx.metyId === 115 || tx.containsSubtypes
  ){
    //if the device_tx metyId matches any of these we colour them blue. metyId Descriptions are found in the texts table. metydesc
    tx.isNotifyEvent = true;
  }
  if(tx.alarmType && tx.tranSource === TransactionSource.HISTORICAL){
    tx.alarmType = false;
  }
  if(!tx.addressLookupDone){
    tx.addressLookupDone = false;
  }
  setPoorPosition(tx);
  setGpsQualityNumSat(tx, true);
  setGpsQualityHdop(tx, true);
}

export const setPoorPosition = (tx: Transaction) => {
  tx.poorPositionDisplayed = false;
}

export const setGpsQualityNumSat = (tx: Transaction, toplovoGpsSelfValidation: boolean) => {
  const toplovo = tx.isToplovo;
  const gps = (tx.posType === 0 && tx.direction == 'I');
  if( tx.hasPoorPosition && tx.poorPositionDisplayed  && tx.numSatellites > 0){
    tx.gpsQualityNumSat = tx.numSatellites;
  } else if(toplovoGpsSelfValidation && toplovo && gps){
    if(tx.numSatellites && isNaN(tx.numSatellites) && tx.numSatellites > 0){
      tx.gpsQualityNumSat = tx.numSatellites;
    } 
    else{
      tx.gpsQualityNumSat = "OK";
    }
  } else if(gps && tx.numSatellites > 0) {
    tx.gpsQualityNumSat = tx.numSatellites;
  }
}

export const setGpsQualityHdop= (tx: Transaction, toplovoGpsSelfValidation: boolean) => {
  if( tx.dekmId > 0 || tx.inloId > 0 || tx.metyId === 2 || tx.metyId === 18 ){
    tx.gpsQualityNumSat = null;
    tx.numSatellites = 0;
    return;
  }
  const toplovo = tx.isToplovo;
  const gps = (tx.posType === 0 && tx.direction == 'I');
  if( tx.hasPoorPosition && tx.poorPositionDisplayed && tx.hdop > 0 ){
    tx.gpsQualityHdop = tx.hdop;
  } else if(toplovoGpsSelfValidation && toplovo && gps) {
    if (tx.hdop && !isNaN(tx.hdop) && tx.hdop > 0) {
      tx.gpsQualityHdop = tx.hdop;
    } else {
      tx.gpsQualityHdop = "OK";
    }
    tx.gpsQualityHdop = "OK";
  } else if(gps && tx.hdop > 0) {
    tx.gpsQualityHdop = tx.hdop;
  }
};

export const getLocations = async (txs: Transaction[], render: boolean) => {
  for(let i = 0; i < txs.length; i++){
    await getLocation(txs[i],render);
  }
}

export const getLocation = async (tx: Transaction, render: boolean) => {

  if(tx.posType == 0){
    await getLocationAll(tx, render);
  }else if( tx.hasPoorPosition && tx.poorPositionDisplayed ){
    await getLocationAll(tx, render);
  }
  else{
    tx.address = "";
    tx.addressLookupDone = true;
    if( !tx.numSatellites ){
      tx.numSatellites = 0;
    }
  }
}

export const getLocationAll = async (tx: Transaction, render: boolean) => {
  if(tx.posType === 7){
    tx.address = "";
    tx.addressLookupDone = true;
    return;
  }
  if(!tx.address){
    tx.address = tx.posLati ? `${tx.posLati}<br>${tx.posLong}` : "";
    tx.addressLookupDone = false;
  }
  if(!tx.addressLookupDone && tx.posLati && tx.posLong){
    try{
      const address = await GoogleUtils.addressLookup(parseFloat(tx.posLati), parseFloat(tx.posLong), tx.dekmId, tx.inloId);
      if(address){
        tx.address = address.replace(/,/g, '<br>');
      } else{
        tx.address = tx.posLati ? `${tx.posLati}<br>${tx.posLong}` : "";
      }
      tx.addressLookupDone = true;
      render = !render;
      // tx.posTimeDiff = (tx.sysTimeUTC - tx.posTime > 60000) ? tx.posTime
    }
    catch(e){
      tx.address = tx.posLati ? `${tx.posLati}<br>${tx.posLong}` : "";
      tx.addressLookupDone = true;
      render = !render;
    }
    
  }
}

export const mapHeader = (formatMessage: any, dehaId: DeviceHardwareEnum, mapType: MapType, displaySensor: boolean = true) => {
  return[
    {value: formatMessage({id: "transactionId", defaultMessage: "Transaksjons-id"}).toUpperCase(), display: true, priority: 2},
    {value: formatMessage({id: "time", defaultMessage: "TID"}).toUpperCase(), display: true},
    {value: formatMessage({id: "deviceName", defaultMessage: "Enhetsnavn"}).toUpperCase(), display: mapType === MapType.ALL},
    {value: formatMessage({id: "msgType", defaultMessage: "MELDING"}).toUpperCase(), display: true},
    {value: formatMessage({id: "comm", defaultMessage: "KOMM."}).toUpperCase(), display: true, priority: 2},
    {value: formatMessage({id: "sensor", defaultMessage: "SENSOR"}).toUpperCase(), display: isGx8(dehaId) && displaySensor},
    {value: formatMessage({id: "position", defaultMessage: "POSISJON"}).toUpperCase(), display: !isGx8(dehaId)},
    {value: formatMessage({id: "sat", defaultMessage: "SAT"}).toUpperCase(), display: !isGx8(dehaId), priority: 3},
    {value: formatMessage({id: "gsm", defaultMessage: "GSM"}).toUpperCase(), display: !isGx8(dehaId), priority: 3},
    {value: formatMessage({id: "battery", defaultMessage: "BATTERI"}).toUpperCase(),  display: !isGx8(dehaId), priority: 3}
  ]
}

export const mapTransaction = (transaction: Transaction, formatMessage: any, dehaId: number, devices: IDevice[], mapType: MapType, displaySensor: boolean = true) => {
  const metyId: number =  transaction.metyId === 5 && transaction.hasPoorPosition ? 62 : transaction.metyId;
  return[
    {value: transaction.id, display: true, priority: 2},
    {value: formatDate(transaction.recTs, true, false, true, false, false, formatMessage), display: true},
    {value: devices.length > 0 ? devices.find(device => device.deviId === transaction.deviId).deviceName : "", display: mapType === MapType.ALL},
    {value: formatMessage({id: `metyDesc${metyId}`, defaultMessage: `metyDesc${metyId}`}), display: true},
    {value: TransactionSource[transaction.tranSource], display: true, priority: 2},
    {value: 
      transaction.sensorName 
        ? `${transaction.sensorName} (${transaction.sensorId})`
        : transaction.sensorId
          ? transaction.sensorId
          : transaction.gsmGeo
      , display: isGx8(dehaId) && displaySensor},
    {
      value: 
        transaction.posType === 7 
          ? ""
          : transaction.addressLookupDone 
            ? transaction.hasPoorPosition && !transaction.poorPositionDisplayed 
              ? "poorPosition" 
              : <span dangerouslySetInnerHTML={{__html: transaction.address 
                ? transaction.address 
                : transaction.posLati && transaction.posLong && + transaction.posLati + "<br>" + transaction.posLong}} /> 
            : transaction.posLati 
              ? <Loader/> 
              : '',
      display: !isGx8(dehaId),
      pdf: `${transaction.address}`.replace(/<br>/gi, "\n").trim(),
      csv: `${transaction.address}`.substring(0, `${transaction.address}`.indexOf("<br>"))
    },
    {value: transaction.gpsQualityNumSat === "OK" ? formatMessage({id: "OK", defaultMessage: "OK"}) : transaction.gpsQualityNumSat, display: !isGx8(dehaId), priority: 3},
    {value: transaction.gsmStrength > 0 ? <SignalBars dehaId={transaction.dehaId ? transaction.dehaId : dehaId} size={30} strength={transaction.gsmStrength} /> : transaction.gpsQualityHdop === "OK" ? formatMessage({id: "OK", defaultMessage: "OK"}) : transaction.gpsQualityHdop, display: (!isGx8(dehaId) || transaction.gsmStrength > 0), priority: 3},
    {value: transaction.direction === "I" && transaction.battery > 0 ? `${transaction.battery}%` : "", display: !isGx8(dehaId), priority: 3}
  ]
}

export enum ExportType{
  CSV,
  PDF
}

export const getExportData = (type: ExportType, transactions: Transaction[], formatMessage: any, dehaId: DeviceHardwareEnum, beta: boolean, filteredTransactions: Transaction[]): string[][] => {
  const exportData = [];
  const header = mapHeader(formatMessage, dehaId, beta);
  exportData.push(header.map(item => {
    if(item.display){
      return item.value || ""
    }
  }).filter(item => item !== undefined));

  transactions.forEach((transaction) => {
    exportData.push(mapTransaction(transaction, formatMessage, dehaId, beta, filteredTransactions).map(item => {
      if(item.display){
        if(type === ExportType.PDF && item.pdf !== undefined){
          return item.pdf || "";
        }
        else if(type === ExportType.CSV && item.csv !== undefined){
          return item.csv || "";
        }
        return item.value || "";
      }
    }).filter(item => item !== undefined));
  })
  return exportData;
}
