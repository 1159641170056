import withFormWrapper from "@Elements/Form/formWrapper";
import { Radio } from "@Elements/RadioFormik";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import { Capabilities } from "@Safemate/Settings/Store/types";
import * as React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import SimpleDevice from "../Model/SimpleDevice";
import { RootState } from "../Store/types";
import { EventSetupForm } from "./types";
import { useDefault } from "./utils";
import { AppState } from "@Safemate/Store/types";

interface CallTypeSelectorProps{
  capability: Capabilities;
  selectedDevice: SimpleDevice;
}

const mapStateToProps = ({ eventSetup: {capability, device: { selectedDevice }}}: AppState) => {
  return {
    capability,
    selectedDevice
  }
}

const CallTypeSelector = connect(mapStateToProps)(
  withFormWrapper<EventSetupForm, CallTypeSelectorProps>(({ capability, selectedDevice, formik: { values: { sequentialCalling, eventSetups }, setFieldValue }} ) => {

  const { formatMessage } = useIntl();
  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  if(!permissions.get(PolicyNameEnum.CONTROL_CALLING).generalPermissions.all 
    || !eventSetups.find(setup => setup.email === null) 
    || !capability.callGsm
    || useDefault(selectedDevice)){
    return null;
  }

  return(
    <div style={{ float: 'left' }}>
      <Radio
        name="sequentialCalling"
        radioValue={false}
        value={sequentialCalling}
        onChange={(value: React.SyntheticEvent<HTMLInputElement>) => setFieldValue("sequentialCalling", false)}
        label={formatMessage({id: "parallelCalling", defaultMessage: "Parallel Calling"})}
      />
      <Radio
        name="sequentialCalling"
        radioValue={true}
        value={sequentialCalling}
        onChange={(value: React.SyntheticEvent<HTMLInputElement>) => setFieldValue("sequentialCalling", true)}
        label={formatMessage({id: "sequentialCalling", defaultMessage: "Sequential Calling"})}
        />
    </div>
  )
}))

export default CallTypeSelector;