import {call, put, all, takeLatest, take} from 'redux-saga/effects';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import { feedbackFailure, feedbackSuccess } from '../../../Feedback/reducer';
import * as Api from "../api";
import SimpleDevice from '@Safemate/EventSetupNew/Model/SimpleDevice';
import { getDevices as getDevicesSelector } from "../selectors";


function* getDevices(action: AppAction<null>){
  try{
    const devices: SimpleDevice[] = yield call(Api.getDevices);
    yield put(Routines.getDevices.success(devices));
  }
  catch(e){
    yield put(Routines.getDevices.failure());
    console.log(e)
  }
  yield put(Routines.getDevices.fulfill());
}

function* setDevice(action: AppAction<SimpleDevice>){
  try{
    const { deviId } = action.payload;

    yield put(Routines.getCapabilities(deviId));
    yield put(Routines.getDefaultAhp(deviId));
    yield put(Routines.getDeviceEventSetups(deviId));
    yield put(Routines.getPolicies(deviId));

    yield all([
      take(Routines.getCapabilities.FULFILL),
      take(Routines.getDefaultAhp.FULFILL),
      take(Routines.getDeviceEventSetups.FULFILL),
      take(Routines.getPolicies.FULFILL),
    ])
  }
  catch(e){
    yield put(Routines.setDevice.failure());
  }
  yield put(Routines.setDevice.fulfill());
}

export interface UpdateName{
  deviceId: number;
  deviceName: string;
}

function* updateName(action: AppAction<UpdateName>){
  try{
    const {deviceId, deviceName} = action.payload;
    yield call(Api.updateName, deviceId, deviceName);

    yield put(Routines.updateName.success(deviceName));
    yield feedbackSuccess("deviceNameUpdated");
  }
  catch(e){
    yield put(Routines.updateName.failure());
    yield feedbackFailure(e);
  }
}

export interface UpdateOverride{
  deviceId: number;
  value: boolean;
}

function* updateOverride(action: AppAction<UpdateOverride>){
  try{
    const {deviceId, value} = action.payload;
    const response: boolean = yield call(Api.updateOverride, deviceId, value);

    yield put(Routines.updateOverride.success(response));
    yield put(Routines.getDeviceEventSetups.trigger(deviceId));
    yield feedbackSuccess("deviceOverrideUpdated");
  }
  catch(e){
    console.log(e);
    yield put(Routines.updateOverride.failure());
    yield feedbackFailure(e);
  }
}

function* deviceSaga(){
    yield takeLatest(Routines.getDevices.TRIGGER, getDevices);
    yield takeLatest(Routines.setDevice.TRIGGER, setDevice);
    yield takeLatest(Routines.updateName.TRIGGER, updateName);
    yield takeLatest(Routines.updateOverride.TRIGGER, updateOverride);
}

export default deviceSaga;
