import ArrowLeft from "@Elements/Icon/Icons/arrows/ArrowLeft";
import ArrowRight from "@Elements/Icon/Icons/arrows/ArrowRight";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { setDevice } from "../Store/routines";
import { RootState } from "../Store/types";
import SimpleDevice from "../Model/SimpleDevice";
import { AppState } from "@Safemate/Store/types";

const SwitchWrapper = styled.div`
  border: 1px solid #C9C6C1;
  background: ${props => props.theme.colors.backgroundPrimary};
  display: inline-flex;
  min-width: 100%;
`;

export const SwitchButton = styled.button`
  background-color: ${props => props.theme.colors.backgroundPrimary};
  height: 38px;
  opacity: 1;
  padding: 0 12px;
  svg {
    margin-top: 0px;
    height: 2em;
    width: 2em;
  }

`;



const LeftButton = styled(SwitchButton)`
  border-right: ${props => props.theme.colors.border};
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
`;

const RightButton = styled(SwitchButton)`
  border-left: ${props => props.theme.colors.border};
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  flex-align: end;
`;

const H2 = styled.h2`
  background: ${props => props.theme.colors.backgroundPrimary};
  height: 38px;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 0 25px;
`;

const mapDispatchToProps = {
  setDevice
}

const mapStateToProps = ({ eventSetup: {device: { selectedDevice: { deviceSNNum, deviId }, devices}}}: AppState) => {
  return {
    deviceSNNum,
    deviId,
    devices
  }
}

interface DeviceSelectorProps{
  deviceSNNum: string;
  deviId: number;
  devices: SimpleDevice[];
  setDevice: Routine<ActionFunctionAny<Action<any>>>;
}

const DeviceSelector = connect(mapStateToProps, mapDispatchToProps)(({ deviceSNNum, deviId, devices, setDevice }: DeviceSelectorProps) => {

  const current = devices.findIndex(device => device.deviId === deviId);

  const decrement = () => {
    if(current > 0){
      setDevice(devices[current - 1]);
    }
  }

  const increment = () => {
    if(current < devices.length - 1){
      setDevice(devices[current + 1]);
    }
  }

  return(
    <SwitchWrapper>
      <LeftButton onClick={decrement} className="btn"><ArrowLeft/></LeftButton>
      <H2>{deviceSNNum}</H2>
      <RightButton onClick={increment} className="btn"><ArrowRight/></RightButton>
    </SwitchWrapper>
  )
})

export default DeviceSelector;