import {takeLatest, put, race, take, all } from 'redux-saga/effects';
import { AppAction, IRace } from '@Safemate/Store/types';
import * as Routines from "../routines";
import { feedbackFailure } from '../../../Feedback/reducer';
import * as Api from "../api";
import SimpleDevice from '@Safemate/EventSetupNew/Model/SimpleDevice';
import { connectBaseStation } from '@Safemate/Settings/Store/routines';

interface Initialize{
  deviceId: number;
}

function* initialize(action: AppAction<Initialize>){
  try{

    yield put(Routines.getDevices());

    const {success: { payload: devices }, failure}: IRace<SimpleDevice[]> = yield race({
      success: take(Routines.getDevices.SUCCESS),
      failure: take(Routines.getDevices.FAILURE)
    })

    if(devices && devices.length > 0){
      const selectedDevice = devices.find(device => device.deviId == action.payload.deviceId);
      yield put(Routines.setDevice(selectedDevice));
    }

    yield put(Routines.getAhpPrivateLink());
  }
  catch(e){
    console.log(e)
  }
  yield put(Routines.initialize.fulfill());
}

function* initializationSaga(){
    yield takeLatest(Routines.initialize.TRIGGER, initialize);
}

export default initializationSaga;
