import {call, put, takeLatest, select} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import { OrgValues } from '../../Organisation';
import { feedbackSuccess, feedbackFailure } from '../../../Feedback/reducer';
import { Feedback } from '../../labels';
import { RootState } from '../types';

function* fetchOrganisation(action: AppAction<number>){
  try {
    const organisations = yield call(Api.getOrganisation, action.payload);
    yield put(Routines.fetchOrganisation.success(organisations));
  }
  catch(e){
    yield put(Routines.fetchOrganisation.failure());
  }
}

function* saveOrganisation(action: AppAction<OrgValues>){
  try{
    const response = yield call(Api.saveOrganisation, action.payload);
    yield put(Routines.saveOrganisation.success(response));
    const { deviId } = action.payload;
    yield put(Routines.initialize.trigger({deviceId: deviId}));
    yield feedbackSuccess(Feedback.SUBORG_CHANGED);
  }
  catch(e){
    console.log(e);
    yield feedbackFailure(e);
    yield put(Routines.saveOrganisation.failure());
  }
}

function* organisationSaga(){
    yield takeLatest(Routines.fetchOrganisation.TRIGGER, fetchOrganisation);
    yield takeLatest(Routines.saveOrganisation.TRIGGER, saveOrganisation);
}

export default organisationSaga;
