import * as React from "react";
import GoogleMap, {Maps} from 'google-map-react';
import {Row} from "react-bootstrap";
import styled from "styled-components";
import {GeofenceWrapper} from "./GeofenceWrapper";
import {Geofence, } from "@Safemate/Settings/Geofence/types";
import withFormWrapper from "@Elements/Form/formWrapper";
import {GeofenceTypeEnum} from "@Safemate/Settings/Geofence/GeofenceTypeEnum";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {GeofenceSettings} from "@Safemate/DefaultSettings/Settings/types";
import {Location} from "@Safemate/DefaultSettings/Store/types";
import { AppState } from "@Safemate/Store/types";
import { IndoorLocation } from "@Safemate/Settings/IndoorLocation/types";
import { connect } from "react-redux";


const MapWrapper = styled(Row)`
  height: 40em;
  padding: 0px 5px;
  position: relative;
`;

const Search = styled.input`
  width: 15%;
  height: 40px;
  color: #CACACB;
  margin-left: 6px;
  margin-top: 6px;
  background: black;
  opacity: 0.4;

  border: 1px solid #36393e;
  border-radius: 4px;
  outline: none;
  padding: 0 15px;

  transition: width 1s, opacity 1s;
  -webkit-transition: width 1s, opacity 1s;
  -moz-transition: width 1s, opacity 1s;
  -ms-transition: width 1s, opacity 1s;
  -o-transition: width 1s, opacity 1s;

  &:hover {
    opacity: 0.6;
  }
  &:focus {
    opacity: 0.8;
    width: 33%;
  }
`;

interface MapProps{
  initialFences: Geofence[];
  geofenceType: GeofenceTypeEnum;
  languageCode: string;
  geofencesIndex: number;
  setGeofencesIndex: Function;
  setAddState: Function;
  customerLocation: null | Location
  customerIndoorLocations: IndoorLocation[]
}

const mapStateToProps = ({ appData: { user: { languageCode }}, defaultSettings: { settings: {customerLocation, customerIndoorLocations, geofenceSettings: { geofences }} } }: AppState) => {
  return{
    customerLocation,
    customerIndoorLocations,
    initialFences: geofences,
    languageCode
  }
}

export const Map = connect(mapStateToProps)(withFormWrapper<GeofenceSettings, MapProps>(
  ({formik: { setFieldValue, values: { geofences } }, initialFences, geofenceType, languageCode,  geofencesIndex, setGeofencesIndex,  setAddState, customerIndoorLocations, customerLocation}) => {

    const searchRef = React.useRef<HTMLInputElement>(null);

    const [ mapLoaded, setMapLoaded ] = React.useState(false);
    const [ inloIdslength, setInloIdslength ] = React.useState(0);

    let geofenceWrapper: GeofenceWrapper = React.useMemo(() => {
      return new GeofenceWrapper(setFieldValue, geofencesIndex, setGeofencesIndex, {geofence: geofences[geofencesIndex]});
    }, [])

    React.useEffect(() => {
      if( mapLoaded ){
        console.log(initialFences);
        geofenceWrapper.setInitialFences(initialFences);
        geofenceWrapper.setFences(initialFences);
        geofenceWrapper.updateValues({geofence: geofences[geofencesIndex]});
        geofenceWrapper.select(geofences[geofencesIndex].id);
        geofenceWrapper.resetFences();
        geofenceWrapper.fitBounds();
      }

    }, [initialFences, customerLocation])

    React.useEffect(() => {
      geofenceWrapper.setIndoorLocations(customerIndoorLocations);
    }, [customerIndoorLocations])

    React.useEffect(() => {
      if( mapLoaded ){
        if(geofenceType !== GeofenceTypeEnum.NONE && geofenceType !== GeofenceTypeEnum.INDOOR && mapLoaded){
          geofenceWrapper.getDrawingManager().setOptions({
            drawingMode : geofenceType
          });
        } else if(geofenceType === GeofenceTypeEnum.INDOOR && mapLoaded){
          setFieldValue(`geofences.${geofencesIndex}.vertices`, null);
          geofenceWrapper.disableDrawingManager();
        }
      }
    }, [geofenceType])

    React.useEffect(() => {
      if( mapLoaded ){
        if(geofences[geofencesIndex].id < 1){
          geofenceWrapper.disableDrawingManager();
        }
        geofenceWrapper.setIndex(geofencesIndex);
        geofenceWrapper.setFences(geofences);
        geofenceWrapper.updateValues({geofence: geofences[geofencesIndex]});
        geofenceWrapper.select(geofences[geofencesIndex].id);
        geofenceWrapper.resetFences();
        if(mapLoaded){
            geofenceWrapper.fitBounds();
        }
      }
    }, [geofencesIndex ])

    React.useEffect(() => {
      if( mapLoaded ){
        geofenceWrapper.selectIndoorMarker(geofences[geofencesIndex].inloIds);
        if( inloIdslength !== geofences[geofencesIndex].inloIds.length ){
          setInloIdslength(geofences[geofencesIndex].inloIds.length);
          geofenceWrapper.fitBounds();
        }
      }
    }, [geofences[geofencesIndex].inloIds])

    React.useEffect(() => {
      if( mapLoaded ){
        geofenceWrapper.toggleEnable();
      }
    }, [geofences[geofencesIndex].enabled])

    return(
      <MapWrapper>
        <GoogleMap 
          defaultZoom={14} 
          defaultCenter={ customerLocation ? customerLocation : { lat: 59.5058648, lng: 9.1907483 }}
          bootstrapURLKeys={{key: 'AIzaSyAQxbShd7veF5B0YU9O_uYPOQFCOHoe8no', language: languageCode}}
          options={(map: Maps) => {
            return {
              streetViewControl: true,
              mapTypeControl: true,
              maxZoom: 18,
              mapTypeControlOptions: {
                mapTypeIds: [
                  map.MapTypeId.ROADMAP,
                  map.MapTypeId.SATELLITE,
                  map.MapTypeId.TERRAIN
                ]
              }
            }
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({map, maps}) => {
            geofenceWrapper.init(map, maps, initialFences);
            geofenceWrapper.createFences(geofences);
            geofenceWrapper.select(geofences[geofencesIndex].id);
            geofenceWrapper.resetFences();
            if(searchRef.current){
              geofenceWrapper.setSearchBox(searchRef.current);
            }
            setMapLoaded(true);
            if(geofenceType !== GeofenceTypeEnum.NONE && geofenceType !== GeofenceTypeEnum.INDOOR && mapLoaded){
              geofenceWrapper.getDrawingManager().setOptions({
                drawingMode : geofenceType
              });
            } else if(geofenceType === GeofenceTypeEnum.INDOOR && mapLoaded){
              geofenceWrapper.disableDrawingManager();
            }
            geofenceWrapper.setAddStateFunction(setAddState);
            geofenceWrapper.fitBounds();
          }}
        />
        <Search ref={searchRef}/>
      </MapWrapper>
  )}
))