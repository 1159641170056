export enum AlarmFilterEnum{
  ALARM = "alarm",
  GEOFENCE = "geofence",
  BATTERY = "battery",
  STATIC = "static",
  SELFCHECK = "selfcheck",
  OUTDATEDPOSITION = "outdatedposition",
  OFFLINE = "offline",
  UNKNOWN = "unknown",
  CLEAR = "clear",
  TECHNICAL = "technical",
  OK = "ok"
}
