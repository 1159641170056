export type Header = {
  text: string;
  priority?: number;
  align?: string;
  export?: boolean;
}



export const header: Array<Header> = [
  { text: "name" },
  { text: "phone", priority: 2 },
  { text: 'trained'},
  { text: 'email', priority: 2 },
  { text: 'role'},
  {
    text: 'active',
    align: 'center'
  },
  {
    text: 'lastVisit',
    priority: 2
  },
  { text: 'subOrgs'},
  { text: 'thAction', export: false}
]

export const partnerHeader: Array<Header> = [
  { text: "name" },
  { text: "phone", priority: 2 },
  { text: 'email', priority: 2 },
  {
    text: 'active',
    align: 'center'
  },
  {
    text: 'lastVisit',
    priority: 2
  },
  { text: 'subOrgs'},
  { text: 'thAction', export: false}
]

