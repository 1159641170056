import { AppAction } from "../../../Safemate/Store/types";
import { AlarmFilterEnum } from '../AlarmFilterEnum';
import { OrderFilterEnum } from '../OrderFilterEnum';
import { DeviceListActionTypes } from './actionTypes';

export type FilterDeviceType = (devicetypes: number[]) => AppAction<number[]>;
export type FilterCustomer = (customers: number[]) => AppAction<number[]>;
export type FilterAlarm = (alarmfilters: AlarmFilterEnum[]) => AppAction<AlarmFilterEnum[]>;
export type FilterSearch = (search: string) => AppAction<string>;
export type FilterOrder = (order: OrderFilterEnum) => AppAction<OrderFilterEnum>;
export type ToggleInfo = (device: number) => AppAction<number>;

export const filterDeviceType: FilterDeviceType = (deviceTypes) => ({
  type: DeviceListActionTypes.FILTER_DEVICE_TYPE,
  payload: deviceTypes
})

export const filterCustomer: FilterCustomer = (customers) => ({
  type: DeviceListActionTypes.FILTER_CUSTOMER,
  payload: customers
})

export const filterAlarm: FilterAlarm = (alarmfilters) => ({
  type: DeviceListActionTypes.FILTER_ALARM,
  payload: alarmfilters
})

export const filterSearch: FilterSearch = (search) => ({
  type: DeviceListActionTypes.FILTER_SEARCH,
  payload: search
})

export const filterOrder: FilterOrder = (order) => ({
  type: DeviceListActionTypes.FILTER_ORDER,
  payload: order
})

export const toggleInfo: ToggleInfo = (deviceId) => ({
  type: DeviceListActionTypes.TOGGLE_INFO,
  payload: deviceId
})