import * as React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { Checkbox } from "@Elements/CheckboxFormik";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import Ahp from "../Model/Ahp";
import { setAhp } from "../Store/routines";
import { AppState } from "@Safemate/Store/types";

interface AhpProps{
  defaultAhp: Ahp;
  ahpId: number;
  deviId: number;
  setAhp: Routine<ActionFunctionAny<Action<any>>>;
  isPrivate: boolean;
}

const mapStateToProps = ({ appData: {isPrivate}, eventSetup: {ahp: { defaultAhp }, device: { selectedDevice: { ahpId, deviId }}}}: AppState) => {
  return {
    defaultAhp,
    ahpId,
    deviId,
    isPrivate
  }
}

const mapDispatchToProps = {
  setAhp
}

const SetAhp = connect(mapStateToProps, mapDispatchToProps)(({ isPrivate, defaultAhp, ahpId, deviId, setAhp }: AhpProps) => {
  
  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
  const { formatMessage } = useIntl();

  if(isPrivate || !permissions.get(PolicyNameEnum.ALARM_CENTER).generalPermissions.all || !defaultAhp){
    return null;
  }

  return(
    <Checkbox
      label={`${formatMessage({id: "connectToSOS", defaultMessage: "Connect unit to AHP"})} ${defaultAhp.name}`}
      value={ahpId}
      id="ahp-checkbox"
      onChange={() => {
        setAhp({
          deviId,
          ahpId: ahpId ? 0 : defaultAhp.ahpId
        })
      }}
    />
  )
}) 

export default SetAhp;