import {Reducer} from "redux";
import {CustomerState} from "../types";
import {initializeCustomers} from "../routines";

const customerState: CustomerState = {
  customerHierarchy: null
};

const customer: Reducer<CustomerState> = (state = customerState, action) => {
  switch (action.type) {
    case initializeCustomers.SUCCESS:
      return{
        ...state,
        customerHierarchy: action.payload
      }

    default:
      return state;
  }
};

export default customer;
