import { DeviceState } from "../types";
import {Reducer} from "redux";
import { getDevices, setAhp, setDevice, updateName, updateOverride } from "../routines";

const deviceState: DeviceState = {
  devices: [],
  selectedDevice: null,
  selectingDeviceLoad: false
};

const device: Reducer<DeviceState> = (state: DeviceState = deviceState, action) => {
  switch (action.type) {

    case getDevices.SUCCESS:
      return {
        ...state,
        devices: action.payload
      }

    case setDevice.TRIGGER:
      return {
        ...state,
        selectedDevice: action.payload,
        selectingDeviceLoad: true
      }

    case setDevice.FULFILL:
      return {
        ...state,
        selectingDeviceLoad: false
      }

    case updateName.SUCCESS:
      const newName = {
        ...state.selectedDevice,
        deviceName: action.payload
      }
      return {
        ...state,
        devices: state.devices.map(device => {
          if(device.deviId === newName.deviId){
            return newName;
          }
          return device;
        }),
        selectedDevice: newName,
      }

    case updateOverride.SUCCESS:
      const newOverride = {
        ...state.selectedDevice,
        overrideDefaultEventSetup: action.payload
      }    
      return {
        ...state,
        devices: state.devices.map(device => {
          if(device.deviId === newOverride.deviId){
            return newOverride;
          }
          return device;
        }),
        selectedDevice: newOverride
      }

    case setAhp.SUCCESS:
      const newAhp = {
        ...state.selectedDevice,
        ahpId: action.payload.ahpId
      }
      return {
        ...state,
        devices: state.devices.map(device => {
          if(device.deviId === newAhp.deviId){
            return newAhp;
          }
          return device;
        }),
        selectedDevice: newAhp
      }

    default:
      return state;
  }
};

export default device;
