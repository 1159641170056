import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import * as React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import withFormWrapper from "@Elements/Form/formWrapper";
import confirmModal from "@Safemate/Modals/confirmModal";
import { Capabilities } from "@Safemate/Settings/Store/types";
import HeartDotted from "../Icons/HeartDotted";
import HeartEmpty from "../Icons/HeartEmpty";
import HeartFull from "../Icons/HeartFull";
import HeartRight from "../Icons/HeartRight";
import DeviceEventSetup from "../Model/DeviceEventSetup";
import { setDeviceEventSetupModal } from "../Store/routines";
import { RootState } from "../Store/types";
import { EventSetupForm } from "./types";
import { ModalEnum } from "./utils";
import { Grid, Row } from "react-bootstrap";
import { Checkbox } from "@Elements/CheckboxFormik";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import { HeartModalSelectRow } from "./GridStyles";
import { NextOfKinCheckWrapper, NextOfKinDescWrapper, NextOfKinDivWrapper } from "./WrapperStyles";
import Binocular from "../Icons/Binocular";
import Schedule from "../Icons/Schedule";
import Phone from "../Icons/Phone";
import Photo from "../Icons/Photo";
import { AppState } from "@Safemate/Store/types";
import SimpleDevice from "@Safemate/EventSetupNew/Model/SimpleDevice";

interface HeartSelectorProps{
  capability: Capabilities;
  modalType: ModalEnum;
  modalEventSetupIndex: number;
  selectedDevice: SimpleDevice;
  selectedEventSetup: DeviceEventSetup;
  setDeviceEventSetupModal: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToHeartSelectorProps = ({ eventSetup: {deviceEventSetup: { selectedEventSetup, modalType, modalEventSetupIndex }, capability , device: { selectedDevice }}}: AppState) => {
  return {
    capability,
    selectedEventSetup,
    modalType,
    modalEventSetupIndex,
    selectedDevice
  }
}

const mapDispatchToProps = {
  setDeviceEventSetupModal
}

interface HeartSelectorState{
  familyMember: boolean;
  familyMemberTracking: boolean;
  familyMemberCalling: boolean;
  familyMemberImage: boolean;
  familyMemberSchedule: boolean;
}

export const HeartSelector = connect(mapStateToHeartSelectorProps, mapDispatchToProps)(
  withFormWrapper<EventSetupForm, HeartSelectorProps>(({ formik: { setFieldValue }, capability, modalType, selectedEventSetup, modalEventSetupIndex, setDeviceEventSetupModal, selectedDevice }) => {
  const [ family, setFamily ] = React.useState<HeartSelectorState>({
    familyMember: false,
    familyMemberTracking: false,
    familyMemberCalling: false,
    familyMemberImage: false,
    familyMemberSchedule: false
  })
  
  const { formatMessage } = useIntl();
  const { Comp: Modal, func: displayModal } = confirmModal();
  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  React.useEffect(() => {
    if(modalType === ModalEnum.NEXT_OF_KIN){
      displayModal(true);
      setFamily({
        familyMember: selectedEventSetup.familyMember,
        familyMemberTracking: selectedEventSetup.familyMemberTracking,
        familyMemberCalling: selectedEventSetup.familyMemberCalling,
        familyMemberImage: selectedEventSetup.familyMemberImage,
        familyMemberSchedule: selectedEventSetup.familyMemberSchedule
      });
    }
    else{
      displayModal(false);
    }
  }, [modalType, selectedEventSetup])

  const confirm = () => {
    setFieldValue(`eventSetups.${modalEventSetupIndex}`,{
      ...selectedEventSetup,
      ...family
    });
    closeModal();
  }

  const closeModal = () => {
    setDeviceEventSetupModal({
      selectedEventSetup: null,
      modalType: ModalEnum.NONE,
      modalEventSetupIndex: 0
    })
  }

  return(
    <Modal
      buttonAlign="center"
      standardBtn
      disableClose
      cancelFunc={closeModal}
      confirmFunc={confirm}
      confirmText={formatMessage({id: "ok", defaultMessage: "Ok"})}
      title={formatMessage({id: 'nextOfKin', defaultMessage: 'Next-of-Kin'})}
      body={
        <Grid fluid>
          <Row>
            <Checkbox
              value={family.familyMember}
              id="family-checkbox"
              label={formatMessage({id: "isNextOfKin", defaultMessage: "Denne mottakeren er en pårørende."})}
              onChange={() => {
                setFamily({
                  ...family,
                  familyMember: !family.familyMember
                })
              }}
            />
          </Row>
          <HeartModalSelectRow
            id="heart-active"
            hasRight={permissions.get(PolicyNameEnum.NEXT_OF_KIN_CHECK).generalPermissions.all}
            tabIndex={0}
            onClick={() => {
              if (permissions.get(PolicyNameEnum.NEXT_OF_KIN_CHECK).generalPermissions.all && !family.familyMember) {
                setFamily({
                  ...family,
                  familyMember: true
                })
              }
            }}
            onKeyDown={event => {
              if (event.key === 'Enter' && permissions.get(PolicyNameEnum.NEXT_OF_KIN_CHECK).generalPermissions.all && !family.familyMember) {
                setFamily({
                  ...family,
                  familyMember: true
                })
              }
            }}
            selected={family.familyMember}
          >
            <div>
              {formatMessage({
                id: 'nextOfKinDesc',
                defaultMessage: 'How do you want to involve next-of-kin?'
              })}
            </div>
            {capability.positioning &&
              <NextOfKinDivWrapper disabled={selectedDevice.positionDisabled}>
                <NextOfKinCheckWrapper>
                  <Checkbox
                    id="next-of-kin-tracking"
                    value={family.familyMemberTracking}
                    disabled={!permissions.get(PolicyNameEnum.NEXT_OF_KIN_TRACKING).generalPermissions.all || selectedDevice.positionDisabled}
                    onChange={() => {
                      setFamily({
                        ...family,
                        familyMemberTracking: !family.familyMemberTracking
                      })
                    }}
                  />
                  <Binocular className="icon" />
                </NextOfKinCheckWrapper>
                <div>
                  <NextOfKinDescWrapper>
                    <span>
                      {formatMessage({
                        id: 'nextOfKinTrackingTitle',
                        defaultMessage: 'Tracking'
                      })}
                    </span>
                    <small>
                      {formatMessage({
                        id: 'nextOfKinTracking',
                        defaultMessage:
                          'Allows next-of-kin to track Safemate.'
                      })}
                    </small>
                    {
                      selectedDevice.positionDisabled &&
                      <small>
                        {formatMessage({
                          id: 'nextOfKinTrackingPositionDisabled',
                          defaultMessage:
                            'Positioning has been disabled.'
                        })}
                      </small>
                    }
                  </NextOfKinDescWrapper>
                </div>
              </NextOfKinDivWrapper>}
              <NextOfKinDivWrapper disabled>
                <NextOfKinCheckWrapper>
                  <Checkbox
                    id="next-of-kin-schedule"
                    value={false}
                    disabled={true}
                    onChange={() => {
                      setFamily({
                        ...family,
                        familyMemberSchedule: !family.familyMemberSchedule
                      })
                    }}
                  />
                  <Schedule className="icon" />
                </NextOfKinCheckWrapper>
                <NextOfKinDescWrapper>
                  <span>
                    {formatMessage({
                      id: 'nextOfKinScheduleTitleDeprecated',
                      defaultMessage: 'Tilgjengelighet (Ikke lengre støttet)'
                    })}
                  </span>
                  <small>
                    {formatMessage({
                      id: 'nextOfKinSchedule',
                      defaultMessage:
                        'Allows next-of-kin to manage their own schedule.'
                    })}
                  </small>
                  <small>
                    {formatMessage({
                      id: 'nextOfKinScheduleDeprecated',
                      defaultMessage:
                        'Støtte for å la pårørende styre egen tilgjengelighet er fra mars 2024 fjernet.'
                    })}
                  </small>
                </NextOfKinDescWrapper>
              </NextOfKinDivWrapper>
              {capability.callGsm && 
                <NextOfKinDivWrapper>
                  <NextOfKinCheckWrapper>
                    <Checkbox
                      id="next-of-kin-calling"
                      value={family.familyMemberCalling}
                      disabled={!permissions.get(PolicyNameEnum.NEXT_OF_KIN_CALL).generalPermissions.all}
                      onChange={() => {
                        setFamily({
                          ...family,
                          familyMemberCalling: !family.familyMemberCalling
                        })
                      }}
                    />
                    <Phone className="icon" />
                  </NextOfKinCheckWrapper>
                  <NextOfKinDescWrapper>
                    <span>
                      {formatMessage({
                        id: 'nextOfKinCallingTitle',
                        defaultMessage: 'Call Safemate'
                      })}
                    </span>
                    <small>
                      {formatMessage({
                        id: 'nextOfKinCalling',
                        defaultMessage: 'Allows next-of-kin to call device.'
                      })}
                    </small>
                  </NextOfKinDescWrapper>
                </NextOfKinDivWrapper>
                }
              <NextOfKinDivWrapper>
                <NextOfKinCheckWrapper>
                  <Checkbox
                    id="next-of-kin-image"
                    disabled={!permissions.get(PolicyNameEnum.NEXT_OF_KIN_IMAGE).generalPermissions.all}
                    value={family.familyMemberImage}
                    onChange={() => {
                      setFamily({
                        ...family,
                        familyMemberImage: !family.familyMemberImage
                      })
                    }}
                  />
                  <Photo className="icon" />
                </NextOfKinCheckWrapper>
                <NextOfKinDescWrapper>
                  <span>
                    {formatMessage({
                      id: 'nextOfKinImageTitleInfo',
                      defaultMessage: 'Upload Safemate user image'
                    })}
                  </span>
                  <small>
                    {formatMessage({
                      id: 'nextOfKinImage',
                      defaultMessage:
                        'Allows next-of-kin to manage their own image.'
                    })}
                  </small>
                </NextOfKinDescWrapper>
              </NextOfKinDivWrapper>
          </HeartModalSelectRow>
        </Grid>
      }
    />
  )
}))

interface HeartProps{
  eventSetup: DeviceEventSetup;
  capability: Capabilities;
}

const mapStateToProps = ({ eventSetup: {capability}}: AppState) => {
  return {
    capability
  }
}

export const Heart = connect(mapStateToProps)(({ eventSetup, capability }: HeartProps) => {
  const familyTracking = eventSetup.familyMemberTracking || !capability.positioning;
  const familyCalling = eventSetup.familyMemberCalling || !capability.callGsm;
  const familyImage = eventSetup.familyMemberImage;
  const familySchedule = eventSetup.familyMemberSchedule;

  if (!eventSetup.familyMember) {
    return <HeartDotted style={{ width: '25px', height: '25px' }} />;
  }
  if (
    eventSetup.familyMemberTracking ||
    eventSetup.familyMemberCalling ||
    eventSetup.familyMemberImage ||
    eventSetup.familyMemberSchedule
  ) {
    if (
      familyTracking &&
      familyCalling &&
      familyImage &&
      familySchedule
    ) {
      return <HeartFull style={{ width: '25px', height: '25px' }} />;
    }
    return <HeartRight style={{ width: '25px', height: '25px' }} />;
  }
  return <HeartEmpty style={{ width: '25px', height: '25px' }} />;
})
