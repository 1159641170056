import { AlarmFilterEnum } from "@Safemate/DeviceList/AlarmFilterEnum";
import { StatusEnum } from "./StatusEnum";

type GetStatusLink = (status: StatusEnum) => AlarmFilterEnum;
type GetDeviceLink = (device: string) => string;



export const getStatusLink: GetStatusLink = (status) => {
  switch(status){
    case StatusEnum.ACTIVE:
      return AlarmFilterEnum.CLEAR
    case StatusEnum.FAILED_CHECK:
      return AlarmFilterEnum.SELFCHECK;
    case StatusEnum.OUTDATED_POSITION:
      return AlarmFilterEnum.OUTDATEDPOSITION;
    case StatusEnum.OFFLINE:
      return  AlarmFilterEnum.OFFLINE;
    case StatusEnum.STATIC:
      return AlarmFilterEnum.STATIC;
    case StatusEnum.UNKNOWN:
      return AlarmFilterEnum.UNKNOWN;
    default:
      return AlarmFilterEnum.CLEAR;
  }
}