import withFormWrapper from "@Elements/Form/formWrapper";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import * as React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import DeviceEventSetup from "../Model/DeviceEventSetup";
import SimpleDevice from "../Model/SimpleDevice";
import { Table, Th, Tr } from "./TableElementStyles";
import { EventSetupForm } from "./types";
import { hasGsmRecipient, ModalEnum, useDefault } from "./utils";
import SequentialOrder from "./SequentialOrder";
import { Field } from "formik";
import { setDeviceEventSetupModal } from "../Store/routines";
import { Days } from "./Days";
import { Events } from "./Events";
import Trained from "@Elements/Icon/Icons/Trained";
import { Heart } from "./Heart";
import { HeartWrapper, TrashWrap } from "./WrapperStyles";
import Trash from "@Elements/Icon/Icons/Trash";
import { TdWrap } from ".";
import styled from "styled-components";
import { AppState } from "@Safemate/Store/types";

const Wrap = styled.div`
  display: inline-block;
  padding-top: 5px;
  width: 100%;
`;


const GsmRecipient = withFormWrapper<EventSetupForm, {}>(({ formik: { values: { sequentialCalling, eventSetups } } }) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
  const { formatMessage } = useIntl();
  if(!hasGsmRecipient(eventSetups) || (!permissions.get(PolicyNameEnum.GSM_RECIPIENTS).generalPermissions.all && !permissions.get(PolicyNameEnum.GSM_RECIPIENTS).generalPermissions.read)) return null;

  let gsmIndex = 0;
  let gsmLength = eventSetups.filter(setup => setup.email === null).length;

  return(
    <Wrap>
      <h2>{formatMessage({id: 'gsmRecipients', defaultMessage: 'GSM Recipients'})}</h2>
      <Table>
        <TableHeader sequentialCalling={sequentialCalling}/>
        {eventSetups.sort((a, b) => a.sort - b.sort).map((eventSetup, index) => {
          if(eventSetup.email === null){
            gsmIndex++;
            return(
              <TableBody eventSetup={eventSetup} gsmIndex={gsmIndex} index={index} count={gsmLength}/>
            )
          }
        })}
      </Table>
    </Wrap>
  )
})

const mapStateToTableHeaderProps = ({ appData: {isPrivate}, eventSetup: {device: { selectedDevice }}}: AppState) => {
  return{
    device: selectedDevice,
    isPrivate
  }
}

interface TableHeaderProps{
  device: SimpleDevice;
  sequentialCalling: boolean;
  isPrivate: boolean;
}

const TableHeader = connect(mapStateToTableHeaderProps)(({ isPrivate, device, sequentialCalling }: TableHeaderProps) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
  const { formatMessage } = useIntl();

  return(
    <thead>
      <tr>
        {sequentialCalling && permissions.get(PolicyNameEnum.CONTROL_CALLING).generalPermissions.all && 
          <Th width="5%">#</Th>}
        <Th width="15%">{formatMessage({id: "receiver", defaultMessage: "Recipient"})}</Th>
        <Th width="105px">{formatMessage({id: "phone", defaultMessage: "tlf. nummer"})}</Th>
        <Th hideMobile>{formatMessage({id: "from", defaultMessage: "Fra"})}</Th>
        <Th hideMobile>{formatMessage({id: "to", defaultMessage: "Til"})}</Th>
        <Th hideMobile>{formatMessage({id: "days", defaultMessage: "Dager"})}</Th>
        <Th hideMobile>{formatMessage({id: "eventTypes", defaultMessage: "Hendelser"})}</Th>
        {!isPrivate && !useDefault(device) &&
          <Th hideMobile>{formatMessage({id: "trained", defaultMessage: "Opplært"})}</Th>}
        {!useDefault(device) &&
          <Th hideMobile>{formatMessage({id: "nextOfKin", defaultMessage: "Pårørende"})}</Th>}
        {!useDefault(device) && 
          <Th width="5%">{formatMessage({id: "deleteSlett", defaultMessage: "Slett"})}</Th>}
      </tr>
    </thead>
  )
})

interface TableBodyProps{
  setDeviceEventSetupModal: Routine<ActionFunctionAny<Action<any>>>;
  eventSetup: DeviceEventSetup;
  gsmIndex: number;
  index: number;
  count: number;
  selectedDevice: SimpleDevice;
  isPrivate: boolean;
}

const mapStateToTableBodyProps = ({ appData: {isPrivate}, eventSetup: {device: { selectedDevice }}}: AppState) => {
  return {
    selectedDevice,
    isPrivate
  }
}

const mapDispatchToProps = {
  setDeviceEventSetupModal
}

const TableBody = connect(mapStateToTableBodyProps, mapDispatchToProps)(withFormWrapper<EventSetupForm, TableBodyProps>(({ isPrivate, selectedDevice, eventSetup, index, gsmIndex, count, formik: { values: { sequentialCalling }} }) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  const disable: boolean = !permissions.get(PolicyNameEnum.GSM_RECIPIENTS).generalPermissions.all || useDefault(selectedDevice);

  return(
    <tbody>
      <Tr id={`row-${index}`}>
        {sequentialCalling && permissions.get(PolicyNameEnum.CONTROL_CALLING).generalPermissions.all &&
          <TdWrap eventSetup={eventSetup} width="5%">
            <SequentialOrder index={gsmIndex} count={count} eventSetup={eventSetup}/>
          </TdWrap>}
        <TdWrap eventSetup={eventSetup} width="20%" mobileWidth="100%">
          <Field style={{width: "100%"}} disabled={disable} type="text" name={`eventSetups.${index}.givenName`} />
        </TdWrap>
        <TdWrap eventSetup={eventSetup} width="13%">
          <Field disabled={disable} style={{width: "105px"}} type="text" name={`eventSetups.${index}.phone`} />
        </TdWrap>
        <TdWrap hideMobile eventSetup={eventSetup} clickable={!disable} modal={ModalEnum.FROM} index={index}>
          {eventSetup.warnTimeFromString}
        </TdWrap>
        <TdWrap hideMobile eventSetup={eventSetup} clickable={!disable} modal={ModalEnum.TO} index={index}>
          {eventSetup.warnTimeToString}
        </TdWrap>
        <TdWrap hideMobile eventSetup={eventSetup} clickable={!disable} modal={ModalEnum.DAYS} index={index}>
          <Days eventSetup={eventSetup}/>
        </TdWrap>
        <TdWrap hideMobile eventSetup={eventSetup} clickable modal={ModalEnum.EVENT_TYPES} index={index}>
          <Events eventSetup={eventSetup}/>
        </TdWrap>
        {!isPrivate && !useDefault(selectedDevice) && 
          <TdWrap hideMobile eventSetup={eventSetup} clickable={!disable} modal={ModalEnum.TRAINED} index={index}>
            <Trained marginAuto color={eventSetup.trained ? "#64800c" : "#cc0000"} size="medium"/> 
          </TdWrap>}
        {!useDefault(selectedDevice) &&
          <TdWrap hideMobile eventSetup={eventSetup} clickable modal={ModalEnum.NEXT_OF_KIN} index={index}>
            <HeartWrapper>
              <Heart eventSetup={eventSetup} />
            </HeartWrapper>
          </TdWrap>}
        {!useDefault(selectedDevice) && <TdWrap eventSetup={eventSetup} clickable={!disable} modal={ModalEnum.DELETE} index={index}>
          <TrashWrap>
            <Trash style={{ width: '20px', height: '20px', margin: '0 auto' }} />
          </TrashWrap>
        </TdWrap>}
      </Tr>
    </tbody>
  )
}))

export default GsmRecipient;