import * as React from "react";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import EditIcon from "@Icons/FontAwesome/EditName";
import { defineMessages, useIntl } from "react-intl";
import confirmModal from "@Safemate/Modals/confirmModal";
import { connect } from "react-redux";
import { Field, Formik } from "formik";
import { editSensorName } from "@Safemate/Settings/Store/routines";
import { HorizontalRow, SelectWrap } from "../styles";
import { ISensorDTO } from "../types";
import { AppState } from "@Safemate/Store/types";

const label = defineMessages({
  update: { id: 'updateLabel', defaultMessage: 'Oppdater:' },
  updateNameBtn: { id: 'updateNameLabel', defaultMessage: 'Oppdater navn' },
  cancelBtn: { id: 'universalCancelBtn', defaultMessage: 'Avbryt' }
});


interface EditNameProps{
  sensor: ISensorDTO;
  dehaId: number;
  editSensorName: Routine<ActionFunctionAny<Action<any>>>;
}


const mapStateToProps = ({ settings: {initialization: { dehaId }}}: AppState) => {
  return {
    dehaId
  }
}

const mapDispatchToProps = {
  editSensorName,
}

export const EditName = connect(mapStateToProps, mapDispatchToProps)(({ sensor, editSensorName }: EditNameProps) => {

  const { formatMessage } = useIntl();
  const { Comp, func } = confirmModal();
  const [open, setOpen] = React.useState(false);

  const { name, gatewayId, deseId } = sensor.sensor;

  return(
    <Formik
      initialValues={{
        name,
        active: sensor.eventConfig && sensor.eventConfig.active
      }}
      onSubmit={(values) => {
        /*if(sensor.multiSensorId){
          processMultiSensor({
            sensorId: sensor.id,
            name: values.name,
            configId: sensor.eventConfigs.id,
            status: values.active
          })
        }
        else{*/
          editSensorName({
            sensorId: deseId,
            name: values.name
          })
        //}
      }}
      enableReinitialize
    >{({ submitForm }) => 
      <React.Fragment>
        <EditIcon 
          onClick={() => {func(true); setOpen(true);}}
          medium
          animate
          title={formatMessage({id: 'editNameActionTooltip', defaultMessage: 'Endre navn' })}
        />
        <Comp
          title={`${formatMessage(label.update)} ${name} (${gatewayId})`}
          standardBtn
          body={
            <EditRows open={open} sensor={sensor} confirmFunc={() => {submitForm();func(false);}}/>
          }
          confirmText={formatMessage(label.updateNameBtn)}
          confirmFunc={() => {
            submitForm();
            func(false);
            setOpen(false);
          }}
        />
      </React.Fragment>
    }
    </Formik>
  )
})

interface EditRowsProps{
  sensor: ISensorDTO;
  open: Boolean;
  confirmFunc: Function;
}

export const EditRows = (({ sensor, open, confirmFunc }: EditRowsProps) => {

  const formRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        if(open){
          if (formRef.current) {
            confirmFunc && confirmFunc();
          }
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [open]);


  return(
    <div ref={formRef}>
      <HorizontalRow><Field maxlength="31" type="text" name="name"/></HorizontalRow>
      {/*sensor.multiSensorId &&
      <HorizontalRow>{React.createElement(CheckWrap("active", {label: {id: "onOff", defaultMessage: "Av/På"}}))}</HorizontalRow>*/}
    </div>
  )
});