import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';

export const PaddedRow = styled(Row)`
  padding: 15px;
`;

export const HeartModalSelectRow = styled(Row)<{selected?: boolean, hasRight?: boolean, notNextOfKin?: boolean}>`
  margin-top: 15px;
  padding: 20px;
  border: ${props =>
    props.selected ? '1px solid green' : props.theme.colors.border};
  border-radius: 5px;
  cursor: ${props => (props.hasRight ? 'pointer' : 'not-allowed')};
  color: ${props => (props.selected ? props.theme.colors.textPrimary : 'gray')};
  min-height: 375px;
  width: ${props => (props.notNextOfKin ? '100%' : 'auto')};

  @media (max-width: 768px) {
    min-height: ${props => (props.notNextOfKin ? '100px' : '375px')};
  }

  .icon {
    fill: ${props =>
      props.selected ? props.theme.colors.textPrimary : 'gray'};
  }
`;

export const ScrollRow = styled(Row)``;

export const TopBorderRow = styled(Row)`
  display: flex;
  align-items: center;
  border-top: ${props => props.theme.colors.border};
  margin-top: 20px;
  padding: 10px 0px;
`;

export const EventWrapperRow = styled(Row)<{write?: boolean, selected?: boolean}>`
  color: white;
  float: left;
  width: 125px;
  height: 125px;
  border: ${props => props.theme.colors.border};
  border-radius: 5px;
  margin: 15px;
  text-align: center;
  cursor: ${props => (props.write ? 'pointer' : 'not-allowed')};
  background: ${props =>
    props.selected ? props.theme.colors.accent : 'transparent'};

  @media (max-width: 768px) {
    width: 110px;
  }

  @media (max-width: 705px) {
    width: 100px;
    height: 100px;
  }

  .svg-inline--fa.fa-w-16 {
    width: 1.85em;
  }

  .svg-inline--fa {
    height: 1.45em;
  }

  .svg-inline--fa.fa-w-16.fa-envolope {
    width: 1.7em;
  }

  .svg-inline--fa.fa-envelope {
    height: 1.45em;
  }

  .svg-inline--fa.fa-w-16.fa-phone {
    width: 1.45em;
  }

  .svg-inline--fa.fa-phone {
    height: 1.45em;
    margin-left: 1.7px;
    margin-top: 1.7px;
  }
`;

export const AhpCol = styled(Col)`
  display: flex;

  label {
    display: flex;
    padding-right: 5px;
  }
`;
