import * as Api from '../api';
import * as Routines from "../routines";
import { call, put, takeLatest } from 'redux-saga/effects';
import { AppAction } from '@Safemate/Store/types';


function* fetchWalktest(action: AppAction<string>){
  try{
    const walktest = yield call(Api.fetchWalktest, action.payload);
    yield put(Routines.fetchWalktest.success(walktest));
  }
  catch(e){
    yield put(Routines.fetchWalktest.failure());
  }
  yield put(Routines.fetchWalktest.fulfill());
}

function* startWalktest(action: AppAction<string>){

  try{
    const data = yield call(Api.startWalktest, action.payload);
    yield put(Routines.startWalktest.success(data));
  }
  catch(e){
    yield put(Routines.startWalktest.failure());
  }
}

function* stopWalktest(action: AppAction<string>){

  try{
    const data = yield call(Api.stopWalktest, action.payload);
    yield put(Routines.stopWalktest.success(data));
  }
  catch(e){
    yield put(Routines.stopWalktest.failure());
  }
}

function* walktestSaga(){
  yield takeLatest(Routines.startWalktest.TRIGGER, startWalktest);
  yield takeLatest(Routines.stopWalktest.TRIGGER, stopWalktest);
  yield takeLatest(Routines.fetchWalktest.TRIGGER, fetchWalktest);
}

export default walktestSaga;
