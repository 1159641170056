import Axios, { AxiosResponse } from "axios";
import {Loader, LoaderOptions} from 'google-maps';

export const MIN_LOOKUP_INTERVAL = 1500;

type AddressLookup = (lat: number, long: number, deviId: number, inloId:number) => Promise<string>;
interface AddressCache{
  [index: string]: {status: number, address: string};
}
let loadedGoogle: any = null;
if(typeof google === 'object' && typeof google.maps === 'object'){
  loadedGoogle = google;
}

export class GoogleUtils{

  addressCache: AddressCache = {};
  queue = new Array();
  loader = new Loader("AIzaSyAQxbShd7veF5B0YU9O_uYPOQFCOHoe8no");
  geocoder: any;
  latestLookupTime: number = new Date().getTime();

  Googleutils(){}

  addressLookup: AddressLookup = async (lat, long, deviId, inloId) => {
    const index = lat +"-"+ long +"-"+ deviId +"-"+ inloId;
    if(this.addressCache[index]){
      return this.addressCache[index].address;
    }
    try{
      const response: Response = await lookupGeocode(lat, long, deviId, inloId);
      if(response.address){
        this.addressCache[index] = {status: 1, address: response.address};
        return response.address;
      }
      else{
        this.addressCache[index] = {status: 0, address: ""};
      }
    }
    catch(e){
      if(this.addressCache[index]){
        return this.addressCache[index].address;
      }

      if(!loadedGoogle)
        loadedGoogle = await this.loader.load();
      if(!this.geocoder){
        this.geocoder = new loadedGoogle.maps.Geocoder();
      }
      const loc = new google.maps.LatLng(lat, long)
      try{
        const result: string = await getAddress(this.geocoder, loc);
        const address = result;
        this.addressCache[index] = { status : 1, address };
        registerGeocode(lat, long, address);
        await new Promise(r => setTimeout(r, 1500));
        return address;
      }
      
      catch(e){
        this.addressCache[index] = { status: 0, address: ""};
        registerGeocode(lat, long, "");
        await new Promise(r => setTimeout(r, 1500));
      }
    } 
    return "";
  }
}

export const getAddress = (geocoder: google.maps.Geocoder, location: google.maps.LatLng) => {
  return new Promise<string>((resolve, reject) => {
      geocoder.geocode({location}, (results, status) => {
          if (status === 'OK') {
              resolve(results[0].formatted_address);
          } else {
              reject(status);
          }    
      });    
  });
};

type LookupGeocode = (lat: number, long: number, deviId: number, inloId: number) => Promise<Response>;
interface Response{
  address?: string;
}
export const lookupGeocode: LookupGeocode = async (lat, long, deviId, inloId) => {
  const response = await Axios.get<Response>(`/ajax/geocode/indoorlocation/${lat}/${long}/${deviId}/${inloId}`);
  return response.data;
}

type RegisterGeocode = (lat: number, lng: number, address: string) => void;
export const registerGeocode: RegisterGeocode = async (lat, lng, address) => {
  const data = {
    lat: `${lat}`,
    lng: `${lng}`,
    address: address
  }
  await Axios.post("/ajax/geocode", data);
}

export default new GoogleUtils();
