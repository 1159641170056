
import { call, put, takeEvery } from 'redux-saga/effects';
import * as Routines from '../routines';
import * as Api from '../api';
import { AppAction } from '../../../DefaultSettings/Store/types';
import { Policies } from '@Safemate/Policy/types';

function* getPolicies(action: AppAction<number>){
  try{
    const policies: Policies = yield call(Api.getPoliciesForDevice, action.payload);
    yield put(Routines.getPolicies.success(policies));
  }
  catch(e){
    yield put(Routines.getPolicies.failure());
  }
  yield put(Routines.getPolicies.fulfill());
}
function* policySaga(){
    yield takeEvery(Routines.getPolicies.TRIGGER, getPolicies);
}

export default policySaga;
