import * as React from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";
import { Button, Col, Glyphicon, Row } from "react-bootstrap";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import moment, { Moment } from "moment";
import { AlarmFilterEnum } from "../../DeviceList/AlarmFilterEnum";
import { FilterAlarm, filterAlarm, SelectDate, selectDate } from "../Store/actions";
import { getTransactionsForDate, getTransactionsForAll, getAllDevices } from "../Store/routines";
import { AppState } from "@Safemate/Store/types";
import Dropdown from "@Elements/Dropdown/Dropdown";
import { Option } from "@Elements/Dropdown/types";
import { getTransactionFilter } from "../utils";
import { Wrapper as DateWrapper } from "@Elements/Datepicker/styles";
import { MapType } from "..";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";

const DropWrap = styled.div`
  margin-right: 10px;
  width: 180px;
  @media (max-width: 600px) {
    display: none;
  }
`;

const StyledDateWrapper = styled<any>(DateWrapper)`
  margin-right: 15px;
  @media (max-width: 600px) {
    display:flex;
  }
`;

const DateHeader = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 185px;
  margin-right: 10px;
  @media (max-width: 600px) {
    .SingleDatePickerInput{
      display: flex;
    }
    .SingleDatePickerInput_calendarIcon{
      padding-left: 0px!important;
      margin-left: 0px!important;
    }
    .DateInput{
      width: 110px!important;
    }
  }
`;

const FilterWrapper = styled.div`
  display: flex;
`;



interface FilterProps{
  alarmFilter: AlarmFilterEnum[];
  filterAlarm: FilterAlarm;
  selectDate: SelectDate;
  getTransactionsForDate: Routine<ActionFunctionAny<Action<any>>>;
  getAllDevices: Routine<ActionFunctionAny<Action<any>>>;
  datesWithTransactions: number[];
  date: Moment;
  dehaId: number;
  id: number;
  mapType: MapType;
}

const mapStateToProps = ({ transactionLog: { device: { id, dehaId }, transaction: { alarmFilter, datesWithTransactions, date}}}: AppState) => {
  return{
    alarmFilter,
    datesWithTransactions,
    date,
    dehaId,
    id
  }
}

const mapDispatchToProps = {
  filterAlarm,
  selectDate,
  getTransactionsForDate,
  getAllDevices
}

const Filter = connect(mapStateToProps, mapDispatchToProps)(({ mapType, alarmFilter, selectDate, filterAlarm, getTransactionsForDate, getAllDevices, datesWithTransactions, date, dehaId, id }: FilterProps) => {

  const [ dateFocus, setDateFocus ] = React.useState(false);
  const { formatMessage } = useIntl();

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
  const logPermission = permissions.get(PolicyNameEnum.DEVICE_TRANSACTION_LOG).generalPermissions.all
  
  const validDates = React.useMemo(() => {
    return datesWithTransactions.map(d => moment(d));
  }, [datesWithTransactions])

  const today = moment(new Date());

  if(!logPermission) return null;

  return(
    <FilterWrapper>
      {mapType === MapType.SINGLE && 
      <DateHeader>
        <StyledDateWrapper>
          <DatePicker
            dateFormat="dd.MM.yyyy"
            showIcon
            includeDates={validDates.map(date => date.toDate())}
            selected={date.toDate()}
            onChange={(d: Date | null) => {
              if(d){
                const date = moment(d);
                selectDate(date);
                getTransactionsForDate({
                  deviceId: id,
                  date: date.format("DD-MM-YYYY")
                })
              }
            }}
            monthsShown={1}
            onFocus={({ focused }: any) => setDateFocus(focused)}
            id={"date-selector"}
            icon={<Glyphicon glyph="calendar" />}
          />
        </StyledDateWrapper>
      </DateHeader>}
      <DropWrap>
        <Dropdown
          multi
          initial={alarmFilter}
          options={getTransactionFilter(dehaId, formatMessage)}
          title={formatMessage({id: "selectAlarmFilter", defaultMessage: "Alle tilstander"})}
          onChange={option => {
            const selected = (option as Option[]).map(item => item.value);
            filterAlarm(selected);
            if(mapType === MapType.SINGLE){
              getTransactionsForDate({
                deviceId: id,
                date: date.format("DD-MM-YYYY")
              });
            }
            else if(mapType === MapType.ALL){
              getAllDevices();
            }
          }}
        />
      </DropWrap>
    </FilterWrapper>
  )
})

export default Filter;