import * as React from "react";
import { connect } from "react-redux";
import { Row } from 'react-bootstrap';
import { Table, Column, WindowScroller, RowMouseEventHandlerParams } from "react-virtualized";
import styled, { ThemeProps, withTheme } from "styled-components";
import uuid from 'uuid';

import 'react-virtualized/styles.css';

import { mapDevices, MappedDevice, TwinType } from './utils';
import { filterSearch, FilterSearch, ToggleInfo, toggleInfo, } from '../Store/actions';
import { LabelEnum } from '../LabelEnum';
import TableExport from "../../../Elements/Export/TableExport";
import { getExportData, ExportType } from "../utils";
import { ITheme } from "@Theme/types";
import { filterPartnerDevices } from "../Store/Routines";
import { Routine } from "redux-saga-routines";
import { ActionFunctionAny, Action } from "redux-actions";
import { InfoModal } from "./TableElements";
import { AppState } from "@Safemate/Store/types";
import { useIntl } from "react-intl";
import { IDevice, IPartnerDevice } from "@Safemate/Model/Device";
import { IUser } from "@Safemate/Model/User";
import { SubLoader } from "@Safemate/Spinner";
import { SiteTypeEnum } from "@Safemate/SiteTypeEnum";
import {AlarmAnalysisState} from "@Safemate/DeviceList/Store/types";



interface DeviceListProps{
  listKey: string;
  filteredDevices: IDevice[];
  filteredPartnerDeviceList: IPartnerDevice[];
  devices: IDevice[];
  initialized: boolean;
  user: IUser;
  filterSearch: FilterSearch;
  search: string;
  filterPartnerDevices: Routine<ActionFunctionAny<Action<any>>>;
  partnerDeviceList: IDevice[];
  isPrivate: boolean;
  twinSuffix: string;
  isTracker: boolean;
  ahpContext: boolean;
  alarmAnalysis: AlarmAnalysisState;
}

const mapStateToProps = (
  { 
    deviceList: {
      device: {
        filteredDevices, devices, filteredPartnerDeviceList, partnerDeviceList
      }, 
      initialization: {
        initialized, twinSuffix
      }, 
      filter: {
        search
      } ,
      alarmAnalysis
    },
    appData: {
      isPrivate,
      isTrackerOnly,
      currentContext,
      user
    }
  }
  : AppState) => {
  return {
    filteredDevices,
    filteredPartnerDeviceList,
    devices,
    initialized,
    user,
    search,
    partnerDeviceList,
    isPrivate,
    twinSuffix,
    isTracker: isTrackerOnly,
    ahpContext: currentContext === SiteTypeEnum.AHP,
    alarmAnalysis
  }
}

const mapDispatchToProps = {
  filterSearch,
  filterPartnerDevices
}

const DeviceList = connect(mapStateToProps, mapDispatchToProps)(({
  listKey,
  partnerDeviceList, 
  filterPartnerDevices, 
  search, 
  filteredDevices, 
  devices, 
  filteredPartnerDeviceList, 
  initialized, 
  user, 
  twinSuffix, 
  isPrivate,
  ahpContext,
  isTracker,
  alarmAnalysis
}: DeviceListProps) => {

  const rowRef = React.useRef<HTMLDivElement>(null);

  const { formatMessage } = useIntl();

  const [ timelineRendered, setTimelineRendered ] = React.useState(0);

  const timelineCallback = () => {
    setTimelineRendered(timelineRendered + 1);
  }

  React.useEffect(() => {
    if(isPrivate){
      filteredDevices = devices;
    }
  }, [filteredDevices]);

  const { mappedRows, alarmRows, cloneDevices, masterDevices } = mapDevices(ahpContext, isPrivate, isPrivate ? devices : filteredDevices, user.beta, alarmAnalysis, timelineCallback, twinSuffix);

  /*
   A bit of a hack to force the entire table to be remade when data is changed
   Specifically data relevant to the timeline, as the timeline changed size after
   the list had been rendered, and messed up the internal indexing in the Table element
   causing the list to behave undesirable when scrolling upwards.
  */
  const MainList = React.useMemo(() => {
    return React.createElement(SafemateList,
      {
        key: uuid.v4(),
        row: rowRef,
        mappedDevices: mappedRows,
        isTracker,
        ahpContext,
        cloneDevices,
        masterDevices
      })
  }, [listKey, timelineRendered, rowRef.current && rowRef.current.clientWidth])

  React.useEffect(() => {
    if(search){
      filterPartnerDevices();
    }
  }, [partnerDeviceList])

  const getPartnerText = (device: IPartnerDevice) => {
    return `${device.deviceSNNum} ${formatMessage({id: "belongTo", defaultMessage: "tilhører"})} ${device.customerName} - ${formatMessage({id: "goTo", defaultMessage: "gå til"})} ${device.customerName} ${formatMessage({id: "under", defaultMessage: "under"})} ${device.topLevelCustomerName} ${formatMessage({id: "toFindDevice", defaultMessage: "for å finne denne enheten."})}`; 
  }


  return(
    <React.Fragment>
    <RowWrapper>
      {(initialized || filteredDevices.length > 0)
        ? <div ref={rowRef}> 
          {filteredPartnerDeviceList.length !== 0 && filteredDevices.length === 0 
            ? <h2>{getPartnerText(filteredPartnerDeviceList[0])}</h2>
            : !isPrivate && filteredDevices.length === 0
            ? <h2>{formatMessage({id: LabelEnum.NO_DEVICES_FOUND, defaultMessage: "Ingen enheter funnet"})}</h2> 
            : <React.Fragment>
                <AlarmList alarmDevices={alarmRows} cloneDevices={cloneDevices} masterDevices={masterDevices} isTracker={isTracker} ahpContext={ahpContext}/>
                {React.cloneElement(MainList, {mappedDevices: mappedRows, row: rowRef})}
                <ExportData isPrivate={isPrivate} />
            </React.Fragment>
          }
        </div>
        : <SubLoader/>
      }
    </RowWrapper>
    <InfoModal/>
    </React.Fragment>
  )
})

interface SafemateListProps extends ThemeProps<ITheme>{
  mappedDevices: MappedDevice[];
  toggleInfo: ToggleInfo;
  row: React.MutableRefObject<HTMLDivElement | null>;
  isTracker: boolean;
  ahpContext: boolean;
  cloneDevices: number[];
  masterDevices: number[];
}

const RowWrapper = styled(Row)`
  .hide-lg {
    @media (max-width: 1200px) {
      display: none;
    }
  }

  .hide-md {
    @media (max-width: 992px) {
      display: none;
    }
  }

  .hide-sm.hide-sm {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .centered {
    display: flex;
    justify-content: center;
    .deviceIcons{
      @media (max-width: 360px) {
        margin-right: 10px;
      }
    }
  }

  .end {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 550px) {
      overflow: visible!important;
    }
  }

  .tcpStatusDisabledHover{
    margin-top: 15px;
  }

  .twin {
    height: 100%;
    margin-right: 0px;
    display: block;
  }
`

export const StyledTable = styled(Table)`

  .ReactVirtualized__Table__headerRow {
    display: flex;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
  }

  .ReactVirtualized__Table__headerColumn{
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ReactVirtualized__Table__row {
    display: flex;
    align-items: center;
    border: ${props => props.theme.colors.border};
    background: ${props => props.theme.colors.backgroundPrimary};
    color: ${props => props.theme.colors.textPrimary};
  }
`

const ListRow = styled.div<{twin?: TwinType, alarm?: boolean}>`
  cursor: pointer;
  height: 64px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border: ${props => props.twin === TwinType.NONE ? props.theme.colors.border : "none"};
  ${props => props.twin === TwinType.MASTER 
    ? `
        border-top: ${props.theme.colors.border};
        border-left: ${props.theme.colors.border};
      `
    : ""}
  ${props => props.twin === TwinType.CLONE 
    ? `
        ${props.alarm ? "" : `border-bottom: ${props.theme.colors.border};`}
        border-left: ${props.theme.colors.border};
      `
    : ""}
  background: ${props => props.theme.colors.backgroundPrimary};
  color: ${props => props.theme.colors.textPrimary};
`;

const ExportDiv = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;

const ListCol = styled.div<{flex: string, center?: boolean, end?: boolean}>`
  flex: ${props => props.flex};
  overflow: hidden;
  display: flex;
  ${props => props.center && "justify-content: center;"}
  ${props => props.end && "justify-center: flex-end;"}
`
const TimelineWrapper = styled.div<{twin?: TwinType}>`

${props => props.twin === TwinType.MASTER 
  ? `
      border-left: ${props.theme.colors.border};
    `
  : ""}
${props => props.twin === TwinType.CLONE 
  ? `
      border-bottom: ${props.theme.colors.border};
      border-left: ${props.theme.colors.border};
    `
  : ""}

  ${props => {
    return (!props.twin || props.twin === TwinType.NONE) ? "padding: 10px;" : ""}
  }
`

const TwinTimelineWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const TwinTimeline = styled.div`
  flex: 1;
  padding: 10px;
`;

const TwinTimelineBorder = styled.div<{twin?: TwinType}>`
  background: ${props => props.theme.colors.accent};
  width: 5px;
  ${props => props.twin === TwinType.CLONE ? "margin-bottom: 3px;" : ""}
`;

interface AlarmListProps{
  alarmDevices: MappedDevice[];
  cloneDevices: number[];
  masterDevices: number[];
  isTracker: boolean;
  ahpContext: boolean;
  toggleInfo: ToggleInfo;
}

const selectColumns = (isTracker: boolean, ahpContext: boolean) => ahpContext ? columnsAhp : isTracker ? columnsTracker : columnsNormal;

const mapListDispatchToProps = {
  toggleInfo,
}


const AlarmList = connect(null, mapListDispatchToProps)(({ toggleInfo, alarmDevices, cloneDevices, masterDevices, isTracker, ahpContext}: AlarmListProps) => {
  const columns = selectColumns(isTracker, ahpContext);

  return(
    <React.Fragment>
      {alarmDevices.map(device => {
        const twinType = 
          cloneDevices.includes(device.deviceId) 
            ? TwinType.CLONE
            : masterDevices.includes(device.deviceId)
              ? TwinType.MASTER
              : TwinType.NONE;
        return(
          <React.Fragment>
            <ListRow onClick={() => { if( !isTracker) {toggleInfo(device.deviceId)} } } twin={twinType} alarm={!!device.timeline}>
              {columns.map(col => {
                let flex = col.width ? 0 : 1
                if(col.flexGrow)
                  flex = col.flexGrow;
                return(
                  <ListCol
                    flex={`${flex} 1 ${col.width || 0}px`}
                    className={col.class}
                  >
                    {device[col.key]}
                  </ListCol>
                )
              })}
            </ListRow>
            {device.timeline &&
            <TimelineWrapper twin={twinType}>
              {device.twin 
                ? <TwinTimelineWrapper>
                    <TwinTimeline>{device.timeline}</TwinTimeline>
                    <TwinTimelineBorder twin={twinType}></TwinTimelineBorder>
                  </TwinTimelineWrapper>
              :  device.timeline}
            </TimelineWrapper>}
          </React.Fragment>
        )
      })}
    </React.Fragment>
  )
})


interface ExportDataProps{
  filteredDevices: IDevice[];
  isPrivate: boolean;
  user: IUser;
}

const mapStateToPropsExportData = ({ deviceList: { device: {filteredDevices} }, appData: { user }}: AppState) => {
  return {
    filteredDevices,
    user
  }
}

const ExportData = connect(mapStateToPropsExportData)(({filteredDevices, isPrivate, user, }: ExportDataProps) => {

  const { formatMessage } = useIntl();

  const isBeta = user.beta;
  let exportData = getExportData(ExportType.CSV, filteredDevices, isBeta, formatMessage);
  React.useEffect(() => {
    exportData = getExportData(ExportType.CSV, filteredDevices, isBeta, formatMessage);
  }, [filteredDevices]);
  return(
    <React.Fragment>
      {!isPrivate && <ExportDiv style={{marginTop:'1em'}}>
        <TableExport type="csv" data={exportData} fileName={`device`} />
        <TableExport colWidth={[6, 6, 7, 8, 5, 7, 7, 9, 8, 12, 7, 6, 6, 8,4]} type="pdf" data={exportData} fileName={`device`} />
      </ExportDiv>
      }
    </React.Fragment>
  )
})

const SafemateList = withTheme(connect(null, mapListDispatchToProps)(({ toggleInfo, theme, mappedDevices, cloneDevices, masterDevices, row, isTracker, ahpContext}: SafemateListProps) => {

  const getRow = ({index}: any) => mappedDevices[index];

  const [width, setWidth] = React.useState(1000);

  let timer: NodeJS.Timeout;

  React.useEffect(() => {
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    }

  }, [])

  React.useEffect(() => {
    resizeListener();
  }, [row.current])

  
  
  const debounceResize = (func: () => void, timeout = 100) => {
    return () => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this); }, timeout);
    };
  }
  
  const resizeListener = debounceResize(() => setTableWidth());

  const setTableWidth = () => {
    if(row.current){
      // Resize table to match parent div size, if parent div is larger than the window resize to fit in window
      const newRowWidth = row.current.clientWidth;
      const newWindowWidth = window.outerWidth > window.innerWidth ? window.innerWidth : window.outerWidth;
      if(newWindowWidth < 500){
        setWidth(newWindowWidth < newRowWidth ? newWindowWidth * 0.95 : newRowWidth);
      }else{
        setWidth(newWindowWidth < newRowWidth ? newWindowWidth - 100 : newRowWidth);
      }
    }
  }

  const rowStyleFormat = (row: any) => {
    if(mappedDevices[row.index] && cloneDevices.includes(mappedDevices[row.index].deviceId)){
      return {
        cursor: "pointer",
        border: "none",
        borderBottom: theme.colors.border,
        borderLeft: theme.colors.border,
        borderTop: "none",
        borderRight: "none"
      }
    }
    if(mappedDevices[row.index] && masterDevices.includes(mappedDevices[row.index].deviceId)){
      return {
        cursor: "pointer",
        border: "none",
        borderTop: theme.colors.border,
        borderLeft: theme.colors.border,
        borderBottom: "none",
        borderRight: "none"
      }
    }
    return {
      cursor: "pointer"
    }
  }
  

  return(
    <WindowScroller>
      {({height, isScrolling, onChildScroll, scrollTop}) => (
      <StyledTable
        onRowClick={(data: RowMouseEventHandlerParams) => {if(!isTracker){ toggleInfo(data.rowData.deviceId) }}}
        autoHeight={true}
        isScrolling={isScrolling}
        onScroll={onChildScroll}
        scrollTop={scrollTop}
        rowGetter={getRow}
        height={height}
        rowHeight={64}
        rowCount={mappedDevices.length}
        width={width}
        headerHeight={0}
        disableHeader={true}
        rowStyle={rowStyleFormat}
      >
        {getColumns(isTracker, ahpContext)}
      </StyledTable>
      )}
    </WindowScroller>
  )
}));

interface Columns{
  key: string;
  width?: number;
  class?: string;
  flexGrow?: number;
}

const columnsAhp: Columns[] = [
  {key: "status", width: 60, class: "centered"},
  {key: "deviceIcon", width: 50, class: "centered"},
  {key: "deviceSNNum", width: 70},
  {key: "municialityImage", width: 60, class: "centered"},
  {key: "deviceName", flexGrow: 2},
  {key: "battery", width: 50, class: "centered hide-sm"},
  {key: "tracking", width: 50, class: "centered hide-sm"},
  {key: "lastdate", class: "hide-lg"},
  {key: "lasttime", class: "hide-lg"},
  {key: "phone", class: "hide-md", flexGrow: 2},
  {key: "actions", class: "end", width: 210},
  {key: "info", width: 50, class: "centered"},
  {key: "twin", width: 5, class: "twin"}
]

const columnsNormal: Columns[] = [
  {key: "status", width: 60, class: "centered"},
  {key: "deviceIcon", width: 50, class: "centered"},
  {key: "deviceSNNum", width: 75},
  {key: "deviceName", width: 100, flexGrow: 2},
  {key: "gsm", width: 40, class: "centered hide-sm gsmTab"},
  {key: "battery", width: 40, class: "centered hide-sm"},
  {key: "tracking", width: 50, class: "centered hide-sm"},
  {key: "lastdate", class: "hide-lg"},
  {key: "lasttime", class: "hide-lg"},
  {key: "phone", class: "hide-md", flexGrow: 2},
  {key: "actions", class: "end", width: 210},
  {key: "info", width: 50, class: "centered hide-sm"},
  {key: "twin", width: 5, class: "twin"}
]

const columnsTracker: Columns[] = [
  {key: "status", width: 60, class: "centered"},
  {key: "deviceIcon", width: 50, class: "centered"},
  {key: "deviceSNNum", flexGrow: 1.2},
  {key: "deviceName", flexGrow: 2},
  {key: "gsm", width: 50, class: "centered hide-sm"},
  {key: "battery", width: 50, class: "centered hide-sm"},
  {key: "tracking", width: 50, class: "centered hide-sm"},
  {key: "actions", class: "end", width: 260},
  {key: "info", width: 50, class: "centered"},
  {key: "twin", width: 5, class: "twin"}
]

const getColumns = (isTracker: boolean, isAhp: boolean) => {

  const elementRenderer = (node: any) => {
    return node.cellData;
  }

  const columns = selectColumns(isTracker, isAhp);

  return columns.map(col => {

    let flex = col.width ? 0 : 1
    if(col.flexGrow)
      flex = col.flexGrow;

    return (
      <Column
        className={col.class}
        cellRenderer={elementRenderer}
        dataKey={col.key}
        width={col.width || 0}
        style={{marginRight: "0px", marginLeft: "0px", whiteSpace: "pre-wrap"}}
        flexGrow={flex}
      />
    )
  }).filter(item => item);
}

export default DeviceList;
