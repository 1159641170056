import * as React from "react";
import { connect } from "react-redux";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import moment from "moment";
import { DeviceHardwareEnum, isGx8 } from "@Safemate/DeviceHardwareEnum";
import { getBatteryIcon } from "@Safemate/DeviceList/DeviceList/utils";
import List from "@Elements/SpacedList";
import { fetchSensors, updateSensors } from "../Store/routines";
import { mapFunction } from "./sensorFunction";
import { mapStatus } from "./sensorStatus";
import { mapRssi } from "./sensorRssi";
import { mapType } from "./sensorType";
import { AddWrap, WrapDiv } from "./styles";
import { ISensor, ISensorDTO } from "./types";
import Tooltip from "@Elements/Tooltip";
import { mapActions } from "./sensorActions";
import { LoaderWrap } from "@Safemate/Spinner";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DescriptionParagraph } from "./Add/styles";
import { SensorTypeEnum } from "./SensorTypeEnum";
import Loader from "@Elements/Loader";
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router";
import { AppState } from "@Safemate/Store/types";

enum SensorTableElements{
  STATUS,
  TYPE,
  NAME,
  FUNCTION,
  SERIAL_NUMBER,
  MAC,
  RSSI,
  LAST_CONTACT,
  BATTERY,
  ACTIONS
}

const SAFEMATE_HOME: SensorTableElements[] = [
  SensorTableElements.STATUS,
  SensorTableElements.TYPE,
  SensorTableElements.NAME,
  SensorTableElements.FUNCTION,
  SensorTableElements.SERIAL_NUMBER,
  SensorTableElements.RSSI,
  SensorTableElements.LAST_CONTACT,
  SensorTableElements.ACTIONS
]

const SAFEMATE_HOME_DT35 = SAFEMATE_HOME;
const SAFEMATE_HOME_MAX = SAFEMATE_HOME;

const TRIGGER_FOUR: SensorTableElements[] = [
  SensorTableElements.STATUS,
  SensorTableElements.BATTERY,
  SensorTableElements.TYPE,
  SensorTableElements.MAC,
  SensorTableElements.RSSI,
  SensorTableElements.ACTIONS
]

const TRIGGER_THREE_E = TRIGGER_FOUR
const WATCH_FOUR = TRIGGER_FOUR
const TRACK_TWO = TRIGGER_FOUR


const tableElements: Map<DeviceHardwareEnum, SensorTableElements[]> = new Map<DeviceHardwareEnum, SensorTableElements[]>([
  [DeviceHardwareEnum.SAFEMATE_HOME, SAFEMATE_HOME],
  [DeviceHardwareEnum.SAFEMATE_HOME_DT35, SAFEMATE_HOME_DT35],
  [DeviceHardwareEnum.SAFEMATE_HOME_MAX, SAFEMATE_HOME_MAX],
  [DeviceHardwareEnum.TRIGGER_FOUR, TRIGGER_FOUR],
  [DeviceHardwareEnum.TRIGGER_THREE_E, TRIGGER_THREE_E],
  [DeviceHardwareEnum.WATCH_FOUR, WATCH_FOUR],
  [DeviceHardwareEnum.TRACK_TWO, TRACK_TWO]
]);

interface SensorTableProps{
  dehaId: number;
  deviId: number;
  sensorRefresh: number;
  sensors: ISensorDTO[];
  updatingName: number[];
  updatingType: number[];
  updatingFunction: number[];
  fetchSensors: Routine<ActionFunctionAny<Action<any>>>; 
  updateSensors: Routine<ActionFunctionAny<Action<any>>>; 
}

interface Params{
  name: string;
}

const SensorTable = (
  {dehaId, 
  deviId,
  sensorRefresh,
  sensors, 
  updatingName, 
  updatingType, 
  updatingFunction, 
  fetchSensors, 
  updateSensors}: SensorTableProps) => {

  const { formatMessage } = useIntl();

  React.useEffect(() => {
    fetchSensors(deviId);
    updateSensors(deviId);

    const refresh = setInterval(() => {
      fetchSensors(deviId)
    }, sensorRefresh * 1000)

    return () => {
      clearTimeout(refresh);
    }

  }, [])

  const history = useHistory();
  const {path, url} = useRouteMatch();
  const params = useParams<Params>();

  const addlink = `${url.split(params.name)[0]}add`;
  const sensorInfoLink = `${url.split(params.name)[0]}info/sensor`;

  return(
    <React.Fragment>
      {sensors.length === 0
        ? <DescriptionParagraph>
            {formatMessage({id: "noSensors", defaultMessage: "Det er ingen sensorer lagt til denne enheten"})}
          </DescriptionParagraph>
        : <List
            onRowClick={(index: number) => {
              history.push(`${sensorInfoLink}/${sensors[index].sensor.deseId}`);
              console.log(sensors[index])
            }}
            header={mapHeader(dehaId, formatMessage)}
            content={mapContent(dehaId, sensors, updatingName, updatingType, updatingFunction, formatMessage)}
          />}
      <AddWrap>
        <Link to={addlink}>
          <Button>
            {formatMessage({id: "missionTaskAddSensor", defaultMessage: "Legg til ny sensor"})}
          </Button>
        </Link>
      </AddWrap>
    </React.Fragment>
  )
}


const mapStatetoProps = ({ appSettings: { sensorRefresh }, settings: {initialization: { deviId, dehaId }, sensor: { sensors, updatingName, updatingType, updatingFunction }}}: AppState) => {
  return {
    sensors,
    dehaId,
    deviId,
    updatingName, 
    updatingType, 
    updatingFunction,
    sensorRefresh
  }
}

const mapDispatchToProps = {
  fetchSensors,
  updateSensors
}

export default connect(mapStatetoProps, mapDispatchToProps)(SensorTable);

const header = defineMessages({
  mac: { id: 'macAddress', defaultMessage: 'Mac addresse' },
  name: { id: 'sensTableHeadName', defaultMessage: 'Navn' },
  type: { id: "type", defaultMessage: "Type"},
  function: { id: 'sensTableHeadFunction', defaultMessage: 'Funksjon'},
  serialNumber: { id: 'sensTableHeadSerialNumber', defaultMessage: 'Serienummer'},
  lastContact: { id: 'sensTableHeadLastContact', defaultMessage: 'Sist kontakt'},
  actions: { id: 'sensTableHeadActions', defaultMessage: 'Handlinger'},
  rssi: { id: 'signalStrength', defaultMessage: 'Signalstyrke'},
  status: { id: 'sensTableHeadStatus', defaultMessage: 'Status'},
  battery: { id: 'battery', defaultMessage: 'Batteri'},
});

type MapHeader = (dehaId: DeviceHardwareEnum, formatMessage: (message: MessageDescriptor) => string) => any[];

const mapHeader: MapHeader = (dehaId, formatMessage) => {
  const elements = tableElements.get(dehaId);
  if(!elements) return [];
  
  return elements.sort().map(element => {
    switch(element){
      case SensorTableElements.STATUS:
        return {text: formatMessage(header.status), align: "center"};
      case SensorTableElements.TYPE:
        return {text: formatMessage(header.type), align: "center"};
      case SensorTableElements.NAME:
        return {text: formatMessage(header.name)};
      case SensorTableElements.FUNCTION:
        return {text: formatMessage(header.function), align: "center"};
      case SensorTableElements.SERIAL_NUMBER:
        return {text: formatMessage(header.serialNumber)};
      case SensorTableElements.MAC:
        return {text: formatMessage(header.mac)}
      case SensorTableElements.RSSI:
        return {text: formatMessage(header.rssi), align: "center"};
      case SensorTableElements.BATTERY:
        return {text: formatMessage(header.battery), align: "center"};
      case SensorTableElements.ACTIONS:
        return {text: formatMessage(header.actions)};
      default:
        return null;
    }
  }).filter(item => item);
}

const isLoading = (sensor: ISensor, updatingName: number[], updatingType: number[], updatingFunction: number[]) => {
  return updatingName.includes(sensor.deseId) || updatingType.includes(sensor.deseId) || updatingFunction.includes(sensor.deseId)
}

type MapContent = (dehaId: DeviceHardwareEnum, sensors: ISensorDTO[], updatingName: number[], updatingType: number[], updatingFunction: number[], formatMessage: (message: MessageDescriptor) => string) => any;

const mapContent: MapContent = (dehaId, sensors, updatingName, updatingType, updatingFunction, formatMessage) => {
  const elements = tableElements.get(dehaId);
  if(!elements) return [];

  return sensors.filter((sensor: ISensorDTO) => filterSensors(sensor))
    .map((sensor: ISensorDTO) => {
    if(sensor.sensor.type === SensorTypeEnum.SAFEMATE_HOME && isLoading(sensor.sensor, updatingName, updatingType, updatingFunction)){
      return [
        {text: <LoaderWrap><Loader/></LoaderWrap>, colspan: SAFEMATE_HOME.length}
      ]
    }
    return elements.sort().map(element => {
      switch(element){
        case SensorTableElements.STATUS:
          return {text: mapStatus(sensor, formatMessage)};
        case SensorTableElements.TYPE:
          return {text: mapType(sensor)};
        case SensorTableElements.NAME:
          return {text: sensor.sensor.name};
        case SensorTableElements.FUNCTION:
          return {text: mapFunction(sensor)};
        case SensorTableElements.SERIAL_NUMBER:
          return {text: mapSerialNumber(sensor)};
        case SensorTableElements.MAC:
          return {text: mapMac(sensor)};
        case SensorTableElements.RSSI:
          return {text: mapRssi(sensor, formatMessage)};
        case SensorTableElements.BATTERY:
          return {text: mapBattery(sensor)};
        case SensorTableElements.ACTIONS:
          return {text: mapActions(sensor), preventClick: true}
        default:
          return null;
      }
    }).filter(item => item)
  });
}

const filterSensors = ({sensor}: ISensorDTO) => {
  switch(sensor.type){
    case SensorTypeEnum.BASE_STATION:
    case SensorTypeEnum.WRIST_BAND:
      return sensor.saved;
    default: return true;
  }
}

const mapSerialNumber = (sensor: ISensorDTO) => {
  switch(sensor.sensor.type){
    case SensorTypeEnum.SAFEMATE_HOME:{
      let snNum = sensor.sensor.gatewayId;
      if (snNum.endsWith('0')) {
        return snNum.slice(0, -1);
      }
      return snNum;
    }
    
    default: return null;
  }
}


const mapMac = (sensor: ISensorDTO) => {
  switch(sensor.sensor.type){
    case SensorTypeEnum.BASE_STATION:
    case SensorTypeEnum.WRIST_BAND:
      return sensor.sensor.macAddress;

    default: return null;
  }
}

const mapLastContact = (sensor: ISensorDTO, formatMessage: (message: MessageDescriptor) => string) => {
  const unknownYearFromDevice = 1970;
  switch(sensor.sensor.type){
    case SensorTypeEnum.SAFEMATE_HOME:
      return sensor.sensor.lastContact && moment.utc(sensor.sensor.lastContact).year() > unknownYearFromDevice
        ? moment.utc(sensor.sensor.lastContact).format('DD.MM.YYYY HH:mm')
        : formatMessage({id: "unknown", defaultMessage: "Ukjent"});

    default: return null;
  }
}

const mapBattery = (sensor: ISensorDTO) => {

  switch(sensor.sensor.type){
    case SensorTypeEnum.WRIST_BAND:
    case SensorTypeEnum.WRIST_BAND:
      const {battery, connectedStateDate} = sensor.sensor;
      return battery 
        ? <Tooltip
            popoverId={`battery_popover`}
            labelId={`${battery}%`}
            defaultMessage={`${battery}% (${moment(connectedStateDate).format("DD.MM.YYYY HH:mm")})`}
          >
            <WrapDiv>
              {getBatteryIcon(battery, false)}
            </WrapDiv>
          </Tooltip> 
        : null;

    default: return null;
  }
}
