import * as React from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import styled from "styled-components";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";

import { initialize, addDevice, getTwin, checkTwinSerialNumber } from "./Store/routines";
import Step from "./Step";
import Dropdown from "../../Elements/Dropdown/Dropdown";
import {Option} from "../../Elements/Dropdown/types";

import init from "../Initialization";
import { Customer, TwinDevice, CheckedTwin } from "./Store/types";
import { Grid, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import Plus from "../../Elements/Icon/Icons/Safemate/solid/PlusCircle";
import SafemateHeader from "../Header";
import { Checkbox } from "../../Elements/CheckboxFormik";
import UnsavedCheck from "../SaveNeeded/unsavedModal";
import { AppState } from "@Safemate/Store/types";
import { useParams } from "react-router";
import { parseUrlParams } from "@Safemate/utils";

const StyledInput = styled.input`
  && {
    width: 100%;
    height: 60px;
    font-size: 24px;
  }
`;

const SerialNumberInput = styled(StyledInput)`
  text-transform: uppercase;
  font-weight: bold;
  @media (min-width: 500px){
    letter-spacing: 16px;
    font-size: 24px;
  }
`;

const DropdownWrap = styled.div`
  margin-bottom: 15px;
  font-size: 24px;
  ul *{
    padding-top: 1px!important;
    padding-bottom: 1px!important;
  }
`;


interface AddSafemateProps{
  customers: Customer[];
  twinDevice: TwinDevice;
  processing: boolean;
  checkedTwin: CheckedTwin;
  addDevice: Routine<ActionFunctionAny<Action<any>>>;
  getTwin: Routine<ActionFunctionAny<Action<any>>>;
  checkTwinSerialNumber: Routine<ActionFunctionAny<Action<any>>>;
  isPrivate: boolean
}

const mapStateToProps = ({appData: { isPrivate }, addSafemate: { initialization: { initialized, processing }, customer: { customers }, device: { twinDevice, checkedTwin }}}: AppState) => {
  return {
    initialized,
    processing,
    customers,
    twinDevice,
    checkedTwin,
    isPrivate
  }
}

const mapDispatchToState = {
  initialize,
  getTwin,
  addDevice,
  checkTwinSerialNumber
}

const SERIAL_NUMBER_LENGTH = 8;

interface Params{
  params?: string;
}

export default connect(mapStateToProps, mapDispatchToState)(
  init<AddSafemateProps>(({customers, processing, addDevice, twinDevice, checkedTwin, getTwin, isPrivate, checkTwinSerialNumber}) => {

    const { params } = useParams<Params>();

    let twin = 0;

    if(params){
      const parsedParams = parseUrlParams(params);
      twin = parsedParams.twin;
    }

    const isTwin = !!twin;

    const [step, setStep] = React.useState(Steps.SERIAL_NUMBER);
    const [loop, setLoop] = React.useState(false);
    const [twinSerialToCheck, setTwinSerialToCheck] = React.useState("");

    const serialRef = React.useRef<HTMLInputElement>(null);
    const nameRef = React.useRef<HTMLInputElement>(null);

    const { formatMessage } = useIntl();

    React.useEffect(() => {
      if(isTwin){
        getTwin(twin);
      }
    }, [])

    const mappedCustomers: Option[] = React.useMemo(() => {
      return customers.map((customer) => {
        return{
          text: customer.firmName,
          value: customer.custId
        }
      })
    }, [customers])

    React.useEffect(() => {
      switch (step){
        case Steps.SERIAL_NUMBER:
            setTimeout(() => {
              if(serialRef.current)
                serialRef.current.focus();
            }, 100);
          break;
        case Steps.NAME:
          if(nameRef.current){
            nameRef.current.focus();
          }
          break;
      }

    }, [step, serialRef, nameRef])

    React.useEffect(() => {
      if(checkedTwin.match && checkedTwin.serialNumber === twinSerialToCheck){
        if(!processing){
          addDevice({
            values: {
              serialNumber: checkedTwin.serialNumber,
              unitName: `${twinDevice.deviceName} - Twin`,
              firmId: twinDevice.ownedByCustId,
              twin
            },
            callback: () => {
              location.hash = "safemates";
            },
            device: formatMessage({ id: "deviceAdded", defaultMessage: "ble lagt til" })
          });
        }
      }
    }, [checkedTwin.match, checkedTwin.serialNumber, checkedTwin.twinCheckUUID])


    return (
      <Formik
        onSubmit={(values, {resetForm}) => {
          addDevice({
            values: {
              ...values,
              firmId: isTwin ? twinDevice.ownedByCustId : values.firmId,
              unitName: isTwin ? `${twinDevice.deviceName} - Twin` : values.unitName,
              twin
            },
            callback: () => {
              if(loop){
                setStep(Steps.SERIAL_NUMBER)
                resetForm({
                  serialNumber: "",
                  unitName: "",
                  firmId: values.firmId
                });
              }
              else{
                resetForm();
                location.hash = "safemates";
              }
            },
            deviceCSID: formatMessage({ id: "deviceAddedCSID", defaultMessage: "ble lagt til med CSID" }),
            device: formatMessage({ id: "deviceAdded", defaultMessage: "ble lagt til" })
          })
        }}
        initialValues={{serialNumber: "", unitName: "", firmId: mappedCustomers ? mappedCustomers[0].value : ""}}
      >
        {({setFieldValue, values, dirty}) => 
        <Form>
          <Grid fluid>
            <Row>
              <Col lg={12}>
                <SafemateHeader
                  icon={<Plus size="large" inline/>}
                  title={
                    isTwin
                      ? `${formatMessage({id: "addTwinFor", defaultMessage: "Legg til tvilling av"})} ${twinDevice.deviceSNNum}`
                      : formatMessage({id: "addSafemate", defaultMessage: "Legg til en Safemate i"})
                  }
                />
              </Col>
            </Row>
            <Row>
              <Step
                step={Steps.SERIAL_NUMBER}
                active={{frame: step === Steps.SERIAL_NUMBER, forward: values.serialNumber.length === SERIAL_NUMBER_LENGTH}}
                title={formatMessage({id: "addSafeHeader1", defaultMessage: "Serienummer"})}
                desc={formatMessage({id: "addSafeInfo1", defaultMessage: "Legg til serienummer"})}
                twin={isTwin}
                actions={{next: () => {
                  if(isTwin){
                    setTwinSerialToCheck(values.serialNumber);
                    checkTwinSerialNumber({
                      serialNumber: values.serialNumber,
                      twinDeviId: twin
                    })
                  }
                  else{
                    setStep(Steps.NAME)
                  }
                }}}
              >
                <SerialNumberInput 
                  ref={serialRef}
                  onChange={(event) => {
                    const { value } = event.currentTarget;
                    if(value.length <= SERIAL_NUMBER_LENGTH) 
                      setFieldValue("serialNumber", value);
                    if(value.length >= SERIAL_NUMBER_LENGTH && !isTwin)
                      setStep(Steps.NAME);
                  }} 
                  value={values.serialNumber}
                  placeholder="NAAA1234" 
                  disabled={step !== Steps.SERIAL_NUMBER} 
                  type="text"
                />
              </Step>
              {!isTwin &&
              <Step
                step={Steps.NAME}
                active={{frame: step === Steps.NAME, forward: values.unitName !== ""}}
                title={formatMessage({id: "addSafeHeader2", defaultMessage: "Navn"})}
                desc={formatMessage({id: "addSafeInfo2", defaultMessage: "Velg navn du ønsker å gi enheten"})}
                actions={
                  (isPrivate || isTwin) 
                    ? { back: () => setStep(Steps.SERIAL_NUMBER), finish: true}
                    : {next: () => setStep(Steps.BELONGING), back: () => setStep(Steps.SERIAL_NUMBER)}
                }
              >
                <StyledInput 
                  ref={nameRef}
                  onChange={(event) => setFieldValue("unitName", event.currentTarget.value)}
                  value={values.unitName} 
                  disabled={step !== Steps.NAME} 
                  placeholder={formatMessage({id: "unitName", defaultMessage: "Enhetsnavn"})}
                  type="text"
                />
              </Step>}
              {!isPrivate && !isTwin &&
              <Step
                step={Steps.BELONGING}
                active={{frame: step === Steps.BELONGING, forward: values.firmId !== ""}}
                title={formatMessage({id: "addSafeHeader3", defaultMessage: "Tilhørighet"})}
                desc={formatMessage({id: "addSafeInfo3", defaultMessage: "Velg underavdeling enheten skal tilhøre"})}
                actions={{back: () => setStep(Steps.NAME), finish: true}}
                processing={processing}
              >
                <div>
                  <DropdownWrap>
                    <Dropdown
                      options={mappedCustomers}
                      onChange={(option) => {
                        const { value } = option as Option;
                        setFieldValue("firmId", value);
                      }}
                      initial={mappedCustomers && mappedCustomers[0].value}
                      disabled={step !== Steps.BELONGING}
                      searchable={true}
                      size={{width: "100%", height: "60px"}}
                    />
                  </DropdownWrap>
                  <Checkbox
                    disabled={step !== Steps.BELONGING}
                    value={loop}
                    id={"loop"}
                    label={formatMessage({id: "addSafeLoopInfo", defaultMessage: "Legg til flere Safemate etter denne"})}
                    onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
                      const value = event.currentTarget.checked;
                      setLoop(value);
                    }}
                  />
                </div>
              </Step>}
            </Row>
          </Grid>
          <UnsavedCheck dirtyOverride={() => {
            if(isTwin){
              return false;
            }
            else{
              return dirty;
            }
          }}/>
        </Form>}
      </Formik>
    ) 
  })
)

enum Steps{
  SERIAL_NUMBER = 1,
  NAME = 2,
  BELONGING = 3
}
