import * as React from "react";
import { injectIntl, MessageDescriptor, useIntl, WrappedComponentProps } from "react-intl";
import styled from "styled-components";

import { SettingEnum } from "./settingEnum";
import { ValueSelector } from "./SoundLight/Components/valueSelector";
import ValueSlider from "./SoundLight/Components/slider";
import { DeviceHardwareEnum, isGx8 } from "../DeviceHardwareEnum";
import {
  SoundsForCharging,
  IncomingRingTone,
  Led,
  NotApplicable,
  LightMode,
  LowBatteryVoicePrompt,
  ScreenBrightness, ScreenLightMode, SoundFilesLanguage
} from "./SoundLight/Components";
import GetPosition from "./Tracking/TrackingMode/getPosition";
import AutoToggle from "./Tracking/TrackingMode/autoToggle";
import { AlwaysOn } from "./Tracking/TrackingMode/autoTrackingDesc";
import GeneralSettings, {
  StaticTooLong,
  EnableOffButton,
  Vibration,
  StepCounter,
  AclCheck,
  HealthHeartRateBloodPressure,
  WatchRemoval
} from "./General/Tabs/generalSettings";
import {
  HeartRateBloodPressureSettings,
  HeartRateBloodPressureSetting,
  HeartRate,
  BloodPressure,
  CalibrateBloodPressureSetting,
  CalibrateSystolicPressure,
  CalibrateDiastolicPressure
} from "./General/Tabs/heartRateBloodPressure";
import DeviceLog, { DeviceAuditLog, DeviceCallLog } from "./General/Tabs/deviceLog";
import DeleteData, { DeleteSafemate, ResetSafemate, DeleteHistory, SwitchSafemate, ClearWifiCache } from "./General/Tabs/deleteData";
// import Positioning, { PositionToggle, Satellites } from "./General/Tabs/positioning";
import Battery, { BatteryThreshold } from "./General/Tabs/battery";
import AlarmSettings, { ButtonBehaviour, ButtonSensitivity, DeliveryFormat, FallDetection, SilentAlarm, T4Vibration, SOSCancellation, VoicePromptSosAlarm, TeamAlert } from "./General/Tabs/alarmSettings";

import Tracking from "./Tracking";
import Geofence from "./Geofence";
import IndoorLocationCom from "./IndoorLocation";
import SoundLight from "./SoundLight";
import Organisation from "./Organisation";
import General from "./General";
import Info from "./Info";
import { PolicyNameEnum } from "../PolicyNameEnum";

import Cog from "../../Elements/Icon/Icons/Safemate/solid/Cog";
import Log from "../../Elements/Icon/Icons/Log";
import Bell from "../../Elements/Icon/Icons/Safemate/solid/Bell";
import LightbulbOn from "../../Elements/Icon/Icons/Safemate/outline/LightbulbOn";
import BatteryBolt from "../../Elements/Icon/Icons/Safemate/solid/BatteryBolt";
import Database from "../../Elements/Icon/Icons/Safemate/solid/Database";
import FallSensor from "../../Elements/Icon/Icons/Safemate/solid/FallSensor";
import HeartRateIcon from "../../Elements/Icon/Icons/Safemate/outline/HeartRateIcon";
import TrackingInterval, { NoTrackingInterval } from "./Tracking/TrackingOptions/trackingInterval";
import { ManualTracking } from "./Tracking/trackingInfo";
import { PositionToggle, Satellites, AcceptLBS } from "./Tracking/TrackingOptions/positioning";
import FindMe from "./Tracking/TrackingMode/findMe";
import { FallDownAlarm, TiltAlert, ManDown, WatchTwoRadioManDown } from "./General/Tabs/manDown";
import { BypassTimer, GuardTime, LocalLearning as LocalLearningCheck, SensorSensitivity } from "./General/Tabs/gx8/generalSettings";
import CommunicationSettings, {GX8CommunicationSettingsSectionOne, GX8CommunicationSettingsSectionTwo} from "./General/Tabs/gx8/communicationSettings";
import LightSoundSettings, {GX8LightSoundSettingsSectionOne, GX8LightSoundSettingsSectionTwo} from "./General/Tabs/gx8/lightSoundSettings";
import {PowerFailureReport} from "./General/Tabs/gx8/batterySettings";
import LogsReport, { GdprReport, SignalStrengthReport } from "./General/Tabs/gx8/logsReports";
import Information, { RebootDevice } from "./General/Tabs/gx8/information";
import Gx8Log, { AuditLog, CallLog } from "./General/Tabs/gx8/log";
import NotBackInBed from "./EventConfig/NotBackInBed";
import Schedule from "./EventConfig/Schedule";
import EventConfig from "./EventConfig";
import { ActiveIndicator } from "./styles";
import { FunctionStatus, Moon } from "./EventConfig/utils";
import { EPILEPSY_FUNCTION, FRIDGE_FUNCTION, NOT_BACK_IN_BED_FUNCTION, OUT_OF_BED, OUT_OF_BEDROOM, WANDERER_FUNCTION } from "./Sensor/SensorFunctionEnum";
import NotinbedSensor from "@Icons/Notinbed";
import FridgeSensor from "@Icons/Fridge";
import WandererSensor from "@Icons/Movement";
import InactiveIcon from '@Icons/Inactive';
import EpilepsySensor from '@Icons/Epilepsysensor';
import Fridge, { FridgeAllowTimeOpen } from "./EventConfig/Fridge";
import Wanderer, { VoiceSatellite } from "./EventConfig/Wanderer";
import { Delay, DelayWanderer } from "./EventConfig/components";
import Inactivity, { InactivityField, InactivityWarnTime } from "./EventConfig/Inactivity";
import Epilepsy from "./EventConfig/Epilepsy";
import Walktest from "./Walktest";
import SensorTable from "./Sensor/sensorTable";
import LocalLearning from "./Sensor/Add/localLearning";
import AddSensor from "./Sensor/Add/addSensor";
import TwinDevice from "./General/Tabs/twinDevice";
import Clone from "../../Elements/Icon/Icons/FontAwesome/Clone";
import PowerSaving from "./Tracking/TrackingMode/powerSaving";
import { ISensorDTO } from "./Sensor/types";
import {Device} from "@Safemate/Settings/types";
import Simulate from "./General/Tabs/simulate";
import OutOfBed from "./EventConfig/OutOfBed";
import {IndoorLocation} from "@Safemate/Settings/IndoorLocation/types";
import VoicePromptSettings, { VoicePromptACFailure, VoicePromptACRestore, VoicePromptAlarm, VoicePromptBed, VoicePromptCallback, VoicePromptFall, VoicePromptWanderer } from "./General/Tabs/gx8/voicePromptSettings";
import LightSettings, { CallbackIndicator, HelpArriveIndicator, LightAlarmMode, LightNormalMode } from "./General/Tabs/gx8/lightSettings";
import SelfcheckFail from "@Elements/Icon/Icons/Safemate/solid/SelfcheckFail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faInfoCircle, faMicrophoneAlt, faVolumeUp } from "@fortawesome/pro-solid-svg-icons";
import LightbulbOnSolid from "@Elements/Icon/Icons/Safemate/solid/LightbulbOnSolid";


const Title = styled.span`
  display: flex;
  align-items: center;
  path{
    fill: ${props => props.theme.colors.textPrimary};
  }
`;

const TitleContent = styled.span`
  margin-left: 10px;
`;

interface LightHeaderProps extends WrappedComponentProps {
  message: MessageDescriptor;
}

export const Header = injectIntl(({ intl: { formatMessage }, message }: LightHeaderProps) => {
  return (
    <div>
      <h3>
        {formatMessage(message)}
      </h3>
    </div>
  )
})


export const DEFAULT: Map<SettingEnum, JSX.Element[]> = new Map<SettingEnum, JSX.Element[]>([
  [SettingEnum.LIGHT, 
    [<ValueSelector
      title={{ id: "ledStrength", defaultMessage: "Lysstyrke av/på-knapp" }}
      name="lightLevel"
      count={5}
    />]],
  [SettingEnum.SOUND,
    [<ValueSelector
      title={{ id: "volume", defaultMessage: "Høyttalervolum" }}
      name="soundLevel"
      startLevel={1}
      count={4}
    />,
    <SoundsForCharging/>,
    <IncomingRingTone/>]],
  [SettingEnum.TRACKING_MANUAL,
    [<ManualTracking />]],
  [SettingEnum.TRACKING_OPTIONS,
    [<PositionToggle/>, <Satellites/>, <AutoToggle />, <TrackingInterval/>]],
  [SettingEnum.GENERAL_SETTINGS,
    [<AclCheck/>, <StaticTooLong />]],
  [SettingEnum.DEVICE_LOG,
    [<DeviceAuditLog />, <DeviceCallLog />]],
  [SettingEnum.DELETE_DATA,
    [<SwitchSafemate />, <ResetSafemate />, <DeleteHistory />, <ClearWifiCache />, <DeleteSafemate />]],
  /*[SettingEnum.POSITIONING,
    [<PositionToggle />, <Satellites />]],*/
  [SettingEnum.BATTERY,
    [<BatteryThreshold />]],
  [SettingEnum.ALARM_SETTINGS,
    [<DeliveryFormat />]],
  [SettingEnum.SOUND_LIGHT,
    []],
  [SettingEnum.TWIN_DEVICE,
    []],
  [SettingEnum.SIMULATE,
    []]
]);

export const WATCH_ONE: Map<SettingEnum, JSX.Element[]> = new Map<SettingEnum, JSX.Element[]>([
  ...DEFAULT,
  [SettingEnum.LIGHT,
  [<NotApplicable />]],
  [SettingEnum.SOUND,
  [<ValueSelector
    title={{ id: "ringtoneVolume", defaultMessage: "Anropsvolum" }}
    name="soundLevelRingtone"
    count={3}
    label={[
      { id: "lowVol", defaultMessage: "Lavt" },
      { id: "mediumVol", defaultMessage: "Medium" },
      { id: "highVol", defaultMessage: "Høyt" },
    ]}
  />,
  <ValueSelector
    title={{ id: "callVolume", defaultMessage: "Samtalevolum" }}
    name="soundLevel"
    count={3}
    label={[
      { id: "lowVol", defaultMessage: "Lavt" },
      { id: "mediumVol", defaultMessage: "Medium" },
      { id: "highVol", defaultMessage: "Høyt" },
    ]}
  />
  ]],
  [SettingEnum.TRACKING_MANUAL,
    [<GetPosition />, <FindMe/>]],
  [SettingEnum.TRACKING_OPTIONS,
    [<PositionToggle/>, <Satellites/>, <AutoToggle />, <NoTrackingInterval/>, <AcceptLBS />]],
  [SettingEnum.POSITIONING,
    [<PositionToggle/>]],
  [SettingEnum.GENERAL_SETTINGS,
    [<AclCheck/>]]
]);

export const WATCH_THREE = WATCH_ONE;

export const WATCH_TWO: Map<SettingEnum, JSX.Element[]> = new Map<SettingEnum, JSX.Element[]>([
  ...DEFAULT,
  [SettingEnum.LIGHT,
    [
      <ScreenBrightness />,
      <ScreenLightMode  />,
    ]],
  [SettingEnum.SOUND,
    [<LowBatteryVoicePrompt />]],
  [SettingEnum.TRACKING_MANUAL,
    [<ManualTracking />, <GetPosition />, <FindMe />]],
  [SettingEnum.TRACKING_OPTIONS,
    [<PositionToggle/>, <Satellites/>, <AutoToggle />, <TrackingInterval dehaId={DeviceHardwareEnum.WATCH_TWO} />, <AcceptLBS />]],
  [SettingEnum.MAN_DOWN_SETTINGS,
    [
      <WatchTwoRadioManDown/>,
    ]],
  [SettingEnum.GENERAL_SETTINGS,
    [<EnableOffButton/>, <AclCheck/>, <StepCounter/>, <Vibration/>, <HealthHeartRateBloodPressure />, <WatchRemoval />]],
  [SettingEnum.ALARM_SETTINGS,
    [<ButtonBehaviour/>,
      <ButtonSensitivity/>,
      <DeliveryFormat/>,
      <SilentAlarm/>,
      <TeamAlert/>
    ]],
  [SettingEnum.HEART_RATE_BLOOD_PRESSURE,
    [
      <HeartRateBloodPressureSetting />,
      <HeartRate/>,
      <BloodPressure/>,
      <CalibrateBloodPressureSetting/>,
      <CalibrateSystolicPressure/>,
      <CalibrateDiastolicPressure/>,
    ]],
]);

export const WATCH_FIVE = new Map<SettingEnum, JSX.Element[]>([
  ...DEFAULT,
  [SettingEnum.LIGHT,
    [
      <ScreenBrightness />,
      <ScreenLightMode  />,
    ]],
  [SettingEnum.SOUND,
    [<LowBatteryVoicePrompt />]],
  [SettingEnum.TRACKING_MANUAL,
    [<ManualTracking />, <GetPosition />, <FindMe />]],
  [SettingEnum.TRACKING_OPTIONS,
    [<PositionToggle/>, <Satellites/>, <AutoToggle />, <TrackingInterval dehaId={DeviceHardwareEnum.WATCH_TWO} />, <AcceptLBS />]],
  [SettingEnum.GENERAL_SETTINGS,
    [<EnableOffButton/>, <AclCheck/>, <StepCounter/>, <Vibration/>, <HealthHeartRateBloodPressure />, <WatchRemoval />]],
  [SettingEnum.ALARM_SETTINGS,
    [<ButtonBehaviour/>,
      <ButtonSensitivity/>,
      <DeliveryFormat/>,
      <SilentAlarm/>,
      <TeamAlert/>
    ]],
  [SettingEnum.HEART_RATE_BLOOD_PRESSURE,
    [
      <HeartRateBloodPressureSetting />,
      <HeartRate/>,
      <BloodPressure/>,
      <CalibrateBloodPressureSetting/>,
      <CalibrateSystolicPressure/>,
      <CalibrateDiastolicPressure/>,
    ]],
]);

export const TRIGGER_ONE: Map<SettingEnum, JSX.Element[]> = new Map<SettingEnum, JSX.Element[]>([
  ...DEFAULT,
  [SettingEnum.ALARM_SETTINGS,
    [<ButtonBehaviour/>,
    <ButtonSensitivity/>,
    <DeliveryFormat/>,
    <FallDetection/>]]
]);

export const TRIGGER_THREE: Map<SettingEnum, JSX.Element[]> = new Map<SettingEnum, JSX.Element[]>([
  ...DEFAULT,
  [SettingEnum.TRACKING_MANUAL,
    [<ManualTracking />, <GetPosition />]],
  [SettingEnum.LIGHT,
    [<Led />]],
  [SettingEnum.SOUND,
    [<NotApplicable />]],
  [SettingEnum.TRACKING_OPTIONS, 
    [<PositionToggle/>, <Satellites/>, <AutoToggle/>, <PowerSaving/>, <TrackingInterval/>]],
  [SettingEnum.GENERAL_SETTINGS,
    [<AclCheck/>]],
  [SettingEnum.ALARM_SETTINGS,
    [...DEFAULT.get(SettingEnum.ALARM_SETTINGS), <FallDetection/>, <SilentAlarm/>]]

]);

export const GTX_SOLE: Map<SettingEnum, JSX.Element[]> = new Map<SettingEnum, JSX.Element[]>([
  ...DEFAULT,
  [SettingEnum.TRACKING_MANUAL,
    []],
  [SettingEnum.TRACKING_OPTIONS,
    [<Satellites/>, ]],
  [SettingEnum.GENERAL_SETTINGS,
    [<StaticTooLong />]],
  [SettingEnum.DEVICE_LOG,
    [<DeviceAuditLog />]],
  [SettingEnum.LIGHT,
    [<NotApplicable />]],
  [SettingEnum.SOUND,
    [<NotApplicable />]],

]);

export const TRIGGER_FOUR: Map<SettingEnum, JSX.Element[]> = new Map<SettingEnum, JSX.Element[]>([
  ...DEFAULT,
  [SettingEnum.TRACKING_MANUAL,
    [<ManualTracking />, <GetPosition />, <FindMe/>]],
  [SettingEnum.TRACKING_OPTIONS,
    [<PositionToggle/>, <Satellites/>, <AutoToggle />, <TrackingInterval dehaId={DeviceHardwareEnum.TRIGGER_FOUR} />, <AcceptLBS />]],
  [SettingEnum.LIGHT,
    [<Led/>]],
  [SettingEnum.SOUND,
    [<ValueSlider
      title={{ id: "microphoneSensitivityToolTipTitle", defaultMessage: "Mikrofonsensitivitet" }}
      name="micVolume"
      limit={[0, 15]}
    />,
    <ValueSlider
      title={{ id: "volume", defaultMessage: "Høyttalervolum" }}
      name="soundLevel"
      limit={[0, 100]}
    />,
    <ValueSlider
      title={{ id: "ringtoneVolume", defaultMessage: "Anropsvolum" }}
      name="soundLevelRingtone"
      limit={[0, 100]}
    />,
    <ValueSlider
      title={{ id: "voicePromptVolume", defaultMessage: "Talemeldingvolum" }}
      name="voicePromptVolume"
      limit={[0, 100]}
    />,
    <SoundsForCharging/>, <SoundFilesLanguage/> ]],
  [SettingEnum.ALARM_SETTINGS, 
    [<ButtonBehaviour/>,
    <ButtonSensitivity/>,
    <DeliveryFormat/>,
    <T4Vibration/>,
    <SilentAlarm/>,
    <SOSCancellation />,
    <VoicePromptSosAlarm/>
    ]],
  [SettingEnum.GENERAL_SETTINGS,
    [<EnableOffButton/>, <AclCheck/>]],
  [SettingEnum.MAN_DOWN_SETTINGS,
    [<FallDownAlarm/>,
    <ValueSlider
      title={{ id: "fallDownSensitivity", defaultMessage: "Fallalarm sensitivitet" }}
      name="fallDownSensitivity"
      parent="fallDownAlert"
      limit={[1, 9]}
    />,
    <TiltAlert/>,
    <ValueSlider
      title={{ id: "tiltAngle", defaultMessage: "Tiltalarm vinkel" }}
      name="tiltAngle"
      parent="tiltAlert"
      limit={[0, 90]}
    />,
    <ValueSlider
      title={{ id: "tiltTime", defaultMessage: "Tiltalarm tid" }}
      name="tiltTime"
      parent="tiltAlert"
      limit={[1, 3600]}
    />,
    ]],
  [SettingEnum.SENSOR,
    [<AddSensor/>]]
]);

export const TRIGGER_THREE_E = TRIGGER_FOUR;
export const TRACK_TWO = TRIGGER_FOUR;
export const WATCH_FOUR = TRIGGER_FOUR;
export const WATCH_SIX = new Map<SettingEnum, JSX.Element[]>([
  ...TRIGGER_FOUR,
  [SettingEnum.LIGHT,
    [<ValueSlider
      title={{ id: "screenBrightness", defaultMessage: "Screen brightness" }}
      name="screenBrightness"
      limit={[1, 10]}
    />]],
]);

export const SAFEMATE_HELP: Map<SettingEnum, JSX.Element[]> = new Map<SettingEnum, JSX.Element[]>([
  ...DEFAULT,
  [SettingEnum.LIGHT,
    [<NotApplicable />]],
  [SettingEnum.SOUND,
    [<NotApplicable />]],
  [SettingEnum.TRACKING_OPTIONS,
    [<PositionToggle/>, <AutoToggle />, <TrackingInterval/>]]
]);

export const TRACK_ONE: Map<SettingEnum, JSX.Element[]> = new Map<SettingEnum, JSX.Element[]>([
  ...DEFAULT,
  [SettingEnum.LIGHT,
    [<LightMode />]],
  [SettingEnum.SOUND,
    [<NotApplicable />]],
  [SettingEnum.TRACKING_MANUAL,
    DEFAULT.get(SettingEnum.TRACKING_MANUAL) as JSX.Element[]],
  [SettingEnum.TRACKING_OPTIONS,
    [<PositionToggle/>, <Satellites/>, <AutoToggle />, <TrackingInterval/>]],
  [SettingEnum.ALARM_SETTINGS,
    [<ButtonBehaviour/>,
      <ButtonSensitivity/>,
      <DeliveryFormat/>,
      <SilentAlarm/>]],
]);

export const TRIGGER_TWO: Map<SettingEnum, JSX.Element[]> = new Map<SettingEnum, JSX.Element[]>([
  ...TRACK_ONE,
  [SettingEnum.GENERAL_SETTINGS,
    [...TRACK_ONE.get(SettingEnum.GENERAL_SETTINGS), <EnableOffButton/>]],
  [SettingEnum.TRACKING_OPTIONS,
    [<PositionToggle/>, <Satellites/>, <AutoToggle />, <TrackingInterval/>]],
]);

export const TRIGGER_TWO_4G : Map<SettingEnum, JSX.Element[]> = new Map<SettingEnum, JSX.Element[]>([
  ...TRIGGER_TWO,
  [SettingEnum.TRACKING_MANUAL,
    [<ManualTracking />, <GetPosition />]],
  [SettingEnum.MAN_DOWN_SETTINGS,
    [<FallDownAlarm/>,
      <ValueSlider
        title={{ id: "fallDownSensitivity", defaultMessage: "Fallalarm sensitivitet" }}
        name="fallDownSensitivity"
        parent="fallDownAlert"
        limit={[1, 9]}
      />,
    ]],
  [SettingEnum.ALARM_SETTINGS,
    [<ButtonBehaviour/>,
      <ButtonSensitivity/>,
      <DeliveryFormat/>,
      <SilentAlarm/>,
      <SOSCancellation />,
    ]],
  [SettingEnum.LIGHT,
    [<LightMode />]],
  [SettingEnum.SOUND,
    [
      <ValueSlider
        title={{ id: "volume", defaultMessage: "Høyttalervolum" }}
        name="soundLevel"
        limit={[1, 100]}
      />,
      <ValueSlider
        title={{ id: "ringtoneVolume", defaultMessage: "Anropsvolum" }}
        name="soundLevelRingtone"
        limit={[0, 8]}
      />,
    ]],
  [SettingEnum.GENERAL_SETTINGS,
    [
      <EnableOffButton/>, <AclCheck/>
    ]],
]);

export const TRACK_FOUR : Map<SettingEnum, JSX.Element[]> = new Map<SettingEnum, JSX.Element[]>([
  ...TRIGGER_TWO_4G,
  [SettingEnum.TRACKING_MANUAL,
    [<ManualTracking />, <GetPosition />]],
  [SettingEnum.MAN_DOWN_SETTINGS,
    []],
  [SettingEnum.ALARM_SETTINGS,
    []],
  [SettingEnum.LIGHT,
    []],
  [SettingEnum.SOUND,
    [,
    ]],
  [SettingEnum.GENERAL_SETTINGS,
    []],
]);

export const TRACK_THREE: Map<SettingEnum, JSX.Element[]> = new Map<SettingEnum, JSX.Element[]>([
  ...DEFAULT,
  [SettingEnum.LIGHT,
    [<Led />]],
  [SettingEnum.SOUND,
    [<NotApplicable />]],
  [SettingEnum.TRACKING_MANUAL,
    [<ManualTracking />, <GetPosition/>]],
  [SettingEnum.TRACKING_OPTIONS,
    [<PositionToggle/>, <Satellites/>, <TrackingInterval/>]],
  [SettingEnum.ALARM_SETTINGS, 
    [<DeliveryFormat/>,
    <FallDetection/>]],
]);


export const SAFEMATE_HOME: Map<SettingEnum, JSX.Element[]> = new Map<SettingEnum, JSX.Element[]>([
  [SettingEnum.GX8_GENERAL,
    [<SensorSensitivity/>, <GuardTime/>, <BypassTimer/>, <AclCheck/>, <LocalLearningCheck/>]],
  [SettingEnum.GX8_COMMUNICATION,
    [<GX8CommunicationSettingsSectionOne/>, <GX8CommunicationSettingsSectionTwo/>]],
  [SettingEnum.GX8_SOUND,
    [<GX8LightSoundSettingsSectionOne/>, <GX8LightSoundSettingsSectionTwo/>]],
  [SettingEnum.GX8_LIGHT,
    [<LightAlarmMode/>, <LightNormalMode/>, <HelpArriveIndicator/>, <CallbackIndicator/>]],
  [SettingEnum.GX8_VOICE_PROMPT,
    [<VoicePromptAlarm/>, <VoicePromptCallback/>, <VoicePromptFall/>,  <VoicePromptWanderer/>, <VoicePromptBed/>, <VoicePromptACFailure/>, <VoicePromptACRestore/>]],
  [SettingEnum.GX8_BATTERY,
    [<BatteryThreshold/>, <PowerFailureReport/>]],
  [SettingEnum.GX8_INFORMATION,
    [<ResetSafemate/>, <DeleteHistory/>, <RebootDevice/>, <DeleteSafemate/>]],
  [SettingEnum.GX8_LOG,
    [<SignalStrengthReport/>, <GdprReport/>, <AuditLog/>, <CallLog/>]],
  [SettingEnum.FRIDGE_EVENT,
    [<FridgeAllowTimeOpen/>]],
  [SettingEnum.WANDERER_EVENT,
    [<Schedule from to/>, <VoiceSatellite/>, <DelayWanderer/>]],
  [SettingEnum.NOT_BACK_IN_BED_EVENT,
    [<Schedule from to/>, <Delay/>]],
  [SettingEnum.OUT_OF_BED_EVENT,
    [<Schedule from to/>, <VoiceSatellite/>]],
  [SettingEnum.INACTIVITY_EVENT,
    [<InactivityField 
      intervalName="inactivityIntervalOne" 
      periodToName="inactivityToOne" 
      periodFromName="inactivityFromOne"
      count={1}
    />,
    <InactivityField 
      intervalName="inactivityIntervalTwo" 
      periodToName="inactivityToTwo" 
      periodFromName="inactivityFromTwo"
      count={2}
    />,
    <InactivityWarnTime/>]],
    [SettingEnum.EPILEPSY_EVENT,[]],
    [SettingEnum.SENSOR, 
      [<LocalLearning/>]]
  ])

export const SAFEMATE_HOME_DT35 = SAFEMATE_HOME;
export const SAFEMATE_HOME_MAX = SAFEMATE_HOME;
export const SAFEMATE_HOME_STCB1 = SAFEMATE_HOME;


export const DeviceComponents: Map<DeviceHardwareEnum, Map<SettingEnum, JSX.Element[]>> = new Map<DeviceHardwareEnum, Map<SettingEnum, JSX.Element[]>>([
  [DeviceHardwareEnum.DEFAULT, DEFAULT],
  [DeviceHardwareEnum.WATCH_ONE, WATCH_ONE],
  [DeviceHardwareEnum.WATCH_TWO, WATCH_TWO],
  [DeviceHardwareEnum.WATCH_THREE, WATCH_THREE],
  [DeviceHardwareEnum.WATCH_FIVE, WATCH_FIVE],
  [DeviceHardwareEnum.TRIGGER_ONE, TRIGGER_ONE],
  [DeviceHardwareEnum.TRIGGER_TWO, TRIGGER_TWO],
  [DeviceHardwareEnum.TRIGGER_THREE, TRIGGER_THREE],
  [DeviceHardwareEnum.TRIGGER_THREE_E, TRIGGER_THREE_E],
  [DeviceHardwareEnum.TRIGGER_FOUR, TRIGGER_FOUR],
  [DeviceHardwareEnum.TRACK_ONE, TRACK_ONE],
  [DeviceHardwareEnum.TRACK_THREE, TRACK_THREE],
  [DeviceHardwareEnum.SAFEMATE_HELP, SAFEMATE_HELP],
  [DeviceHardwareEnum.SAFEMATE_HOME, SAFEMATE_HOME],
  [DeviceHardwareEnum.SAFEMATE_HOME_DT35, SAFEMATE_HOME_DT35],
  [DeviceHardwareEnum.SAFEMATE_HOME_MAX, SAFEMATE_HOME_MAX],
  [DeviceHardwareEnum.TRIGGER_TWO_4G, TRIGGER_TWO_4G],
  [DeviceHardwareEnum.WATCH_FOUR, WATCH_FOUR],
  [DeviceHardwareEnum.TRACK_TWO, TRACK_TWO],
  [DeviceHardwareEnum.GTX_SOLE, GTX_SOLE],
  [DeviceHardwareEnum.WATCH_SIX, WATCH_SIX],
  [DeviceHardwareEnum.TRACK_FOUR, TRACK_FOUR],
  [DeviceHardwareEnum.SAFEMATE_HOME_STCB1, SAFEMATE_HOME_STCB1],
])

export const SettingToPolicyMap: Map<SettingEnum, PolicyNameEnum> = new Map<SettingEnum, PolicyNameEnum>(
  [
    [SettingEnum.TRACKING, PolicyNameEnum.DEVICE_TRACKING],
    [SettingEnum.GEOFENCE, PolicyNameEnum.DEVICE_GEOFENCE],
    [SettingEnum.INDOOR, PolicyNameEnum.DEVICE_INDOOR],
    [SettingEnum.INDOOR_LOCATION, PolicyNameEnum.DEVICE_INDOOR],
    [SettingEnum.SENSOR, PolicyNameEnum.DEVICE_SENSOR],
    [SettingEnum.SENSOR_TEMP, PolicyNameEnum.DEVICE_SENSOR],
    [SettingEnum.ORGANIZATION, PolicyNameEnum.DEVICE_ORGANIZATION],
    [SettingEnum.INFO, PolicyNameEnum.DEVICE_INFO],
    [SettingEnum.GENERAL, PolicyNameEnum.GENERAL_SETTINGS],
    [SettingEnum.GENERAL_SETTINGS, PolicyNameEnum.SAFEMATE_GENERAL],
    [SettingEnum.DEVICE_LOG, PolicyNameEnum.SAFEMATE_DEVICE_LOGS],
    [SettingEnum.DELETE_DATA, PolicyNameEnum.SAFEMATE_DELETE_DATA],
    [SettingEnum.SOUND_LIGHT, PolicyNameEnum.DEVICE_LIGHT_SOUND],
    [SettingEnum.POSITIONING, PolicyNameEnum.SAFEMATE_POSITIONING],
    [SettingEnum.BATTERY, PolicyNameEnum.SAFEMATE_BATTERY_TRESHHOLD],
    [SettingEnum.ALARM_SETTINGS, PolicyNameEnum.SAFEMATE_ALARM],
    [SettingEnum.MAN_DOWN_SETTINGS, PolicyNameEnum.SAFEMATE_MAN_DOWN],
    [SettingEnum.HEART_RATE_BLOOD_PRESSURE, PolicyNameEnum.SAFEMATE_HEART_RATE_BLOOD_PRESSURE],
    [SettingEnum.GX8_SOUND, PolicyNameEnum.GX8_LIGHT_SOUND],
    [SettingEnum.GX8_LIGHT, PolicyNameEnum.GX8_LIGHT_SOUND],
    [SettingEnum.GX8_VOICE_PROMPT, PolicyNameEnum.GX8_LIGHT_SOUND],
    [SettingEnum.GX8_BATTERY, PolicyNameEnum.GX8_BATTERY],
    [SettingEnum.GX8_LOGS_REPORT, PolicyNameEnum.GX8_LOGS_REPORT],
    [SettingEnum.GX8_GENERAL, PolicyNameEnum.GX8_GENERAL],
    [SettingEnum.GX8_COMMUNICATION, PolicyNameEnum.GX8_COMMUNICATION],
    [SettingEnum.GX8_INFORMATION, PolicyNameEnum.GX8_INFORMATION],
    [SettingEnum.GX8_LOG, PolicyNameEnum.GX8_LOGS_REPORT],
    [SettingEnum.EVENT_CONFIG, PolicyNameEnum.DEVICE_SETTINGS],
    [SettingEnum.WALKTEST, PolicyNameEnum.DEVICE_SETTINGS],
    [SettingEnum.TWIN_DEVICE, PolicyNameEnum.GENERAL_SETTINGS],
    [SettingEnum.SIMULATE, PolicyNameEnum.GENERAL_SETTINGS]
  ]
)

export interface GSMRange{
  low: number,
  high: number,
  label: string,
  desc: string
}

const gsm9: GSMRange = {
  low: 0,
  high: 9,
  label: "gsmStrengthDescSafemateHome",
  desc: "0 - 5, over 3 er et godt signal"
}

const gsm31: GSMRange = {
  low: 0,
  high: 31,
  label: "gsmStrengthDescTriggerFour",
  desc: "0 - 31, over 20 er et godt signal"
}

export const gsm100: GSMRange = {
  low: 0,
  high: 100,
  label: "gsmStrengthDescWatchTwo",
  desc: "0 - 100, over 65 er et godt signal"
}


export const supportGsmStrength: Map<DeviceHardwareEnum, GSMRange> = new Map<DeviceHardwareEnum, GSMRange>(
  [
    [DeviceHardwareEnum.ALL, gsm100],
    [DeviceHardwareEnum.TRACK_ONE, gsm31],
    [DeviceHardwareEnum.TRACK_TWO, gsm31],
    [DeviceHardwareEnum.TRACK_THREE, gsm31],
    [DeviceHardwareEnum.TRACK_FOUR, gsm31],
    [DeviceHardwareEnum.TRIGGER_TWO, gsm31],
    [DeviceHardwareEnum.TRIGGER_TWO_4G, gsm31],
    [DeviceHardwareEnum.TRIGGER_THREE, gsm31],
    [DeviceHardwareEnum.TRIGGER_FOUR, gsm31],
    [DeviceHardwareEnum.TRIGGER_THREE_E, gsm31],
    [DeviceHardwareEnum.TRIGGER_FIVE, gsm31],
    [DeviceHardwareEnum.WATCH_SIX, gsm31],
    [DeviceHardwareEnum.WATCH_ONE, gsm100],
    [DeviceHardwareEnum.WATCH_TWO, gsm100],
    [DeviceHardwareEnum.WATCH_THREE, gsm100],
    [DeviceHardwareEnum.WATCH_FOUR, gsm100],
    [DeviceHardwareEnum.WATCH_FIVE, gsm100],
    [DeviceHardwareEnum.SAFEMATE_HOME,  gsm9],
    [DeviceHardwareEnum.SAFEMATE_HOME_DT35,  gsm9],
    [DeviceHardwareEnum.SAFEMATE_HOME_MAX,  gsm9],
    [DeviceHardwareEnum.SAFEMATE_HOME_STCB1,  gsm9],

  ]
)

export const supportWifiScan: Map<DeviceHardwareEnum, boolean> = new Map<DeviceHardwareEnum, boolean>(
  [
    [DeviceHardwareEnum.TRIGGER_FOUR, true],
    [DeviceHardwareEnum.TRIGGER_THREE_E, true],
    [DeviceHardwareEnum.WATCH_FOUR, true],
    [DeviceHardwareEnum.TRACK_TWO, true],
    [DeviceHardwareEnum.WATCH_SIX, true]

  ]
)

export const excludeConnectedHeader: Map<DeviceHardwareEnum, boolean> = new Map<DeviceHardwareEnum, boolean>(
  [
    [DeviceHardwareEnum.TRIGGER_ONE, true],
    [DeviceHardwareEnum.SAFEMATE_HELP, true]
  ]
)

interface GeneralTitleProps extends WrappedComponentProps{
  icon?: JSX.Element;
  title: string;
}

export const GeneralTitle = injectIntl(({ intl: { formatMessage }, icon, title }: GeneralTitleProps) =>
    <Title>
      {icon && React.cloneElement(icon, {inline: true, size: "medium"})}
      <TitleContent>{formatMessage({id: title, defaultMessage: title})}</TitleContent>
    </Title>
)

export interface GeneralTabs{
  tab: SettingEnum;
  comp: JSX.Element;
  title: JSX.Element;
}

type GetGeneralTabs = (dehaId: DeviceHardwareEnum, device: Device, beta?: boolean, translator?: boolean) => GeneralTabs[];

export const getGeneralTabs: GetGeneralTabs = (dehaId, device, beta, translator) => {

  if(device.twinChild){
    return [{tab: SettingEnum.DEVICE_LOG, comp: <DeviceLog/>, title: <GeneralTitle icon={<Log/>} title={SettingEnum.DEVICE_LOG} />}];
  }

  const defaultTabs =
    [{tab: SettingEnum.GENERAL_SETTINGS, comp: <GeneralSettings/>, title: <GeneralTitle icon={<Cog/>} title={SettingEnum.GENERAL_SETTINGS} />},
    {tab: SettingEnum.DEVICE_LOG, comp: <DeviceLog/>, title: <GeneralTitle icon={<Log/>} title={SettingEnum.DEVICE_LOG} />},
    {tab: SettingEnum.DELETE_DATA, comp: <DeleteData/>, title: <GeneralTitle icon={<Database/>} title={SettingEnum.DELETE_DATA} />},
  // {tab: SettingEnum.POSITIONING, comp: <Positioning/>, title: <GeneralTitle icon={<Location/>} title={SettingEnum.POSITIONING} />},
    {tab: SettingEnum.BATTERY, comp: <Battery/>, title: <GeneralTitle icon={<BatteryBolt/>} title={SettingEnum.BATTERY} />},
    ];

  if(dehaId !== DeviceHardwareEnum.TRACK_FOUR){
    defaultTabs.push({
      tab: SettingEnum.ALARM_SETTINGS, comp: <AlarmSettings/>, title: <GeneralTitle icon={<Bell/>} title={SettingEnum.ALARM_SETTINGS} />
    })
  }

  if(dehaId !== DeviceHardwareEnum.SAFEMATE_HELP && dehaId !== DeviceHardwareEnum.GTX_SOLE && dehaId !== DeviceHardwareEnum.TRACK_FOUR){
    defaultTabs.push({
      tab: SettingEnum.SOUND_LIGHT, comp: <SoundLight/>, title: <GeneralTitle icon={<LightbulbOn/>} title={SettingEnum.LIGHT_SOUND_SETTINGS} />
    })
  }

    if(dehaId === DeviceHardwareEnum.WATCH_SIX || dehaId === DeviceHardwareEnum.TRIGGER_FOUR || dehaId === DeviceHardwareEnum.WATCH_TWO || dehaId === DeviceHardwareEnum.WATCH_FIVE || dehaId === DeviceHardwareEnum.TRIGGER_THREE_E || dehaId === DeviceHardwareEnum.TRIGGER_TWO_4G || dehaId === DeviceHardwareEnum.WATCH_FOUR || dehaId === DeviceHardwareEnum.TRACK_TWO){
      defaultTabs.push({
        tab: SettingEnum.MAN_DOWN_SETTINGS, comp: <ManDown/>, title: <GeneralTitle icon={<FallSensor/>} title={SettingEnum.MAN_DOWN_SETTINGS}/>
      })
    }

  if((dehaId === DeviceHardwareEnum.WATCH_TWO || dehaId === DeviceHardwareEnum.WATCH_FIVE) && beta ){
    defaultTabs.push({
      tab: SettingEnum.HEART_RATE_BLOOD_PRESSURE, comp: <HeartRateBloodPressureSettings/>, title: <GeneralTitle icon={<HeartRateIcon/>} title={SettingEnum.HEART_RATE_BLOOD_PRESSURE}/>
    })
  }

  defaultTabs.push(
    {tab: SettingEnum.TWIN_DEVICE, comp: <TwinDevice/>, title: <GeneralTitle icon={<Clone medium/>} title={SettingEnum.TWIN_DEVICE} />}
  )

  if(translator){
    defaultTabs.push(
      {tab: SettingEnum.SIMULATE, comp: <Simulate/>, title: <GeneralTitle icon={<Cog medium/>} title={SettingEnum.SIMULATE} />}
    )
  }

  if(isGx8(dehaId)){
    return [
      {tab: SettingEnum.GX8_GENERAL, comp: <GeneralSettings/>, title: <GeneralTitle icon={<Cog inline size="medium"/>} title={SettingEnum.GX8_GENERAL}/>},
      {tab: SettingEnum.GX8_COMMUNICATION, comp: <CommunicationSettings/>, title: <GeneralTitle icon={<SelfcheckFail inline size="medium"/>} title={SettingEnum.GX8_COMMUNICATION}/>},
      {tab: SettingEnum.GX8_SOUND, comp: <LightSoundSettings/>, title: <GeneralTitle icon={<FontAwesomeIcon style={{width: "24px", height: "24px"}} icon={faVolumeUp} size="lg" />} title={SettingEnum.GX8_SOUND}/>},
      {tab: SettingEnum.GX8_LIGHT, comp: <LightSettings/>, title: <GeneralTitle icon={<LightbulbOnSolid inline size="medium"/>} title={SettingEnum.GX8_LIGHT}/>},
      {tab: SettingEnum.GX8_VOICE_PROMPT, comp: <VoicePromptSettings/>, title: <GeneralTitle icon={<FontAwesomeIcon style={{width: "24px", height: "24px"}} icon={faMicrophoneAlt} size="lg" />} title={SettingEnum.GX8_VOICE_PROMPT}/>},
      {tab: SettingEnum.GX8_BATTERY, comp: <Battery/>, title: <GeneralTitle icon={<BatteryBolt inline size="medium"/>} title={SettingEnum.GX8_BATTERY}/>},
      {tab: SettingEnum.GX8_INFORMATION, comp: <Information/>, title: <GeneralTitle icon={<FontAwesomeIcon style={{width: "24px", height: "24px"}} icon={faInfoCircle} size="lg" />} title={SettingEnum.GX8_INFORMATION}/>},
      {tab: SettingEnum.GX8_LOG, comp: <Gx8Log/>, title: <GeneralTitle icon={<Log inline size="medium"/>} title={SettingEnum.GX8_LOG}/>}
    ]
  }

  return defaultTabs;

}

interface EventConfigTitleProps{
  icon?: JSX.Element;
  title: string;
  sensors: ISensorDTO[];
  active?: boolean;
  displayActivity?: boolean;
  deviceFunction?: string[];
}

const EventConfigTitle = ({ icon, title, sensors, deviceFunction, active, displayActivity }: EventConfigTitleProps) => {

  const { formatMessage } = useIntl();

  const filteredSensors = deviceFunction ? sensors.filter(sensor => sensor.function && deviceFunction.includes(sensor.function.code)) : [];

  const activeSensors = filteredSensors.filter(sensor => sensor.eventConfig.active);
  const inactiveSensors = filteredSensors.filter(sensor => !sensor.eventConfig.active);

  const allActive = activeSensors.length > 0 && inactiveSensors.length === 0;
  const noneActive = activeSensors.length === 0 && inactiveSensors.length > 0;
  const mixActive = activeSensors.length > 0 && inactiveSensors.length > 0;

  return(
    <Title>
      {icon && React.cloneElement(icon, {inline: true, size: "medium"})}
      <TitleContent>{formatMessage({id: title, defaultMessage: title})}</TitleContent>
      {(deviceFunction || displayActivity) &&
      mixActive ? <Moon/>
      : <ActiveIndicator
        status={displayActivity 
          ? active 
            ? FunctionStatus.ACTIVE
            : FunctionStatus.INACTIVE
          : allActive ? FunctionStatus.ACTIVE : FunctionStatus.INACTIVE}
      />}
    </Title>
  )
}

type GetEventConfigTabs = (dehaId: DeviceHardwareEnum, sensors: ISensorDTO[], inactivity: boolean) => GeneralTabs[];

export const getEventConfigTabs: GetEventConfigTabs = (dehaId, sensors, inactivity) => {

  if(isGx8(dehaId)){
    return [
      {
        tab: SettingEnum.FRIDGE_EVENT, 
        comp: <Fridge/>, 
        title: <EventConfigTitle 
          icon={<FridgeSensor color="textPrimary" size="medium"/>}
          title={SettingEnum.FRIDGE_EVENT}
          sensors={sensors}
          deviceFunction={[FRIDGE_FUNCTION.code]}
        />
      },
      {
        tab: SettingEnum.WANDERER_EVENT, 
        comp: <Wanderer/>, 
        title: <EventConfigTitle 
          icon={<WandererSensor color="textPrimary" size="medium"/>}
          title={SettingEnum.WANDERER_EVENT}
          sensors={sensors}
          deviceFunction={[WANDERER_FUNCTION.code]}
        />
      },
      {
        tab: SettingEnum.NOT_BACK_IN_BED_EVENT, 
        comp: <NotBackInBed/>, 
        title: <EventConfigTitle 
          icon={<NotinbedSensor color="textPrimary" size="medium"/>}
          title={SettingEnum.NOT_BACK_IN_BED_EVENT}
          sensors={sensors}
          deviceFunction={[NOT_BACK_IN_BED_FUNCTION.code]}
        />
      },
      {
        tab: SettingEnum.OUT_OF_BED_EVENT,
        comp: <OutOfBed/>,
        title: <EventConfigTitle
          icon={<NotinbedSensor color="textPrimary" size="medium"/>}
          title={SettingEnum.OUT_OF_BED_EVENT}
          sensors={sensors}
          deviceFunction={[OUT_OF_BED.code, OUT_OF_BEDROOM.code]}
        />
      },
      {
        tab: SettingEnum.INACTIVITY_EVENT, 
        comp: <Inactivity/>, 
        title: <EventConfigTitle 
          icon={<InactiveIcon color="textPrimary" size="medium"/>}
          title={SettingEnum.INACTIVITY_EVENT}
          sensors={sensors}
          active={inactivity}
          displayActivity={true}
        />
      },
      {
        tab: SettingEnum.EPILEPSY_EVENT, 
        comp: <Epilepsy/>, 
        title: <EventConfigTitle 
          icon={<EpilepsySensor color="textPrimary" size="medium"/>}
          title={SettingEnum.EPILEPSY_EVENT}
          sensors={sensors}
          deviceFunction={[EPILEPSY_FUNCTION.code]}
        />
      }
    ]
  }
  return [];
}

export const getMain = (hardware: DeviceHardwareEnum, device: Device, connected: boolean, isAhp: boolean, isPrivate: boolean) => {

  if(device.twinChild){
    return new Map<SettingEnum, JSX.Element>([
      [SettingEnum.GENERAL, <General/>],
      [SettingEnum.INFO, <Info/>],
    ]);
  }

  const DEFAULT = new Map<SettingEnum, JSX.Element>([
    [SettingEnum.TRACKING, <Tracking/>],
    [SettingEnum.GEOFENCE, <Geofence/>]
  ]);
  if((hardware === DeviceHardwareEnum.WATCH_ONE ||
      hardware === DeviceHardwareEnum.WATCH_TWO ||
      hardware === DeviceHardwareEnum.WATCH_THREE ||
      hardware === DeviceHardwareEnum.TRIGGER_TWO_4G ||
      hardware === DeviceHardwareEnum.TRIGGER_THREE_E ||
      hardware === DeviceHardwareEnum.TRIGGER_FOUR ||
      hardware === DeviceHardwareEnum.WATCH_FOUR ||
      hardware === DeviceHardwareEnum.WATCH_FIVE ||
      hardware === DeviceHardwareEnum.WATCH_SIX ||
      hardware === DeviceHardwareEnum.TRACK_TWO ||
      hardware === DeviceHardwareEnum.TRIGGER_FIVE )
    ){
    DEFAULT.set(SettingEnum.INDOOR_LOCATION, <IndoorLocationCom  />);

  } 

  if(hardware === DeviceHardwareEnum.WATCH_SIX || hardware === DeviceHardwareEnum.TRIGGER_FOUR || hardware === DeviceHardwareEnum.TRIGGER_THREE_E || hardware === DeviceHardwareEnum.TRACK_TWO || hardware === DeviceHardwareEnum.WATCH_FOUR){
    if(device.hasDeviceSensor){
      DEFAULT.set(SettingEnum.SENSOR, <SensorTable />);
    }
  }

  if(isAhp || isPrivate ){
    
  }else{
    DEFAULT.set(SettingEnum.ORGANIZATION, <Organisation/>);
  }
  DEFAULT.set(SettingEnum.GENERAL, <General/>);
  DEFAULT.set(SettingEnum.INFO, <Info/>);

  let DEFAULT_NO_CONTACT;
  if(isPrivate){
    DEFAULT_NO_CONTACT = new Map<SettingEnum, JSX.Element>([
      [SettingEnum.GENERAL, <General/>],
      [SettingEnum.INFO, <Info/>],
    ]);
  
  }else{
    DEFAULT_NO_CONTACT = new Map<SettingEnum, JSX.Element>([
      [SettingEnum.ORGANIZATION, <Organisation/>],
      [SettingEnum.GENERAL, <General/>],
      [SettingEnum.INFO, <Info/>],
    ]);
  
  }

  if(isGx8(hardware)){
    return new Map<SettingEnum, JSX.Element>([
      [SettingEnum.SENSOR, <SensorTable/>],
      [SettingEnum.EVENT_CONFIG, <EventConfig/>],
      [SettingEnum.GENERAL, <General/>],
      [SettingEnum.WALKTEST, <Walktest/>],
      [SettingEnum.ORGANIZATION, <Organisation/>],
      [SettingEnum.INFO, <Info />]
    ])
  }
  
  switch(hardware){
    case DeviceHardwareEnum.WATCH_ONE:
      return DEFAULT;
    default:
      if(connected)
        return DEFAULT;
      else
        return DEFAULT_NO_CONTACT;
  }
}


interface IndoorLocationTitleProps extends WrappedComponentProps{
  icon?: JSX.Element;
  title: string;
}

const IndoorLocationTitle = injectIntl(({ intl: { formatMessage }, icon, title }: IndoorLocationTitleProps) =>
  <Title>
    {icon && React.cloneElement(icon, {inline: true, size: "medium"})}
    <TitleContent>{title}</TitleContent>
  </Title>
)

export interface IndoorLocationTabs{
  id: number;
  indoorLocation: IndoorLocation;
  shared: boolean;
  title: string;
}

type GetIndoorLocationTabs = (indoorLocations: IndoorLocation[], formatMessage: Function) => IndoorLocationTabs[];

export const getIndoorLocationTabs: GetIndoorLocationTabs = (indoorLocations, formatMessage: Function) => {
  if( indoorLocations !== undefined && indoorLocations.length < 1 ){
    return [];
  } else {
    let tabs:IndoorLocationTabs[] = [];
    indoorLocations.map((indoorLocation) => {
      tabs.push( { id: indoorLocation.inloId, title: indoorLocation.name ? indoorLocation.name : formatMessage({id:"newIndoorLocation", defaultMessage: "Add indoor location"}), indoorLocation: indoorLocation, shared: (indoorLocation.custId > 0) });
    });
    return tabs;
  }
}
