import * as React from "react";
import {Route} from 'react-router-dom';
import { connect } from "react-redux";
import { WrappedComponentProps, useIntl } from "react-intl";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import styled from "styled-components";
import { Device, RootState, Transaction } from "./Store/types";
import {
  initialize,
  getPositions,
  getTransactions,
  fetchGeofence,
  fetchGlobalGeofence,
  getAllDevices,
  getPositionsUuid
} from "./Store/routines";
import { ResetState, resetState, GenerateMapUuid, generateMapUuid } from "./Store/actions";
import {v4 as uuidv4} from 'uuid';
import { H1 } from "../Header";
import LogTable from "./Log/table";
import Map from "./Map/map";
import Dropdown from "../../Elements/Dropdown/Dropdown";
import {Option} from "../DefaultSettings/Settings/FieldWrapper";
import Footer from "./footer";
import Header from "./header";
import MapConsent from "./mapConsent";
import { Policies } from "../Policy/types";
import TrackHeader from "./trackHeader";
import { AppState } from "@Safemate/Store/types";
import { useParams } from "react-router";
import { SiteTypeEnum } from "@Safemate/SiteTypeEnum";

import Spinner from "../Spinner";
import Filter from "./Filter";
import Graph from "./Graph";
import { IDevice } from "@Safemate/Model/Device";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";

export enum MapType{
  ALL,
  SINGLE
}

const Wrap = styled.div`
  position: relative;
`;

const DropdownWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  font-size: 14pt;
  @media (max-width: 600px) {
    margin-right: auto;
    margin-left: 0;
    margin-top: 10px;
  }
`;

export const DropdownLabel = styled.span`
  margin-right: 10px;
  white-space: nowrap;
`;

const HeaderRow = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

interface LogProps{
  transactions: Transaction[],
  positionCount: number;
  getPositions: Routine<ActionFunctionAny<Action<any>>>;
  getPositionsUuid: Routine<ActionFunctionAny<Action<any>>>;
  getTransactions: Routine<ActionFunctionAny<Action<any>>>;
  initialize: Routine<ActionFunctionAny<Action<any>>>;
  device: Device;
  policies: Policies;
  resetState: ResetState;
  generateMapUuid: GenerateMapUuid;
  initialized: boolean;
  isTrackerOnly: boolean;
  noMoreTransactionsToFetch: boolean;
  fetchGeofence: Routine<ActionFunctionAny<Action<any>>>;
  fetchGlobalGeofence: Routine<ActionFunctionAny<Action<any>>>;
  getAllDevices: Routine<ActionFunctionAny<Action<any>>>;
  currentContext: SiteTypeEnum;
  mapType: MapType;
  uuid: string;
}

const logStateToProps = ({ appData: {isTrackerOnly, currentContext}, transactionLog: {device, transaction: { transactions, positionCount, noMoreTransactionsToFetch, uuid }, initialization: { initialized }, policy: { policies }}}: AppState) => {
  return {
    transactions,
    positionCount,
    noMoreTransactionsToFetch,
    initialized,
    device,
    policies,
    isTrackerOnly,
    currentContext,
    uuid
  }
}

const mapDispatchToProps = {
  initialize,
  getAllDevices,
  getPositions,
  getPositionsUuid,
  getTransactions,
  resetState,
  generateMapUuid,
  fetchGeofence,
  fetchGlobalGeofence
}

export interface LogParams{
  id: string;
}

const Log = connect(logStateToProps, mapDispatchToProps)(
  ({ initialize, mapType, getPositions, getPositionsUuid, resetState, positionCount, getTransactions, getAllDevices, device, generateMapUuid, currentContext, uuid, isTrackerOnly, policies, initialized, noMoreTransactionsToFetch, fetchGeofence, fetchGlobalGeofence }: LogProps) => {
    const { formatMessage } = useIntl();

    const { id } = useParams<LogParams>();
    const deviceId = parseInt(id);

    const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
    const logPermission = permissions.get(PolicyNameEnum.DEVICE_TRANSACTION_LOG).generalPermissions.all;

    React.useEffect(() => {

      if(mapType === MapType.SINGLE){
        fetchGeofence(deviceId);
        fetchGlobalGeofence();
        getPositions({deviceId, uuid, newDevice: true});
      }
    }, [device])

    React.useEffect(() => {
      generateMapUuid(uuidv4());
      setTimeout(() => {
        initialize({deviceId, mapType, logPermission, uuid});
      }, 100)

      let transactionInterval: NodeJS.Timeout;
      let positionInterval: NodeJS.Timeout;
      let devicesInterval: NodeJS.Timeout;


      if(mapType === MapType.SINGLE){
        if(!isTrackerOnly && logPermission)
          transactionInterval = setInterval(() => {
            getTransactions({deviceId, transactionCount: 25})
          }, 20000);
        getPositionsUuid({deviceId, uuid});
        positionInterval = setInterval(() => {
          getPositionsUuid({deviceId, uuid});
        }, 20000);
      }
      else if(mapType === MapType.ALL){
        devicesInterval = setInterval(() => {
          getAllDevices();
        }, 20000);
      }
  
      return () => {
        // Cleanup polling
        if(mapType === MapType.SINGLE){
          clearTimeout(transactionInterval);
          clearTimeout(positionInterval);
        }
        else if(mapType === MapType.ALL){
          clearTimeout(devicesInterval)
        }
        resetState();
      }

    }, [])



    if(!initialized){
      return(
        <Wrap>
          <Spinner show message={formatMessage({id: "initData", defaultMessage: "Initialisering av data"})}/>
        </Wrap>
      )
    }

    return(
      <React.Fragment>
        <TrackHeader/>
        {!device.gx8 && initialized && <Map mapType={mapType} deviceId={deviceId} />}
        <HeaderRow>
          {!isTrackerOnly && 
          <H1 className="mobileHide">
            {`${formatMessage({id: "transactionLogFor", defaultMessage: "Transaksjonslogg for"})} ${mapType === MapType.SINGLE ? `${device.deviceName} (${device.serialNumber})` : device.devices.length}`}
          </H1>}
          {!device.gx8 && mapType !== MapType.ALL && <DropdownWrapper>
            <DropdownLabel>
              {formatMessage({id: "numPositions", defaultMessage: "Antall posisjoner vist"})}
            </DropdownLabel>
            <Dropdown
              initial={positionCount}
              options={[
                {text: "1", value: 1},
                {text: "10", value: 10},
                {text: "25", value: 25},
                {text: "50", value: 50}
              ]}
              onChange={(option) => {
                const selected = (option as Option).value;
                getPositions({
                  count: selected,
                  deviceId: id,
                  uuid
                })}
              }
            />
          </DropdownWrapper>}
          {!isTrackerOnly && 
          <H1 className="mobileShow">
            {`${formatMessage({id: "transactionLogFor", defaultMessage: "Transaksjonslogg for"})} ${mapType === MapType.SINGLE ? `${device.deviceName} (${device.serialNumber})` : device.devices.length}`}
          </H1>}
        </HeaderRow>
        {mapType === MapType.SINGLE && <Header mapType={mapType}/>}
        {mapType === MapType.SINGLE && <Graph mapType={mapType}/>}
        <Filter mapType={mapType}/>
        <LogTable mapType={mapType}/>
        <Footer mapType={mapType} deviceId={deviceId} noMoreToFetch={noMoreTransactionsToFetch}/>
        <MapConsent/>
      </React.Fragment>
    )
  })


export default Log;
