import {call, put, select, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import { feedbackSuccess, feedbackFailure } from '../../../Feedback/reducer';
import { Feedback } from '../../labels';
import { ISensor, ISensorDTO } from '../../Sensor/types';
import { BaseStationConnectionStatus } from '../../Sensor/SensorTypeEnum';
import { EventConfigForm } from '@Safemate/Settings/EventConfig/types';
import { SensorStatusEnum } from '@Safemate/Settings/Sensor/sensorStatus';
import { getDeviId } from '../selectors';
import { Transaction } from '@Safemate/map/Store/types';

function* fetchSensors(action: AppAction<number>){
  try {
    const sensors = yield call(Api.getSensor, action.payload);
    const sync = sensors.find(sensor => sensor.status && (sensor.status.code === SensorStatusEnum.PENDING_UPDATE || sensor.status.code === SensorStatusEnum.PENDING_DELETE));
    if(sync)
      yield put(Routines.syncSensors(action.payload));
    
    yield put(Routines.fetchSensors.success(sensors));
  }
  catch(e){
    yield put(Routines.fetchSensors.failure());
  }
}

export type SaveSensor = {
  deviceId: number;
  sensors: ISensor[];
}

function* saveSensor(action: AppAction<SaveSensor>){
  try{
    const sensors = yield call(Api.saveSensor, action.payload);
    yield put(Routines.saveSensor.success(sensors));
    yield feedbackSuccess(Feedback.SAVED_SETTINGS);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.saveSensor.failure());
  }
}

function* syncSensors(action: AppAction<number>){
  try{
    const data = yield call(Api.syncSensors, action.payload);
    
    if(data.synced)
      yield feedbackSuccess(Feedback.SENSOR_SYNC);

    yield put(Routines.fetchSensors.success(data.sensors));
  }
  catch(e){
    console.log("Sync failed", e);
  }
}

export type ConnectBaseStation = {
  deviceId: number;
  mac: string;
  status: BaseStationConnectionStatus;
}

function* connectBaseStation(action: AppAction<ConnectBaseStation>){
  try{
    yield call(Api.connectBaseStation, action.payload);
    yield put(Routines.connectBaseStation.success());
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.connectBaseStation.failure());
  }
}

function* deleteSensor(action: AppAction<ISensor>){
  try{
    const sensors = yield call(Api.deleteSensor, action.payload);
    yield put(Routines.deleteSensor.success(sensors));
    yield feedbackSuccess("sensorDeleted");
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.deleteSensor.failure());
  }
}

function* addScannedSensor(action: AppAction<ISensor>){
  try{
    const sensors = yield call(Api.addScannedSensor, action.payload);
    yield put(Routines.addScannedSensor.success(sensors));
    yield feedbackSuccess("sensorSaved");
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.addScannedSensor.failure());
  }
  yield put(Routines.addScannedSensor.fulfill());
}

export interface AddSensor{
  deviId: number;
  mac: string;
}

function* addSensor(action: AppAction<AddSensor>){
  try{
    const sensors = yield call(Api.addSensor, action.payload);
    yield put(Routines.addSensor.success(sensors));
    yield feedbackSuccess("sensorSaved");
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.addSensor.failure());
  }
  yield put(Routines.addSensor.fulfill());
}

export interface ProcessSensor{
  sensorId: string;
  name: string;
  function: string;
  type: string;
  funcType: string;
}

function* processSensor(action: AppAction<ProcessSensor>){

  yield put(Routines.editSensorName.trigger({
    sensorId: action.payload.sensorId,
    name: action.payload.name
  }));

  if(action.payload.type){
    yield put(Routines.editSensorType.trigger({
      sensorId: action.payload.sensorId,
      type: action.payload.type
    }));
  }

  if(action.payload.function){
    yield put(Routines.editSensorFunction.trigger({
      sensorId: action.payload.sensorId,
      functionId: action.payload.function,
      funcType: action.payload.funcType
    }));
  }
}

export interface EditSensorName{
  sensorId: string;
  name: string;
}

function* editSensorName(action: AppAction<EditSensorName>){
  try{
    const sensor: ISensorDTO = yield call(Api.editSensorName, action.payload);
    yield put(Routines.editSensorName.success(sensor));
    yield feedbackSuccess(sensor.updated ? Feedback.SENSOR_NAME_UPDATED : Feedback.SENSOR_NAME_UPDATED_NO_CONTACT);  
  }
  catch(e){
    yield feedbackFailure(e);
  }
  yield put(Routines.editSensorName.fulfill(action.payload.sensorId));
}

export interface EditSensorType{
  sensorId: string;
  type: string;
}

function* editSensorType(action: AppAction<EditSensorType>){
  try{
    const sensor = yield call(Api.editSensorType, action.payload);
    yield put(Routines.editSensorType.success(sensor));
    yield feedbackSuccess(Feedback.SENSOR_TYPE_UPDATED);  
  }
  catch(e){
    yield feedbackFailure(e);
  }
  yield put(Routines.editSensorType.fulfill(action.payload.sensorId));
}

export interface EditSensorFunction{
  sensorId: string;
  functionId: string;
  funcType: string
}

function* editSensorFunction(action: AppAction<EditSensorFunction>){
  try{
    const sensor: ISensorDTO = yield call(Api.editSensorFunction, action.payload);
    yield put(Routines.editSensorFunction.success(sensor));
    yield feedbackSuccess(sensor.updated ? Feedback.SENSOR_FUNCTION_UPDATED : Feedback.SENSOR_FUNCTION_UPDATED_NO_CONTACT);  
  }
  catch(e){
    yield feedbackFailure(e);
  }
  yield put(Routines.editSensorFunction.fulfill(action.payload.sensorId));
}

function* startLocalLearning(action: AppAction<number>){
  try{
    const res = yield call(Api.startLocalLearning, action.payload);
    yield put(Routines.startLocalLearning.success(res));
    yield feedbackSuccess(Feedback.START_LOCAL_LEARN);
  }
  catch(e){
    yield put(Routines.startLocalLearning.failure());
    yield feedbackFailure(e);
  }
}

function* stopLocalLearning(action: AppAction<number>){
  try{
    const res = yield call(Api.stopLocalLearning, action.payload);
    yield put(Routines.stopLocalLearning.success(res));
    yield feedbackSuccess(Feedback.STOP_LOCAL_LEARN);
  }
  catch(e){
    yield put(Routines.stopLocalLearning.failure());
    yield feedbackFailure(e);
  }
}

export interface AddLocalLearning{
  deviId: number;
  gatewayId: string;
  rssi: string;
}

function* addLocalLearning(action: AppAction<AddLocalLearning>){
  try{
    const res = yield call(Api.addLocalLearning, action.payload);
    yield put(Routines.addLocalLearning.success(res));
    yield feedbackSuccess(Feedback.ADD_LOCAL_LEARN);
  }
  catch(e){
    yield feedbackFailure(e);
  }
  yield put(Routines.addLocalLearning.fulfill(action.payload));
}


function* getFunctionsForType(action: AppAction<string>){
  try{
    const res = yield call(Api.getFunctionsForType, action.payload);
    yield put(Routines.getFunctionsForType.success(res));
  }
  catch(e){
    yield feedbackFailure(e);
  }
  yield put(Routines.getFunctionsForType.fulfill());
}

export interface SaveEventConfig{
  deviceId: number;
  settings: EventConfigForm;
}

function* saveEventConfig(action: AppAction<SaveEventConfig>){
  try{
    const sensor: ISensorDTO = yield call(Api.saveEventConfig, action.payload.settings);
    yield put(Routines.saveEventConfig.success(sensor));
    yield feedbackSuccess(sensor.updated ? Feedback.SENSOR_CONFIG_SAVED : Feedback.SENSOR_CONFIG_SAVED_NO_CONTACT);
  }
  catch(e){
    yield feedbackFailure(e);
  }
}

function* getSensorStatus(action: AppAction<number>){
  try{
    const res = yield call(Api.getSensorStatus, action.payload);
    yield put(Routines.updateSensors.success(res));
  }
  catch(e){
    console.log(e)
    yield feedbackFailure(e);
  }
}

function* captureImage(action: AppAction<string>) {
  try {
    const deviId: number = yield select(getDeviId);
    yield call(Api.captureImage, action.payload, deviId);
    yield put(Routines.captureImage.success());
    yield feedbackSuccess(Feedback.SENSOR_IMAGE_REQUESTED);
  } catch (e) {
    yield feedbackFailure(e);
    yield put(Routines.captureImage.failure());
  }
}

function* getTransactionsForSensor(action: AppAction<number>) {
  try {
    const deviceTx: Transaction[] = yield call(Api.getTransactionsForSensor, action.payload);
    yield put(Routines.getTransactionsForSensor.success({sensorId: action.payload, transactions: deviceTx}));
  } catch (e) {
    yield feedbackFailure(e);
    yield put(Routines.getTransactionsForSensor.failure());
  }
}

function* sensorSaga(){
    yield takeLatest(Routines.fetchSensors.TRIGGER, fetchSensors);
    yield takeLatest(Routines.updateSensors.TRIGGER, getSensorStatus);
    yield takeLatest(Routines.saveSensor.TRIGGER, saveSensor);
    yield takeLatest(Routines.syncSensors.TRIGGER, syncSensors);
    yield takeLatest(Routines.connectBaseStation.TRIGGER, connectBaseStation);
    yield takeLatest(Routines.deleteSensor.TRIGGER, deleteSensor);
    yield takeLatest(Routines.addScannedSensor.TRIGGER, addScannedSensor);
    yield takeLatest(Routines.addSensor.TRIGGER, addSensor);

    yield takeLatest(Routines.processSensor.TRIGGER, processSensor);
    yield takeLatest(Routines.editSensorName.TRIGGER, editSensorName);
    yield takeLatest(Routines.editSensorType.TRIGGER, editSensorType);
    yield takeLatest(Routines.editSensorFunction.TRIGGER, editSensorFunction)
    yield takeLatest(Routines.getFunctionsForType.TRIGGER, getFunctionsForType);

    yield takeLatest(Routines.getTransactionsForSensor.TRIGGER, getTransactionsForSensor);

    yield takeLatest(Routines.saveEventConfig.TRIGGER, saveEventConfig);

    yield takeLatest(Routines.startLocalLearning.TRIGGER, startLocalLearning)
    yield takeLatest(Routines.stopLocalLearning.TRIGGER, stopLocalLearning)
    yield takeLatest(Routines.addLocalLearning.TRIGGER, addLocalLearning)

    yield takeLatest(Routines.captureImage.TRIGGER, captureImage);
}

export default sensorSaga;
