import React, { ReactNode } from "react";
import Epilepsysensor from "@Elements/Icon/Icons/Epilepsysensor";
import Fire from "@Elements/Icon/Icons/FontAwesome/Fire";
import Fridge from "@Elements/Icon/Icons/Fridge";
import Movement from "@Elements/Icon/Icons/Movement";
import Notinbed from "@Elements/Icon/Icons/Notinbed";
import Notstoodup from "@Elements/Icon/Icons/Notstoodup";
import BatteryQuarter from "@Elements/Icon/Icons/Safemate/solid/BatteryQuarter";
import PowerOff from "@Elements/Icon/Icons/Safemate/solid/PowerOff";
import SelfcheckFail from "@Elements/Icon/Icons/Safemate/solid/SelfcheckFail";
import UserIdle from "@Elements/Icon/Icons/Safemate/solid/UserIdle";
import FallSensor from "@Elements/Icon/Icons/Safemate/solid/FallSensor";
import HeadlineAlarm from "@Safemate/EventSetupNew/Icons/HeadlineAlarm";
import HeadlineGeofence from "@Safemate/EventSetupNew/Icons/HeadlineGeofence";
import { Capabilities } from "@Safemate/Settings/Store/types";
import BatteryEmpty from "@Elements/Icon/Icons/Safemate/solid/BatteryEmpty";

const phone = true;
const sms = true;

export interface IEventType{
  [index: string]: {
    type: string;
    text: {
      label: string;
      fallback: string;
    },
    iconSmall: JSX.Element;
    iconMedium: JSX.Element;
    sms?: boolean;
    phone?: boolean;
  }
}

export const eventTypes: IEventType = {
  USER_ALARM: {
    type: 'alarmWarnRecipient',
    text: {
      label: 'userAlarm',
      fallback: 'User triggered alarm'
    },
    iconSmall: <HeadlineAlarm size="small" inline />,
    iconMedium: <HeadlineAlarm size="medium" inline />,
    phone
  },
  LOW_BATTERY: {
    type: 'batteryWarnRecipient',
    text: {
      label: 'lowBatteryAlarm',
      fallback: 'Low battery alarm'
    },
    iconSmall: <BatteryQuarter size="small" inline />,
    iconMedium: <BatteryQuarter size="medium" inline />,
    sms
  },
  EPILEPSY: {
    type: 'epilepsyAlarmRecipient',
    text: {
      label: 'epilepsyAlarm',
      fallback: 'Epilepsy alarm'
    },
    iconSmall: <Epilepsysensor size="small" inline />,
    iconMedium: <Epilepsysensor size="medium" inline />,
    phone
  },
  FIRE: {
    type: 'fireWarnRecipient',
    text: {
      label: 'fireAlarm',
      fallback: 'Fire alarm'
    },
    iconSmall: <Fire />,
    iconMedium: <Fire />,
    phone
  },
  FRIDGE: {
    type: 'fridgeWarnRecipient',
    text: {
      label: 'fridgeAlarm',
      fallback: 'Fridge alarm'
    },
    iconSmall: <Fridge size="small" inline />,
    iconMedium: <Fridge size="medium" inline />,
    sms
  },
  GEOFENCE: {
    type: 'geofenceWarnRecipient',
    text: {
      label: 'geofenceAlarm',
      fallback: 'Geofence alarm'
    },
    iconSmall: <HeadlineGeofence size="small" inline />,
    iconMedium: <HeadlineGeofence size="medium" inline />,
    sms
  },
  IN_BED: {
    type: 'inBedWarnRecipient',
    text: {
      label: 'notStoodUpAlarm',
      fallback: 'Not stood up alarm'
    },
    iconSmall: <Notstoodup size="small" inline />,
    iconMedium: <Notstoodup size="medium" inline />,
    sms
  },
  MAN_DOWN: {
    type: 'manDownWarnRecipient',
    text: {
      label: 'fallAlarm',
      fallback: 'Fall alarm'
    },
    iconSmall: <FallSensor size="small" inline />,
    iconMedium: <FallSensor size="medium" inline />,
    phone
  },
  OFFLINE: {
    type: 'offlineWarnRecipient',
    text: {
      label: 'deviceOfflineAlarm',
      fallback: 'Device offline alarm'
    },
    iconSmall: <PowerOff size="small" inline />,
    iconMedium: <PowerOff size="medium" inline />,
    sms
  },
  OUT_OF_BED: {
    type: 'outOfBedWarnRecipient',
    text: {
      label: 'outOfBedAlarm',
      fallback: 'Ut av seng alarm'
    },
    iconSmall: <Notinbed size="small" inline />,
    iconMedium: <Notinbed size="medium" inline />,
    sms
  },
  SELF_CHECK: {
    type: 'selfcheckWarnRecipient',
    text: {
      label: 'deviceFailedSelfCheckAlarm',
      fallback: 'Device failed self-check alarm'
    },
    iconSmall: <SelfcheckFail size="small" inline />,
    iconMedium: <SelfcheckFail size="medium" inline />,
    sms
  },
  OUT_OF_BATTERY: {
    type: 'outOfBatteryWarnRecipient',
    text: {
      label: 'deviceOutOfBatteryAlarm',
      fallback: 'Device is out of battery'
    },
    iconSmall: <BatteryEmpty size="small" inline />,
    iconMedium: <BatteryEmpty size="medium" inline />,
    sms
  },
  STATIC: {
    type: 'staticWarnRecipient',
    text: {
      label: 'noActivityAlarm',
      fallback: 'Inactivity alarm'
    },
    iconSmall: <UserIdle size="small" inline />,
    iconMedium: <UserIdle size="medium" inline />,
    sms
  },
  WANDERER: {
    type: 'wandererAlarmRecipient',
    text: {
      label: 'wandererAlarm',
      fallback: 'Door alarm'
    },
    iconSmall: <Movement size="small" inline />,
    iconMedium: <Movement size="medium" inline />,
    phone
  }
};


export const getEvents = (capabilities: Capabilities) => {
  const base = [];

  if (capabilities.alarm) {
    base.push(eventTypes.USER_ALARM);
  }
  if (capabilities.outOfBed) {
    base.push(eventTypes.OUT_OF_BED);
  }
  if (capabilities.fridge) {
    base.push(eventTypes.FRIDGE);
  }
  if (capabilities.wanderer) {
    base.push(eventTypes.WANDERER);
  }
  if (capabilities.epilepsy) {
    base.push(eventTypes.EPILEPSY);
  }
  if (capabilities.motion) {
    base.push(eventTypes.STATIC);
  }
  if (capabilities.fallImpact || capabilities.fallTilt) {
    base.push(eventTypes.MAN_DOWN);
  }
  if (capabilities.fire) {
    base.push(eventTypes.FIRE);
  }
  if (capabilities.selfcheck) {
    base.push(eventTypes.SELF_CHECK);
  }
  if (capabilities.batteryPercentage || capabilities.batteryLow) {
    base.push(eventTypes.LOW_BATTERY);
    base.push(eventTypes.OUT_OF_BATTERY);
  }
  if (capabilities.shutdownAlert) {
    base.push(eventTypes.OFFLINE);
  }
  if (capabilities.positioning) {
    base.push(eventTypes.GEOFENCE);
  }
  return base;
};

export const getEventSubset = (list: IEventType[], offset: number, limit: number) => {
  const subset = [];
  for (let i = offset; i < offset + limit; i++) {
    if (!list[i]) break;
    subset.push(list[i]);
  }
  return subset;
}

export const AllEvents = [
  eventTypes.USER_ALARM,
  eventTypes.GEOFENCE,
  eventTypes.OUT_OF_BED,
  eventTypes.FRIDGE,
  eventTypes.WANDERER,
  eventTypes.EPILEPSY,
  eventTypes.IN_BED,
  eventTypes.STATIC,
  eventTypes.MAN_DOWN,
  eventTypes.FIRE,
  eventTypes.SELF_CHECK,
  eventTypes.OUT_OF_BATTERY,
  eventTypes.LOW_BATTERY,
  eventTypes.OFFLINE
];
