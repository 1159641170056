import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Phone from "../Icons/Phone";
import { AppState } from "@Safemate/Store/types";

const PhoneWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  font-size: 13pt;
  fill: ${props => props.theme.colors.textPrimary};
  padding: 5px;
`;

const mapStateToProps = ({ eventSetup: {device: { selectedDevice: { phoneNum }}}}: AppState) => {
  return{
    phoneNum  
  }
}

interface PhonenumberProps{
  phoneNum: string;
}

const Phonenumber = connect(mapStateToProps)(({ phoneNum }: PhonenumberProps) => 
  <PhoneWrapper>
    <Phone height="20px" width="20px"/>
    <p>{phoneNum}</p>
  </PhoneWrapper>
)

export default Phonenumber;