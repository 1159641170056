export class MapLabel extends google.maps.OverlayView {
  private div_: HTMLElement;
  private divDevice_: HTMLElement;
  private useDeviceName_: boolean;

  constructor(useDeviceName: boolean) {
    super();
    var div = this.div_ = document.createElement('div');
    div.style.cssText = 'position: absolute; display: none;';
    
    var divDevice = this.divDevice_ = document.createElement('div');
    divDevice.style.cssText = 'position: absolute; display: none;';

    this.useDeviceName_ = useDeviceName;
  }

  /**
   * onAdd is called when the map's panes are ready and the overlay has been
   * added to the map.
   */
  onAdd() {
    const panes = this.getPanes().overlayLayer;
    panes.appendChild(this.div_);
    panes.appendChild(this.divDevice_);
  }

  draw() {
    var projection = this.getProjection();
    var position = projection.fromLatLngToDivPixel(this.get('position'));
    var deviceName = this.divDevice_;
    var div = this.div_;
    var textCl='';
    var tranIdClass = '';
     
    if(this.get('tranId')) {
      tranIdClass = this.get('tranId').toString();
    }
     
    if(this.useDeviceName_){
      var deviceNameValue =  '';
      if(this.get('deviceName')) {
        deviceNameValue = this.get('deviceName').toString();
      }
      if(this.get('text')!=null){
        textCl = this.get('text').toString();
      }
      deviceName.style.left = (position.x-45) + 'px'; 
      deviceName.style.top = (position.y-70) + 'px';  
      deviceName.style.display = 'block';
      deviceName.style.zIndex = this.get('zIndex'); //ALLOW LABEL TO OVERLAY MARKER
      deviceName.innerHTML = '<span class="map-user">' + '<span class="map-user-text-holder '+tranIdClass+'"><span class="map-user-text"><em>'
          + deviceNameValue + '</em> (' + textCl + ')</span></span></span>';      
    }
    else{
      textCl = '';
    	if(this.get('text')!=null){
    		textCl = this.get('text').toString();
    	}
    	
    	div.style.left = (position.x) + 'px'; 
    	div.style.top = (position.y) + 'px'; 
    	div.style.display = 'block';
    	div.style.zIndex = this.get('zIndex'); //ALLOW LABEL TO OVERLAY MARKER
    	div.innerHTML = '<span class="map-user-clock '+tranIdClass+'">' +
    	'<strong><em>' + textCl+'</em></strong>' +
      '</span>';
    }
  }

  /**
   * The onRemove() method will be called automatically from the API if
   * we ever set the overlay's map property to 'null'.
   */
  onRemove() {
    if(this.div_.parentNode != null)	
      this.div_.parentNode.removeChild(this.div_);
    if(this.divDevice_.parentNode != null) 
      this.divDevice_.parentNode.removeChild(this.divDevice_);
  }
}