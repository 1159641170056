import * as React from "react";
import styled from "styled-components";
import { Device, DeviceConnectionEvent } from "./types";
import { HeaderWrapper, Highlighted } from "./styles";
import Settings from "../../Elements/Icon/Icons/menu/Settings";
import Users from "../../Elements/Icon/Icons/Safemate/solid/Users";
import Map from "../../Elements/Icon/Icons/settings/Map";
import FileAltLight from "../../Elements/Icon/Icons/FileAltLight";
import { AllPermissions, PolicyContext } from "../Policy/Provider";
import { PolicyNameEnum } from "../PolicyNameEnum";
import { defineMessages, useIntl } from "react-intl";
import { getIcon } from "../DeviceList/DeviceList/utils";
import { DeviceHardwareEnum, isGx8 } from '../../Safemate/DeviceHardwareEnum';
import { excludeConnectedHeader } from "./tabDefinition";
import {Client, IMessage, StompSubscription} from "@stomp/stompjs";
import { connectWebsocket } from "@Safemate/Websocket";
import {setSubscription, SetSubscription, SetWebsocketClient} from "@Safemate/Settings/Store/actions";
import { AppState } from "@Safemate/Store/types";
import { connect } from "react-redux";
import { checkPermissionsForCustomer } from "@Safemate/Policy/rightsUtil";

const connectedMessages = defineMessages({
  offline: {
    id: 'panelOffline',
    defaultMessage: 'Frakoblet'
  },
  online: {
    id: 'panelOnline',
    defaultMessage: 'Tilkoblet'
  }
});

const Heading = styled.div`
  margin-bottom: 11px;
  display: flex;
  @media (max-width: 600px) {
    svg{
      width: 2em!important;
    }
  }
  @media (max-width: 450px) {
    display: flow-root;
  }
`;

const H1 = styled.h1`
  white-space: normal;

`;

const StyledSpan = styled.span`
  @media (max-width: 600px) {
    font-size: 18px;
    margin-left: 10px;
  }
`;

const StyledSpanSecond = styled.span`
  @media (max-width: 600px) {
    font-size: 18px;
    margin-left: 10px;
  }
`;

const StyledDeviceSpan = styled.span`
  display: flex;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
  button {
    margin: 5px;
  }
`;

interface HeaderProps{
    device: Device;
    dehaId: number;
    client: Client | null;
    isPrivate: boolean;
    subscriptions: Map<string, StompSubscription> | null;
    setWebsocketClient: SetWebsocketClient;
    setSubscription: SetSubscription;
}

const mapStateToProps = ({ appData: {isPrivate} }: AppState) => {
  return{
    isPrivate
  }
}

const SettingsHeader = connect(mapStateToProps)(({isPrivate, device, dehaId, client, subscriptions, setWebsocketClient}: HeaderProps) => {

    const { formatMessage } = useIntl();
    const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
    const eventSetup = checkPermissionsForCustomer(permissions, PolicyNameEnum.EVENT_SETUP, device.ownedByCustomer) && !device.twinChild;
    const log = checkPermissionsForCustomer(permissions, PolicyNameEnum.DEVICE_ACCESS_MAP, device.ownedByCustomer) || checkPermissionsForCustomer(permissions, PolicyNameEnum.DEVICE_TRANSACTION_LOG, device.ownedByCustomer);

    const excludeConnected = excludeConnectedHeader.get(dehaId);

    const deviceIcon: React.ReactNode | undefined = getIcon(dehaId);

    const serialNumber = device.deviceSerialNumber;
    const name = device.deviceName;
    const deviId = device.deviId;
    const headerClick = () => {
      if(!isPrivate){
        let searchInput = document.getElementById("search-input") as HTMLInputElement;
        searchInput.value = serialNumber;
        location.hash = `safemates/filter=clear&search=${serialNumber}`
      }
    }
    let cursorStyle = {cursor: 'pointer'};
    if(isPrivate){
      cursorStyle = {cursor: 'default'};
    }

    const [ connected, setConnected ] = React.useState(device.connected);


    React.useEffect(() => {
      startSocket();
      return () => {
        stopSocket();
      }
    }, [device.deviId])
  
  
    const startSocket = () => {
  
      if(!device.deviId) return;

      if(client && !client.active){
        client.activate();
      }
  
      if(client.connected){
        setSubscription({ key: "connection", subscription: client.subscribe(`/topic/connection/${device.deviceId}`, (message: IMessage) => {
            handleConnectionEvent(message);
          }) });
      }
      else{
        client.onConnect = (frame) => {
          setSubscription({ key: "connection", subscription: client.subscribe(`/topic/connection/${device.deviceId}`, (message: IMessage) => {
              handleConnectionEvent(message);
            }) });
        }
      }

      client.onDisconnect = () => {
        stop();
      }
    };
  
    const stopSocket = () => {
      if (client && client.active) {
        let subscription: StompSubscription | null = subscriptions.get("connection");
        if(subscription){
          subscription.unsubscribe();
          subscriptions.delete("connection");
        }
      }
    };
  
  
    const handleConnectionEvent = (message: IMessage) => {
      const body: DeviceConnectionEvent = JSON.parse(message.body);
      setConnected(body.connected);
    }


    const online = formatMessage(connectedMessages.online);
    const offline = formatMessage(connectedMessages.offline);

    return(
        <Heading>
           <H1 style={cursorStyle} onClick={headerClick}>
            <HeaderWrapper>
              <StyledDeviceSpan>
                <Settings inline />
                <StyledSpan>
                  {` ${formatMessage({ id: "settingsFor", defaultMessage: "Innstillinger for" })} `}
                </StyledSpan>
              </StyledDeviceSpan>
              <Highlighted>
                &nbsp;
                {deviceIcon}
                <StyledSpanSecond>
                  {` ${name} (${serialNumber}) ${excludeConnected ? "" : connected ? online : offline}`}
                </StyledSpanSecond>
              </Highlighted>
            </HeaderWrapper>
          </H1>
          <ButtonWrapper>
            {log && <button onClick={() => {location.hash = `mapsingle/${deviId}`}} className="btn btn-dark-with-line btn-small">{isGx8(device.dehaId) ? <FileAltLight inline size="medium"/> : <Map inline size="medium"/>}</button>}
            {eventSetup && <button onClick={() => {location.hash = `event_setup/${deviId}`}} className="btn btn-dark-with-line btn-small"><Users inline size="medium"/></button>}
          </ButtonWrapper>
        </Heading>
    )
})

export default SettingsHeader;
