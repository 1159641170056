import {call, put, takeLatest} from 'redux-saga/effects';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import * as Api from "../api";
import Ahp from '@Safemate/EventSetupNew/Model/Ahp';
import { feedbackFailure } from '@Safemate/Feedback/reducer';


function* getDefaultAhp(action: AppAction<number>){
  try{
    const ahp: Ahp = yield call(Api.getDefaultAhp, action.payload);
    yield put(Routines.getDefaultAhp.success(ahp));
  }
  catch(e){
    yield put(Routines.getDefaultAhp.failure());
    console.log(e)
  }
}

function* getAhpPrivateLink(action: AppAction<null>){
  try{
    const link: string = yield call(Api.getAhpPrivateLink);
    yield put(Routines.getAhpPrivateLink.success(link));
  }
  catch(e){
    yield put(Routines.getAhpPrivateLink.failure());
  }
}

export interface SetAhp{
  deviId: number;
  ahpId: number;
}

function* setAhp(action: AppAction<SetAhp>){
  try{
    if(action.payload.ahpId){
      yield call(Api.connectAhp, action.payload.deviId);
    }
    else{
      yield call(Api.disconnectAhp, action.payload.deviId);
    }
    yield put(Routines.setAhp.success(action.payload));
  }
  catch(e){
    yield put(Routines.setAhp.failure());
    yield feedbackFailure(e);
  }
}

function* ahpSaga(){
    yield takeLatest(Routines.getDefaultAhp.TRIGGER, getDefaultAhp);
    yield takeLatest(Routines.getAhpPrivateLink.TRIGGER, getAhpPrivateLink);
    yield takeLatest(Routines.setAhp.TRIGGER, setAhp);
}

export default ahpSaga;
