import { combineReducers } from "redux";
import { RootState } from "../types";
import feedback from "../../../Feedback/reducer";
import initialization from "./initialization";
import ahp from "./ahp";
import capability from "./capability";
import device from "./device";
import deviceEventSetup from "./deviceEventSetup";
import policy from "./policy";

export default combineReducers<RootState>({
    feedback,
    initialization,
    ahp,
    capability,
    device,
    deviceEventSetup,
    policy
});
