import _axios, {AxiosError, AxiosInstance} from 'axios';
import { SiteTypeEnum } from '../../SiteTypeEnum';
import { PdfProps} from './Saga/invoices';

// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';

// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---


export const getInvoices = async () => {
  const url = `/customer/getXledgerHistory`;
  const response = await axios.get(url);
  return response.data;
}

export const getCsv = async ({pdfUrl, yearMonth}: PdfProps) => {
  const pdfUrlEncoded = Buffer.from(pdfUrl).toString('base64');
  const url = `/customer/parseCsvData/${yearMonth}/pdfUrl/${pdfUrlEncoded}`;
  const response = await axios.get(url);
  return response.data;
}
