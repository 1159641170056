import {call, put, takeLatest, all, select} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '@Safemate/Store/types';
import * as Routines from "../routines";
import * as SetupRoutines from "@Safemate/Store/routines";
import {feedbackFailure, feedbackFailureString, feedbackFullfill, feedbackSuccess} from '@Safemate/Feedback/reducer';
import { getUser } from '@Safemate/Store/api';
import { IUser } from '@Safemate/Model/User';
import { useIntl } from 'react-intl';
import { parseAuthException } from '@Safemate/Session/Login/utils';
import {AxiosError, AxiosResponse} from 'axios';
import { ITexts } from '@Safemate/Model/Text';
import { removeHashFromUrl } from '@Safemate/utils';
import { selectSso, selectTexts, selectUser } from '@Safemate/Store/selectors';
import { ISso } from '@Safemate/Model/Sso';
import { RegisterFirmForm, RegisterUserForm } from '@Safemate/Session/Register/types';
import { setRegisterStep } from '../actions';
import { RegisterStep } from '@Safemate/Session/Register';

function* validate(action: AppAction<string>){
  try{
    const validated: boolean = yield call(Api.validate, action.payload);
    yield put(Routines.validate.success(validated));
    yield feedbackSuccess("validationAccepted");
  }
  catch(e){
    yield put(Routines.validate.failure(e));
  }
}

function* prePopulateEmail(action: AppAction<string>){
  try{
    const email: string = yield call(Api.prePopulateEmail, action.payload);
    yield put(Routines.prePopulateEmail.success(email));
  }
  catch(e){
    yield put(Routines.prePopulateEmail.failure(e));
  }
}

export interface ILogin{
  username: string;
  password: string;
  TWO_FACTOR_KEY: string;
  formatMessage: Function;
}

function* login(action: AppAction<ILogin>){
  const { formatMessage, ...rest} = action.payload;
  try{
    const log: boolean = yield call(Api.login, rest);
    yield put(Routines.login.success(log));
  }
  catch(e){
    const response = (e as AxiosError).response;

    if(response.status === 301){
      const ssoReq = response.headers["x-safemate-sso-redirect"];
      if (ssoReq && ssoReq.length > 0 ) {
        location.href = ssoReq;
      }
    }

    else if(response.status === 403){
      const authException = response.headers["authexception"];
      const loginResponse = parseAuthException(authException, formatMessage)

      if(loginResponse.error){
        yield feedbackFailureString(loginResponse.feedback);
      }
      else{
        yield feedbackSuccess(loginResponse.feedback);
      }

      yield put(Routines.login.failure(loginResponse.showTfa));
    }
    else{
      yield put(Routines.login.failure(e));
    }
  }
}

function* logout(action: AppAction<null>){
  try{

    yield call(Api.logout);
    yield put(Routines.logout.success());
  }
  catch(e){
    location.href = "/";
  }
}

export interface IForgotPassword{
  username: string;
}

function* forgotPassword(action: AppAction<IForgotPassword>){
  try{
    yield call(Api.forgotPassword, action.payload);
    yield feedbackSuccess("forgotSuccess");
    yield put(Routines.forgotPassword.success());
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.forgotPassword.failure(e));
  }
}

export interface IResetPassword{
  token: string;
  password: string;
  passwordCopy?: string;
}

function* resetPassword(action: AppAction<IResetPassword>){
  const {passwordCopy, ...rest} = action.payload;

  if(passwordCopy !== action.payload.password){
    yield feedbackFailureString("T172");
    return;
  }

  try{
    yield call(Api.resetPassword, rest);
    yield feedbackSuccess("passwordResetSuccess");
    removeHashFromUrl();
    yield put(Routines.resetPassword.success());
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.resetPassword.failure(e));
  }
}

export interface IResendVerification{
  username: string;
}

function* resendVerification(action: AppAction<IResendVerification>){
  try{
    yield call(Api.resendVerification, action.payload);
    yield feedbackSuccess("verificationResendSuccess");
    yield put(Routines.resendVerification.success());
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.resendVerification.failure(e));
  }
}

function* userValidation(action: AppAction<RegisterUserForm>){
  try{
    yield feedbackFullfill();
    const user = action.payload;
    if(user.username.includes("kommune") && !action.payload.force){
      yield put(setRegisterStep(RegisterStep.MUNICIPALITY_CONFIRM));
      return;
    }
    if(user.passwordClear === user.confirmPassword){
      yield call(Api.userValidation, action.payload);
      yield put(Routines.couponValidation.trigger(user.couponCode));
      yield put(Routines.userValidation.success());
    }
    else{
      yield feedbackFailureString("T172");
    }
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.userValidation.failure(e));
  }

}

function* couponValidation(action: AppAction<string>){
  try{
    yield call(Api.couponValidation, action.payload);
    yield put(Routines.couponValidation.success());
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.couponValidation.failure(e));
  }
}

function* registerUserPrivate(action: AppAction<RegisterUserForm>){
  try{
    yield call(Api.registerUserPrivate, action.payload);
    yield feedbackSuccess("registerSuccess")
    yield put(Routines.registerUserPrivate.success(action.payload.username));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.registerUserPrivate.failure(e));
  }
}

export interface CustomerValidation{
  values: RegisterFirmForm;
  validateName: boolean;
}

function* customerValidation(action: AppAction<CustomerValidation>){

  const { values, validateName } = action.payload

  try{
    if(validateName){
      const showPrompt: boolean = yield call(Api.customerNameValidation, values.companyName);
      if(showPrompt){
        yield put(Routines.customerValidation.success(true));
        return;
      }
    }

    yield call(Api.customerValidation, values);
    yield put(Routines.customerValidation.success());
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.customerValidation.failure(e));
  }
  yield put(Routines.customerValidation.fulfill());
}

function* registerCustomer(action: AppAction<RegisterFirmForm>){
  const customer = action.payload;
  try{
    if(customer.passwordClear === customer.confirmPassword){
      const user: RegisterUserForm = {
        firstName: customer.firstName,
        lastName: customer.lastName,
        mobile: customer.mobile,
        username: customer.username,
        passwordClear: customer.passwordClear,
        confirmPassword: customer.confirmPassword,
      }
      yield call(Api.userValidation, user);
      yield call(Api.registerCustomer, customer);
      yield put(Routines.registerCustomer.success(customer.username));
    }
    else{
      yield feedbackFailureString("T172");
    }
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.registerCustomer.failure(e));
  }
}


function* getTexts(action: AppAction<null>){
  const texts: ITexts = yield select(selectTexts);
  if(!texts){
    try{
      const texts: ITexts = yield call(Api.getTexts);
      yield put(SetupRoutines.getTexts.success(texts));
    }
    catch(e){
      yield put(SetupRoutines.getTexts.failure(e));
    }
  }
}

function* sendGdpr(action: AppAction<null>){
  try{
    yield call(Api.sendGdpr);
    yield put(Routines.sendGdpr.success());
    yield feedbackSuccess("sendGdprEmailSuccess");
  }
  catch(e){
    yield put(Routines.sendGdpr.failure(e));
  }
  yield put(Routines.sendGdpr.fulfill());
}

function* acceptGdpr(action: AppAction<null>){
  try{
    const user: IUser = yield select(selectUser);
    yield call(Api.acceptGdpr, user.username);
    yield put(Routines.acceptGdpr.success());
  }
  catch(e){
    yield put(Routines.acceptGdpr.failure(e));
  }
  yield put(Routines.acceptGdpr.fulfill());
}

function* sessionSaga(){
    yield takeLatest(Routines.prePopulateEmail.TRIGGER, prePopulateEmail);
    yield takeLatest(Routines.validate.TRIGGER, validate);
    yield takeLatest(Routines.login.TRIGGER, login);
    yield takeLatest(Routines.logout.TRIGGER, logout);
    yield takeLatest(Routines.forgotPassword.TRIGGER, forgotPassword);
    yield takeLatest(Routines.resetPassword.TRIGGER, resetPassword);
    yield takeLatest(Routines.resendVerification.TRIGGER, resendVerification);
    yield takeLatest(Routines.userValidation.TRIGGER, userValidation);
    yield takeLatest(Routines.couponValidation.TRIGGER, couponValidation);
    yield takeLatest(Routines.registerUserPrivate.TRIGGER, registerUserPrivate);
    yield takeLatest(Routines.customerValidation.TRIGGER, customerValidation);
    yield takeLatest(Routines.registerCustomer.TRIGGER, registerCustomer);
    yield takeLatest(Routines.sendGdpr.TRIGGER, sendGdpr);
    yield takeLatest(Routines.acceptGdpr.TRIGGER, acceptGdpr);
    yield takeLatest(Routines.getTexts.TRIGGER, getTexts);

}

export default sessionSaga;
