import { combineReducers } from 'redux';
import {RootState} from "../types";
import user from "./user";
import initialization from "./initialization";
import action from "./actions";
import role from "./role";
import customer from "./customer";
import filter from "./filter";
import feedback from "../../../Feedback/reducer";

export default combineReducers<RootState>({
  user,
  initialization,
  action,
  role,
  feedback,
  customer,
  filter,
});
