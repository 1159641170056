import { AppAction } from "../../Store/types";
import { AlarmFilterEnum } from "../../DeviceList/AlarmFilterEnum";
import { MapActionTypes } from "./actionTypes";
import { Moment } from "moment";

export type FilterAlarm = (alarmfilters: AlarmFilterEnum[]) => AppAction<AlarmFilterEnum[]>;

export const filterAlarm: FilterAlarm = (alarmfilters) => ({
  type: MapActionTypes.FILTER_ALARM,
  payload: alarmfilters
})

export type SelectDate = (date: Moment | null) => AppAction<Moment | null>;

export const selectDate: SelectDate = (date) => ({
  type: MapActionTypes.SELECT_DATE,
  payload: date
})

export type Rerender = (render: boolean) => AppAction<boolean>;

export const rerender: Rerender = (render) => ({
  type: MapActionTypes.RERENDER,
  payload: render
})

export type ResetState = () => AppAction<null>;

export const resetState: ResetState = () => ({
  type: MapActionTypes.RESET_STATE,
  payload: null
})

export type DisplayGraph = () => AppAction<boolean>;

export const displayGraph: DisplayGraph = () => ({
  type: MapActionTypes.DISPLAY_GRAPH,
  payload: null
})

export type GenerateMapUuid = (uuid: string) => AppAction<string>;

export const generateMapUuid: GenerateMapUuid = (uuid) => ({
  type: MapActionTypes.GENERATE_MAP_UUID,
  payload: uuid
})

