import * as React from "react";
import styled from 'styled-components';
import moment from 'moment';
import { defineMessages, useIntl } from 'react-intl';
import { StatusWrap } from "../styled";
import SignalBars from "./signalBars";
import RefreshIcon from "@Icons/FontAwesome/Refresh";
import { connect } from "react-redux";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { getDevice, syncDevice } from "@Safemate/Settings/Store/routines";
import { AppState } from "@Safemate/Store/types";
import { DeviceStatus } from "@Safemate/Settings/Info";

const translatedText = defineMessages({
  invalid: {
    id: 'statusBarInvalidDate',
    defaultMessage: 'Har aldri oppnådd kontakt'
  },
  updateConfig: {
    id: 'updateConfigBtn',
    defaultMessage: 'Oppdater konfigurasjon'
  },
  nullRssi: {
    id: 'noSignal',
    defaultMessage: 'Ingen signal'
  }
});

const Item = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  align-items: baseline;
`;

const W = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Text = styled.p`
  text-transform: capitalize;
  margin: 0
    ${({
      theme: {
        margins: { standard }
      }
    }) => standard}
    0 0;
  padding: 0;
  border: 0;
  color: ${({
    theme: {
      colors: { textPrimary }
    }
  }) => textPrimary};
  & :last-child {
    margin-right: 0;
  }
`;

const HighlightText = styled.span`
  color: ${({
    theme: {
      colors: { textHighlight }
    }
  }) => textHighlight};
`;

interface StatusBarProps{
  updatingDevice: boolean;
  deviId: number;
  gsmStrength: number;
  lastMsgIn: number;
  fiwaName: string;
  syncDevice: Routine<ActionFunctionAny<Action<any>>>;
  getDevice: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToProps = ({ settings: {device: { updatingDevice, device: { deviId, gsmStrength, lastMsgIn, fiwaName }}}}: AppState) => {
  return {
    updatingDevice,
    deviId,
    gsmStrength, 
    lastMsgIn, 
    fiwaName,
  }
}

const mapDispatchToProps = {
  syncDevice,
  getDevice
}

export const StatusBar = connect(mapStateToProps, mapDispatchToProps)(({updatingDevice, deviId, gsmStrength, lastMsgIn, fiwaName, syncDevice, getDevice}: StatusBarProps) => {

  const { formatMessage } = useIntl();

  React.useEffect(() => {

    let updateInfoInterval: NodeJS.Timeout = setInterval(() => {
      getDevice({deviceId: deviId});
    }, 10000);

    return () => {
      // Cleanup polling
      clearTimeout(updateInfoInterval);
    }

  }, [])

  const statusDate = moment
      .utc(lastMsgIn)
      .local()
      .format('DD.MM.YYYY HH:mm');

  const statusLastContact =
    statusDate === 'Invalid date'
      ? formatMessage(translatedText.invalid)
      : statusDate;

  let rssiTooltip;

  if (gsmStrength >= 0 && gsmStrength <= 1) {
    rssiTooltip = `${1} / 5`;
  } else if (gsmStrength >= 2 && gsmStrength <= 3) {
    rssiTooltip = `${2} / 5`;
  } else if (gsmStrength >= 4 && gsmStrength <= 5) {
    rssiTooltip = `${3} / 5`;
  } else if (gsmStrength >= 6 && gsmStrength <= 7) {
    rssiTooltip = `${4} / 5`;
  } else if (gsmStrength >= 8 && gsmStrength <= 9) {
    rssiTooltip = `${5} / 5`;
  } else {
    rssiTooltip = formatMessage(translatedText.nullRssi);
  }

  const update = () => {
    syncDevice(deviId)
  }

  return (
    <StatusWrap>
      <W>
        <Item>
          <Text>
            {formatMessage({id: "statusBarFirmwareVers", defaultMessage: "Firmware versjon"})}:
          </Text>
          <Text>
            <HighlightText>{fiwaName}</HighlightText>
          </Text>
        </Item>
        <Item>
          <Text>
            {formatMessage({id: "status", defaultMessage: "Status"})}:
          </Text>
          <Text>
            <DeviceStatus/>
          </Text>
        </Item>
        <Item>
          <Text>
            {formatMessage({id: "statusBarLastConfig", defaultMessage: "Forrige konfigurasjonsendring: "})}
          </Text>
          <Text>
            <HighlightText>{statusLastContact}</HighlightText>
          </Text>
        </Item>
        <Item
          style={{ display: 'flex', alignItems: 'center' }}
          title={rssiTooltip}
        >
          <Text>
            {formatMessage({id: "statusBarGsmSignal", defaultMessage: "GSM signal"})}:
          </Text>
          <SignalBars size={30} strength={(gsmStrength * 100) / 9} />
        </Item>
        <RefreshIcon
          tabIndex="0"
          onKeyDown={(event: React.KeyboardEvent<HTMLOrSVGElement>) => event.key === 'Enter' && update()}
          onClick={update}
          spin={updatingDevice}
          title={formatMessage(translatedText.updateConfig)}
        />
      </W>
    </StatusWrap>
  )
})
