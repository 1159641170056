import {Reducer} from "redux";
import {InvoiceState} from "../types";
import * as Routines from "../routines";

const invoiceState: InvoiceState = {
  invoiceList: [],
  downloadInProgress: false,
  csvData: "",
  csvInvoiceNumber: "", 
  pdfData: "",
  csvProcessing: false,
};

const invoices: Reducer<InvoiceState> = (state = invoiceState, action) => {
  switch (action.type) {
    case Routines.fetchInvoices.SUCCESS:
      return{
        ...state,
        invoiceList: action.payload,
      }
    case Routines.setDownloading.SUCCESS:
      return{
        ...state,
        downloadInProgress: action.payload,
      }
    case Routines.getCsv.SUCCESS:
      return{
        ...state,
        csvData: action.payload.csvData,
        csvInvoiceNumber: action.payload.invoiceNumber
      }
    case Routines.setCsvProcessing.SUCCESS:
      return{
        ...state,
        csvProcessing: action.payload.csvProcessing,
        csvInvoiceNumber: action.payload.invoiceNumber
      }
    case Routines.setCsvProcessing.FAILURE:
      return{
        ...state,
        csvProcessing: action.payload.csvProcessing,
      }
    default:
      return state;
  }
};

export default invoices;
