import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

const x = 300;
const y = 100;
const adjX = 1;
const adjY = 1;

const Wrap = styled.div<{size: number}>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  margin: 0;
  padding: 0;
`;

const SVG = styled.svg`
  width: 100%;
  height: 100%;
`;

const Polygon = styled.polygon`
  fill: green;
`;

interface SignalBarsProps{
  strength: number;
  size: number
}

const SignalBars = ({strength, size}: SignalBarsProps) => {

  const { formatMessage } = useIntl();

  if (strength <= 0 || Number.isNaN(strength))
    return <React.Fragment>{formatMessage({id: "noSignal", defaultMessage: "Ingen signal"})}</React.Fragment>

  const darkGrey = '#333';

  return (
    <Wrap size={size}>
      <SVG viewBox={`0 0 ${x * adjX} ${y * adjY}`}>
        <Polygon
          points="0,60 40,60 40,100 0,100"
          style={{ fill: strength > 0 ? 'green' : darkGrey }}
        />
        <Polygon
          points="55,30 95,30 95,100 55,100"
          style={{ fill: strength > 20 ? 'green' : darkGrey }}
        />
        <Polygon
          points="110,0 150,0 150,100 110,100"
          style={{ fill: strength > 40 ? 'green' : darkGrey }}
        />
        <Polygon
          points="165,-30 205,-30 205,100 165,100"
          style={{ fill: strength > 60 ? 'green' : darkGrey }}
        />
        <Polygon
          points="220,-60 260,-60 260,100 220,100"
          style={{ fill: strength > 80 ? 'green' : darkGrey }}
        />
      </SVG>
    </Wrap>
  );
};

export default SignalBars;
