import styled from "styled-components";

export const Title = styled.h1`
  padding: 15px;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const TitleSmall = styled.h1`
  padding: 15px;
    @media only screen and (min-width: 601px) {
      display: none;
    }
`;

export const Frame = styled.div`
  border-radius: 4px;
  padding: 25px;
  ${props =>
  `
    background: ${props.theme.colors.backgroundPrimary};
    border: ${props.theme.colors.border};
  `
}
`;
