import React from 'react';

const Schedule = props => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="128px"
      height="128px"
      viewBox="0 0 128 128"
      enableBackground="new 0 0 128 128"
      {...props}
    >
      <g transform="translate(0,-1036.3622)">
        <g transform="translate(628,-140.49998)">
          <g transform="translate(-599.5,50.350676)">
            <g transform="translate(-29.5,89.649313)">
              <g transform="translate(1.5,-1.5000527)">
                <g transform="translate(127.5,-148.00021)">
                  <path
                    d="M-123.217,1187.147c-2.178,0-3.944,1.766-3.944,3.944v94.657c0,2.177,1.766,3.944,3.944,3.944h44.987
                                    c-0.895-2.492-1.271-5.176-1.587-7.889h-39.456v-70.993h86.769v23.68c2.715,0.315,5.395,0.686,7.889,1.586v-44.987
                                    c0-2.178-1.767-3.944-3.944-3.944H-123.217z M-119.273,1195.035h86.769v7.888h-86.769V1195.035z M-111.385,1218.699v7.888
                                    h7.888v-7.888H-111.385z M-95.608,1218.699v7.888h7.888v-7.888H-95.608z M-79.833,1218.699v7.888h7.888v-7.888H-79.833z
                                    M-64.056,1218.699v7.888h7.888v-7.888H-64.056z M-48.188,1218.699v7.888h7.889v-7.888H-48.188z M-111.385,1234.476v7.887
                                    h7.888v-7.887H-111.385z M-95.608,1234.476v7.887h7.888v-7.887H-95.608z M-79.833,1234.476v7.887h7.888v-7.887H-79.833z
                                    M-64.056,1234.476v7.887h4.114c1.269-0.845,2.416-1.831,3.774-2.542v-5.345H-64.056z M-36.447,1242.363
                                    c-19.568,0-35.497,15.929-35.497,35.498s15.929,35.496,35.497,35.496s35.495-15.928,35.495-35.496
                                    S-16.88,1242.363-36.447,1242.363z M-36.447,1248.819c16.082,0,29.041,12.958,29.041,29.041
                                    c0,16.082-12.959,29.041-29.041,29.041c-16.083,0-29.042-12.959-29.042-29.041
                                    C-65.489,1261.777-52.53,1248.819-36.447,1248.819z M-111.385,1250.252v7.888h7.888v-7.888H-111.385z M-95.608,1250.252v7.888
                                    h7.888v-7.888H-95.608z M-79.833,1250.252v7.888h5.346c0.707-1.354,1.701-2.493,2.542-3.76v-4.128H-79.833z M-36.494,1258.124
                                    c-2.178,0.025-3.923,1.812-3.898,3.99c0,0.005,0,0.01,0,0.016v15.715c0.001,2.178,1.767,3.944,3.945,3.944h14.097
                                    c2.178,0.03,3.969-1.711,3.999-3.889c0.031-2.178-1.71-3.969-3.888-4c-0.036,0-0.075,0-0.111,0h-10.153v-11.771
                                    c0.033-2.179-1.705-3.972-3.883-4.006C-36.422,1258.123-36.458,1258.123-36.494,1258.124z M-111.385,1266.027v7.889h7.888
                                    v-7.889H-111.385z M-95.608,1266.027v7.889h7.888v-7.889H-95.608z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Schedule;
