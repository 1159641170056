import * as React from "react";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { RootState } from "../Store/types";
import styled from "styled-components";
import Check from "@Elements/Icon/Icons/Check";
import { updateName } from "../Store/routines";
import { AppState } from "@Safemate/Store/types";

const PhoneInput = styled.input`
  border-radius: 4px 0 0 4px;
`;

const Wrapper = styled.div`
  background: ${props => props.theme.colors.backgroundPrimary};
  display: inline-flex;
  margin-left: auto;
  @media (max-width: 768px) {
    display: none;
  }
`;

const CheckWrapper = styled.button<{disabled?: boolean}>`
  flex: 0 1;
  border: ${props => props.theme.colors.border};
  border-left: none;
  border-radius: 0 4px 4px 0;
  color: white;
  fill: white;
  display: inline-flex;
  background: ${props => props.theme.colors.backgroundPrimary};
  align-items: center;
  padding: 0 15px;
  transition: 0.2s;

  ${props => props.disabled && "cursor: not-allowed;"}
  ${props => !props.disabled && `&:hover{
    background: ${props.theme.colors.accent};
    svg g{
      fill: ${props.theme.colors.backgroundSecondary};
    }
  }`}

  svg g{
    fill: ${props => props.disabled ? props.theme.colors.borderColor : props.theme.colors.textPrimary};
  }
`;

const mapStateToProps = ({ eventSetup: {device: { selectedDevice: { deviceName, deviId } }}}: AppState) =>{
  return {
    deviceName,
    deviId
  }
}

const mapDispatchToProps = {
  updateName
}

interface DeviceNameProps{
  deviceName: string;
  deviId: number;
  updateName: Routine<ActionFunctionAny<Action<any>>>;
}

const DeviceName = connect(mapStateToProps, mapDispatchToProps)(({deviceName, deviId, updateName}: DeviceNameProps) => {
  
  const [ name, setName ] = React.useState(deviceName);
  const [ disabled, setDisabled ] = React.useState(deviceName === name);

  React.useEffect(() => {
    setName(deviceName);
    setDisabled(true);
  }, [deviceName])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName(value);
    setDisabled(value === deviceName);
  }

  const saveName = () => {
    if(!disabled){
      updateName({deviceId: deviId, deviceName: name});
    }
  }

  return(
    <Wrapper>
      <PhoneInput type="text" value={name} onChange={handleChange}/>
      <CheckWrapper disabled={disabled}><Check onClick={saveName} size={{width: "25px", height: "25px"}} inline/></CheckWrapper>
    </Wrapper>
  )
})

export default DeviceName;