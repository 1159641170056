import * as React from "react";
import { injectIntl, WrappedComponentProps, useIntl } from "react-intl";
import { Field } from "formik";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import {Action, ActionFunctionAny} from "redux-actions";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import * as Api from '../Store/api';
import withFormWrapper from "@Elements/Form/formWrapper";
import Dropdown from "../../../Elements/Dropdown/Dropdown";
import { Option } from "../../../Elements/Dropdown/types";
import {  OrganizationForm, PostalCityResult } from "./types";
import InformationIcon from "../../../Elements/Icon/Icons/InformationIcon";
import {PaymentBillingState, AlarmState, AlarmForm, RootState, PostCity, MappedSubOrgs} from "../Store/types";
import { getPostCity} from "../Store/routines";
import { AllPermissions, PolicyContext } from "../../Policy/Provider";
import { PolicyNameEnum } from "../../PolicyNameEnum";
import { FullWidthInput } from "./styled";
import {confirmModal} from "../../Settings/General/Tabs/deleteData";
import Tooltip from "@Elements/Tooltip";
import { AppState } from "@Safemate/Store/types";
import { MarketEnum } from "@Safemate/MarketEnum";
import {getZipCodeLength } from "@Safemate/utils";
import { checkPermissionsForCustomer } from "@Safemate/Policy/rightsUtil";
import {connectDevice, saveImage, updateTraining} from "@Safemate/Settings/Store/routines";




const Label = styled.label`
  text-transform: capitalize;
  display: block;
`;

const StyledField = styled(Field)`
  width: 100%;
`;

const StyledRow = styled(Row)`
  padding-bottom: 10px;
`;

const BillingRow = styled(Row)`
  padding-bottom: 10px;
  float:right;
`;

const StyledCheckRow = styled(Row)`
  margin-left: 31px;
`;

const StyledInvoiceRow = styled(Row)`
  margin-bottom: 10px;
  @media (min-width: 1201px) {
    display:flex;
    align-items:center; 
  }
  .invoiceCheck #overrideStaticTooLong-label{
    font-size: 13.5px;
  }
  @media (min-width: 1201px){
    .invoiceCheck .checkContainer{
      margin-top:11px;
    }
  }
  @media (max-width: 1200px) {
    .invoiceCheck .checkContainer{
      margin-top:24px;
    }
    display:block;
    align-items:center; 
    .invoiceDiv{
      float: left;
    }

    .invoiceCheck{
      float: none;
    }
  }
  .col-md-6 .col-md-4{
    padding-left: 0px;
  }
  .col-md-6 .col-md-4 label{
    margin-top:10px;
  }
  .col-md-6 .col-md-9{
    padding-left: 0px;
  }
  .col-md-6{
    display:block;
  }
  label h4{
    padding-right:5px;
    margin-top:20px;
    font-size:16px;
  }
  .col-md-4{
    padding-left: 5px;
    padding-right: 0px;
  }
  span{
    height: 14px;
    width: 14px;
    margin-left:5px;
  }
  #useShippingAddress-label{
    padding-top:13px;
    width: max-content;
  }
  .check{
    padding-left:5px;
  }
`;

const StyledAddressRow = styled(Row)`
  margin-bottom: 10px;
  @media (min-width: 992px) {
    display:flex;
    align-items:center; 
  }
  @media (max-width: 991px) {
    .col-md-8{
      padding-left: 0px;
    }
    .shippingDiv{
      float: left;
    }
  }

  .col-md-8{
    padding-right: 0px;
  }


  .col-md-6 .col-md-4{
    padding-left: 0px;
  }
  .col-md-6 .col-md-4 label{
    margin-top:10px;
  }
  .col-md-6 .col-md-9{
    padding-left: 0px;
  }
  .col-md-6{
    display:block;
  }
  label h4{
    padding-right:5px;
    margin-top:20px;
    font-size:16px;
  }
  .col-md-4{
    padding-left: 10px;
    padding-right: 0px;
  }
  span{
    height: 14px;
    width: 14px;
    margin-left:5px;
  }
  #useShippingAddress-label{
    padding-top:13px;
    width: max-content;
  }
  .check{
    padding-left:5px;
  }

`;

const StyledMainRow = styled(Row)`
  margin-bottom: 10px;
  @media (min-width: 992px) {
    display:flex;
    align-items:center; 
  }
  .col-md-6 .col-md-4{
    padding-left: 0px;
  }
  .col-md-6 .col-md-4 label{
    margin-top:10px;
  }
  .col-md-6 .col-md-9{
    padding-left: 0px;
  }
  .col-md-6{
    display:block;
  }
  label h4{
    padding-right:5px;
    margin-top:20px;
  }
  .col-md-4{
    padding-left: 20px;
  }

`;

interface CustomerField extends WrappedComponentProps{
  custId: number;
  preventPartnerReferenceChange: boolean;
}

interface DisableableField extends CustomerField{
  disabled: boolean;
}

// export const Zip = connect(mapStateToPropsZip, mapDispatchFromStateZip)(
//   withFormWrapper<OrganizationForm, ZipProps>(
//     ({formik: { setFieldValue }, custId, market }) => {

export const Email = injectIntl(({ intl: { formatMessage }, custId}: CustomerField) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  return (
    <StyledMainRow>
      <Col md={4}>
        <Label>{formatMessage({id: "deliveryAddress", defaultMessage: "Leveringsadresse"})}</Label>
      </Col>
      <Col md={8}>
      <StyledField
          type="text"
          name="email"
          placeholder={formatMessage({id: "sub-unit-email", defaultMessage: "E-post"})}
          disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId)}
        />
      </Col>
    </StyledMainRow>
  )
})

export const SubOrgName = injectIntl(({ intl: { formatMessage }, custId}: CustomerField) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  return (
    <StyledMainRow>
      <Col md={4}>
        <Label>{formatMessage({id: "subUnitName", defaultMessage: "Virksomhetsnavn *"})}</Label>
      </Col>
      <Col md={8}>
        <StyledField
          disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId)}
          type="text"
          name="lastName"
          placeholder={formatMessage({id: "subUnitName", defaultMessage: "Virksomhetsnavn"})}
        />
      </Col>
    </StyledMainRow>
  )
})
 
export const OrgNummer = injectIntl(({ intl: { formatMessage }, custId}: CustomerField) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  return (
    <StyledMainRow>
      <Col md={4}>
        <Label>{formatMessage({id: "orgnum", defaultMessage: "orgNummer"})}</Label>
      </Col>
      <Col md={8}>
        <StyledField
          type="text"
          name="orgNum"
          placeholder={formatMessage({id: "orgnum", defaultMessage: "org.nummer"})}
          disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId)}
        />
      </Col>
    </StyledMainRow>
  )
})

const mapStateToPropsReference = ({ appData: {preventPartnerReferenceChange} }: AppState) => {
  return{
    preventPartnerReferenceChange
  }
}

export const Reference = connect(mapStateToPropsReference)(injectIntl(({ intl: { formatMessage }, custId, preventPartnerReferenceChange }: CustomerField) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  return (
    <StyledAddressRow>
      <Col md={4}>
        <Label>{formatMessage({id: "ADDR_REFERENCE", defaultMessage: "Referanse"})}</Label>
      </Col>
      <Col md={8}>
        <MirroredInput
          primaryField="addrReference"
          mirroredField="billingAddrReference"
          placeholder={formatMessage({id: "ADDR_REFERENCE", defaultMessage: "referanse"})}
          disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId) || preventPartnerReferenceChange}
        />
      </Col>
    </StyledAddressRow>
  )
}))

export const BillingReference = injectIntl(({ intl: { formatMessage }, custId, disabled}: DisableableField) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  return (
    <StyledAddressRow>
      <Col md={4}>
        <Label>{formatMessage({id: "ADDR_REFERENCE", defaultMessage: "Referanse"})}</Label>
      </Col>
      <Col md={8}>
        <StyledField
          type="text"
          name="billingAddrReference"
          placeholder={formatMessage({id: "ADDR_REFERENCE", defaultMessage: "referanse"})}
          disabled={disabled || !checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId)}
        />
      </Col>
    </StyledAddressRow>
  )
})

export const AddressOne = injectIntl(({ intl: { formatMessage }, custId}: CustomerField) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  return (
    <StyledAddressRow>
      <Col md={4}>
        <Label>{formatMessage({id: "ADDRESS_LINE_1", defaultMessage: "Adresselinje 1"})}</Label>
        </Col>
        <Col md={8}>
          <MirroredInput
            primaryField="addressLine1"
            mirroredField="billingAddrLine1"
            placeholder={formatMessage({id: "ADDRESS_LINE_1", defaultMessage: "addresslinje1"})}
            disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId)}
          />
      </Col>
    </StyledAddressRow>
  )
})

export const AddressTwo = injectIntl(({ intl: { formatMessage }, custId}: CustomerField) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  return (
    <StyledAddressRow>
      <Col md={4}>
      <Label>{formatMessage({id: "ADDRESS_LINE_2", defaultMessage: "Adresselinje 2"})}</Label>
      </Col>
      <Col md={8}>
        <MirroredInput
          primaryField="addressLine2"
          mirroredField="billingAddrLine2"
          placeholder={formatMessage({id: "ADDRESS_LINE_2", defaultMessage: "addresslinje2"})}
          disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId)}
        />
      </Col>
    </StyledAddressRow>
  )
})

export const Post = injectIntl(({ intl: { formatMessage }, custId}: CustomerField) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  return (
    <StyledAddressRow>
      <Col md={4}>
        <Label>{formatMessage({id: "ADDR_CITY", defaultMessage: "Poststed *"})}</Label>
      </Col>
      <Col md={8}>
        <MirroredInput
          primaryField="addrCity"
          mirroredField="billingAddrCity"
          placeholder={formatMessage({id: "ADDR_CITY", defaultMessage: "poststed"})}
          disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId)}
        />
      </Col>
    </StyledAddressRow>
  )
})

export const BillingPost = injectIntl(({ intl: { formatMessage }, custId, disabled} : DisableableField) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  return (
    <StyledAddressRow>
      <Col md={4}>
        <Label>{formatMessage({id: "ADDR_CITY", defaultMessage: "Poststed *"})}</Label>
      </Col>
      <Col md={8}>
        <StyledField
          type="text"
          name="billingAddrCity"
          placeholder={formatMessage({id: "ADDR_CITY", defaultMessage: "poststed"})}
          disabled={disabled || !checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId)}
        />
      </Col>
    </StyledAddressRow>
  )
})

interface MirroredInputProps{
  primaryField: string;
  mirroredField: string;
  disabled: boolean;
  placeholder?: string;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const MirroredInput = withFormWrapper<OrganizationForm, MirroredInputProps>(({formik: { values, setFieldValue }, primaryField, mirroredField, placeholder, disabled, onKeyUp}) => {

  const value = values[primaryField];

  const handleChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    setFieldValue(primaryField, newValue);
    if(values.addrSamesame){
      setFieldValue(mirroredField, newValue);
    }
  }

  return(
    <FullWidthInput
      value={value}
      onChange={handleChange}
      disabled={disabled}
      type="text"
      placeholder={placeholder}
      onKeyUp={onKeyUp}
    />
  )
})

export const AddressName = injectIntl(({ intl: { formatMessage }, custId}: CustomerField) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  return (
    <StyledAddressRow>
      <Col md={4}>
        <Label>{formatMessage({id: "NAME", defaultMessage: "Navn"})}</Label>
      </Col>
      <Col md={8}>
        <MirroredInput
          primaryField="addrName"
          mirroredField="billingAddrName"
          placeholder={formatMessage({id: "NAME", defaultMessage: "navn"})}
          disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId)}
        />
      </Col>
    </StyledAddressRow>
  )
})

export const BillingAddressName = injectIntl(({ intl: { formatMessage }, custId, disabled} : DisableableField) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  return (
    <StyledAddressRow>
      <Col md={4}>
        <Label>{formatMessage({id: "NAME", defaultMessage: "Navn"})}</Label>
      </Col>
      <Col md={8}>
        <StyledField
            type="text"
            name="billingAddrName"
            placeholder={formatMessage({id: "NAME", defaultMessage: "navn"})}
            disabled={disabled || !checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId)}
          />
      </Col>
    </StyledAddressRow>
  )
})

interface AlarmProps extends WrappedComponentProps{
   alarmState: AlarmState;
   whiteListPhoneNumbers: string;
}

const mapAlarmCentralStateToProps = ({ subUnits: {alarmState, customer: { selectedCustomer: { whiteListPhoneNumbers } }}}: AppState) => {
  return{ 
    alarmState,
    whiteListPhoneNumbers
  }
}

export const AlarmCentral = connect(mapAlarmCentralStateToProps)(injectIntl(
  withFormWrapper<OrganizationForm, AlarmProps>(({ intl: { formatMessage }, formik: { setFieldValue, values: { custId, defaultAhp, tempAhp } }, alarmState, whiteListPhoneNumbers}) => {

    const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
    const { Comp, func } = confirmModal();
    const alarmMethods = React.useMemo(() => {
      const availableAlarmHelpProviders = alarmState.alarms.map((element:AlarmForm) => {
          return {value: `${element.ahpId}`, text: formatMessage({id: element.name, defaultMessage: element.name})}
      })
      availableAlarmHelpProviders.unshift({value: "0", text: ' -- ' + formatMessage({id: "none", defaultMessage: "ingen"}) + ' -- '})
      return availableAlarmHelpProviders;
    }, [alarmState])

    return (
      <StyledMainRow>
        <Col md={4}>
          <Label>{formatMessage({id: "subUnitsAlarmCentral", defaultMessage: "Alarmsentral"})}</Label>
        </Col>
        <Col md={8}>
          <Dropdown
            options={alarmMethods}
            onChange={(option) => {
              const { value } = option as Option;
              if( whiteListPhoneNumbers && value !== "0" ){
                setFieldValue("tempAhp", value);
                func(true);
              } else {
                setFieldValue("defaultAhp", value);
              }
            }}
            disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId)}
            initial={`${defaultAhp}`}
            size={{width: "100%"}}
          />  
        </Col>
        <Comp
          title={`${formatMessage({id: "whiteListPhoneNumberActivated", defaultMessage: "White list phone number is provided"})}`}
          body={
            <p>
              <span>{formatMessage({id: "whiteListPhoneNumberActivatedDesc", defaultMessage: "AHP numbers will take precedence and white list will be overwritten by AHP numbers"})}</span>
            </p>
          }
          confirmText={formatMessage({id: "ok", defaultMessage: "OK"})}
          confirmFunc={() => {
            setFieldValue("defaultAhp", tempAhp);
            func(false);
          }}
          cancelHide={true}
        />
      </StyledMainRow>
    )
  })
))

interface BillingProps{
  individualPaymentProfiles: PaymentBillingState[];
  customersForForm: MappedSubOrgs;
}

const mapBillingStateToProps = ({ subUnits: {paymentProfile: { individualPaymentProfiles }, customer: { customersForForm }}}: AppState) => {
  return {
    individualPaymentProfiles,
    customersForForm
  }
}

export const Billing = connect(mapBillingStateToProps)(
  withFormWrapper<OrganizationForm, BillingProps>(({ formik: { values: { parent, pptyId, custId }, setFieldValue }, customersForForm, individualPaymentProfiles}) => {

    
    const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
    const { formatMessage } = useIntl();


    const billingMethod = React.useMemo(() => {
      
      let customerName = "";

      if(customersForForm[parent]){
        customerName = customersForForm[parent].firm.lastName;
      }

      return individualPaymentProfiles.map((element:PaymentBillingState) => {
        const value: string = `${element.pptyId}`;
        if(element.pptyId == 3){
          return {value, text: formatMessage({id: element.name, defaultMessage: element.name}) + ": " + customerName}
        }else{
          return {value, text: formatMessage({id: element.name, defaultMessage: element.name})}
        }
      })
    }, [individualPaymentProfiles, customersForForm, parent]) 

    if(!custId && !parent) return null;

    return (
      <StyledMainRow>
        <Col md={4}>
          <Label>{formatMessage({id: "subUnitsPayment", defaultMessage: "Betalingsform"})}</Label>
        </Col>
        <Col md={8}>
        {billingMethod.length === 0 
          ? <span>{formatMessage({id: "payment", defaultMessage: "Betaling"})}: {customersForForm[parent] ? customersForForm[parent].firm.lastName : customersForForm[custId].paymentProfile.paymentDetail}</span>
          : <Dropdown
              options={billingMethod}
              onChange={(option) => {
                const { value } = option as Option;
                setFieldValue("pptyId", value);
              }}
              disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId)}
              initial={pptyId}
              size={{width: "100%"}}
            />}
        </Col>
      </StyledMainRow>
    )
  })
);


export const ShippingAddress = injectIntl(({ intl: {formatMessage}, custId}: CustomerField)=> {
  return (
      <Col md={12} >
        <StyledAddressRow>
          <Col className="shippingDiv">
            <Tooltip
              popoverId="reset-popover"
              labelId={"shippingAddressMsg"}
              defaultMessage="Shipping address is used in conjunction with shipping"
            >
              <Label><h4>{formatMessage({id: "deliveryAddress", defaultMessage: "Leveringsadresse"})}</h4></Label>
              <InformationIcon size="medium" />
            </Tooltip>
          </Col>
        </StyledAddressRow>
        <AddressName custId={custId}/>
        <Reference custId={custId}/>
        <AddressOne custId={custId}/>
        <AddressTwo custId={custId}/>
        <Zip custId={custId}/>
        <Post custId={custId}/>
      </Col>
  )
})


interface ZipProps{
  getPostCity: Routine<ActionFunctionAny<Action<any>>>;
  postCity: PostCity;
  market: MarketEnum;
  custId: number;
}
const mapStateToPropsZip = ( { appSettings: {market}, subUnits: {customer: {postCity, billingPostCity}}} : AppState) => {
  return{
    postCity,
    billingPostCity,
    market
  }
};
const mapDispatchFromStateZip = {
  getPostCity,
}

export const Zip = connect(mapStateToPropsZip, mapDispatchFromStateZip)(
  withFormWrapper<OrganizationForm, ZipProps>(
    ({formik: { setFieldValue }, custId, market }) => {

    const { formatMessage } = useIntl();
    const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
  
    const getPostalCity = async (e: React.FormEvent<HTMLInputElement>) => {
      if(e.currentTarget.value.length == getZipCodeLength(market) ){

        const postalCity: PostalCityResult = await Api.getPostCity(e.currentTarget.value);
        if(postalCity.valid){
          setFieldValue("addrCity", postalCity.result);
        }
      }
    }

    return(
      <StyledAddressRow>
      <Col md={4}>
        <Label>{formatMessage({id: "ADDR_ZIP_CODE", defaultMessage: "Postnummer"})}</Label>
      </Col>      
      <Col md={8}>
        <MirroredInput
          primaryField="addrZipCode"
          mirroredField="billingAddrZipCode"
          onKeyUp={getPostalCity}
          placeholder={formatMessage({id: "ADDR_ZIP_CODE", defaultMessage: "postnummer"})}
          disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId)}
        />
      </Col>
    </StyledAddressRow>
    )
  })
)


interface BillingZipProps{
  getPostCity: Routine<ActionFunctionAny<Action<any>>>;
  billingPostCity: PostCity;
  postCity: PostCity;
  disabled: boolean;
  market: MarketEnum;
}
  

export const BillingZip = connect(mapStateToPropsZip, mapDispatchFromStateZip)(
  withFormWrapper<OrganizationForm, BillingZipProps>(
    ({ formik: { setFieldValue, values: { custId } }, disabled, market }) => {
      
      const autoFill = market !== MarketEnum.SAFECALL;

      const { formatMessage } = useIntl();
      const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

      const getPostalCity = async (e: React.FormEvent<HTMLInputElement>) => {
        if(e.currentTarget.value.length == 4 && autoFill){
          const postalCity: PostalCityResult = await Api.getPostCity(e.currentTarget.value);
          if(postalCity.valid){
            setFieldValue("billingAddrCity", postalCity.result);
          }
        }
      }

    return(
      <StyledAddressRow>
      <Col md={4}>
        <Label>{formatMessage({id: "ADDR_ZIP_CODE", defaultMessage: "Postnummer *"})}</Label>
      </Col>      
      <Col md={8}>
        <StyledField
          type="text"
          name="billingAddrZipCode"
          placeholder={formatMessage({id: "ADDR_ZIP_CODE", defaultMessage: "postnummer"})}
          disabled={disabled || !checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId)}
          onKeyUp={getPostalCity}
        />
      </Col>
    </StyledAddressRow>
    )
  })
)

