import {call, put, takeLatest, select, take, race} from 'redux-saga/effects';

import {AppAction} from "../../../DefaultSettings/Store/types";

import * as Routines from '../routines';
import * as Api from '../api';
import { feedbackFailure } from '../../../Feedback/reducer';


export interface CsvProps{
  invoiceNumber: string,
  pdfUrl: string, 
  yearMonth: string;
}

export interface PdfProps{
  pdfUrl: string, 
  yearMonth: string;
}



function* fetchInvoices(action: AppAction<string | undefined>){
  try{
    const invoices = yield call(Api.getInvoices);
    if(invoices.data == null){
      yield put(Routines.fetchInvoices.success(invoices.data));
    }else{
      const invoiceData = invoices.data.arTransactions.edges;
      yield put(Routines.fetchInvoices.success(invoiceData));
    }
  }
  catch(e){
    yield feedbackFailure(e)
  }
}

function* setCustomersClickedOpen(action: AppAction<Array<number>>){
    try{
      yield put(Routines.setCustomersClickedOpen.success(action.payload));
    }
    catch(e){
      yield put(Routines.setCustomersClickedOpen.failure());
    }
  }

function* setDownloading(action: AppAction<boolean>){
  try{
    yield put(Routines.setDownloading.success(action.payload));
  }
  catch(e){
    yield put(Routines.setDownloading.failure());
  }
}


function* getCsv(action: AppAction<CsvProps>){
  const {pdfUrl, invoiceNumber, yearMonth} = action.payload;
  let csvProcessing = true;
  try{
    yield put(Routines.setCsvProcessing.success({csvProcessing, invoiceNumber}));
    const csvData = yield call(Api.getCsv, {pdfUrl, yearMonth});
    yield put(Routines.getCsv.success({csvData, invoiceNumber}));
    csvProcessing = false;
    yield put(Routines.setCsvProcessing.success({csvProcessing, invoiceNumber}));
  }
  catch(e){
    csvProcessing = false;
    yield put(Routines.setCsvProcessing.failure({csvProcessing}));
    yield put(Routines.getCsv.failure());
  }
}


function* invoiceSaga(){
  yield takeLatest(Routines.fetchInvoices.TRIGGER, fetchInvoices);
  yield takeLatest(Routines.setCustomersClickedOpen.TRIGGER, setCustomersClickedOpen);
  yield takeLatest(Routines.setDownloading.TRIGGER, setDownloading);
  yield takeLatest(Routines.getCsv.TRIGGER, getCsv);
}

export default invoiceSaga;
