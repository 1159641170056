import { filterSearch, FilterSearch } from "@Safemate/DeviceList/Store/actions";
import { redirectPartner } from "@Safemate/Store/routines";
import {AppState, MarketSetting} from "@Safemate/Store/types";
import * as React from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Routine } from "redux-saga-routines";
import { ActionFunctionAny, Action } from "redux-actions";
import { SetSelectedRoles, setSelectedRoles, SetSiteContext, setSiteContext } from "@Safemate/Store/actions";
import { SiteTypeEnum } from "@Safemate/SiteTypeEnum";
import { CustomRole } from "@Safemate/Model/Role";
import { ICustomerWithPolicy } from "@Safemate/Model/Customer";
import { ahpMenuOperational, ahpMenuSetup, Menu, menuOperational } from "./menuItems";
import {accessToCustomer, accessToCustomerPolicies} from "@Safemate/Policy/rightsUtil";
import { NavLink } from "react-router-dom";
import { menuSetup } from "./menuItems";
import { logout } from "@Safemate/Session/Store/routines";
import { useTemp } from "@Elements/Dropdown/utils";
import { changeAhpStyleSheet } from "@Safemate/MyPage/fields";
import { AhpRoles } from "@Safemate/Policy/GlobalRoleName";

interface MenuProps{
  isPrivate: boolean;
  isAhp: boolean;
  isPro: boolean;
  partnerUser: string;
  backdoorUser: string;
  isRestrictedUser: boolean;
  search: string;
  name: string;
  translator: boolean;
  filterSearch: FilterSearch;
  redirectPartner: Routine<ActionFunctionAny<Action<any>>>;
  logout: Routine<ActionFunctionAny<Action<any>>>;
  setSiteContext: SetSiteContext;
  activeRoles: CustomRole[];
  selectedRoles: CustomRole[];
  setSelectedRoles: SetSelectedRoles;
  currentContext: SiteTypeEnum;
}

const mapStateToProps = ({ appData: { activeRoles, selectedRoles, isPrivate, currentContext, isAhp, isPro, partnerUser, backdoorUser, isRestrictedUser, user: { name, translator } }, deviceList: { filter: { search } } }: AppState) => {
  return {
    activeRoles,
    selectedRoles,
    isPrivate,
    isAhp,
    isPro,
    currentContext,
    partnerUser,
    backdoorUser,
    isRestrictedUser,
    search,
    name,
    translator
  }
}

const mapDispatchToProps = {
  filterSearch,
  redirectPartner,
  setSiteContext,
  setSelectedRoles,
  logout
}

const Menu = connect(mapStateToProps, mapDispatchToProps)(({
  activeRoles,
  selectedRoles,
  isPrivate, 
  isAhp,
  isPro,
  logout, 
  partnerUser, 
  backdoorUser, 
  isRestrictedUser, 
  search, 
  name, 
  translator,
  filterSearch, 
  setSiteContext, 
  setSelectedRoles,
  redirectPartner,
  currentContext
}: MenuProps) => {

  const { formatMessage } = useIntl();

  const history = useHistory();

  const [ localSearch, setSearch ] = React.useState(search);
  //const [ open, setOpen ] = React.useState(false); 
  const { ref, isComponentVisible, setIsComponentVisible } = useTemp<HTMLUListElement>(false);

  const getUsername = () => {
    if(partnerUser){
      return partnerUser
    }
    else if(backdoorUser){
      return <React.Fragment>{name} (via <strong>{backdoorUser}</strong>)</React.Fragment>
    }
    else if(name){
      return name;
    }
    return "";
  }

  const logoutPost = () => {
    let logoutForm = document.getElementById("logoutFormHeader");
    logoutForm.submit();
  }

  const toggleRole = (role: CustomRole) => {
    let newRoles: CustomRole[] = [];
    let match = false;
    for(let i = 0; i < selectedRoles.length; i++){
      if(selectedRoles[i].id === role.id){
        newRoles = selectedRoles.filter(r => r.id !== role.id);
        match = true;
        break;
      }
    }
    if(!match){
      newRoles = [...selectedRoles, role];
    }
    setSelectedRoles(newRoles);
  }

  const mappedRoles = React.useMemo(() => {
    return activeRoles.map(role => {

      let checked = false;

      for(let i = 0; i < selectedRoles.length; i++){
        if(selectedRoles[i].id === role.id){
          checked = true;
          break;
        }
      }
      return (
        <li key={`role-${role.roleName}`}>
          <input style={{display: "none"}} checked={checked} id={role.roleName} type="checkbox"/>
          <label onClick={() => toggleRole(role)} htmlFor={role.roleName}> <span></span> <span>{role.roleName}</span></label>
        </li>)
    })
  }, [selectedRoles])

  return(
    <div className="header">
			<Grid>
				<Row>
					<Col xs={3} sm={3} md={2} className="logo" onClick={() => window.location.replace("")}>
            <span><img src={`/proffweb/images/${isPrivate ? "logogo.png" : "logopro.png"}`}></img></span>
          </Col>
          <Col xs={3} sm={4} className="search_field">
            <div className="search_field_container">
              <input 
                type="search"
                id="search-input"
                placeholder={formatMessage({id: "searchPlaceholder", defaultMessage: "Search"})}
                value={localSearch}
                onChange={(event) => {
                  setSearch(event.currentTarget.value);
                }}
                onKeyUp={(event) => {

                  if(event.key === "Enter"){
                    filterSearch(event.currentTarget.value);
                    history.push("/safemates");
                  }

                }}
              />
              <div className="search-button" onClick={() => {
                filterSearch(localSearch);
                history.push("/safemates");
              }}></div>
            </div>
          </Col>
          <Col xsPull={20} sm={5} md={6} className="dropDownOption">
            <div>
              <div className="pull-right-sm">
                <Col md={12} sm={12} xs={12} className="user_container" style={{paddingRight: "0"}}>
                  <div className="user userText" style={{display: "flex"}}>
                    <span style={{padding: "0px"}} className="name" id="loggedInAs">
                      {formatMessage({id: "loggedInAs", defaultMessage: "Logged in as"})}
                    </span>
                    <span style={{paddingLeft: "5px"}} className="name">
                      {getUsername()}
                    </span>
                  </div>
                  {!isPrivate && 
                    <div className={`user btn-group ${isComponentVisible ? "open" : ""}`} role="group">
                      <button onClick={() => {setIsComponentVisible(!isComponentVisible)}} type="button" className="btn dropdown-toggle">
                        <span className="caret arrow-down"></span>
                      </button>
                      <ul ref={ref} className="dropdown-menu user-menu pull-right" role="menu">
											{!isRestrictedUser &&
                        <React.Fragment>
                          {partnerUser && 
                            <li key="back-partner" onClick={() => {
                              redirectPartner()
                              setIsComponentVisible(false)
                            }} style={{width: "max-content"}}>
                              {formatMessage({id: "backPartner", defaultMessage: "Back to Partner Portal"})}
                            </li>}
                          <li key="my-page" onClick={() => {
                            location.hash = 'my_page';
                            setIsComponentVisible(false)
                          }}>
                            {formatMessage({id: "myPage", defaultMessage: "Min side"})}
                          </li>
                          {(isAhp && currentContext === SiteTypeEnum.PROFFWEB) &&
                          <li key="ahp" onClick={() => {
                            setSiteContext(SiteTypeEnum.AHP);
                            changeAhpStyleSheet(true);
                            location.hash = "dashboard";
                            setIsComponentVisible(false);
                          }}>
                            {formatMessage({id: "toAhp", defaultMessage: "Til Response"})}
                          </li>}
                          {(isPro && currentContext === SiteTypeEnum.AHP) &&
                          <li key="pro" onClick={() => {
                            setSiteContext(SiteTypeEnum.PROFFWEB);
                            changeAhpStyleSheet(false);
                            location.hash = "dashboard";
                            setIsComponentVisible(false);
                          }}>
                            {formatMessage({id: "toPro", defaultMessage: "Til PRO"})}
                          </li>}
                          {(translator && currentContext === SiteTypeEnum.PROFFWEB) &&
                            <li key="translate" onClick={() => {
                              location.hash = "translate/";
                              setIsComponentVisible(false);
                            }}>
                            {formatMessage({id: "translateTool", defaultMessage: "Translate tool"})}
                          </li>}
                          <li key="gdprManager" onClick={() => {
                            location.hash = "gdpr"
                            setIsComponentVisible(false)
                          }}>
                            {formatMessage({id: "gdprDocument", defaultMessage: "Databehandleravtale"})}
                          </li>
                          <li key="logout" onClick={() => {
                            logoutPost();
                            setIsComponentVisible(false);
                          }}>
                            {formatMessage({id: "logout", defaultMessage: "Logg ut"})}
                          </li>
                          {currentContext === SiteTypeEnum.PROFFWEB && mappedRoles}
                        </React.Fragment>}
										  </ul>
                    </div>
                  }
                </Col>
              </div>
            </div>
          </Col>
        </Row>
      </Grid>
      <form id={`logoutFormHeader`} action={`/logout`} method={`post`}>
        <input type={`hidden`} name={`name`} value={`value`} />
      </form>
		</div>
  )
})

interface NavigationProps{
  customersWithPolicy: ICustomerWithPolicy[];
  isPrivate: boolean;
  isPartner: boolean;
  isRestrictedUser: boolean;
  currentContext: SiteTypeEnum;
  logout: Routine<ActionFunctionAny<Action<any>>>;
  ahpRoles: AhpRoles;
  marketSetting: MarketSetting;
}

const mapStateToNavigationProps = ({ appData: { ahpRoles, customersWithPolicy, isPrivate, isPartner, isRestrictedUser, currentContext }, appSettings:{marketSetting} } : AppState) => {
  return {
    customersWithPolicy,
    isPrivate,
    isPartner,
    isRestrictedUser,
    currentContext,
    ahpRoles,
    marketSetting
  }
}

const mapDispatchToNavigationProps = {
  logout
}

export const Navigation = connect(mapStateToNavigationProps, mapDispatchToNavigationProps)(({ ahpRoles, logout, customersWithPolicy, isPrivate, isPartner, isRestrictedUser, currentContext, marketSetting }: NavigationProps) => {

  const { formatMessage } = useIntl();
  const location = useLocation();

  const isAhp = SiteTypeEnum.AHP === currentContext;

  const mapOperational = () => {

    let menu = currentContext === SiteTypeEnum.PROFFWEB ? menuOperational : ahpMenuOperational;

    return menu.map(item => {
      if(hasAccess(item)){
        return mapMenuItem(item);
      }
    }).filter(item => item);
  }

  const mapSetup = () => {

    let menu = currentContext === SiteTypeEnum.PROFFWEB ? menuSetup : ahpMenuSetup;

    return menu.map(item => {
      if(hasAccess(item)){
        return mapMenuItem(item);
      }
    }).filter(item => item);
  }


  const mapMenuItem = (menuItem: Menu) => {
    if(menuItem.name === 'shop'){
      if( marketSetting.showWebShopLink ){
        if(marketSetting.webShopLink){
          menuItem.externalLink = marketSetting.webShopLink;
        }
      } else {
        return(<React.Fragment></React.Fragment>);
      }
    }
    const MenuItem =
      <li id={`menu${menuItem.name}`} className={location.pathname.includes(menuItem.link) ? "menu-item active" : "menu-item"}>
        <span className="menu-icon svg" style={{width: "38px", height: "32px"}}>
          <div className="wrapdiv">
            {menuItem.icon}
          </div>
        </span>
        <p dangerouslySetInnerHTML={{__html: formatMessage({id: menuItem.label, defaultMessage: menuItem.label})}}></p>
      </li>

    if(menuItem.link){
      return(
        <NavLink to={menuItem.link}>
          {MenuItem}
        </NavLink>
      )
    }
    else if(menuItem.externalLink){

      return(
      <li onClick={() => {
        const win = window.open(menuItem.externalLink, '_blank');
        win.focus();
      }} id={`menu${menuItem.name}`} className={location.pathname.includes(menuItem.link) ? "menu-item active" : "menu-item"}>
        <span className="menu-icon svg" style={{width: "38px", height: "32px"}}>
          <div className="wrapdiv">
            {menuItem.icon}
          </div>
        </span>
        <p dangerouslySetInnerHTML={{__html: formatMessage({id: menuItem.label, defaultMessage: menuItem.label})}}></p>
      </li>
      )
    }
    else if(menuItem.name === "logout"){
      return(
        <li onClick={logout} id={`menu${menuItem.name}`} className={location.pathname.includes(menuItem.link) ? "menu-item active" : "menu-item"}>
          <span className="menu-icon svg" style={{width: "38px", height: "32px"}}>
            <div className="wrapdiv">
              {menuItem.icon}
            </div>
          </span>
          <p dangerouslySetInnerHTML={{__html: formatMessage({id: menuItem.label, defaultMessage: menuItem.label})}}></p>
        </li>
        )
    }
  }

  const hasAccess = (menuItem: Menu) => {

    if(currentContext === SiteTypeEnum.AHP){
      if(menuItem.superAhp && !ahpRoles.SUPERAHP){
        return false;
      }
      return true;
    }

    if( menuItem.restrictedUser && isRestrictedUser ){
      return true;
    }
    if(isPrivate){
      return menuItem.private;
    }

    if( menuItem.showByDefault ){
      return true
    }


    let hasRight = false;
    for(let i = 0; i < customersWithPolicy.length; i++){
      if( menuItem.showIfRight ){
        if(accessToCustomerPolicies(customersWithPolicy[i].policies, menuItem.showIfRight, menuItem.allowedGlobals)){
          hasRight = true;
          break;
        }
      } else {
        if(accessToCustomer(customersWithPolicy[i].policies, null, menuItem.allowedGlobals)){
          hasRight = true;
          break;
        }
      }
    }

    if(menuItem.partnerOnly){
      return hasRight && isPartner;
    }
    return (!menuItem.privateOnly ) && (!menuItem.partnerOnly ) && hasRight;
  }

  return(
    <nav className="main-nav">
      <Grid>
        <Row style={{display: "flex"}}>
          <div style={{paddingRight: "0px", paddingLeft: "0px"}}>
            <ul className="pull-left">
              {mapOperational()}
            </ul>
          </div>
          <div style={{paddingRight: "0px", paddingLeft: "0px", marginLeft: "auto"}}>
            <ul className="pull-right">
              {mapSetup()}
            </ul>
          </div>
        </Row>
      </Grid>
    </nav>
  )
})

export default Menu;
