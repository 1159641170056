import * as React from "react";
import { MessageDescriptor} from "react-intl";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { Invoice, RootState, PartnerbillingDTO, InvoiceFile} from "../Store/types";
import { formatDateMonthYearOnly } from '../../utils';
import {SafemateInfo, SafemateInfoContext} from "../SafemateInformation/SafemateInfoProvider";
import { connect } from "react-redux";
import { Col, Row, Button } from "react-bootstrap";
import ExportPDF from "../../../Elements/Icon/Icons/Safemate/outline/ExportPDF";
import ExportCSV from "../../../Elements/Icon/Icons/Safemate/outline/ExportCSV";
import { getCsv } from "../Store/routines";
import styled from "styled-components";
import { TextEncoder as CustomTextEncoder } from "text-encoding";
import {saveAs} from 'file-saver';
import  Loader  from "@Elements/Loader";
import { AppState } from "@Safemate/Store/types";

export type MapMunicipalities = (
  customer: Array<Invoice>,
) => Array<Array<RowContent>>;

export type Header = {
  text: string;
  priority?: number;
  align?: string;
  export?: boolean;
}

const ExportButton = styled(Button)`
  min-width: 90px;
  svg{
    fill: black!important;
  }
`

const ExportDiv = styled(Col)`
  display:flex;
`
const MarginDiv = styled(Col)`
  margin-right: 5px;
`

export type MapHeader = (formatMessage: (message: MessageDescriptor) => string, isAhp: boolean, isPrivate: boolean) => Header[];


export interface RowContent{
  text: string | React.ReactElement;
  priority?: number;
  className: string;
  skipTranslate: boolean;
  parent: number;

}


export const mapHeader: MapHeader = (formatMessage) => {
 const header: Header[] = [
  { text: formatMessage({id: "datoUtsendt", defaultMessage: "Dato utsendt"})},
  { text: formatMessage({id: "invoiceNo", defaultMessage: "Fakturanummer"})},
  { text: formatMessage({id: "utstedTil", defaultMessage: "Utsted Til"}) },
  { text: formatMessage({id: "belop", defaultMessage: "Beløp"})},
  { text: formatMessage({id: "export", defaultMessage: "Export"})},]
  return header;
}
const mapStateToProps = ( { partnerBilling: {invoices: {csvData, csvInvoiceNumber, pdfData, csvProcessing}}} : AppState) => {
  return{
    csvData,
    csvInvoiceNumber,
    pdfData,
    csvProcessing
  }
};
const mapDispatchFromState = {
  getCsv
}

export const mapXledgerInvoices: MapMunicipalities = (
  invoices
) => {
  if(invoices != null){
    const filteredInvoices =  invoices.filter(invoice => {
      return invoice;
    }).sort((a, b) => {
      const f = filterOrder(a, b)
      return f;
    });
    return filteredInvoices.map((item) => {
      let floatAmount = parseFloat(item.node.amount);
      const invoiceDateFormatted = formatDateMonthYearOnly(item.node.invoiceDate);
      const mappedUser = [
        {text: item.node.invoiceDate ? invoiceDateFormatted: "", skipTranslate: true},
        {text: item.node.invoiceNumber, skipTranslate: true},
        {text: item.node.subledger.description, skipTranslate: true},
        {text: item.node.amount && floatAmount.toFixed(2), skipTranslate: true},
        {text: item.node.invoiceFile ? <Col><ExportXledger invoiceNumber={item.node.invoiceNumber} custId={item.node.subledger.code} invoiceFileData={item.node.invoiceFile} invoiceDate={item.node.invoiceDate} /></Col> : "", skipTranslate: true},
      ];
      if(item.node.invoiceFile && item.node.amount > 0){
        return mappedUser;
      }
    });
  }else{
    return null;
  }

};

const filterOrder = (invoiceOne: Invoice, invoiceTwo: Invoice): number => {
    if (invoiceOne.node.invoiceNumber > invoiceTwo.node.invoiceNumber) return -1;
    if (invoiceOne.node.invoiceNumber < invoiceTwo.node.invoiceNumber) return 1;
}



interface ExportXledgerProps {
  invoiceFileData: InvoiceFile;
  invoiceDate: string;
  custId: string;
  csvData: Array<String>;
  pdfData: string;
  invoiceNumber: string;
  csvInvoiceNumber: string;
  getCsv: Routine<ActionFunctionAny<Action<any>>>;
  csvProcessing: boolean;
}



const ExportXledger = connect(mapStateToProps, mapDispatchFromState)((({getCsv, csvProcessing, pdfData, csvInvoiceNumber, csvData, custId, invoiceNumber, invoiceFileData, invoiceDate}: ExportXledgerProps) => {
  const fileName = invoiceDate + " " + invoiceFileData;
  const pdfUrl = invoiceFileData.url;
  const yearMonth = invoiceDate.substring(0,7).replace("-","");
  const [ csvState, setCsvState ] = React.useState([]);
  const validBillingFileDate = parseInt(yearMonth) > 202010 ? true : false;

  const getCsvData = () => {
    if(!csvProcessing){
      getCsv({pdfUrl, invoiceNumber, yearMonth});
    }
  }  


  React.useEffect(() => {
    if(csvState != csvData){
      if(csvInvoiceNumber == invoiceNumber){
        let csvContent = "data:text/csv;ccharset=utf-8,%EF%BB%BF";
        csvContent + "\uFEFF";
          csvData.forEach(function(csvArray) {
              let row = csvArray;
              var commaDelimiter = ','
              row = row.split(';').join(commaDelimiter);
              csvContent += row + "\r\n";
          });
          var textEncoder = new CustomTextEncoder('windows-1252', {NONSTANDARD_allowLegacyEncoding: true});
          var csvContentEncoded = textEncoder.encode([csvContent]);
          var blob = new Blob([csvContentEncoded], {type: 'text/csv;charset=windows-1252;'});
          saveAs(blob, invoiceNumber+'.csv');
          setCsvState(csvData);
      }
    }
  },[csvData])

  return(
    <React.Fragment>
      <ExportDiv>
        <MarginDiv>
        <a href={pdfUrl} download={fileName} target={"_blank"} >
          <ExportButton>
          <ExportPDF size="medium" inline /> PDF
          </ExportButton>
        </a>
        </MarginDiv>
        <Col>
        {validBillingFileDate &&<ExportButton onClick={getCsvData} disabled={csvProcessing && csvInvoiceNumber != invoiceNumber}>
           {(csvInvoiceNumber == invoiceNumber && csvProcessing) ? <Loader/> : <Col><ExportCSV size="medium" inline /> CSV</Col>  }
        </ExportButton>}
        </Col>
      </ExportDiv>

    </React.Fragment>
  )
}))