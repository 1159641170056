import { CustomRoleWithPolicy } from "./Policy";
import { PaymentProfile } from "./PaymentProfile";

export interface ICustomerWithPolicy{
  firm: ICustomer;
  customerId: number;
  customerName: string;
  parent: number;
  defaultAhp: number;
  level: number;
  whiteListPhoneNumbers: string | null;
  numberOfDevices: number;
  policies: CustomRoleWithPolicy;
  paymentProfile: PaymentProfile;
  zone: number;
}

export interface ICustomer {
  lastChanged: number
  lastChangedBy: number
  created: any
  createdBy: number
  custId: number
  firstName: any
  lastName: string
  email: string
  mobile: string
  status: number
  activatedDate: number
  prgrId: number
  invoiceCreditLimitExclVat: number
  invoiceBalanceExclVat: number
  allowAllPhoneNo: boolean
  latestInvoiceDate: any
  orgNum: string
  addrName: any
  billingAddrName: any
  addrReference: any
  addressLine1: string
  addressLine2: any
  addrZipCode: string
  addrCity: string
  addrCounId: number
  country: any
  parent: number
  defaultAhp: number
  forceTfa: boolean
  forceCoverage: boolean
  individualBilling: boolean
  integrationId: any
  integrationRole: any
  climaxConfig: boolean
  allowDeviceDeletes: boolean
  priceGroup: PriceGroup
  defaultBillingCode: BillingCode
  billingCodes: BillingCode[]
  numActiveDevices: number
  numActiveDevicesIncludingChildren: number
  active: boolean
  company: boolean
  languageCode: string
  prioritySupport: boolean
  privateUser: boolean
  coaddress: string
  deleted: boolean
  addrNameForBilling: any
  whiteListPhoneNumbers: any
  billingAccepted: boolean
  informalContactName: string
  firmName: string
  streetAddress: string
  name: string
}

export interface PriceGroup {
  prgrId: number
  siteIdOriginated: number
  name: string
  defaultGroup: boolean
  defaultCompanyGroup: boolean
  invoiceCreditLimitExclVat: number
  couponCode: string
  id: number
}

export interface BillingCode {
  lastChanged: any
  lastChangedBy: number
  created: any
  createdBy: number
  bicoId: number
  custId: number
  sapeId: number
  billingCode: string
  ruleThemAll: boolean
  prgrId: number
  priceGroup: any
  forSalesPerson: boolean
}


export const defaultCustomer = {
  activatedDate: 0,
  active: false,
  addrCity: "",
  addrCounId: 0,
  addressLine1: "",
  addressLine2: "",
  addrName: "",
  addrNameForBilling: "",
  addrReference: "",
  addrZipCode: "",
  allowAllPhoneNo: false,
  billingAddrName: "",
  coaddress: "",
  company: false,
  country: "",
  custId: 0,
  created: 0,
  createdBy: 0,
  defaultAhp: 0,
  deleted: false,
  email: "",
  firstName: "",
  firmName: "",
  forceCoverage: false,
  forceTfa: false,
  informalContactName: "",
  integrationId: "",
  integrationRole: "",
  invoiceBalanceExclVat: 0,
  invoiceCreditLimitExclVat: 0,
  languageCode: "",
  lastChanged: 0,
  lastChangedBy: 0,
  lastName: "",
  latestInvoiceDate: 0,
  mobile: "",
  name: "",
  numActiveDevices: 0,
  orgNum: "",
  parent: 0,
  prgrId: 0,
  priceGroup: {
    prgrId: 0,
    siteIdOriginated: 0,
    name: "",
    defaultGroup: false,
    defaultCompanyGroup: false,
    invoiceCreditLimitExclVat: 0,
    couponCode: "",
    id: 0,
  },
  privateUser: false,
  status: 0,
  streetAddress: "",
  individualBilling: false,
  climaxConfig: false,
  allowDeviceDeletes: false,
  defaultBillingCode: {
    lastChanged: 0,
    lastChangedBy: 0,
    created: 0,
    createdBy: 0,
    bicoId: 0,
    custId: 0,
    sapeId: 0,
    billingCode: "",
    ruleThemAll: false,
    prgrId: 0,
    priceGroup: 0,
    forSalesPerson: false
  },
  billingCodes: [] as BillingCode[],
  numActiveDevicesIncludingChildren: 0,
  prioritySupport: false,
  whiteListPhoneNumbers: "",
  billingAccepted: false
}
