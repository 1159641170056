import {Reducer} from "redux";
import { getCapabilities, getDevices, setDevice } from "../routines";
import { Capabilities } from "@Safemate/Settings/Store/types";

const capabilityState: Capabilities = {
  id: 0,
  dehaId: 0,
  detyId: 0,
  alarm: false,
  selfcheck: false,
  teamAlert: false,
  motion: false,
  shutdownAlert: false,
  batteryPercentage: false,
  batteryLow: false,
  outOfBed: false,
  fridge: false,
  wanderer: false,
  epilepsy: false,
  inBed: false,
  fire: false,
  persistentConnection: false,
  positioning: false,
  positioningRequest: false,
  callGsm: false,
  callVoip: false,
  reportsAccessPoints: false,
  reportsCellTowers: false,
  whitelistSize: 0,
  speakerVolumeMin: 0,
  speakerVolumeMax: 0,
  microphoneVolumeMin: 0,
  microphoneVolumeMax: 0,
  gsmStrengthMin: 0,
  gsmStrengthMax: 0,
  heartbeatIntervalMin: 0,
  heartbeatIntervalMax: 0,
  configurableSosButton: false,
  configurableSosButtonDuration: 0,
  configurableOffButton: false,
  fallImpact: false,
  fallImpactMin: 0,
  fallImpactMax: 0,
  fallTilt: false,
  fallTiltMin: 0,
  fallTiltMax: 0,
  vitalsPulse: false,
  vitalsBloodPressure: false,
  vitalsWearing: false,
  twin: false,
  silentMode: false,
  findMe: false
};

const capability: Reducer<Capabilities> = (state: Capabilities = capabilityState, action) => {
  switch (action.type) {

    case getCapabilities.SUCCESS:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state;
  }
};

export default capability;
