import moment from "moment";
import { Moment } from "moment";
import { MessageDescriptor } from "react-intl";
import {Option} from "../DefaultSettings/Settings/FieldWrapper";
import { DeviceHardwareEnum } from "../DeviceHardwareEnum";
import { AlarmFilterEnum } from "../DeviceList/AlarmFilterEnum";
import { Transaction } from "./Store/types";
import {User} from "../UserAdmin/Models/User";
import { IDevice } from "@Safemate/Model/Device";

export type GetTransactionFilter = (dehaId: number, formatMessage: (message: MessageDescriptor) => string) => Option[];

export const getTransactionFilter: GetTransactionFilter = (dehaId, formatMessage) => {
  if(dehaId === DeviceHardwareEnum.SAFEMATE_HOME){
    return[
      {text: formatMessage({id: "alarm", defaultMessage: "Alarm"}), value: AlarmFilterEnum.ALARM},
      {text: formatMessage({id: "battery", defaultMessage: "Battery"}), value: AlarmFilterEnum.BATTERY},
      {text: formatMessage({id: "selfcheck", defaultMessage: "Selfcheck"}), value: AlarmFilterEnum.SELFCHECK},
      {text: formatMessage({id: "offline", defaultMessage: "Offline"}), value: AlarmFilterEnum.OFFLINE},
      {text: formatMessage({id: "technical", defaultMessage: "Technical"}), value: AlarmFilterEnum.TECHNICAL},
    ]
  }
  return[
    {text: formatMessage({id: "alarm", defaultMessage: "Alarm"}), value: AlarmFilterEnum.ALARM},
    {text: formatMessage({id: "geofence", defaultMessage: "Geofence"}), value: AlarmFilterEnum.GEOFENCE},
    {text: formatMessage({id: "battery", defaultMessage: "Battery"}), value: AlarmFilterEnum.BATTERY},
    {text: formatMessage({id: "selfcheck", defaultMessage: "Selfcheck"}), value: AlarmFilterEnum.SELFCHECK},
    {text: formatMessage({id: "offline", defaultMessage: "Offline"}), value: AlarmFilterEnum.OFFLINE},
    {text: formatMessage({id: "technical", defaultMessage: "Technical"}), value: AlarmFilterEnum.TECHNICAL},
  ]
}

export const alarmFiltering = (transaction: Transaction, alarmFilters: AlarmFilterEnum[]) => {
  if(alarmFilters.length < 1 ) return true;

  if(transaction.alarmType && alarmFilters.includes(AlarmFilterEnum.ALARM)) return true;
  
  if(transaction.geofenceType && alarmFilters.includes(AlarmFilterEnum.GEOFENCE)) return true;

  if(transaction.batteryType && alarmFilters.includes(AlarmFilterEnum.BATTERY)) return true;
  
  if(transaction.offlineType && alarmFilters.includes(AlarmFilterEnum.OFFLINE)) return true;
  
  if(transaction.selfCheckType && alarmFilters.includes(AlarmFilterEnum.SELFCHECK)) return true;

  if(transaction.technicalType && alarmFilters.includes(AlarmFilterEnum.TECHNICAL)) return true;

  return false;
}

export const deviceFiltering = (device: IDevice, alarmFilters: AlarmFilterEnum[]) => {
  if(alarmFilters.length < 1) return true;

  if(device.alarmWarning && alarmFilters.includes(AlarmFilterEnum.ALARM)) return true;
  if(device.geofenceWarning && alarmFilters.includes(AlarmFilterEnum.GEOFENCE)) return true;
  if(device.selfCheckWarning && alarmFilters.includes(AlarmFilterEnum.SELFCHECK)) return true;
  if(device.inactiveWarning && alarmFilters.includes(AlarmFilterEnum.STATIC)) return true;
  if(device.offlineWarning && alarmFilters.includes(AlarmFilterEnum.OFFLINE)) return true;
  if(device.unknownWarning && alarmFilters.includes(AlarmFilterEnum.UNKNOWN)) return true;
  if((device.batteryWarning || device.powerFailure) && device.lastMsgIn && !device.selfCheckWarning && alarmFilters.includes(AlarmFilterEnum.BATTERY)) return true;
  return false;
}

export const mapTechnical = (alarmFilters: AlarmFilterEnum[]): boolean => {
  if( alarmFilters.length > 0 && alarmFilters.includes(AlarmFilterEnum.TECHNICAL) ){
    return true;
  }
  return false;
}

export const dateFiltering = (transaction: Transaction, date: Moment | null) => {
  if(!date || transaction.ignoreFilter) return true;
  const m =  moment(new Date(transaction.recTs));
  return date.isSame(m, "date");
}

export const getGoogleMapLanguageCode = ( user: User ) => {
  if( user.languageCode === 'no_nb' ){
    return "no";
  } else if(user.languageCode === 'se'){
    return "sv";
  } else if(user.languageCode === 'dk'){
    return 'da'
  }
  return "en";
}