import { Geofence, LatLng } from "../types";
import { GeofenceTypeEnum } from "../GeofenceTypeEnum";
import { GeofenceValues } from "..";
import { isSaveNeeded } from "../utils";
import { ControlState } from "../Controls";
import { Position } from "@Safemate/map/Store/types";
import { getMarker } from "@Safemate/map/Map/mapWrapper";
import { MapLabel } from "@Safemate/map/Map/mapLabel";
import { formatDateForMap } from "@Safemate/utils";
import {IndoorLocation} from "@Safemate/Settings/IndoorLocation/types";

export class GeofenceWrapper{

  private drawingManager: any;
  private map: any;
  private maps: any;
  private overlays: any[] = [];
  private fences: Geofence[] = [];
  private initialFences: Geofence[] = [];
  private setFieldValue: Function;
  private setGeofenceType: Function;
  private setControlState: Function;
  private setUnsaved: Function;
  private values: GeofenceValues;
  private clickedId: number;
  private markers: any[] = [];
  private position: Position;
  private indoorLocations: IndoorLocation[] = [];
  private wifiGeofences: number[] = [];
  private markersIndoor: Map<number, any> = new Map<number, any>();
  private infoWindowsIndoor: Map<number, any> = new Map<number, any>();
  private geofenceId: number;

  constructor(setGeofenceType: Function, setControlState: Function, setFieldValue: Function, setUnsaved: Function, values?: GeofenceValues){
    this.setGeofenceType = setGeofenceType;
    this.setControlState = setControlState;
    this.setFieldValue = setFieldValue;
    this.setUnsaved = setUnsaved;
    if(values){
      this.values = values;
      this.clickedId = values.geofence.id;
    }
  }

  public updateValues = (values: GeofenceValues) => {

    this.clickedId = values.geofence.id;

    if(this.values)
      if(this.values.geofence.radius !== values.geofence.radius){
        if(values.geofence.shape)
          values.geofence.shape.setOptions({
            radius: values.geofence.radius
          })
      }
    this.values = values;
  }

  public setInitialFences = (initial: Geofence[]) => {
    this.initialFences = initial;
  }

  public setFences = (fences: Geofence[]) => {
    this.fences = fences;
  }

  public setPosition = (pos: Position) => {
    this.position = pos;
  }

  public setIndoorLocations = (indoorLocations: IndoorLocation[]) => {
    this.indoorLocations = indoorLocations;
  }

  public createIndoorMarkers = () =>{
    const self = this;
    self.clearIndoorMarkers();
    self.indoorLocations.map((indoorLocation)=>{
      if( self.wifiGeofences.includes(indoorLocation.inloId) ){
        self.createMarkerIndoor(indoorLocation);
      }
    });
    if( self.drawingManager ){
      self.drawingManager.setOptions({
        drawingMode : self.maps.drawing.OverlayType.NONE
      });
    }

  }

  private clearIndoorMarkers = () =>{
    const self = this;
    if(self.markersIndoor.size > 0){
      self.markersIndoor.forEach((value: any, key: number) => {
        value.setMap(null);
      })
      self.markersIndoor.clear();
    }

    if(self.infoWindowsIndoor.size > 0){
      self.infoWindowsIndoor.forEach((value: any, key: number) => {
        value.close();
      })
      self.infoWindowsIndoor.clear();
    }

  }

  private createMarkerIndoor = (indoorLocation:IndoorLocation) => {
    const self = this;
    if(self.maps){
      const marker = new self.maps.Marker();
      marker.setMap(self.map);
      marker.setDraggable(false);
      marker.setPosition(self.getPositionFromString(indoorLocation.latLng));
      if( this.wifiGeofences.includes(indoorLocation.inloId) ){
        marker.setIcon('/proffweb/images/logo-map.png');
        marker.setZIndex(1);
      } else {
        marker.setIcon('/proffweb/images/logo-map-gray.png');
        marker.setZIndex(0);
      }
      /*
      this.maps.event.addListener(marker, 'click', (event: any) => {
        self.setFieldValue("geofence.inloId", indoorLocation.inloId);
        self.setFieldValue("geofence.name", indoorLocation.name);
        self.setFieldValue("geofence.enabled", true);
        self.setFieldValue("geofence.radius", 0);
      });
       */
      const infoWindow = self.createInfoWindow(indoorLocation.name);
      infoWindow.open({
        anchor: marker,
      });
      self.infoWindowsIndoor.set(indoorLocation.inloId, infoWindow);
      self.markersIndoor.set(indoorLocation.inloId, marker);
    }
  }

  public selectIndoorMarker = (inloIds: number[]) => {
    const self = this;
    self.wifiGeofences = inloIds;
    self.createIndoorMarkers();
  }

  public createInfoWindow: any = (content: string) =>{
    const self =this;
    const contentString = `<div id="content"><div id="siteNotice"></div><div id="mapInfowindowContent">${content}</div></div>`;
    const infoWindow = new self.maps.InfoWindow({
      content: contentString,
    });
    return infoWindow;
  }

  private getPositionFromString( position: any ){
      const latAndLng = position.split(",");
      return {
        lat: parseFloat(latAndLng[0]),
        lng: parseFloat(latAndLng[1])
      };
    }

  public disableDrawingManager = () => {
    return this.drawingManager.setOptions(
      {
        drawingMode: this.maps.drawing.OverlayType.NONE
      }
    );
  }

  public resetFences = () => {
    const self = this;
    this.fences.forEach(fence => {
      const initialValues = self.initialFences.find(initialFence => initialFence.id === fence.id);
      if(fence.shape && initialValues){
        if(fence.polygon){
          const path = initialValues.vertices.map((edge) => {
            return {
              lat: edge.lat,
              lng: edge.lng
            }
          })

          fence.shape.setOptions({
            paths: path
          })
          if(this.maps){
            self.addIndividualPolyListeners(fence);
          }
        }
        else{
          if(this.maps){
            self.addIndividualCircleListeners(fence);
          }
        }
        fence.shape.setOptions({
          radius: initialValues.radius
        })
      
      }
    })
  }

  public addIndividualPolyListeners = (fence: Geofence) => {
    const self = this;
    this.maps.event.addListener(fence.shape.getPath(), 'insert_at', function(index: number) {
      var vertex = {
        lat : fence.shape.getPath().getArray()[index].lat(),
        lng : fence.shape.getPath().getArray()[index].lng()
      }
      const vertices = JSON.parse(JSON.stringify(fence.vertices));
      vertices.splice(index, 0, vertex);
      self.setFieldValue("geofence.inEdit", true);
      self.setFieldValue("geofence.vertices", vertices);
      self.setFieldValue("geofence.verticesChanged", true);      
    });
    this.maps.event.addListener(fence.shape.getPath(), 'set_at',	function(index: number) {
      const vertices = JSON.parse(JSON.stringify(fence.vertices));
      vertices[index].lat = fence.shape.getPath().getAt(index).lat();
      vertices[index].lng = fence.shape.getPath().getAt(index).lng();
      self.setFieldValue("geofence.inEdit", true);
      self.setFieldValue("geofence.vertices", vertices);
      self.setFieldValue("geofence.verticesChanged", true);      
    });
  }

  public addIndividualCircleListeners = (fence: Geofence) => {
    const self = this;
    this.maps.event.addListener(fence.shape, 'center_changed',	() => {
      const vertices: LatLng[] = [{
        lat: fence.shape.getCenter().lat(),
        lng: fence.shape.getCenter().lng()
      }];
      self.setFieldValue("geofence.inEdit", true);
      self.setFieldValue("geofence.vertices", vertices);
      self.setFieldValue("geofence.verticesChanged", true);      
    });
    this.maps.event.addListener(fence.shape, 'radius_changed',() => {
      const radius = parseInt(fence.shape.getRadius());
      if(this.clickedId === fence.id && fence.radius !== radius) {
        self.setFieldValue("geofence.inEdit", true);
        self.setFieldValue("geofence.radius", radius);        
      }
    });
  }

  public toggleEnable = () => {
    if(this.values.geofence.shape) 
      this.values.geofence.shape.setOptions({
        fillColor : this.values.geofence.enabled ? 'green' : 'gray',
        strokeColor : this.values.geofence.enabled ? 'green' : 'gray',
      })
  }
  
  public setDrawingManager = (drawingManager: any) => {
    this.drawingManager = drawingManager
  }

  public getDrawingManager = () => {
    return this.drawingManager;
  }

  public copyFence = (geofence: Geofence) => {
    if(this.maps){
      if(geofence.polygon){
        this.createPolygon(geofence);
        this.addIndividualPolyListeners(geofence);
      }
      else{
        this.createCircle(geofence);
        this.addIndividualCircleListeners(geofence);
      }
      geofence.shape.setOptions({
        editable: true
      });
      this.fitBounds();
    }
  }

  public createFences = (geofence: Geofence[]) => {
    if(!this.map || !this.maps || !this.drawingManager) return;
    this.fences = geofence;

    this.overlays.forEach((overlay) => {
      overlay.setMap(null);
    })

    this.overlays = [];
    geofence.forEach((fence: Geofence) => {

      if(fence.polygon){
        this.createPolygon(fence);
      } else if( fence.circle ){
        this.createCircle(fence);
      }
    });
    this.fitBounds();
  }

  public fitBounds = () => {
    const bounds = new this.maps.LatLngBounds();
		for (var i = 0; i < this.overlays.length; i++) {
			if (this.overlays[i].fence && this.overlays[i].fence.polygon && ( this.geofenceId === 0 || this.geofenceId === this.overlays[i].fence.id )) {
				this.overlays[i].getPaths().getArray().forEach((path: any) => {
							path.getArray().forEach((path: any) => {
								bounds.extend(path);
							});
						});
			} else if (this.overlays[i].fence && ( this.geofenceId === 0 || this.geofenceId === this.overlays[i].fence.id )) {
				bounds.union(this.overlays[i].getBounds());
			}
		}
    this.indoorLocations.map((indoorLocation)=>{
      if( this.wifiGeofences && this.wifiGeofences.length>0 && this.wifiGeofences.includes(indoorLocation.inloId) ){
        bounds.extend(this.getPositionFromString(indoorLocation.latLng));
      }
    });
		if (!bounds.isEmpty()) {
      if(this.position && isRecent(this.position)){
        const posLatLng = new this.maps.LatLng(this.position.posLati, this.position.posLong);
        bounds.extend(posLatLng);
      }
      this.map.fitBounds(bounds);
    }
    else if(this.position && isRecent(this.position)){
      const posLatLng = new this.maps.LatLng(this.position.posLati, this.position.posLong);
      bounds.extend(posLatLng);
      this.map.setOptions({maxZoom: 16});
      this.map.fitBounds(bounds);
      this.map.setOptions({maxZoom: 20});
    }
  }

  public createPolygon = (fence: Geofence) => {

    const path = fence.vertices.map((edge) => {
      return {
        lat: edge.lat,
        lng: edge.lng
      }
    })
  
    var poly = new this.maps.Polygon({
      paths : path,
      fillOpacity : 0.3,
      fillColor : fence.enabled ?'green':'gray',
      strokeColor : fence.enabled ?'green':'gray',
      strokeWeight : 2,
      clickable : true,
      editable : fence.selected && fence.editEnabled,
      suppressUndo : true,
      fence : fence,
    });
  
    fence.shape = poly;
    poly.setMap(this.map);
    this.overlays.push(poly);  

    const self = this;

    this.maps.event.addListener(poly, 'click', () => {
      self.setClickListener(fence);
    });
  }

  public createCircle = (fence: Geofence) => {
    const circle = new this.maps.Circle({
      center : {
        lat : parseFloat(getCenterLat(fence)),
        lng : parseFloat(getCenterLng(fence))
      },
      radius : fence.radius,
      fillOpacity : 0.3,
      fillColor : fence.enabled ? 'green' : 'gray',
      strokeColor : fence.enabled ? 'green' : 'gray',
      strokeWeight : 2,
      clickable : true,
      editable : fence.selected && fence.editEnabled,
      suppressUndo : true,
      fence,
    });
  
    fence.shape = circle;
    circle.setMap(this.map);
    this.overlays.push(circle);

    const self = this;

    this.maps.event.addListener(circle, 'click', () => {
      self.setClickListener(fence);
    });
  }

  public select = (id: number) => {
  
    this.geofenceId = id;
    if(this.drawingManager)
      this.drawingManager.setOptions({
        drawingMode : this.maps.drawing.OverlayType.NONE
      });
    this.removeNew();
    this.deselectAll();
    this.createFences(this.fences);
    const selectedFence = this.fences.find(fence => {
      return fence.id === id;
    });

    if(selectedFence){
      selectedFence.selected = true;
      if(selectedFence.shape && selectedFence.editEnabled && !selectedFence.indoor){
        selectedFence.shape.setOptions({
          editable: true
        });
      }
      if(selectedFence.indoor && selectedFence.inloIds.length > 0){
        this.selectIndoorMarker(selectedFence.inloIds);
      } else {
        this.selectIndoorMarker([]);
      }
    }
  }

  public removeNew = () => {
    this.overlays.forEach((overlay) => {
      if(!overlay.fence){
        overlay.setMap(null);
      }
    })
  }

  public deselectAll = () => {
    this.fences.forEach((fence: Geofence) => {
      fence.selected = false;
      if(fence.shape){
        fence.shape.setOptions({
          editable: false
        });
      }
    });
  }

  public setClickListener = (fence: Geofence) => {
    const currentFence = this.values.geofence;
    this.clickedId = fence.id;
    const initialValues = this.initialFences.find(initialFence => initialFence.id === currentFence.id);

    const func = () => {
      this.setControlState(ControlState.EDIT);
      this.setFieldValue("geofence", fence);
    }

    if(initialValues){
      if(isSaveNeeded(currentFence, initialValues))
      this.setUnsaved({
          modal: true,
          func
        });
      else{
        func();
      }
    }
    else{
      this.setUnsaved({
        modal: true,
        func
      });
    }
  }

  public init = (map:any, maps: any, initialFences: Geofence[]) => {
    this.map = map;
    this.maps = maps;
    this.initialFences = initialFences;
    this.drawingManager = new maps.drawing.DrawingManager({
      drawingMode: maps.drawing.OverlayType.NONE,
      drawingControl: false,
      drawingControlOptions: {
        position: maps.ControlPosition.TOP_CENTER,
        drawingModes: [maps.drawing.OverlayType.CIRCLE,
          maps.drawing.OverlayType.POLYGON]
      },
      circleOptions : {
        fillColor : 'gray',
        fillOpacity : 0.3,
        strokeWeight : 2,
        strokeColor: 'gray',
        clickable : false,
        editable : true,
        suppressUndo : true,
      },
      polygonOptions : {
        fillColor : 'gray',
        fillOpacity : 0.3,
        strokeWeight : 2,
        strokeColor: 'gray',
        clickable : false,
        editable : true,
        suppressUndo : true,
      }
    });
    this.drawingManager.setMap(map);
    this.setDrawingManager(this.drawingManager);
    this.addCircleListener();
    this.addPolygonListener();
    if(this.position && !this.position.drawMarker){
      this.createMarker(this.position);
    }
  }

  public addCircleListener = () => {
    const self = this;
    self.maps.event.addListener(self.drawingManager, 'circlecomplete', (circle: any) => {
      self.drawingManager.setOptions({
        drawingMode : self.maps.drawing.OverlayType.NONE
      });
      self.overlays.push(circle);
      self.setFieldValue("geofence.shape", circle);
      self.setFieldValue("geofence.inEdit", true);
      self.setFieldValue("geofence.radius", Math.floor(circle.getRadius()));
      self.setFieldValue("geofence.vertices", [{lat: circle.getCenter().lat(), lng: circle.getCenter().lng()}]);
      self.setFieldValue("geofence.enabled", true);
            
      self.maps.event.addListener(circle, 'center_changed',	() => {
        const vertices = JSON.parse(JSON.stringify(self.values.geofence.vertices));
        vertices[0].lat = circle.getCenter().lat();
        vertices[0].lng = circle.getCenter().lng();
        self.setFieldValue("geofence.inEdit", true);
        self.setFieldValue("geofence.vertices", vertices);  
      });
      this.maps.event.addListener(circle, 'radius_changed', () => {
        self.setFieldValue("geofence.inEdit", true);
        self.setFieldValue("geofence.radius", parseInt(circle.getRadius()));
      });
    });
  }

  public addPolygonListener = () => {
    const self = this;
    self.maps.event.addListener(self.drawingManager, 'polygoncomplete', function(poly: any) {
			self.drawingManager.setOptions({
				drawingMode : self.maps.drawing.OverlayType.NONE
      });
      self.setFieldValue("geofence.shape", poly);
      self.setFieldValue("geofence.polygon", true);
      self.setFieldValue("geofence.enabled", true);
      self.setFieldValue("geofence.inEdit", true);

      const vertices: LatLng[] = [];
      poly.getPath().getArray().forEach((path: any) => {
        vertices.push({lat: path.lat(), lng: path.lng()});
      })
      self.setFieldValue("geofence.vertices", vertices);
      self.overlays.push(poly);
      
      self.maps.event.addListener(poly.getPath(), 'insert_at', function(index: number) {
        var vertex = {
          lat : poly.getPath().getArray()[index].lat().toString(),
          lng : poly.getPath().getArray()[index].lng().toString()
        }
  
        const vertices = JSON.parse(JSON.stringify(self.values.geofence.vertices));
        vertices.splice(index, 0, vertex);
        self.setFieldValue("geofence.inEdit", true);
        self.setFieldValue("geofence.vertices", vertices);        
      });
      self.maps.event.addListener(poly.getPath(), 'set_at',	function(index: number) {
        const vertices = JSON.parse(JSON.stringify(self.values.geofence.vertices));
        vertices[index].lat = poly.getPath().getAt(index).lat().toString();
        vertices[index].lng = poly.getPath().getAt(index).lng().toString();
        self.setFieldValue("geofence.inEdit", true);
        self.setFieldValue("geofence.vertices", vertices);        
      });
		});
  }

  public createMarker = (position: Position) =>{
    const self = this;
    this.setPosition(position);
    if(self.maps && isRecent(position)){
      position.drawMarker = true;
      const marker = new self.maps.Marker({
        position: {lat: parseFloat(position.posLati), lng: parseFloat(position.posLong)},
        map : self.map,
        icon : getMarker(position)
      });
      
      const label = new MapLabel(false);
      label.setMap(self.map);
      label.set('zIndex', 1234);
      label.bindTo('position', marker, 'position');
      label.set('text', formatDateForMap(position.posTime));
      this.fitBounds();
    }

  }

  public setSearchBox = (search: HTMLInputElement) => {
    const self = this;
    if(self.maps && self.map){
      const searchBox = new self.maps.places.SearchBox(search);
      self.map.controls[self.maps.ControlPosition.TOP_LEFT].push(search);

      self.map.addListener('bounds_changed', function() {
        searchBox.setBounds(self.map.getBounds());
      });

      searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces();
        if(places.length === 0){
          return;
        }
        self.markers.forEach(function(marker) {
          marker.setMap(null);
        });
        self.markers = [];

        // For each place, get the icon, name and location.
        var bounds = new self.maps.LatLngBounds();
        places.forEach(function(place: any) {
          if (!place.geometry) {
            return;
          }
          var icon = {
            url: place.icon,
            size: new self.maps.Size(71, 71),
            origin: new self.maps.Point(0, 0),
            anchor: new self.maps.Point(17, 34),
            scaledSize: new self.maps.Size(25, 25)
          };

          // Create a marker for each place.
          self.markers.push(new self.maps.Marker({
            map: self.map,
            icon: icon,
            title: place.name,
            position: place.geometry.location
          }));

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
        self.map.fitBounds(bounds);
      })
    }
  }
}

const getCenterLat = (fence: Geofence) => {
  if (fence.vertices.length > 0){
    return `${fence.vertices[0].lat}`;
  }
  return "0";
};
const getCenterLng = (fence: Geofence) => {
  if (fence.vertices.length > 0){
    return `${fence.vertices[0].lng}`;
  }
  return "0";
};

const isRecent = (position: Position) => {
  const now = Date.now();
  const millisecondsInDay = 24*60*60*1000;
  const aDayAgo = now - millisecondsInDay;

  return position.posTime > aDayAgo;
}
