import {
  cancelSettings,
  resetDeviceSettings,
  getClimaxConfig,
  customerIndoorLocations,
  customerPosition,
  fetchCustomerSettings,
  deleteGeofence, deleteIndoorLocation, saveOtherSettings, saveDeviceSettings, saveEventSetupSettings, saveGeofenceSettings, saveIndoorLocationSettings, resetOtherSettings,
} from '../routines';
import { SettingState} from "../types";
import {Reducer} from "redux";
import {
  defaultDeviceSetting,
  defaultEventSetupSetting,
  defaultOtherSetting
} from "../../Settings/defaultSettings";
import uuid from "uuid";
import { DefaultSettingActionTypes } from '../actionTypes';
import {OtherSettingsWithFallback} from "../../Settings/types";

const settingsState: SettingState = {
  climaxConfig: false,
  changed: false,
  saving: false,
  resetting: false,
  customerIndoorLocations: [],
  customerLocation: null,
  deviceSettings: {...defaultDeviceSetting},
  otherSettings: {...defaultOtherSetting},
  eventSetupSettings: {...defaultEventSetupSetting},
  indoorLocationSettings: {indoorLocations:[]},
  geofenceSettings: {geofences: []},
};

const settings: Reducer<SettingState> = (state: SettingState = settingsState, action) => {
  switch (action.type) {

    case fetchCustomerSettings.SUCCESS:
      return {
        ...state,
        deviceSettings: action.payload.deviceSetting,
        otherSettings: mapIntegrationSettings(action.payload.otherSetting),
        eventSetupSettings: action.payload.eventSetupSetting,
        indoorLocationSettings: {indoorLocations: action.payload.indoorLocations},
        initialGeofences: action.payload.geofences,
        geofenceSettings: {geofences: action.payload.geofences},
      }

    case customerIndoorLocations.SUCCESS:
      return{
        ...state,
        customerIndoorLocations: action.payload
      };

    case customerPosition.SUCCESS:
      return{
        ...state,
        customerLocation: action.payload
      };

    case resetDeviceSettings.TRIGGER:
    case resetOtherSettings.TRIGGER:
      return{
        ...state,
        resetting: true
      }

    case resetDeviceSettings.SUCCESS:
      return{
        ...state,
        deviceSettings: action.payload,
        changed: false
      };

    case  resetOtherSettings.SUCCESS:
      return{
        ...state,
        otherSettings: action.payload,
        changed: false
      }

    case resetDeviceSettings.FULFILL:
    case resetOtherSettings.FULFILL:
      return{
        ...state,
        resetting: false
      }
    

    case cancelSettings.TRIGGER:
      return{
        ...state,
        settings: action.payload,
        settingsUUID: uuid.v4(),
        changed: false
      }

    case getClimaxConfig.SUCCESS:
      return{
        ...state,
        climaxConfig: action.payload
      }

    case deleteGeofence.SUCCESS:
      return{
        ...state,
        initialGeofences: action.payload,
        geofenceSettings: {
          ...state.geofenceSettings,
          geofences: action.payload
        }
      }

    case deleteIndoorLocation.SUCCESS:
      return{
        ...state,
        initialIndoorLocations: action.payload,
        indoorLocationSettings: {
          ...state.indoorLocationSettings,
          indoorLocations: action.payload
        }
      }

    case DefaultSettingActionTypes.REPORTS_CHANGED:
      return{
        ...state,
        changed: action.payload
      }

    case saveDeviceSettings.TRIGGER:
    case saveOtherSettings.TRIGGER:
    case saveEventSetupSettings.TRIGGER:
    case saveGeofenceSettings.TRIGGER:
    case saveIndoorLocationSettings.TRIGGER:
      return{
        ...state,
        saving: true
      }

    case saveDeviceSettings.SUCCESS:{
      return{
        ...state,
        deviceSettings: action.payload,
        changed: false
      }
    }

    case saveOtherSettings.SUCCESS:{
      return{
        ...state,
        otherSettings: mapIntegrationSettings(action.payload),
        changed: false
      }
    }

    case saveEventSetupSettings.SUCCESS:{
      return{
        ...state,
        eventSetupSettings: action.payload,
        changed: false
      }
    }

    case saveGeofenceSettings.SUCCESS:{
      return{
        ...state,
        geofenceSettings: {geofences: action.payload},
        initialGeofences: action.payload,
        changed: false
      }
    }

    case saveIndoorLocationSettings.SUCCESS:{
      return{
        ...state,
        indoorLocationSettings: {indoorLocations: action.payload},
        changed: false
      }
    }

    case saveDeviceSettings.FULFILL:
    case saveOtherSettings.FULFILL:
    case saveEventSetupSettings.FULFILL:
    case saveGeofenceSettings.FULFILL:
    case saveIndoorLocationSettings.FULFILL:
      return{
        ...state,
        saving: false
      }


    default:
        return state;
  }
};

const mapIntegrationSettings = (values: OtherSettingsWithFallback) : OtherSettingsWithFallback=> {
  if( values.phoneNoWhiteList && values.phoneNoWhiteList.length > 0 ){
    const whiteListNumbers = values.phoneNoWhiteList.split(";");
    if( whiteListNumbers.length >= 1 ){
      if( whiteListNumbers.length === 1 ){
        values.phoneNoWhiteList1 = whiteListNumbers[0];
      } else if( whiteListNumbers.length === 2 ){
        values.phoneNoWhiteList1 = whiteListNumbers[0];
        values.phoneNoWhiteList2 = whiteListNumbers[1];
      } else if( whiteListNumbers.length === 3 ){
        values.phoneNoWhiteList1 = whiteListNumbers[0];
        values.phoneNoWhiteList2 = whiteListNumbers[1];
        values.phoneNoWhiteList3 = whiteListNumbers[2];
      }
    }
  } else {
    if(values.phoneNoWhiteListWithFallback && values.phoneNoWhiteListWithFallback.length > 0){
      const whiteListNumbers = values.phoneNoWhiteListWithFallback.split(";");
      if( whiteListNumbers.length >= 1 ){
        if( whiteListNumbers.length === 1 ){
          values.phoneNoWhiteList1WithFallback = whiteListNumbers[0];
        } else if( whiteListNumbers.length === 2 ){
          values.phoneNoWhiteList1WithFallback = whiteListNumbers[0];
          values.phoneNoWhiteList2WithFallback = whiteListNumbers[1];
        } else if( whiteListNumbers.length === 3 ){
          values.phoneNoWhiteList1WithFallback = whiteListNumbers[0];
          values.phoneNoWhiteList2WithFallback = whiteListNumbers[1];
          values.phoneNoWhiteList3WithFallback = whiteListNumbers[2];
        }
      }
    }
    values.phoneNoWhiteList = "";
  }
  values.phoneNoWhiteListWithFallback = "";
  if( !values.phoneNoWhiteList1 ){
    values.phoneNoWhiteList1 = "";
  }
  if( !values.phoneNoWhiteList2 ){
    values.phoneNoWhiteList2 = "";
  }
  if( !values.phoneNoWhiteList3 ){
    values.phoneNoWhiteList3 = "";
  }
  if( !values.phoneNoWhiteList1WithFallback ){
    values.phoneNoWhiteList1WithFallback= "";
  }
  if( !values.phoneNoWhiteList2WithFallback ){
    values.phoneNoWhiteList2WithFallback= "";
  }
  if( !values.phoneNoWhiteList3WithFallback ){
    values.phoneNoWhiteList3WithFallback= "";
  }

  return values;
}

export default settings;
