import { darken } from 'polished';
import common from './common';
import {IColors, IModalColors, ITheme, IDeviceStatus, ITableColors, IEventSetupColors, IEventViewColors} from "./types";


const colors: IColors = {
  ...common.colors,
  textPrimary: '#cacacb',
  textSecondary: '#d5dbe0',
  textFocus: 'white',
  textClick: 'white',
  svgFill: 'white',
  textHover: darken(0.5, '#cacacb'),
  backgroundPrimary: '#202125',
  backgroundSecondary: '#2c2e32',
  backgroundTertiary: '#17181b',
  border: '1px solid #36393e',
  borderColor: '#36393e',
  borderRadius: '4px',
  accent: '#f5a623',
  hoverAccent: '#e1e1e1',
  accentContrast: 'black',
  textHighlight: '#ffffff',
  statusBarBackground: '#141517',
  invertBackgroundPrimary: 'white'
};

const deviceStatus: IDeviceStatus = {
  alarm: "#F80000",
  lowBattery: "#F5EECF",
  fire: "red",
  nonEscalatedAlarm: "#F5A623",
  checkmark: "#c0f090",
  geofenceWarning: "#7A7A7A",
  static: "#F9E7C0",
  clear: "#C0F090",
  fridge: "#4BC7FD",
  epilepsy: "yellow"
}

const modalColors: IModalColors = {
  headerBackground: '#2c2e32',
  bodyBackground: '#202125',
  text: '#cacacb'
};

const tableColors: ITableColors = {
  alarm: "#431B27",
  new: "#204525",
  notifyEvent: "#4BC7FD"
}

const eventSetupColors: IEventSetupColors = {
  family: "#204525",
  familyHover: "#637D66"
}

const eventViewColors: IEventViewColors = {
  weakAddress: "#CACACB"
}

const safemateDark: ITheme = {
  ...common,
  colors,
  modalColors,
  deviceStatus,
  tableColors,
  eventSetupColors,
  eventViewColors
};

export default safemateDark;
