import {call, put, takeLatest} from "redux-saga/effects";

import * as Api from '../api';
import * as Routines from '../routines';
import {feedbackFailure, feedbackSuccess} from "../../../Feedback/reducer";
import {LabelEnum} from "../../LabelEnum";
import { AppAction } from "@Safemate/Store/types";

function* testIntegrationPing(action: AppAction<any>){
  let integrationEndpoint = action.payload;
  integrationEndpoint.msgI9nEndpoint = integrationEndpoint.msgI9nEndpoint.replace('https://','');
  integrationEndpoint.msgI9nEndpoint = integrationEndpoint.msgI9nEndpoint.replace('http://','');

  try{
      yield call(Api.pingIntegrationEndpoint, {integrationEndpoint});
      yield feedbackSuccess(LabelEnum.SUCCESSFULLY_PINGED_ENDPOINT);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.testIntegrationPing.failure());
  }
}

function* settingsSaga(){
  yield takeLatest(Routines.testIntegrationPing.TRIGGER, testIntegrationPing);
}


export default settingsSaga;
