import {call, put, takeLatest} from 'redux-saga/effects';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import * as Api from "../api";
import { Capabilities } from '@Safemate/Settings/Store/types';


function* getCapabilities(action: AppAction<number>){
  try{
    const capability: Capabilities = yield call(Api.getCapabilities, action.payload);
    yield put(Routines.getCapabilities.success(capability));
  }
  catch(e){
    yield put(Routines.getCapabilities.failure());
    console.log(e)
  }
  yield put(Routines.getCapabilities.fulfill());
}


function* capabilitySaga(){
    yield takeLatest(Routines.getCapabilities.TRIGGER, getCapabilities);
}

export default capabilitySaga;
