import * as React from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { Form, Formik } from "formik";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { Capabilities } from "@Safemate/Settings/Store/types";
import { CheckWrap } from "@Elements/Form/CheckWrap";
import { SaveButton } from "../../../DefaultSettings/Settings/styled";
import { SimulateWrap } from "./styled";
import { simulate } from "../../Store/routines";
import { AppState } from "@Safemate/Store/types";

interface SimulateProps{
  deviId: number;
  capabilities: Capabilities;
  simulate: Routine<ActionFunctionAny<Action<any>>>;
}

export interface SimulateForm{
  userAlarm: boolean;
  geofence: boolean;
  outOfBed: boolean;
  fridge: boolean;
  wanderer: boolean;
  epilepsy: boolean;
  inBed: boolean;
  noMotion: boolean;
  manDown: boolean;
  fire: boolean;
  selfCheck: boolean;
  lowBattery: boolean;
  offline: boolean;
}

const mapStateToProps = ({ settings: {initialization: { deviId }, device: { capabilities }}}: AppState) => {
  return {
    deviId,
    capabilities
  }
}

const mapDispatchToProps = {
  simulate
}

const Simulate = connect(mapStateToProps, mapDispatchToProps)(({ deviId, capabilities, simulate }: SimulateProps) => {

  const { formatMessage } = useIntl();

  return(
    <div>
      <h2>{formatMessage({id: "simulateEvents", defaultMessage: "Simuler hendelser"})}</h2>
      <div>
          <Formik
            onSubmit={(values) => {
              simulate({
                deviId,
                data: values
              })
            }}
            initialValues={{
              userAlarm: false,
              geofence: false,
              outOfBed: false,
              fridge: false,
              wanderer: false,
              epilepsy: false,
              inBed: false,
              noMotion: false,
              manDown: false,
              fire: false,
              selfCheck: false,
              lowBattery: false,
              offline: false
            }}
          >
            <Form>
              {mapCapabilitiesToEvents(capabilities).map(comp => 
                <SimulateWrap>{React.createElement(comp)}</SimulateWrap>)}
              <SaveButton type="submit">
                {formatMessage({id: "save", defaultMessage: "Lagre"})}
              </SaveButton>
            </Form>
          </Formik>
      </div>
    </div>
  )
})

const mapCapabilitiesToEvents = (capabilities: Capabilities) => {
  const events = [];
  if(capabilities.alarm) events.push(UserAlarm);
  if(capabilities.positioning) events.push(Geofence);
  if(capabilities.outOfBed) events.push(OutOfBed);
  if(capabilities.fridge) events.push(Fridge);
  if(capabilities.wanderer) events.push(Wanderer);
  if(capabilities.epilepsy) events.push(Epilepsy);
  if(capabilities.inBed) events.push(InBed);
  if(capabilities.motion) events.push(NoMotion);
  if(capabilities.fallImpact || capabilities.fallTilt) events.push(ManDown);
  if(capabilities.fire) events.push(Fire);
  if(capabilities.selfcheck) events.push(SelfCheck);
  if(capabilities.batteryLow || capabilities.batteryPercentage) events.push(LowBattery);
  if(capabilities.shutdownAlert) events.push(Offline);
  return events;
}

const UserAlarm = CheckWrap<SimulateForm>("userAlarm", {label: {id: "userAlarm", defaultMessage: "Brukerutløst alarm"}})
const Geofence = CheckWrap<SimulateForm>("geofence", {label: {id: "geofenceAlarm", defaultMessage: "Geofence alarm"}})
const OutOfBed = CheckWrap<SimulateForm>("outOfBed", {label: {id: "notBackInBedAlarm", defaultMessage: "Not back in bed alarm"}})
const Fridge = CheckWrap<SimulateForm>("fridge", {label: {id: "fridgeAlarm", defaultMessage: "Fridge alarm"}})
const Wanderer = CheckWrap<SimulateForm>("wanderer", {label: {id: "wandererAlarm", defaultMessage: "Door alarm"}})
const Epilepsy = CheckWrap<SimulateForm>("epilepsy", {label: {id: "epilepsyAlarm", defaultMessage: "Epilepsy alarm"}})
const InBed = CheckWrap<SimulateForm>("inBed", {label: {id: "notStoodUpAlarm", defaultMessage: "Not stood up alarm"}})
const NoMotion = CheckWrap<SimulateForm>("noMotion", {label: {id: "noActivityAlarm", defaultMessage: "Inactivity alarm"}})
const ManDown = CheckWrap<SimulateForm>("manDown", {label: {id: "fallAlarm", defaultMessage: "Fall alarm"}})
const Fire = CheckWrap<SimulateForm>("fire", {label: {id: "fireAlarm", defaultMessage: "Fire alarm"}})
const SelfCheck = CheckWrap<SimulateForm>("selfCheck", {label: {id: "deviceFailedSelfCheckAlarm", defaultMessage: "Device failed self-check alarm"}})
const LowBattery = CheckWrap<SimulateForm>("lowBattery", {label: {id: "lowBatteryAlarm", defaultMessage: "Low battery alarm"}})
const Offline = CheckWrap<SimulateForm>("offline", {label: {id: "deviceOfflineAlarm", defaultMessage: "Device offline alarm"}})

export default Simulate;