import * as React from "react";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import EditIcon from "@Icons/FontAwesome/EditName";
import { defineMessages, useIntl } from "react-intl";
import confirmModal from "@Safemate/Modals/confirmModal";
import { connect } from "react-redux";
import { Field, Formik } from "formik";
import { captureImage } from "@Safemate/Settings/Store/routines";
import { HorizontalRow, SelectWrap } from "../styles";
import { ISensorDTO } from "../types";
import { AppState } from "@Safemate/Store/types";
import { VideoIcon } from "@Elements/Icon/Icons/FontAwesome";

const label = defineMessages({
  update: { id: 'updateLabel', defaultMessage: 'Oppdater:' },
  updateNameBtn: { id: 'updateNameLabel', defaultMessage: 'Oppdater navn' },
  cancelBtn: { id: 'universalCancelBtn', defaultMessage: 'Avbryt' }
});


interface CaptureImageProps{
  sensor: ISensorDTO;
  captureImage: Routine<ActionFunctionAny<Action<any>>>;
}


const mapDispatchToProps = {
  captureImage,
}

export const CaptureImage = connect(null, mapDispatchToProps)(({ sensor, captureImage }: CaptureImageProps) => {

  const { formatMessage } = useIntl();
  const { Comp, func } = confirmModal();
  const [open, setOpen] = React.useState(false);

  const { name, gatewayId, deseId } = sensor.sensor;

  if(!sensor.status.online) return null;

  return(
    <React.Fragment>
      <VideoIcon 
        onClick={() => {func(true); setOpen(true);}}
        medium
        animate
        title={formatMessage({id: 'captureImage', defaultMessage: 'Ta bilde' })}
      />
      <Comp
        title={`${formatMessage({id: 'captureImage', defaultMessage: 'Ta bilde' })} (${name} ${gatewayId})`}
        body={
          <CaptureImageDialog setOpen={setOpen} open={open} sensor={sensor}/>
        }
        standardBtn
        confirmText={
          <VideoIcon 
            onClick={() => {captureImage(gatewayId)}}
            medium
            title={formatMessage({id: 'captureImage', defaultMessage: 'Ta bilde' })}
          />}
      />
    </React.Fragment>
  )
})

interface EditRowsProps{
  sensor: ISensorDTO;
  open: Boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CaptureImageDialog = (({ sensor, open, setOpen }: EditRowsProps) => {

  const formRef = React.useRef<HTMLDivElement>(null);

  const fallbackImg = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const src = event.currentTarget.src;
    if (src != 'proffweb/images/menu/person.svg')
      event.currentTarget.src = 'proffweb/images/menu/person.svg'
  }

  return(
    <div ref={formRef}>
      <ImagePoller
        onError={fallbackImg}
        imageUrl={`/ajax/file/sensorimage/${sensor.sensor.deseId}`}
      />
    </div>
  )
});

interface ImagePollerProps {
  imageUrl: string;
  onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void
  pollingInterval?: number;
}

export const ImagePoller: React.FC<ImagePollerProps> = ({ imageUrl, onError, pollingInterval = 5000 }) => {
  const [currentImageSrc, setCurrentImageSrc] = React.useState<string>(imageUrl);

  React.useEffect(() => {
    const checkForNewImage = async () => {
      try {
        const response = await fetch(imageUrl, { cache: 'no-store' });
        if (response.ok) {
          const newImageSrc = `${imageUrl}?timestamp=${new Date().getTime()}`;
          setCurrentImageSrc(newImageSrc);
        }
      } catch (error) {
        console.error('Error fetching the image:', error);
      }
    };

    const intervalId = setInterval(checkForNewImage, pollingInterval);

    return () => clearInterval(intervalId);
  }, [imageUrl, pollingInterval]);

  return <img onError={onError} src={currentImageSrc} alt="Polling Image" />;
};