import * as React from "react";
import { configureStore } from "./configureStore";
import rootSaga from "./Saga";
import { Provider, connect } from "react-redux";
import styled, { ThemeProvider } from "styled-components";
import { ITexts } from "@Safemate/Model/Text";
import { AppState } from "./types";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import { Router } from "@Safemate/Router";
import { initialize } from "./routines";
import themes from "@Theme/index";
import {combineReducers} from "redux";
import TranslationProvider from "../TranslationModule/TranslateOverlay/Provider";
import dashboard from "@Safemate/Dashboard/Store/Reducer/index";
import partnerDashboard from "@Safemate/PartnerDashboard/Store/Reducer/index";
import partnerBilling from "@Safemate/PartnerBilling/Store/Reducer/index";
import users from "@Safemate/UserAdmin/Store/Reducer/index";
import deviceList from "@Safemate/DeviceList/Store/Reducer/index";
import feedback from "@Safemate/Feedback/reducer";
import settings from "@Safemate/Settings/Store/Reducer/index";
import billing from "@Safemate/Billing/Store/Reducer/index";
import partnerOrganization from "@Safemate/PartnerOrganization/Store/Reducer/index";
import subUnits from "@Safemate/SubUnits/Store/Reducer/index";
import addSafemate from "@Safemate/AddSafemate/Store/Reducer/index";
import appData from "./Reducer/setup";
import appSettings from "./Reducer/settings";
import eventSetup from "@Safemate/EventSetupNew/Store/Reducer/index";
import transactionLog from "@Safemate/map/Store/Reducer/index";
import myPage from "@Safemate/MyPage/Store/Reducer/index";
import myPageBilling from "@Safemate/PrivateMyBilling/Store/Reducer/index";
import roleEditor from "@Safemate/RoleEditor/Store/Reducer/index";
import roleManager from "@Safemate/RoleManager/Store/Reducer/index";
import defaultSettings from "@Safemate/DefaultSettings/Store/Reducer/index";
import log from "@Safemate/Log/Store/Reducer/index";
import translationOverlay from "@Safemate/TranslationModule/TranslateOverlay/Store/Reducer";
import translationPage from "@Safemate/TranslationModule/TranslatePage/Store/Reducer";
import eventView from "@Safemate/EventView/Store/Reducer";
import session from "@Safemate/Session/Store/Reducer/session";
import { changeStyleSheet } from "@Safemate/MyPage/fields";
import Menu, { Navigation } from "@Safemate/Menu";
import { HashRouter } from "react-router-dom";
import Feedback from "@Safemate/Feedback";
import Session from "@Safemate/Session";
import Spinner, { MainSpinner } from "@Safemate/Spinner";
import AlarmNotification from "@Safemate/AlarmNotification";
import { MarketEnum } from "@Safemate/MarketEnum";
import PolicyProvider from "@Safemate/Policy/Provider";
import { ICustomerWithPolicy } from "@Safemate/Model/Customer";
import { AhpRoles } from "@Safemate/Policy/GlobalRoleName";
import { SiteTypeEnum } from "@Safemate/SiteTypeEnum";
import { setLocale } from "@Safemate/utils";

export default () => {
  const reducers = combineReducers<AppState>({
    appData,
    appSettings,
    addSafemate,
    deviceList,
    dashboard,
    billing,
    partnerDashboard,
    partnerBilling,
    partnerOrganization,
    users,
    feedback,
    settings,
    subUnits,
    eventSetup,
    transactionLog,
    myPage,
    myPageBilling,
    roleEditor,
    roleManager,
    defaultSettings,
    log,
    translationOverlay,
    translationPage,
    eventView,
    session
  })

  const defaultStore = configureStore(reducers, rootSaga);

  //@ts-ignore
  return(<Provider store={defaultStore}>
    <HashRouter>
      <Safemate/>
    </HashRouter>
  </Provider>)
}

interface SafemateProps{
  texts: ITexts;
  proffwebDark: boolean;
  translator: boolean;
  languageCode: string;
  loggedIn: boolean;
  initialized: boolean;
  market: MarketEnum;
  customersWithPolicy: ICustomerWithPolicy[];
  municipalities: ICustomerWithPolicy[];
  ahpRoles: AhpRoles;
  currentContext: SiteTypeEnum;
  initialize: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToProps = ({appSettings: { market }, appData: { currentContext, municipalities, customersWithPolicy, ahpRoles,  user: { languageCode, proffwebDark, translator }, initialized, texts}, session: { loggedIn }}: AppState) => {
  return {
    proffwebDark,
    texts,
    translator,
    languageCode,
    loggedIn,
    initialized,
    market,
    customersWithPolicy,
    municipalities,
    ahpRoles,
    currentContext
  }
}

const mapDispatchToProps = {
  initialize
}

export const Safemate = connect(mapStateToProps, mapDispatchToProps)(({ languageCode, currentContext, customersWithPolicy, municipalities, ahpRoles, market, initialize, initialized, loggedIn, proffwebDark}: SafemateProps) => {

  const [ customers, setCustomers ] = React.useState(currentContext === SiteTypeEnum.PROFFWEB ? customersWithPolicy : municipalities);

  React.useEffect(() => {
    setCustomers(currentContext === SiteTypeEnum.PROFFWEB ? customersWithPolicy : municipalities);
  }, [currentContext, municipalities, customersWithPolicy])

  React.useEffect(() => {
    changeStyleSheet(proffwebDark);
  }, [proffwebDark])

  React.useEffect(() => {
    setLocale(languageCode);
  }, [languageCode])

  React.useEffect(() => {
    document.title = "Safemate Pro";

    const link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = "/favicon.ico?v2.0";

    // Get the current favicon link element (if it exists)
    const existingLink = document.querySelector("link[rel*='icon']");

    // If there's an existing favicon, remove it
    if (existingLink) {
      existingLink.parentNode.removeChild(existingLink);
    }

    // Add the new favicon to the head of the document
    document.head.appendChild(link);

  }, [market])

  React.useEffect(() => {
    if(loggedIn){
      initialize();
    }
  }, [loggedIn])

  if(!initialized && loggedIn){
    return <MainSpinner/>
  }
  
  return (<ThemeProvider theme={themes[proffwebDark ? "safemateDark" : "safemateLight"]}>
    <TranslationProvider>
      <Session>
        <PolicyProvider customers={customers} ahpRoles={ahpRoles}>
          <MainBody currentContext={currentContext}/>
        </PolicyProvider>
      </Session>
    </TranslationProvider>
  </ThemeProvider>)
})

const RouterDiv = styled.div`
  display: block;
  
  .fade-out-exit {
    opacity: 0;
  }
  
  .fade-out-exit-active {
    opacity: 0;
  }
  
  /* Fade-in transition */
  .fade-in-enter {
    opacity: 0;
  }
  
  .fade-in-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
`;

interface MainBodyProps{
  currentContext: SiteTypeEnum;
}

const MainBody = ({ currentContext }: MainBodyProps) => {

  return(
    <div className="global-container">
      <Menu/>
      <Navigation/>
      <Feedback/>
      {currentContext !== SiteTypeEnum.AHP &&
        <AlarmNotification/>
      }
      <div className="content-container">
        <div style={{display: "inline-block"}}>
        <div className="container">
          <RouterDiv>
            <Router/>
          </RouterDiv>
        </div>
        </div>
      </div>
    </div>
  )
}
