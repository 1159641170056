import { Form, Formik } from "formik";
import * as React from "react";
import { Button, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import DeviceEventSetup from "../Model/DeviceEventSetup";
import SimpleDevice from "../Model/SimpleDevice";
import { setDeviceEventSetupModal, saveDeviceEventSetup } from "../Store/routines";
import { RootState } from "../Store/types";
import AddRecipient, { RecipientType } from "./AddRecipient";
import CallTypeSelector from "./CallType";
import { DaySelector } from "./Days";
import DefaultEventSetupCheck from "./DefaultEventSetupCheck";
import Delete from "./Delete";
import { EventSelector } from "./Events";
import GsmRecipient from "./GsmRecipient";
import { HeartSelector } from "./Heart";
import TimeSelector from "./TimeSelector";
import TrainedSelector from "./Trained";
import { AddRow, StyledRow } from "./WrapperStyles";
import { ModalEnum, useDefault } from "./utils";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import { Td } from "./TableElementStyles";
import EmailRecipient from "./EmailRecipient";
import { TopBorderRow } from "./GridStyles";
import SetAhp from "./Ahp";
import { useIntl } from "react-intl";
import { checkCoverage, isRecipientProvided } from "./validation";
import Coverage from "./Coverage";
import { AppState } from "@Safemate/Store/types";

interface EventSetupBody{
  eventSetups: DeviceEventSetup[];
  selectedDevice: SimpleDevice;
  isPrivate: boolean;
  setDeviceEventSetupModal: Routine<ActionFunctionAny<Action<any>>>;
  saveDeviceEventSetup: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToProps = ({ appData: {isPrivate}, eventSetup: {deviceEventSetup: { eventSetups }, device: { selectedDevice }}}: AppState) => {
  return {
    eventSetups,
    selectedDevice,
    isPrivate
  }
}

const mapDispatchToProps = {
  setDeviceEventSetupModal,
  saveDeviceEventSetup
}

const EventSetupBody = connect(mapStateToProps, mapDispatchToProps)(({ isPrivate, setDeviceEventSetupModal, saveDeviceEventSetup, eventSetups, selectedDevice }: EventSetupBody) => {

  const { formatMessage } = useIntl();
  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  if(!selectedDevice) return null;

  return(
    <Formik
      enableReinitialize
      initialValues={{sequentialCalling: selectedDevice.sequentialCalling, eventSetups}}
      onSubmit={(values) => 
        saveDeviceEventSetup({
          deviceId: selectedDevice.deviId,
          ...values
        })
      }
      render={({dirty, values: { eventSetups }, submitForm}) => 
        <Form>
          <Grid fluid>
            <StyledRow>
              <DefaultEventSetupCheck/>
              <GsmRecipient/>
            </StyledRow>
            <AddRow>
              <CallTypeSelector/>
              <AddRecipient type={RecipientType.GSM}/>
            </AddRow>
            <StyledRow>
              <EmailRecipient/>
            </StyledRow>
            <AddRow>
              <AddRecipient type={RecipientType.EMAIL}/>
            </AddRow>
            <TopBorderRow>
              <SetAhp/>
              {(!useDefault(selectedDevice) || isPrivate) &&
                <Button 
                  disabled={
                    !dirty 
                    || (!permissions.get(PolicyNameEnum.GSM_RECIPIENTS).generalPermissions.all && !permissions.get(PolicyNameEnum.EMAIL_RECIPIENTS).generalPermissions.all)
                    || !isRecipientProvided(eventSetups)}
                  style={{marginLeft: "auto"}}
                  onClick={() => {
                    const coverage = checkCoverage(eventSetups, true);
                    if(Object.keys(coverage).length > 0 && !selectedDevice.ahpId && !isPrivate){
                      setDeviceEventSetupModal({
                        selectedEventSetup: null,
                        modalType: ModalEnum.COVERAGE,
                        modalEventSetupIndex: 0
                      })
                    }
                    else{
                      submitForm();
                    }
                  }}
                >
                    {formatMessage({id: "save", defaultMessage: "Save"})}
                </Button>}
            </TopBorderRow>
          </Grid>
          <TimeSelector/>
          <DaySelector/>
          <EventSelector/>
          <TrainedSelector/>
          <HeartSelector/>
          <Delete/>
          <Coverage/>
        </Form>
      }
    />
  )
})

export default EventSetupBody;

interface TdWrap{
  eventSetup: DeviceEventSetup;
  width?: string;
  clickable?: boolean;
  modal?: ModalEnum;
  index?: number;
  setDeviceEventSetupModal: Routine<ActionFunctionAny<Action<any>>>;
  children: React.ReactNode;
  hideMobile?: boolean;
  mobileWidth?: string;
}

const mapDispatchToTdWrapProps = {
  setDeviceEventSetupModal
}

export const TdWrap = connect(null, mapDispatchToTdWrapProps)(({setDeviceEventSetupModal, eventSetup, width, clickable, modal, index, children, hideMobile, mobileWidth}: TdWrap) => {

  const handleClick = () => {
    if(clickable){
      setDeviceEventSetupModal({
        selectedEventSetup: eventSetup,
        modalType: modal,
        modalEventSetupIndex: index
      })
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if(clickable && event.key === "Enter"){
      setDeviceEventSetupModal({
        selectedEventSetup: eventSetup,
        modalType: modal,
        modalEventSetupIndex: index
      })
    }
  }

  return(
    <Td 
      family={eventSetup.familyMember}
      width={width}
      pointer={clickable}
      className={clickable ? "eventSetupHover" : ""}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      hideMobile={hideMobile}
      mobileWidth={mobileWidth}
    >
      {children}
    </Td>
  )
})