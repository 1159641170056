import { AppState } from "./types";

export const selectIsPrivate = ({ appData: { isPrivate} }: AppState) =>  isPrivate;
export const selectIsAhp = ({ appData: { isAhp} }: AppState) =>  isAhp;
export const selectIsPartner = ({ appData: { isPartner} }: AppState) =>  isPartner;
export const selectIsTracker = ({ appData: { isTrackerOnly }}: AppState) => isTrackerOnly;
export const selectCustomers = ({ appData: { customersWithPolicy }}: AppState) => customersWithPolicy;
export const selectMunicipalities = ({ appData: { municipalities } }: AppState) => municipalities;
export const selectCurrentContext = ({ appData: {currentContext} }: AppState) => currentContext;
export const selectUser = ({ appData: { user }}: AppState) => user;
export const selectSso = ({ appData: {sso} }: AppState) => sso;
export const selectTexts = ({ appData: { texts }}: AppState) => texts;