import { Transaction, TransactionState, Position} from "../types";
import {Reducer} from "redux";
import {
  getDatesWithTxForDevice,
  getPositions,
  getPosition,
  getTransactions,
  getMoreTransactions,
  getTransactionsForDate,
  getBatteryForRange,
  getSignalForRange,
  getAllDevices,
  getTransactionsForAll,
  getPositionsUuid
} from "../routines";
import { MapActionTypes } from "../actionTypes";
import { AlarmFilterEnum } from "components/Safemate/DeviceList/AlarmFilterEnum";
import { alarmFiltering, dateFiltering, mapTechnical } from "../../utils";
import { Moment } from "moment";
import moment from "moment";
import {v4 as uuidv4} from 'uuid';

const transactionState: TransactionState = {
  maxTransactionId: 0,
  positionCount: 1,
  transactions: [],
  filteredTransactions: [],
  positions: [],
  poorPositions: [],
  alarmFilter: [],
  datesWithTransactions: [],
  noMoreTransactionsToFetch: false,
  render: false,
  date: moment(new Date()),
  battery: [],
  signal: [],
  displayGraph: false,
  transactionCount: 25,
  uuid: uuidv4()
};

const transaction: Reducer<TransactionState> = (state: TransactionState = transactionState, action) => {
  switch (action.type) {
    case getTransactions.SUCCESS:
      const mergedTransactions = mergeTransactions(state.transactions, [...action.payload.transactions], action.payload.replace);
      return {
        ...state,
        maxTransactionId: action.payload.maxTransactionId,
        transactions: mergedTransactions,
        filteredTransactions: filteredTransactions(mergedTransactions, state.alarmFilter, state.date),
      };

    case getMoreTransactions.SUCCESS:
      return {
        ...state,
        noMoreTransactionsToFetch: action.payload
      }

    case getPositions.SUCCESS:
      return {
        ...state,
        poorPositions: action.payload.newDevice ? [] : state.poorPositions,
        positionCount: action.payload.count,
        positions: getPositionsWithPoorPositions(action.payload.positions, action.payload.newDevice ? [] : state.poorPositions, action.payload.count),
      }

    case getPositionsUuid.SUCCESS:
      return {
        ...state,
        poorPositions: action.payload.newDevice ? [] : state.poorPositions,
        positionCount: action.payload.count,
        positions: getPositionsWithPoorPositions(action.payload.positions, action.payload.newDevice ? [] : state.poorPositions, action.payload.count),
      }

    case getPosition.SUCCESS:
      return {
        ...state,
        poorPositions: addPoorPosition(state.poorPositions, action.payload),
        positions: getPositionsWithPoorPositions(state.positions, state.poorPositions, state.positionCount)
      }

    case getDatesWithTxForDevice.SUCCESS:
      return {
        ...state,
        datesWithTransactions: action.payload
      }

    case getTransactionsForDate.SUCCESS:
      return {
        ...state,
        poorPositions: [],
        positions: getPositionsWithPoorPositions(state.positions, [], state.positionCount)
      }

    case MapActionTypes.FILTER_ALARM:
      return {
        ...state,
        filteredTransactions: filteredTransactions(state.transactions, action.payload, state.date),
        technical: mapTechnical(action.payload),
        alarmFilter: action.payload
      }
    
    case MapActionTypes.SELECT_DATE:
      return {
        ...state,
        date: action.payload
      }

    case MapActionTypes.RERENDER:
      return {
        ...state,
        render: !action.payload
      }

    case MapActionTypes.RESET_STATE:{
      return transactionState
    }

    case MapActionTypes.DISPLAY_GRAPH:{
      return{
        ...state,
        displayGraph: !state.displayGraph
      }
    }

    case getBatteryForRange.SUCCESS:{
      return {
        ...state,
        battery: action.payload
      }
    }

    case getSignalForRange.SUCCESS:{
      return {
        ...state,
        signal: action.payload
      }
    }

    case getAllDevices.SUCCESS:{
      return{
        ...state,
        positions: action.payload.mappedPositions
      }
    }

    case getTransactionsForAll.SUCCESS:{
      return{
        ...state,
        transactionCount: action.payload.transactionCount,
        noMoreTransactionsToFetch: action.payload.noMore
      }
    }
    case MapActionTypes.GENERATE_MAP_UUID:
      return {
        ...state,
        uuid: action.payload
      }

    default:
      return state;
  }
};

const mergeTransactions = (currentTransactions: Transaction[], newTransactions: Transaction[], replace?: boolean) => {
  if(replace){
    return newTransactions.sort(sortTransactions);
  }
  const currentIds = currentTransactions.map(tx => tx.id);

  const mergedList: Transaction[] = [...currentTransactions];
  newTransactions.forEach(tx => {
    if(!currentIds.includes(tx.id)){
      mergedList.push(tx);
    }
  })
  return mergedList.sort(sortTransactions);
}

const filteredTransactions = (transactions: Transaction[], alarmFilter: AlarmFilterEnum[], date: Moment) => {
  return transactions.filter(transaction => alarmFiltering(transaction, alarmFilter) && dateFiltering(transaction, date));
}

const sortTransactions = (a: Transaction, b: Transaction) => {
  if(a.id > b.id){
    return -1;
  }
  if(a.id < b.id){
    return 1;
  }
  return 0;
}

const sortPositions = (a: Position, b: Position) => {
  if(a.tranId > b.tranId){
    return -1;
  }
  if(a.tranId < b.tranId){
    return 1;
  }
  return 0;
}

const addPoorPosition = (poorPositions: Position[], position: Position): Position[] => {
  poorPositions.push(position);
  return poorPositions;
}

const getPositionsWithPoorPositions = ( positions: Position[], poorPositions: Position[], count: number ): Position[]=>{
  positions.push.apply(positions, poorPositions);
  positions.sort(sortPositions);
  return positions.splice(0, count);
}

export default transaction;
