import {ReportState} from "../types";
import {Reducer} from "redux";
import {initializeAlarms, initializeReport, fetchAlarms, fetchReport, fetchAlarmHandleReport, fetchAhpReport} from "../routines";

const reportState: ReportState = {
  alarms: 0,
  status: {
    numStatic: 0,
    numFailedCheck:0,
    numOffline: 0,
    numUnknown: 0,
    numActive:0,
    numOutdatedPosition: 0
  },
  deviceHardware: {},
  recipientPercentage: 0,
  recipientNOKPercentage: 0,
  alarmHandledPercentage: 0,
  alarmHandledNOKPercentage: 0,
  percentageReportInitialized: false,
  ahpReport: {
    numEscalatedAlarms: 0,
    numMunicipalities: 0,
    numNonEscalatedAlarms: 0,
    numOnlineOperators: 0,
    numPrivateCustomers: 0,
    numTotalDevices: 0
  }
};

const report: Reducer<ReportState> = (state: ReportState = reportState, action: any) => {
  switch (action.type) {
    case initializeAlarms.SUCCESS:
      return {
        ...state,
        alarms: action.payload
      };

    case initializeReport.SUCCESS:
      return{
        ...state,
        ...action.payload
      };

    case fetchAlarms.SUCCESS:
      return {
        ...state,
        alarms: action.payload
      };

    case fetchReport.SUCCESS:
      return{
        ...state,
        ...action.payload
      }

    case fetchAlarmHandleReport.SUCCESS:
      return{
        ...state,
        recipientPercentage: action.payload.recipientPercentage,
        alarmHandledPercentage: action.payload.alarmHandledPercentage,
        recipientNOKPercentage: action.payload.recipientNOKPercentage,
        alarmHandledNOKPercentage: action.payload.alarmHandledNOKPercentage,
        percentageReportInitialized: true,
      }

    case fetchAhpReport.SUCCESS:
      return{
        ...state,
        ahpReport: action.payload
      }

    default:
        return state;
  }
};

export default report;
