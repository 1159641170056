import * as React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";
import { Button, Col, Glyphicon, Row } from "react-bootstrap";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import moment, { Moment } from "moment";
import TableExport from "../../Elements/Export/TableExport";
import { getExportData, ExportType } from "./Log/utils";
import { RootState, Transaction } from "./Store/types";
import Settings from "../../Elements/Icon/Icons/settings/Settings";
import Users from "../../Elements/Icon/Icons/Safemate/solid/Users";
import { DeviceHardwareEnum } from "../DeviceHardwareEnum";
import Dropdown from "../../Elements/Dropdown/Dropdown";
import { getTransactionFilter } from "./utils";
import { AlarmFilterEnum } from "../DeviceList/AlarmFilterEnum";
import { DisplayGraph, displayGraph, FilterAlarm, filterAlarm, SelectDate, selectDate } from "./Store/actions";
import {Option} from "../DefaultSettings/Settings/FieldWrapper";
import SmallPin from "../../Elements/Icon/Icons/SmallPin";
import { getCurrentPosition, getTransactionsForDate } from "./Store/routines";
import  Loader  from "@Elements/Loader";
import { AllPermissions, PolicyContext } from "../Policy/Provider";
import { PolicyNameEnum } from "../PolicyNameEnum";
import { Wrapper as DateWrapper } from "@Elements/Datepicker/styles";
import { AppState } from "@Safemate/Store/types";
import Analysis from "@Elements/Icon/Icons/FontAwesome/Analysis";
import { LogParams, MapType } from ".";
import { useParams } from "react-router";

const FetchPositionBtn = styled(Button)`
  svg {
    width: 20px;
    height: 20px;
    margin-top: 0px;
    fill: ${props => props.theme.colors.accentContrast}
  }
  margin-right: 10px;
  min-width: 250px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    margin-right: 0px;
    min-width: 230px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const SubWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
    display: block;
  }
`;

const DropWrap = styled.div`
  margin-right: 10px;
  width: 100%;
  @media (max-width: 600px) {
    display: none;
  }
`;

const DropWrapMobile = styled.div`
  margin-right: 10px;
  width: 100%;
  @media (max-width: 600px) {
    margin-bottom: 10px;
    margin-right: 0px;
    margin-left: 3px;
    width: 155px!important;
  }
  @media (min-width: 601px){
    display: none;
  }
`;

const StyledDateWrapper = styled<any>(DateWrapper)`
  margin-right: 15p.1x;
  @media (max-width: 600px) {
    display:flex;
  }
`;

const DateHeader = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 185px;
  margin-right:10px;
  @media (max-width: 600px) {
    .SingleDatePickerInput{
      display: flex;
    }
    .SingleDatePickerInput_calendarIcon{
      padding-left: 0px!important;
      margin-left: 0px!important;
    }
    .DateInput{
      width: 110px!important;
    }
  }
`;

interface HeaderProps{
  filteredTransactions: Transaction[];
  serialNumber: string;
  dehaId: number;
  alarmFilter: AlarmFilterEnum[];
  filterAlarm: FilterAlarm;
  selectDate: SelectDate;
  getCurrentPosition: Routine<ActionFunctionAny<Action<any>>>;
  getTransactionsForDate: Routine<ActionFunctionAny<Action<any>>>;
  fetchingPosition: boolean;
  datesWithTransactions: number[];
  date: Moment;
  technical: boolean;
  safemateAsReceiver: boolean;
  beta: boolean;
  minionTwin: boolean;
  isTrackerOnly: boolean;
  displayGraph: DisplayGraph;
  mapType: MapType;
}

const mapStateToProps = ({ appData:{ isTrackerOnly}, transactionLog: {initialization:{user:{beta}}, transaction: { filteredTransactions, alarmFilter, datesWithTransactions, date, technical }, device: { serialNumber, dehaId, safemateAsReceiver, minionTwin }, status: { fetchingPosition }}}: AppState) => {
  return{
    filteredTransactions,
    serialNumber,
    dehaId,
    alarmFilter,
    fetchingPosition,
    datesWithTransactions,
    date,
    technical,
    safemateAsReceiver,
    beta,
    minionTwin,
    isTrackerOnly
  }
}

const mapDispatchToProps = {
  filterAlarm,
  selectDate,
  getCurrentPosition,
  getTransactionsForDate,
  displayGraph
}

const Header = connect(mapStateToProps, mapDispatchToProps)(({
  displayGraph,
  getCurrentPosition, 
  getTransactionsForDate, 
  filteredTransactions,
  fetchingPosition, 
  serialNumber, 
  dehaId, 
  date,
  technical,
  safemateAsReceiver,
  beta,
  minionTwin,
  isTrackerOnly,
  mapType
}: HeaderProps) => {

  const { id } = useParams<LogParams>();
  const deviceId = parseInt(id);
    
  const [ csvData, setCsvData ] = React.useState<string[][]>([]);
  const [ pdfData, setPdfData ] = React.useState<string[][]>([]);
  
  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
  const logPermission = permissions.get(PolicyNameEnum.DEVICE_TRANSACTION_LOG).generalPermissions.all

  const { formatMessage } = useIntl();
  
  const name = `${formatMessage({id: "transactions", defaultMessage: "Transactions"})}_${serialNumber}`;

  let csvTimeout: NodeJS.Timeout;
  let pdfTimeout: NodeJS.Timeout;

  React.useEffect(() => {
    csvTimeout = setTimeout(() => {
      setCsvData(getExportData(ExportType.CSV, filteredTransactions, formatMessage, dehaId, beta, filteredTransactions));
    }, 1000);
    pdfTimeout = setTimeout(() => {
      setPdfData(getExportData(ExportType.PDF, filteredTransactions, formatMessage, dehaId, beta, filteredTransactions));
    }, 1000);
    return () => {
      // Cleanup polling
      clearTimeout(csvTimeout);
      clearTimeout(pdfTimeout);
    }
  }, [filteredTransactions])

  React.useEffect(() => {
    if(mapType === MapType.SINGLE && logPermission){
      getTransactionsForDate({
        deviceId,
        date: date.format("DD-MM-YYYY")
      })
    }
  }, [technical])
  
  const supportFetchPosition = dehaId === DeviceHardwareEnum.WATCH_ONE || dehaId === DeviceHardwareEnum.WATCH_TWO || dehaId === DeviceHardwareEnum.WATCH_THREE || dehaId === DeviceHardwareEnum.WATCH_FIVE || dehaId === DeviceHardwareEnum.TRIGGER_THREE_E || dehaId === DeviceHardwareEnum.TRIGGER_FOUR || dehaId === DeviceHardwareEnum.TRIGGER_THREE || dehaId === DeviceHardwareEnum.TRIGGER_TWO_4G || dehaId === DeviceHardwareEnum.WATCH_FOUR || dehaId === DeviceHardwareEnum.TRACK_TWO;

  const settings = localStorage.getItem(deviceId+'settingsTab') || "";
  const settingsUrl = `settings/${deviceId}/${dehaId}${settings}`;

  const getPos = () => {
    if(!fetchingPosition)
    getCurrentPosition(deviceId)
  }

  if(isTrackerOnly || !logPermission) return null;
  return(
    <Row style={{marginBottom: "5px"}}>
      <Col md={7} xs={12}>
        <React.Fragment>
          {permissions.get(PolicyNameEnum.DEVICE_TRACKING).generalPermissions.all && supportFetchPosition &&
            <FetchPositionBtn onClick={getPos}>{fetchingPosition ? <Loader/> : <React.Fragment><SmallPin inline/> {formatMessage({id: "takitForcePositionMessage", defaultMessage: "FETCH CURRENT POSITION"})}</React.Fragment>}</FetchPositionBtn>}
          {permissions.get(PolicyNameEnum.DEVICE_LOGS).generalPermissions.all &&
          <React.Fragment>
            <TableExport type="csv" data={csvData} fileName={name} />
            <TableExport colWidth={[10, 14, 18, 10, 22, 8, 8, 10]} type="pdf" data={pdfData} fileName={name} />
          </React.Fragment>}
        </React.Fragment>
      </Col>
      <Col style={{display: "flex", justifyContent: "end"}} md={5} xs={12}>
        <React.Fragment>
        <button onClick={displayGraph} className="btn btn-dark-with-line btn-small"><Analysis medium/></button>
        {!minionTwin && (safemateAsReceiver || (permissions.get(PolicyNameEnum.EVENT_SETUP).generalPermissions.all &&
        <button onClick={() => {location.hash = `event_setup/${deviceId}`}} className="btn btn-dark-with-line btn-small"><Users inline size="medium"/></button>))}
        {minionTwin && <button disabled={true} title={formatMessage({id: "twinDisabledEventSetupMap", defaultMessage: "Varslingsliste for klone er ikke tilgjengelig."})}  className="btn btn-dark-with-line btn-small"><Users inline size="medium"/></button>}
        <button onClick={() => {location.hash = settingsUrl}} className="btn btn-dark-with-line btn-small"><Settings inline size="medium"/></button>
        </React.Fragment>
      </Col>
    </Row>
  )
})

export default Header;