import * as React from "react";
import { connect } from "react-redux";
import { Routine } from "redux-saga-routines";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Button, Grid } from "react-bootstrap";
import { Action, ActionFunctionAny } from "redux-actions";
import { Field, Formik, FormikProps, Form } from "formik";
import styled from "styled-components";

import { RadioGroup, Radio } from "../../../Elements/RadioFormik";
import { fetchOrganisation, saveOrganisation } from "../Store/routines";
import { Customer } from "../types";
import { VerticalRadioGroup } from "../SoundLight/Components/style";
import { PolicyNameEnum } from "../../PolicyNameEnum";
import { accessToCustomer, checkPermissionsForCustomer } from "../../Policy/rightsUtil";
import { GlobalRoles } from "../../GlobalRoles";
import { Checkbox } from "../../../Elements/CheckboxFormik";
import withFormWrapper from "@Elements/Form/formWrapper";
import Spinner from "../../Spinner";
import { AppState } from "@Safemate/Store/types";
import UnsavedCheck from "@Safemate/SaveNeeded/unsavedModal";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import Loader, { WrappedLoader } from "@Elements/Loader";

interface OrganisationProps<T> extends WrappedComponentProps {
  fetchOrganisation: Routine<ActionFunctionAny<Action<any>>>;
  saveOrganisation: Routine<ActionFunctionAny<Action<any>>>;
  customers: Customer[];
  ownedByCustomer: number;
  ahpId: number;
  deviId: number;
  loaded: boolean;
  deviceSerialNumber: string;
  deviceName: string;
  deviceTypeName: string;
}

const IndentedField = styled.span<{ level: number, disabled: boolean }>`

    margin-left: ${props => props.level * 20}px;
    margin-top: 10px;

    ${({ disabled }) => disabled && "margin-top: 10px"};

`;

const CheckWrapper = styled.div`

    display: flex;
    align-items: center;
    margin: 40px 0 40px 0;

`;

const Wrapper = styled(Grid)`

    margin: 40px auto;
    max-width: 30em;

`;

const CurrentOrgWrapper = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0 25px;
    width: 100%;
    min-height: 30px;
    border-bottom: 2px solid #aaadb0;
    padding: 0 20px;

`;

const PreviewHeader = styled.div`

    margin: 20px 0;

`;

export const UpdateButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
`;

const Wrap = styled.div`
  position: relative;

  #spinner-div{
    position: unset;
    background-color: unset;
    padding: 15px;
  }
`;

export interface OrgValues {
  custId: string;
  updatedAhp: boolean;
  deviId: number;
}

const Organisation = injectIntl(({ intl: { formatMessage }, fetchOrganisation, saveOrganisation, customers, loaded, ownedByCustomer, deviId, ahpId, deviceSerialNumber, deviceName, deviceTypeName }: OrganisationProps<OrgValues>) => {
  const [changedVar, setChangedVar] = React.useState(true);
  const [submitedVal, setSubmitedVal] = React.useState("");
  
  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
  
  const formikChange:any = ((values:any) => {
    setChangedVar(submitedVal !== values);
  })
 
  React.useEffect(() => {
    fetchOrganisation();
  }, [])
 
  const customerInfo = React.useMemo(() => {
    const cust: { [index: string]: Customer } = {};
    customers.forEach((customer) => {
      cust[`${customer.customerId}`] = customer;
    })
    return cust;
  }, [customers])

  const options = React.useMemo(() => {
    return customers.map(customer => {

      const disabled = !checkPermissionsForCustomer(permissions, PolicyNameEnum.DEVICE_ORGANIZATION, customer.customerId);
      
      return (
        <IndentedField
          level={customer.level}
          disabled={disabled}
        >
          {!disabled
            ? <Field
              component={Radio}
              name="custId"
              radioValue={`${customer.customerId}`}
              id={`customer-${customer.customerId}`}
              label={customer.customerName}
            />
            : <span style={{ opacity: "0.5", marginTop: "10px" }}>
              {customer.customerName}
            </span>}
        </IndentedField>
      )
    })
  }, [customers])

  if (!loaded)
    return (
      <WrappedLoader/>
    )

  const initialValues: OrgValues = {
    custId: `${ownedByCustomer}`,
    updatedAhp: false,
    deviId
  }

  return (
    <Formik
      onSubmit={(values) => {
        setChangedVar(false);
        setSubmitedVal(values.custId);
        saveOrganisation(values);
      }}
      enableReinitialize
      initialValues={initialValues}
    >
      {({ values }) => {
        const selectedAhp = customerInfo[values.custId] && customerInfo[values.custId].defaultAhp;
        const changed = `${ownedByCustomer}` !== values.custId;
        formikChange(values.custId);

        return (
          <Wrapper fluid>
            <UnsavedCheck/>
            <Form>
              <div>
                <RadioGroup disabled={false}>
                  <VerticalRadioGroup>
                    {options}
                  </VerticalRadioGroup>
                </RadioGroup>
                {(selectedAhp === 0 && selectedAhp !== ahpId) && changed &&
                  <CheckWrapper>
                    <Field
                      value={values.updatedAhp}
                      name="updatedAhp"
                      id="updatedAhp"
                      label={formatMessage({ id: "ahpCheckBoxLabel", defaultMessage: "Overfør alarmsentral innstillinger til valgt organisasjon" })}
                      component={Checkbox}
                    />
                  </CheckWrapper>}
                {changed &&
                  <div>
                    <PreviewHeader>{formatMessage({ id: "moveOrgParagraphOne", defaultMessage: "Ønsker du å flytte" })} {deviceTypeName} '{deviceName}' ({deviceSerialNumber}) {formatMessage({ id: "moveOrgParagraphTwo", defaultMessage: "slik?" })} </PreviewHeader>
                    <div>
                      <h5>{formatMessage({ id: "fromOrgLabel", defaultMessage: "Fra" })}</h5>
                    </div>
                    <CurrentOrgWrapper>
                      {customerInfo[ownedByCustomer] && customerInfo[ownedByCustomer].customerName}
                    </CurrentOrgWrapper>
                    <div>
                      <h5>{formatMessage({ id: "toOrgLabel", defaultMessage: "Til" })}</h5>
                    </div>
                    <CurrentOrgWrapper>
                      {customerInfo[ownedByCustomer] && customerInfo[values.custId].customerName}
                    </CurrentOrgWrapper>
                  </div>
                }
                <UpdateButtonBox>
                  <Button
                    id="updateBtn"
                    style={{
                      width: '100%',
                      height: '4.5em',
                      margin: '0.5em 0 0.5em 0'
                    }}
                    disabled={!changedVar}
                    type="submit"
                  >
                    {formatMessage({ id: "updateOrgBtn", defaultMessage: "Oppdater" })}
                  </Button>
                </UpdateButtonBox>
              </div>
            </Form>
          </Wrapper>
        )
      }}
    </Formik>
  )
})

const mapStateToProps = ({ settings: {organisation: { customers, loaded }, initialization: {deviId}, device: { device: { ownedByCustomer, ahpId, deviceSerialNumber, deviceTypeName, deviceName } } }}: AppState) => {
  return {
    ownedByCustomer,
    loaded,
    customers,
    ahpId,
    deviId,
    deviceSerialNumber, 
    deviceName,
    deviceTypeName
  }
}

const mapDispatchToProps = {
  fetchOrganisation,
  saveOrganisation
};


export default connect(mapStateToProps, mapDispatchToProps)(Organisation);