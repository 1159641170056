import _axios, {AxiosInstance} from 'axios';
import {DeviceSettings, EventSetupSettings, OtherSettings} from "../Settings/types";
import { addRolesToRequest } from '@Safemate/utils';
import {IndoorLocation} from "@Safemate/Settings/IndoorLocation/types";
import {Geofence} from "@Safemate/Settings/Geofence/types";

// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';

// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---

export interface GetSettings{
  customerId: number;
  deviceHardwareId: number;
}

export interface SaveSettings{
  customerId: number;
  settings: DeviceSettings | OtherSettings | EventSetupSettings | IndoorLocation[] | Geofence[];
}

export interface DeleteSettings{
  customerId: number;
  deleteId: number;
}


export const getSubOrgs = async () => {
  const url: string = `/firm/trimmedsuborgunitswithwithrights${addRolesToRequest()}`;
  const response = await axios.get(url);
  return response.data;
}

export const getDeviceHardware = async () => {
  const url: string = `/device/deviceHardwareForCustomer`;
  const response = await axios.get(url);
  return response.data;
}

export const getUser= async () => {
  const url: string = `/user/me?_=${new Date().getMilliseconds()}`;
  const response = await axios.get(url);
  return response.data;
}

export const getDeviceFirmware = async (dehaId: number) => {
  const url: string = `/firmware/getbydehaid/${dehaId}`;
  const response = await axios.get(url);
  return response.data;
}

export const getClimaxConfig = async (custId: number) => {
  const url: string = `/firm/climaxConfig/${custId}`;
  const response = await axios.get(url);
  return response.data;
}

export const pingIntegrationEndpoint = async ({integrationEndpoint}: any) => {
  const url: string = `/firm/pingIntegrationEndpoint`;
  const response = await axios.post(url, integrationEndpoint);
  return response.data;
};


export const getCustomerIndoorLocations = async (custId: number) => {
  const url: string = `/indoorlocation/customer/name/${custId}`;
  const response = await axios.get(url);
  return response.data;
}

export const getCustomerSettings = async ({customerId, deviceHardwareId}: GetSettings) => {
  const url: string = `/defaultSettings/customer/${customerId}/${deviceHardwareId}`;
  const response = await axios.get(url);
  return response.data;
}

export const saveDeviceSettings = async ({customerId, settings}: SaveSettings) => {
  const url: string = `/defaultSettings/devicesetting/${customerId}`;
  const response = await axios.post(url, settings);
  return response.data;
}

export const saveOtherSettings = async ({customerId, settings}: SaveSettings) => {
  const url: string = `/defaultSettings/othersetting/${customerId}`;
  const response = await axios.post(url, settings);
  return response.data;
}

export const saveEventSetupSettings = async ({customerId, settings}: SaveSettings) => {
  const url: string = `/defaultSettings/eventsetup/${customerId}`;
  const response = await axios.post(url, settings);
  return response.data;
}

export const saveIndoorLocationSettings = async ({customerId, settings}: SaveSettings) => {
  const url: string = `/defaultSettings/indoorlocation/${customerId}`;
  const response = await axios.post(url, settings);
  return response.data;
}

export const deleteIndoorLocation = async ({customerId, deleteId}: DeleteSettings) => {
  const url: string = `/defaultSettings/indoorlocation/${customerId}/${deleteId}`;
  const response = await axios.delete(url);
  return response.data;
}

export const saveGeofenceSettings = async ({customerId, settings}: SaveSettings) => {
  const url: string = `/defaultSettings/geofence/${customerId}`;
  const response = await axios.post(url, settings);
  return response.data;
}

export const deleteGeofence = async ({customerId, deleteId}: DeleteSettings) => {
  const url: string = `/defaultSettings/geofence/${customerId}/${deleteId}`;
  const response = await axios.delete(url);
  return response.data;
}

export const getPositionForCustomer = async (custId: number) => {
  const url: string = `/geofence/customer/position/${custId}`;
  const response = await axios.get(url);
  return response.data;
};


export const revertDeviceSettings = async (custId: number, dehaId: number) => {
  const url: string = `/defaultSettings/${custId}/revert/deviceSettings/${dehaId}`;
  const response = await axios.get(url);
  return response.data;
};

export const revertOtherSettings = async (custId: number) => {
  const url: string = `/defaultSettings/${custId}/revert/otherSettings`;
  const response = await axios.get(url);
  return response.data;
};
