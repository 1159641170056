import * as React from "react";
import { useIntl } from "react-intl";
import { Grid, Row, Col } from "react-bootstrap";
import SafemateHeader from "@Safemate/Header";
import EventSetupIcon from "@Elements/Icon/Icons/menu/EventSetup";
import Tooltip from "@Elements/Tooltip";
import InformationIcon from "@Icons/Information1";
import DeviceSelector from "./DeviceSelector";
import Phonenumber from "./Phonenumber";
import DeviceName from "./DeviceName";
import Log from "@Safemate/DeviceList/DeviceList/Actions/log";
import Settings from "@Safemate/DeviceList/DeviceList/Actions/settings";
import SimpleDevice from "../Model/SimpleDevice";
import { connect } from "react-redux";
import styled from "styled-components";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import { AppState } from "@Safemate/Store/types";

const EndCol = styled(Col)`
  display: flex;
`;

const FullInfoCol = styled(Col)`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

const MobileCol = styled(Col)`
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
`;

const mapStateToProps = ({ eventSetup: {device: { selectedDevice }}}: AppState) => {
  return {
    device: selectedDevice
  }
}

interface HeaderProps{
  device: SimpleDevice;
}

const Header = connect(mapStateToProps)(({device}: HeaderProps) => {
  
  const { formatMessage } = useIntl();

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  return(
    <Grid>
      <Row>
        <Col lg={3} md={12} sm={12}>
          <Row>
            <SafemateHeader
              centered
              icon={<EventSetupIcon/>}
              title={formatMessage({id: "eventSetup", defaultMessage: "Varslingsliste"})}
            >
            < Tooltip
                popoverId="event-setup-popover"
                labelId="tooltip_eventSetup"
                defaultMessage="Her angis hvordan varslingen av hendelser skal skje. Man kan velge mellom varsling på mobiltelefon (GSM) eller pr. epost. Man kan velge mellom å la mottakere være tilgjengelig kun i tider av uka og til visse tider av døgnet.

                Hjerte-ikonet åpner valg for pårørende.
                
                Stiplet hjerte indikerer at hendelsesmottakeren ikke er en pårørende."
              >
                <InformationIcon size="medium" />
              </Tooltip>
            </SafemateHeader>
          </Row>
        </Col>
        <FullInfoCol lg={3} md={3} sm={3} xs={3}>
          <Row>
            <DeviceSelector/>
          </Row>
          <Row>
            <Phonenumber/>
          </Row>
        </FullInfoCol>
        <MobileCol lg={12} md={12} sm={12} xs={12}>
          {device.deviceName + " (" + device.deviceSNNum +  ")(" + device.phoneNum + ")"}
        </MobileCol>
        <EndCol lg={6} md={9} sm={9}>
          <DeviceName/>
          <span>
            {permissions.get(PolicyNameEnum.DEVICE_ACCESS_MAP).generalPermissions.all && <Log deviceHardware={device.dehaId} deviceId={device.deviId} isTracker={false} lastMsgIn={device.lastMsgIn}/>}
            {permissions.get(PolicyNameEnum.DEVICE_SETTINGS).generalPermissions.all && <Settings deviceHardware={device.dehaId} deviceId={device.deviId}/>}
          </span>
        </EndCol>
      </Row>
    </Grid>
  )
})

export default Header;