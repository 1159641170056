import * as React from "react";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import ProcessingRequiredIcon from "@Icons/FontAwesome/ProcReq";
import { defineMessages, useIntl } from "react-intl";
import confirmModal from "@Safemate/Modals/confirmModal";
import { connect } from "react-redux";
import { Field, Form, Formik } from "formik";
import { getFunctionsForType, processSensor } from "@Safemate/Settings/Store/routines";
import withFormWrapper from "@Elements/Form/formWrapper";
import { CUSTOM_SENSOR_FUNCTION, getFunctionName, UNKNOWN } from "../SensorFunctionEnum";
import { getAvailableTypesForType, isSetTypeNeeded } from "../SensorTypeEnum";
import { HorizontalRow, SelectWrap } from "../styles";
import Dropdown from "@Elements/Dropdown/Dropdown";
import { Option } from "@Elements/Dropdown/types";
import { displayFunctionType, getForFunction } from "../SensorFunctionTypeEnum";
import { ISensorDTO, ISensorFunction } from "../types";
import Loader from "@Elements/Loader";
import { AppState } from "@Safemate/Store/types";
import { deconstructFunction, mapFunction } from "./utils";

const label = defineMessages({
  processDeviceText: {
    id: 'processDeviceText',
    defaultMessage: 'Behandle enhet:'
  },
  discovered: {
    id: 'discoveredText',
    defaultMessage: 'Denne enheten har blitt oppdaget på panelet.'
  },
  type: { id: 'walkTableType', defaultMessage: 'Type' },
  defaultText: { id: 'defaultChooseType', defaultMessage: 'Velg type' },
  functionLabel: { id: 'functionLabel', defaultMessage: 'Funksjon:' },
  chooseFunctionLabel: {
    id: 'chooseFunctionLabel',
    defaultMessage: 'Velg funksjon'
  },
  functionTypeLabel: {
    id: 'functionTypeLabel',
    defaultMessage: 'Funksjonstype:'
  },
  chooseFunctionTypeLabel: {
    id: 'chooseFunctionTypeLabel',
    defaultMessage: 'Velg funksjonstype'
  },
  descriptionLabel: { id: 'descriptionLabel', defaultMessage: 'Beskrivelse:' },
  cancelBtn: { id: 'universalCancelBtn', defaultMessage: 'Avbryt' },
  updateBtn: { id: 'universalUpdateBtn', defaultMessage: 'Oppdater' }
});

interface ProcessingRequiredProps{
  sensor: ISensorDTO;
  processSensor: Routine<ActionFunctionAny<Action<any>>>;
  getFunctionsForType: Routine<ActionFunctionAny<Action<any>>>;
  sensorFunctions: ISensorFunction[];
}


const mapFormStateToProps = ({ settings: {sensor: { sensorFunctions }}}: AppState) => {
  return {
    sensorFunctions,
  }
}

const mapDispatchToProps = {
  processSensor,
  getFunctionsForType
}

export interface ProcessRequiredInterface{
  deviceType: string;
  deviceFunction: string;
  funcType: string;
  name: string;
  active: string;
}

export const ProcessingRequired = connect(mapFormStateToProps, mapDispatchToProps)(withFormWrapper<ProcessRequiredInterface, ProcessingRequiredProps>((
  ({ sensor, sensorFunctions, processSensor, getFunctionsForType}) => {

  const { formatMessage } = useIntl();
  const { Comp, func } = confirmModal();
  const [open, setOpen] = React.useState(false);

  const codeFromId = (id: string) => {
    const func = sensorFunctions.find(func => {
      if(func.code === id) return true;
      return false
    })
    if(func) return func.code;
    return "-1";
  }

  return(
    <Formik
      enableReinitialize
      initialValues={{
        deviceType: sensor.type.code,
        deviceFunction: sensor.function.code,
        funcType: sensor.sensor.functionType,
        name: sensor.sensor.name,
        active: sensor.eventConfig && sensor.eventConfig.active
      }}
      onSubmit={(values) => {
        /*if(sensor.multiSensorId){
          processMultiSensor({
            sensorId: sensor.id,
            configId: sensor.eventConfigs.id,
            name: values.name,
            status: values.active
          })
        }
        else{*/
          processSensor({
            sensorId: sensor.sensor.deseId,
            name: values.name,
            function: values.deviceFunction,
            type: values.deviceType,
            funcType: values.funcType,
          })
        //}
      }}
    >{({ submitForm, values: { deviceFunction, deviceType, funcType } }) =>
      {

        const validFunction = !!sensorFunctions.find(func => func.code === deviceFunction);
        const validFuncType = !displayFunctionType(deviceType, codeFromId(deviceFunction)) || funcType;

        return(
          <React.Fragment>
            <ProcessingRequiredIcon 
              onClick={() => {
                func(true);
                getFunctionsForType(sensor.type.code);
                setOpen(true);
              }}
              medium
              animate
              title={formatMessage({ id: 'procReqActionTooltip', defaultMessage: 'Konfigurer sensor' })}
            />
            <Comp
              title={`${formatMessage(label.processDeviceText)} ${sensor.sensor.name} (${sensor.sensor.gatewayId})`}
              standardBtn
              body={
                <Form>
                  <ProcessingRequiredForm 
                    sensor={sensor} 
                    open={open} 
                    confirmFunc={() => {
                      if(validFunction && validFuncType){
                        submitForm();
                        func(false);
                        setOpen(false);
                      }
                    }}
                  />
                </Form>
              }
              confirmText={formatMessage(label.updateBtn)}
              disableConfirm={!validFunction || !validFuncType}
              confirmFunc={() => {
                if(validFunction){
                  submitForm();
                  func(false);
                  setOpen(false);
                }
              }}
            />
          </React.Fragment>
        )}
    }
  </Formik>
)
})))

interface ProcessingRequiredValues{
  deviceType: string;
  deviceFunction: string;
  funcType: string;
  name: string;
  active: boolean;
}

interface ProcessingRequiredFormProps{
  sensor: ISensorDTO;
  confirmFunc?: Function;
  open?:boolean;
  sensorFunctions: ISensorFunction[];
  getFunctionsForType: Routine<ActionFunctionAny<Action<any>>>;
  fetchingFunctions: boolean;
}

const mapFormStateToFormProps = ({ settings: {sensor: { sensorFunctions, fetchingFunctions }}}: AppState) => {
  return {
    sensorFunctions,
    fetchingFunctions
  }
}

const mapDispatchToFormProps = {
  getFunctionsForType
}

const ProcessingRequiredForm = connect(mapFormStateToFormProps, mapDispatchToFormProps)(withFormWrapper<ProcessingRequiredValues, ProcessingRequiredFormProps>(
  ({ getFunctionsForType, fetchingFunctions, sensor, confirmFunc, open, sensorFunctions, formik: { setFieldValue, values: { deviceType, deviceFunction, funcType } } }) => {

    const formRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
      const listener = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          event.preventDefault();
          if(open){
            if (formRef.current) {
              confirmFunc && confirmFunc();
            }
          }
        }
      };
      document.addEventListener("keydown", listener);
      return () => {
        document.removeEventListener("keydown", listener);
      };
    }, [open]);

    const { formatMessage } = useIntl();

    const mappedFunctions = React.useMemo(() => {

      let hasCustom = false;

      const functions = sensorFunctions.filter(func => func.code !== UNKNOWN).map(func => {
        const functionName = getFunctionName(sensor.type, func, formatMessage);
        if(!functionName) return null;

        if(func.code === CUSTOM_SENSOR_FUNCTION.code){
          hasCustom = true;
        }

        return {
          text: functionName,
          value: func.code
        }
      }).filter(func => func && func.value !== CUSTOM_SENSOR_FUNCTION.code) as Option[];

      if(hasCustom){
        const customOptions = getForFunction(CUSTOM_SENSOR_FUNCTION.code, formatMessage);
        return functions.concat(customOptions);
      }
      
      return functions;
    }, [sensorFunctions])

    const displayFunction = React.useMemo(() => {
      if(mappedFunctions && deviceFunction){
        return mappedFunctions.length > 1 || deviceFunction === UNKNOWN;
      }
      return true;
    }, [mappedFunctions, deviceFunction])

    return(
      <div ref={formRef}>
        <HorizontalRow>
          {formatMessage(label.discovered)}
        </HorizontalRow>
        {isSetTypeNeeded(sensor.type.code) && 
        <HorizontalRow>
          <p>{formatMessage(label.type)}</p>
          <SelectWrap>
            <Dropdown
              expand
              options={getAvailableTypesForType(deviceType, formatMessage)}
              initial={deviceType}
              size={{width: "200px"}}
              title={formatMessage(label.defaultText)}
              onChange={(option) => {
                const value = (option as Option).value;
                getFunctionsForType(value)
                setFieldValue("deviceType", value);
              }}
            />
          </SelectWrap>
        </HorizontalRow>}
        {fetchingFunctions 
          ? <Loader/>
          : <React.Fragment>
              {displayFunction &&
              <HorizontalRow>
                <p>{formatMessage(label.functionLabel)}</p>
                <SelectWrap>
                <Dropdown
                  expand
                  options={mappedFunctions}
                  initial={mapFunction(deviceFunction, funcType)}
                  size={{width: "200px"}}
                  title={formatMessage(label.chooseFunctionLabel)}
                  onChange={(option) => {
                    const opt = option as Option;

                    const {deviceFunction, funcType} = deconstructFunction(opt, formatMessage);


                    setFieldValue("deviceFunction", deviceFunction);
                    setFieldValue("funcType", funcType);
                  }}
                />
                </SelectWrap>
              </HorizontalRow>}
              {displayFunctionType(deviceType, deviceFunction) &&
              <HorizontalRow>
                <p>{formatMessage(label.functionTypeLabel)}</p>
                <SelectWrap>
                <Dropdown
                  expand
                  options={getForFunction(deviceFunction, formatMessage)}
                  initial={funcType}
                  size={{width: "200px"}}
                  title={formatMessage(label.chooseFunctionTypeLabel)}
                  onChange={(option) => {
                    const value = (option as Option).value;
                    setFieldValue("funcType", value);
                  }}
                />
                </SelectWrap>
              </HorizontalRow>}
            </React.Fragment>}
        <HorizontalRow>
          <p>{formatMessage(label.descriptionLabel)}</p>
          <Field type="text" name="name"/>
        </HorizontalRow>
        {/*sensor.multiSensorId && <HorizontalRow>
          {React.createElement(CheckWrap("active", {label: {id: "onOff", defaultMessage: "Av/På"}}))}
        </HorizontalRow>*/}
      </div>
    )
  }
))
