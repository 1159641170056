import _axios, {AxiosInstance} from 'axios';
import { IForgotPassword, ILogin, IResendVerification, IResetPassword } from './Saga/session';
import { RegisterFirmForm, RegisterUserForm } from '../Register/types';

// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';


// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---

export const validate = async (tokenId: string) => {
  const url = `/user/validation/${tokenId}`;
  const response = await axios.get(url);
  return response.data;
}

export const prePopulateEmail = async (tokenId: string) => {
  const url = `/user/getValidationEmail/${tokenId}`;
  const response = await axios.get(url);
  return response.data;
}

export const login = async (logs: ILogin) => {
  setBaseURL("/");
  const formData = new FormData();

// Add the properties from the login object to the FormData object
  formData.append('username', logs.username);
  formData.append('password', logs.password);
  formData.append('TWO_FACTOR_KEY', logs.TWO_FACTOR_KEY);
  const url = `/perform_login`;
  const response = await axios.post(url, logs, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      "Context": "PROFFWEB"
    }
  });
  setBaseURL("/ajax");
  return response.data;
}

export const logout = async () => {
  setBaseURL("/");
  const url = `logout`;
  const response = await axios.post(url);
  setBaseURL("/ajax");
    console.log( "Error in logout: " + JSON.stringify(response) );
  return response.data;
}

export const forgotPassword = async (logs: IForgotPassword) => {
  const url = `/user/forgot/${logs.username}`;
  const response = await axios.get(url);
  return response.data;
}

export const resetPassword = async (logs: IResetPassword) => {
  const url = `/user/resetpassword`;
  const response = await axios.post(url, JSON.stringify(logs));
  return response.data;
}

export const resendVerification = async (logs: IResendVerification) => {
  const url = `/user/resendverification/`;
  const response = await axios.post(url, logs.username);
  return response.data;
}

export const userValidation = async (user: RegisterUserForm) => {
  const url = `/user/validate`;
  const response = await axios.post(url,
    {
      ...user,
      languageCode: "nb_no",
      timezone: 0,
      mailTo: user.username,
      emailAsUsername: true,
      userId: 0
    });
  return response.data;
}

export const couponValidation = async (coupon: string) => {
  const url = `/customer/validateCouponCode/${coupon || ""}`;
  const response = await axios.get(url);
  return response.data;
}

export const registerUserPrivate = async (user: RegisterUserForm) => {
  const url = `/customer/new/${user.couponCode || ""}`;
  const response = await axios.post(url,
    {
      customer: {
        addrCity: "safematego",
        addrCounId: 1,
        addrSamesame: true,
        addrZipCode: "safematego",
        addressLine1: "safematego",
        allowDeviceDeletes: true,
        billingAddrCounId: 1,
        billingAddrLine1: "safematego",
        company: false,
        defaultAhp: 1,
        firstName: user.firstName,
        forceCoverage: false,
        forceIncomingRing: false,
        forceIncomingRingAlways: true,
        forceTfa: false,
        lastName: user.lastName,
        orgNum: "",
        parent: 0,
        privateUser: true
      },
      user: {
        ...user,
        mailTo: user.username,
        emailAsUsername: true,
        tfaEnabled: false
      }
    });
  return response.data;
}


export const customerNameValidation = async (name: string) => {
  const url = `/customer/validateOrgName/${name}`;
  const response = await axios.get(url);
  return response.data;
}

export const customerValidation = async (customer: RegisterFirmForm) => {
  const url = `/customer/validate/`;
  const response = await axios.post(url,
    {
        addrCity: customer.city, 
        addrCounId: 1, 
        addressLine1: customer.addrLine1, 
        addressLine2: customer.addrLine2, 
        addrReference: customer.reference, 
        addrSamesame: true, 
        addrZipCode: customer.zip, 
        billingAddrCity: customer.city,
        billingAddrCounId: 1, 
        billingAddrLine1: customer.addrLine1, 
        billingAddrLine2: customer.addrLine2, 
        billingAddrReference: customer.reference, 
        billingAddrZipCode: customer.zip, 
        company: true, 
        forceCoverage: false, 
        forceIncomingRing: false, 
        forceIncomingRingAlways: true,
        forceTfa: false, 
        lastName: customer.companyName, 
        orgNum: customer.organizationNo, 
        parent: 0
    });
  return response.data;
}

export const registerCustomer = async (customer: RegisterFirmForm) => {
  const url = `/customer/new/`;
  const response = await axios.post(url,
    {
      user: {
        emailAsUsername: true, 
        firstName: customer.firstName, 
        lastName: customer.lastName, 
        mobile: customer.mobile, 
        username: customer.username, 
        mailTo: customer.username, 
        passwordClear: customer.confirmPassword, 
        tfaEnabled: false 
      }, 
      customer: { 
        addrCity: customer.city, 
        addrCounId: 1,
        addrName: customer.companyName, 
        addressLine1: customer.addrLine1, 
        addressLine2: customer.addrLine2, 
        addrReference: customer.reference, 
        addrSamesame: true, 
        addrZipCode: customer.zip, 
        billingAddrCity: customer.city,
        billingAddrCounId: 1, 
        billingAddrLine1: customer.addrLine1, 
        billingAddrLine2: customer.addrLine2, 
        billingAddrReference: customer.reference, 
        billingAddrZipCode: customer.zip, 
        company: true, 
        forceCoverage: false, 
        forceIncomingRing: false, 
        forceIncomingRingAlways: true,
        forceTfa: false, 
        lastName: customer.companyName, 
        orgNum: customer.organizationNo, 
        parent: 0, 
        privateUser: false, 
        allowDeviceDeletes: true
      }
    });
  return response.data;
}

export const getTexts = async () => {
  const url = "/text/forLogin";
  const response = await axios.get(url);
  return response.data;
}

export const sendGdpr = async () => {
  const url = "/user/sendEmailGdpr";
  const response = await axios.get(url);
  return response.data;
}

export const acceptGdpr = async (username: string) => {
  const url = "/user/gdpr/ps/true";
  const response = await axios.post(url, {username});
  return response.data;
}
