import {createRoutine, Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import {AppAction} from "../../DefaultSettings/Store/types";
import {UserToDelete} from "./Saga/actions";
import {UserWithCustomer} from "../Models/UserWithCustomer";

// Initialization
export const initialize: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerBilling/INITIALIZE'
);

export const fetchInvoices: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerBilling/FETCH_INVOICES'
);

export const initializeMunicipalities: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerBilling/INITIALIZE_MUNICIPALITIES'
);

export const setCustomersClickedOpen: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerBilling/SET_CUSTOMERS_CLICKED_OPEN'
);

export const initializeUsers: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerBilling/INITIALIZE_USERS'
);

export const initializeDeletedUsers: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerBilling/INITIALIZE_DELETED_USERS'
);

export const initializeLdapUsers: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerBilling/INITIALIZE_LDAP_USERS'
);

export const initializeRoles: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerBilling/INITIALIZE_ROLES'
);

export const initializeCustomers: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerBilling/INITIALIZE_CUSTOMERS'
);

export const initializeAdminRole: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerBilling/INITIALIZE_ADMIN_ROLE'
);

export const initializePrivate: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerBilling/INITIALIZE_PRIVATE'
);

export const initializeAhpRoles: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerBilling/INITIALIZE_AHP_ROLES'
);
//Actions
export const deleteUser: Routine<ActionFunctionAny<AppAction<UserToDelete>>> = createRoutine(
  '@@PartnerBilling/DELETE_USER'
);

export const saveUser: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerBilling/SAVE_USER'
);

export const sendNewPassword: Routine<ActionFunctionAny<AppAction<UserWithCustomer>>> = createRoutine(
  '@@PartnerBilling/SEND_NEW_PASSWORD'
);

export const setDownloading: Routine<ActionFunctionAny<AppAction<UserWithCustomer>>> = createRoutine(
  '@@PartnerBilling/SET_DOWNLOADING'
);


export const initializeInvoices: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerBilling/INITIALIZE_INVOICES'
);

export const getCsv: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerBilling/GET_CSV'
);

export const setCsvProcessing: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerBilling/SET_CSV_PROCESSING'
);