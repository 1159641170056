import * as React from "react";
import GoogleMap, {BootstrapURLKeys, Maps} from "google-map-react";
import styled from "styled-components";
import {Row} from "react-bootstrap";
import { MapWrapper, isValid } from "./mapWrapper";
import { Position } from "../Store/types";
import { connect } from "react-redux";
import {User} from "../../UserAdmin/Models/User";
import {getGoogleMapLanguageCode} from "../utils";
import { Routine } from "redux-saga-routines";
import { ActionFunctionAny, Action } from "redux-actions";
import { Geofence,  } from "../../Settings/Geofence/types";
import { setGeofenceType, SetGeofenceType, setControlState, SetControlState } from "../../Settings/Store/actions";
import {getPositions, getAllDevices} from "@Safemate/map/Store/routines";
import { AppState } from "@Safemate/Store/types";
import { MapType } from "..";


const MapDivWrapper = styled(Row)`
  height: 40em;
  padding: 0px;
  margin: 0px 0px;
  position: relative;
`;

interface MapProps{
  positions: Position[];
  user: User;
  initialFences: Geofence[];
  geofence: Geofence[];
  id: number;
  deviceId: number;
  setGeofenceType: SetGeofenceType;
  setControlState: SetControlState;
  mapType: MapType;
  getPositions: Routine<ActionFunctionAny<Action<any>>>;
  getAllDevices: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToProps = ({ transactionLog: {transaction: { positions }, initialization: {user}, device:{id}, geofence:{initialFences, geofence}}}: AppState) => {
  return {
    positions,
    user,
    id,
    initialFences,
    geofence
  }
}
const mapDispatchToProps = {
  setGeofenceType,
  setControlState,
  getPositions,
  getAllDevices
}

const Map = connect(mapStateToProps, mapDispatchToProps)(({mapType, initialFences, geofence, positions, user, getPositions, getAllDevices, deviceId}: MapProps) => {
  let urlKeys: BootstrapURLKeys;
  let mapWrapper: MapWrapper = React.useMemo(() => {
      return new MapWrapper();
    }, [])


  const getValidPositions = (positions: Position[]) => {
    let firstPos = true;
    const validPositions = positions.map(pos => {
      if(isValid(pos)){
        if(firstPos){
          pos.drawMarker = true;
          firstPos = false;
        }
        return pos;
      }
    }).filter(pos => pos) as Position[];
    return validPositions;
  }

    React.useEffect(() => {
      mapWrapper.setInitialFences(initialFences);
    }, [initialFences])

    React.useEffect(() => {
      mapWrapper.setFences(geofence);
    }, [geofence])


    React.useEffect(() => {
      urlKeys = {key: "AIzaSyAQxbShd7veF5B0YU9O_uYPOQFCOHoe8no",
        language: `${getGoogleMapLanguageCode(user)}`}
    }, [])

    React.useEffect(() => {
      if(mapWrapper.isMapInitialized()){
        mapWrapper.setPositions(getValidPositions(positions));
      }
    }, [positions])

  let languageCode = getGoogleMapLanguageCode(user);
    return(
      <MapDivWrapper>
        <GoogleMap
          defaultZoom={4}
          defaultCenter={{ lat: 59.5058648, lng: 9.1907483 }}
          bootstrapURLKeys={{key: JSON.stringify({
            v: 3,
            key: "AIzaSyAQxbShd7veF5B0YU9O_uYPOQFCOHoe8no",
            channel: 'customer',
            language: 'no',
          }),
            language: 'no',
          }}
          options={(map: Maps) => {
            return {
              streetViewControl: true,
              mapTypeControl: true,
              maxZoom: 20,
              mapTypeControlOptions: {
                mapTypeIds: [
                  map.MapTypeId.ROADMAP,
                  map.MapTypeId.SATELLITE,
                  map.MapTypeId.TERRAIN
                ]
              }
            }
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({map, maps}) => {
            mapWrapper.init(map, maps, initialFences, mapType);
            if(mapType === MapType.SINGLE){
              mapWrapper.createFences(geofence);
              mapWrapper.resetFences();
              getPositions({deviceId: deviceId});
            }
            else if(mapType === MapType.ALL){
              getAllDevices();
            }
          }}
        >
        </GoogleMap>
      </MapDivWrapper>
    )
}
)

export default Map;
