import * as React from "react";
import {PolicyNameEnum} from "../PolicyNameEnum";
import {ICustomerWithPolicy} from "@Safemate/Model/Customer";
import {AhpRoles} from "./GlobalRoleName";
import {accessToCustomer} from "./rightsUtil";
import {GlobalRoles} from "@Safemate/GlobalRoles";

export const PolicyContext = React.createContext({});

export const PolicyConsumer = PolicyContext.Consumer;

export function withPermissions<T, S>(getPermissions:(...args: any[]) => Map<PolicyNameEnum, T>){
  return ({children, ...rest}: React.PropsWithChildren<S>) =>
      <PolicyContext.Provider value={getPermissions(rest)}>
        {React.Children.only(children)}
      </PolicyContext.Provider>
}

export interface Permission{
  edit?: boolean;
  read?: boolean;
  all?: boolean;
  [index: string]: boolean | undefined;
}

export type AllPermissions = {
  customerPermissions: CustomerPermission;
  generalPermissions: Permission;
}

export type UserPermissions = {
  [index: number]: Permission;
}

export type CustomerPermission = {
  [index: number]: Permission;
}

interface GetPermissions{
  customers: ICustomerWithPolicy[];
  ahpRoles: AhpRoles;
}


const getPermissions = ({customers, ahpRoles}: GetPermissions) => {

  const shorthand = (policy: PolicyNameEnum, globals: GlobalRoles[], information?: Array<string>) => {
    return generatePermissionsObject(customers, policy, ahpRoles, globals, information);
  }
  return new Map<PolicyNameEnum, AllPermissions>(
    [
      [PolicyNameEnum.ADMIN, shorthand(PolicyNameEnum.ADMIN, [GlobalRoles.SUPERFIRM])],

      // Default settings
      [PolicyNameEnum.DEFAULT_SETTINGS,
        shorthand(PolicyNameEnum.DEFAULT_SETTINGS, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM], ["all", "edit", "read"])
      ],
      [PolicyNameEnum.DEFAULT_COMMUNICATION,
        shorthand(PolicyNameEnum.DEFAULT_COMMUNICATION, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM], ["all", "edit", "read"])
      ],
      [PolicyNameEnum.DEFAULT_NOT_USED,
        shorthand(PolicyNameEnum.DEFAULT_NOT_USED, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM], ["all", "edit", "read"])
      ],
      [PolicyNameEnum.DEFAULT_POSITIONING,
        shorthand(PolicyNameEnum.DEFAULT_POSITIONING, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM], ["all", "edit", "read"])
      ],
      [PolicyNameEnum.DEFAULT_TRACKING,
        shorthand(PolicyNameEnum.DEFAULT_TRACKING, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM], ["all", "edit", "read"])
      ],
      [PolicyNameEnum.DEFAULT_LIGHT_SOUND,
        shorthand(PolicyNameEnum.DEFAULT_LIGHT_SOUND, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM], ["all", "edit", "read"])
      ],
      [PolicyNameEnum.DEFAULT_BATTERY,
        shorthand(PolicyNameEnum.DEFAULT_BATTERY, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM], ["all", "edit", "read"])
      ],
      [PolicyNameEnum.DEFAULT_GENERAL,
        shorthand(PolicyNameEnum.DEFAULT_GENERAL, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM], ["all", "edit", "read"])
      ],
      [PolicyNameEnum.DEFAULT_PRIVACY,
        shorthand(PolicyNameEnum.DEFAULT_PRIVACY, [GlobalRoles.SUPERFIRM], ["all", "edit", "read"])
      ],
      [PolicyNameEnum.DEFAULT_FIRMWARE,
        shorthand(PolicyNameEnum.DEFAULT_FIRMWARE, [GlobalRoles.SUPERFIRM], ["all", "edit", "read"])
      ],
      [PolicyNameEnum.DEFAULT_WHITELIST,
        shorthand(PolicyNameEnum.DEFAULT_WHITELIST, [GlobalRoles.SUPERFIRM], ["all", "edit", "read"])
      ],
      [PolicyNameEnum.DEFAULT_INTEGRATION,
        shorthand(PolicyNameEnum.DEFAULT_INTEGRATION, [GlobalRoles.SUPERFIRM], ["all", "edit", "read"])
      ],
      [PolicyNameEnum.DEFAULT_EVENT_SETUP,
        shorthand(PolicyNameEnum.DEFAULT_EVENT_SETUP, [GlobalRoles.SUPERFIRM], ["all", "edit", "read"])
      ],
      [PolicyNameEnum.DEVICE_INDOOR_CUSTOMER,
        shorthand(PolicyNameEnum.DEVICE_INDOOR_CUSTOMER, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM], ["all", "edit", "read"])
      ],
      [PolicyNameEnum.CUSTOMER_GEOFENCE,
        shorthand(PolicyNameEnum.CUSTOMER_GEOFENCE, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM], ["all", "edit", "read"])
      ],
      // User admin
      [PolicyNameEnum.USER_PRIVS,
        shorthand(PolicyNameEnum.USER_PRIVS, [GlobalRoles.SUPERFIRM], ["all", "add", "read"])
      ],
      [PolicyNameEnum.AUDIT_LOG,
        shorthand(PolicyNameEnum.AUDIT_LOG, [GlobalRoles.SUPERFIRM])
      ],
      [PolicyNameEnum.PROMOTE_TEMP,
        shorthand(PolicyNameEnum.PROMOTE_TEMP, [GlobalRoles.SUPERFIRM])
      ],
      [PolicyNameEnum.ACTIVATE,
        shorthand(PolicyNameEnum.ACTIVATE, [GlobalRoles.SUPERFIRM])
      ],
      [PolicyNameEnum.DELETED_USERS,
        shorthand(PolicyNameEnum.DELETED_USERS, [GlobalRoles.SUPERFIRM])
      ],
      [PolicyNameEnum.LAST_LOGIN,
        shorthand(PolicyNameEnum.LAST_LOGIN, [GlobalRoles.SUPERFIRM])
      ],
      [PolicyNameEnum.ACCESS_TO_ALL,
        shorthand(PolicyNameEnum.ACCESS_TO_ALL, [GlobalRoles.SUPERFIRM])
      ],
      [PolicyNameEnum.DEVICE_ACCESS_MAP,
        shorthand(PolicyNameEnum.DEVICE_ACCESS_MAP, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.DEVICE_TRANSACTION_LOG,
        shorthand(PolicyNameEnum.DEVICE_TRANSACTION_LOG, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.DEVICE_LOGS,
        shorthand(PolicyNameEnum.DEVICE_LOGS, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.DEVICE_SETTINGS,
        shorthand(PolicyNameEnum.DEVICE_SETTINGS, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.GENERAL_SETTINGS,
        shorthand(PolicyNameEnum.GENERAL_SETTINGS, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.DEVICE_NOTE,
        shorthand(PolicyNameEnum.DEVICE_NOTE, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.DEVICE_TRACKING,
        shorthand(PolicyNameEnum.DEVICE_TRACKING, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.EVENT_SETUP,
        shorthand(PolicyNameEnum.EVENT_SETUP, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.GSM_RECIPIENTS,
        shorthand(PolicyNameEnum.GSM_RECIPIENTS, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER], ["all", "read"])
      ],
      [PolicyNameEnum.EMAIL_RECIPIENTS,
        shorthand(PolicyNameEnum.EMAIL_RECIPIENTS, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER], ["all", "read"])
      ],
      [PolicyNameEnum.NEXT_OF_KIN_CHECK,
        shorthand(PolicyNameEnum.NEXT_OF_KIN_CHECK, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.NEXT_OF_KIN_TRACKING,
        shorthand(PolicyNameEnum.NEXT_OF_KIN_TRACKING, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.NEXT_OF_KIN_SCHEDULE,
        shorthand(PolicyNameEnum.NEXT_OF_KIN_SCHEDULE, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.NEXT_OF_KIN_CALL,
        shorthand(PolicyNameEnum.NEXT_OF_KIN_CALL, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.NEXT_OF_KIN_IMAGE,
        shorthand(PolicyNameEnum.NEXT_OF_KIN_IMAGE, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.ALARM_CENTER,
        shorthand(PolicyNameEnum.ALARM_CENTER, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.CONTROL_CALLING,
        shorthand(PolicyNameEnum.CONTROL_CALLING, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.DEVICE_NAME_CHANGE,
        shorthand(PolicyNameEnum.DEVICE_NAME_CHANGE, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.EDIT_SUB_ORGS,
        shorthand(PolicyNameEnum.EDIT_SUB_ORGS, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.DEVICE_ORGANIZATION,
        shorthand(PolicyNameEnum.DEVICE_ORGANIZATION, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.DEVICE_GEOFENCE,
        shorthand(PolicyNameEnum.DEVICE_GEOFENCE, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.DEVICE_INFO,
        shorthand(PolicyNameEnum.DEVICE_INFO, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.DEVICE_INDOOR,
        shorthand(PolicyNameEnum.DEVICE_INDOOR, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.DEVICE_SENSOR,
        shorthand(PolicyNameEnum.DEVICE_SENSOR, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.DEVICE_LIGHT_SOUND,
        shorthand(PolicyNameEnum.DEVICE_LIGHT_SOUND, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.SAFEMATE_GENERAL,
        shorthand(PolicyNameEnum.SAFEMATE_GENERAL, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.SAFEMATE_DEVICE_LOGS,
        shorthand(PolicyNameEnum.SAFEMATE_DEVICE_LOGS, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.SAFEMATE_DELETE_DATA,
        shorthand(PolicyNameEnum.SAFEMATE_DELETE_DATA, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.SAFEMATE_POSITIONING,
        shorthand(PolicyNameEnum.SAFEMATE_POSITIONING, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.SAFEMATE_BATTERY_TRESHHOLD,
        shorthand(PolicyNameEnum.SAFEMATE_BATTERY_TRESHHOLD, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.SAFEMATE_ALARM,
        shorthand(PolicyNameEnum.SAFEMATE_ALARM, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.SAFEMATE_MAN_DOWN,
        shorthand(PolicyNameEnum.SAFEMATE_MAN_DOWN, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.SAFEMATE_HEART_RATE_BLOOD_PRESSURE,
        shorthand(PolicyNameEnum.SAFEMATE_HEART_RATE_BLOOD_PRESSURE, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.GX8_GENERAL,
        shorthand(PolicyNameEnum.GX8_GENERAL, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.GX8_COMMUNICATION,
        shorthand(PolicyNameEnum.GX8_COMMUNICATION, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.GX8_BATTERY,
        shorthand(PolicyNameEnum.GX8_BATTERY, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.GX8_INFORMATION,
        shorthand(PolicyNameEnum.GX8_INFORMATION, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.GX8_LIGHT_SOUND,
        shorthand(PolicyNameEnum.GX8_LIGHT_SOUND, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.GX8_LOGS_REPORT,
        shorthand(PolicyNameEnum.GX8_LOGS_REPORT, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.TWIN_DEVICE,
        shorthand(PolicyNameEnum.TWIN_DEVICE, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.SUB_ORGS,
        shorthand(PolicyNameEnum.SUB_ORGS, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.PARTNER,
        shorthand(PolicyNameEnum.PARTNER, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
      [PolicyNameEnum.PARTNER_PRICES,
        shorthand(PolicyNameEnum.PARTNER_PRICES, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.RESTRICTED_USER])
      ],
    ]
  )
};

const generatePermissionsObject = (customers: ICustomerWithPolicy[], policy: PolicyNameEnum, ahpRoles: AhpRoles, globals: GlobalRoles[], information?: string[]) => {
  const allPermissions: AllPermissions = {
    customerPermissions: {},
    generalPermissions: {}
  };

  const alwaysAccess: boolean = ahpRoles && (ahpRoles.SUPERAHP || ahpRoles.AHP);

  if( customers !== undefined){
    customers.forEach(({customerId, policies}: ICustomerWithPolicy) => {
      const customerPermission: Permission = {};

      if(information){
        information.forEach((infoPermission: string) => {
          customerPermission[infoPermission] = alwaysAccess || accessToCustomer(policies, policy, globals, [infoPermission]);
          allPermissions.generalPermissions[infoPermission] = allPermissions.generalPermissions[infoPermission] || customerPermission[infoPermission];
        })
      }

      else{
        customerPermission.all = alwaysAccess || accessToCustomer(policies, policy, globals);
        allPermissions.generalPermissions.all = allPermissions.generalPermissions.all || customerPermission.all;
      }

      allPermissions.customerPermissions[customerId] = customerPermission;
    });
  }

  if(alwaysAccess){
    allPermissions.generalPermissions.all = true;
  }
  return allPermissions;
}

export default withPermissions<AllPermissions, GetPermissions>(getPermissions);
