import { MessageDescriptor } from "react-intl";
import {Customer, CustomerWithChildren} from "./Models/Customer";
import {Role} from "./Models/Role";
import {Option} from "../DefaultSettings/Settings/FieldWrapper";
import {User} from "./Models/User";
import { TreeDropdownOption } from "@Elements/Dropdown/types";
import { CustomerPermission } from "@Safemate/Policy/Provider";

export type TranslateOptions = (items: Array<any>, formatMesssage: (message: MessageDescriptor) => string) => Array<any>;
export type MapRoles = (roles: Array<Role>) => Array<Option>;
export type RecursiveCustomer = (children: Array<CustomerWithChildren>, check:boolean) => Array<TreeDropdownOption>;

export const translateOptions: TranslateOptions = (items, formatMessage) => {
  const translatedItems: Array<any> = [];
  items.forEach(item => {
    translatedItems.push({...item, text: formatMessage({id: item.text, defaultMessage: item.text})});
  })
  return translatedItems;
}

export const translateOptionsExport: TranslateOptions = (items, formatMessage) => {
  const translatedItems: Array<String> = [];
  items.forEach(item => {
    if( item.export === undefined || item.export ){
      translatedItems.push(formatMessage({id: item.text, defaultMessage: item.text}).toUpperCase());
    }    
  })
  return translatedItems;``
}

export const mapCustomersForUser = (selectedCustomers: Customer[], customers: CustomerWithChildren, permissions: CustomerPermission, permissionTypes: string[]) => {

  const selectedCustIds = selectedCustomers.map(cust => cust.custId);

  const recursive: RecursiveCustomer = (children, check) => {

    return children.map((child: CustomerWithChildren) => {

      let mappedChildren: TreeDropdownOption[] | null = null;

      let disabled = true;

      permissionTypes.forEach(perm => {
        if(permissions[child.customer.custId] && permissions[child.customer.custId][perm]){
          disabled = false;
        }
      })

      const checked: boolean = selectedCustIds.includes(child.customer.custId) || check;
      const locked: boolean = check;

      if(child.children){
        mappedChildren = recursive(child.children, checked);
      }

      const showChildren: boolean =
        !!mappedChildren &&
        !!mappedChildren
          .find((mappedChild: TreeDropdownOption) =>
            mappedChild.checked || (mappedChild.disabled && mappedChild.showChildren) || (!mappedChild.disabled && disabled)
          );

      const option: TreeDropdownOption = {
        disabled,
        checked,
        locked,
        showChildren,
        key: child.customer.custId.toString(),
        name: child.customer.firmName,
        children: mappedChildren
      }

      return option;

    }) as TreeDropdownOption[];
  }

  if(customers.children)
    return recursive(customers.children, false);
  return [];
}

export const user = (): User =>{
  return {
    userId: 0,
    active: false,
    deactivated: false,
    tempDeactivated: false,
    trained: false,
    name: "",
    firstName: "",
    lastName: "",
    username: "",
    mobile: "",
    email: "",
    mailTo: "",
    coveredByTwoFactorAuthentication: false,
    trainingApprovedByName: "",
    trainingApprovedBy: 0,
    userType: 0,
    deactivatedFrom: "",
    deactivatedTo: "",
    lastActivity: 0,
    emailAsUsername: false,
    newUserFlag: false,
    mapConsent: false,
    lastSso: "",
    languageCode: "en",
    beta: false
  }
}
