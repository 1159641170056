import FieldWrapper, {GenericCheckbox, GenericDropdown} from "../FieldWrapper";
import {SettingEnum, SettingPrefixEnum} from "../settingEnum";
import {trackingInterval} from "./options";
import {LabelEnum} from "../../LabelEnum";

// Dropdowns
export const TrackingInterval = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {
      units: {
        id: 'dropdownMinutes',
        defaultMessage: 'Minutter'
      }
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.TRACKING,
  field: SettingEnum.TRACKING_INTERVAL,
  title: LabelEnum.TRACKING_INTERVAL,
  dataOptions: {
    options: trackingInterval,
    skipMap: true
  }
});


// Checkboxes
export const AutoTracking = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: false,
      label: {id: LabelEnum.AUTO_TRACKING, defaultMessage: "Automatisk lokalisering"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.TRACKING,
  field: SettingEnum.AUTO_TRACKING
});

export const GEOLOCATION_GOOGLE = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: true,
      label: {id: LabelEnum.GEOLOCATION_WIFI_LOOKUP_GOOGLE, defaultMessage: "Google geolocation lookup based on WIFI"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.TRACKING,
  field: SettingEnum.GEOLOCATION_WIFI_LOOKUP_GOOGLE
});

export const GEOLOCATION_COMBAIN = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: true,
      label: {id: LabelEnum.GEOLOCATION_WIFI_LOOKUP_COMBAIN, defaultMessage: "Combain geolocation lookup based on WIFI"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.TRACKING,
  field: SettingEnum.GEOLOCATION_WIFI_LOOKUP_COMBAIN
});

export const ACCEPT_LBS = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: false,
      label: {id: LabelEnum.ACCEPT_LBS, defaultMessage: "Geolocation lookup based on LBS"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.TRACKING,
  field: SettingEnum.ACCEPT_LBS
});
