
import * as React from "react";
import { Row, Col } from "react-bootstrap";
import Plus from "../../../Elements/Icon/Icons/Safemate/solid/PlusCircle";
import Dropdown from "../../../Elements/Dropdown/Dropdown";
import styled from 'styled-components';
import { connect } from "react-redux";
import { Option } from '../../../Safemate/DefaultSettings/Settings/FieldWrapper';
import { getAlarmFilter, translateDeviceTypes, getOrderFilter } from './utils';
import { AlarmFilterEnum } from '../AlarmFilterEnum';
import { 
  filterAlarm, 
  filterCustomer, 
  filterDeviceType, 
  filterOrder,
  FilterAlarm, 
  FilterCustomer, 
  FilterDeviceType,
  FilterOrder
} from '../Store/actions';
import { LabelEnum } from "../LabelEnum";
import { OrderFilterEnum } from "../OrderFilterEnum";
import { AppState } from "@Safemate/Store/types";
import { ICustomerWithPolicy } from "@Safemate/Model/Customer";
import { IDevice } from "@Safemate/Model/Device";
import { useIntl } from "react-intl";

interface HeaderProps{
  deviceTypes: Option[];
  customers: ICustomerWithPolicy[];
  selectedDeviceTypes: number[];
  selectedCustomers: number[];
  selectedAlarmFilters: AlarmFilterEnum[];
  selectedOrderFilter: OrderFilterEnum;
  filterAlarm: FilterAlarm,
  filterDeviceType: FilterDeviceType;
  filterCustomer: FilterCustomer;
  filterOrder: FilterOrder;
  filteredDevices: IDevice[];
  devices: IDevice[];
  isPrivate: boolean;
}

const StyledCol = styled(Col)`
  padding: 15px 0;
`
const SmallWrap = styled.div`
  @media (max-width: 768px) {
    margin: 0 !important;
  }
`

const FirstWrap = styled(SmallWrap)`
  margin-right: 10px;
`
const SecondWrap = styled(SmallWrap)`
  margin: 0 5px;
`
const ThirdWrap = styled(SmallWrap)`
  margin-left: 10px;
`

const FourthWrap = styled(SmallWrap)`
  margin-left: 10px;
  ul label {
    padding: 0px;
  }
`

const DeviceInfoHeader = styled.h2`
  margin-bottom: 0px;
`;

const DeviceInfoSpan = styled.span`
  display:flex;
`;

const mapStateToProps = (
  {
    deviceList: {
      device: {deviceTypes, filteredDevices, devices},
      filter: {deviceTypes: selectedDeviceTypes, customers: selectedCustomers, alarmFilters: selectedAlarmFilters, orderFilter: selectedOrderFilter}
    },
    appData: {
      isPrivate,
      customersWithPolicy
    }
  }: AppState) => {
  return{
    deviceTypes,
    selectedDeviceTypes,
    selectedCustomers,
    selectedAlarmFilters,
    selectedOrderFilter,
    customers: customersWithPolicy,
    devices,
    filteredDevices,
    isPrivate
  }
}

const mapDispatchToProps = {
  filterDeviceType,
  filterAlarm,
  filterCustomer,
  filterOrder
}

export default connect(mapStateToProps, mapDispatchToProps)(
  ({
    filterAlarm, 
    filterDeviceType, 
    filterCustomer, 
    filteredDevices,
    filterOrder, 
    customers, 
    devices, 
    deviceTypes, 
    selectedDeviceTypes,  
    selectedCustomers,
    selectedAlarmFilters,
    selectedOrderFilter,
    isPrivate
  }: HeaderProps) => {

  const { formatMessage } = useIntl();

  const mappedDeviceTypes = React.useMemo(() => {
    return translateDeviceTypes(deviceTypes, formatMessage);
  }, [deviceTypes])

  const noEmptyMappedCustomers: ICustomerWithPolicy[] = [];
  const mappedCustomers = React.useMemo(() => {

    const deviCount: {[index: number]: number} = {};

    devices.forEach(device => {
      deviCount[device.ownedByCustId] ? (deviCount[device.ownedByCustId]++) : (deviCount[device.ownedByCustId] = 1)
    })
    customers.forEach(customer =>{
      if(deviCount[customer.customerId] > 0){
        noEmptyMappedCustomers.push(customer);
      }
    })

    noEmptyMappedCustomers.sort(function(a,b){
      if(a.customerName < b.customerName) {return -1; }
      if(a.customerName > b.customerName) { return 1; }
      return 0;
    })

    return noEmptyMappedCustomers.map((customer: ICustomerWithPolicy) => {
        return{
          text: `${customer.customerName} (${deviCount[customer.customerId] || 0})`,
          value: customer.customerId
        }
    })
  }, [customers, devices])
  return(
    <React.Fragment>
      <Row>
        {!isPrivate &&
        <h1>
          {`${formatMessage({id: LabelEnum.SAFEMATES, defaultMessage: "Antall Safemate"})}: ${filteredDevices.length}`}
        </h1>
        }
        
      </Row>
      {isPrivate && devices.length == 0 &&
        <Row>
          <DeviceInfoHeader>
            <DeviceInfoSpan>
               {`${formatMessage({id: "addingDeviceInformation", defaultMessage: "For å legge til enheter, trykk "})} `}
              <Plus/>
            </DeviceInfoSpan>
          </DeviceInfoHeader>  
        </Row>
        }
      {!isPrivate && devices.length > 1 && <Row>
        <StyledCol lg={3} md={3} sm={3} xs={12}>
          <FirstWrap>
            <Dropdown
              multi
              enableReset
              initial={selectedCustomers}
              options={mappedCustomers}
              title={formatMessage({id: LabelEnum.SELECT_CUSTOMER, defaultMessage: "Alle underavdelinger"})}
              onChange={option => {
                const selected = (option as Option[]).map(item => item.value);
                filterCustomer(selected);
              }}
            />
          </FirstWrap>
        </StyledCol>
        <StyledCol lg={3} md={3} sm={3} xs={12}>
          <SecondWrap>
            <Dropdown
              multi
              enableReset
              initial={selectedDeviceTypes}
              options={mappedDeviceTypes}
              title={formatMessage({id: LabelEnum.SELECT_DEVICE_TYPE, defaultMessage: "Alle enhetstyper"})}
              onChange={(option) => {
                const selected = (option as Option[]).map(item => item.value);
                filterDeviceType(selected);
              }}
            />
          </SecondWrap>
        </StyledCol>
        <StyledCol lg={3} md={3} sm={3} xs={12}>
          <ThirdWrap>
            <Dropdown
              multi
              enableReset
              initial={selectedAlarmFilters}
              options={getAlarmFilter(selectedDeviceTypes, formatMessage)}
              title={formatMessage({id: LabelEnum.SELECT_ALARM_FILTER, defaultMessage: "Alle tilstander"})}
              onChange={option => {
                const selected = (option as Option[]).map(item => item.value);
                filterAlarm(selected);
              }}
            />
          </ThirdWrap>
        </StyledCol>
        <StyledCol lg={3} md={3} sm={3} xs={12}>
          <FourthWrap>
            <Dropdown
              enableReset
              initial={selectedOrderFilter}
              options={getOrderFilter(formatMessage)}
              title={formatMessage({id: LabelEnum.DEVICE_LIST_SORT_PRO, defaultMessage: "Standardsortering"})}
              onChange={option => {
                const selected = (option as Option).value;
                filterOrder(selected);
              }}
            />
          </FourthWrap>
        </StyledCol>
      </Row>}
    </React.Fragment>
  )
});